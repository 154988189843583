import classNames from "classnames";
import { useRef } from "react";

import { BdsButton, BdsIcon, BdsTooltip } from "@buildertrend/components";

import { CommonConstants } from "types/constants";

import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import "commonComponents/entity/media/MediaViewingPermissionReadonly/MediaViewingPermissionReadonly.less";

export interface IMediaViewingPermissionReadonlyProps {
    showOwner: boolean;
    showSubs: boolean;
    showAllSubs?: boolean;
    entityId: number;
    entityType: "folder" | "file";
    justify?: "start" | "end" | "center" | "space-around" | "space-between";
    noWrap?: boolean;
    className?: string;
    canSetPermissions?: boolean;
    showCanAdd?: boolean;
    onEditPermissionsClick?: (id: number) => void;
}

function MediaViewingPermissionReadonly({
    showOwner,
    showSubs,
    showAllSubs = true,
    justify = "center",
    noWrap = false,
    className,
    canSetPermissions,
    entityId,
    entityType,
    showCanAdd,
    onEditPermissionsClick,
}: IMediaViewingPermissionReadonlyProps) {
    const subsText = showAllSubs
        ? `${CommonConstants.Sub.plural} can view`
        : `Some ${CommonConstants.Sub.plural.toLocaleLowerCase()} can view`;
    const ownersText = `${CommonConstants.Owner.plural} can view`;
    const subsViewRef = useRef<HTMLElement>(null);
    const homeownerViewRef = useRef<HTMLElement>(null);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (onEditPermissionsClick) {
            onEditPermissionsClick(entityId);
        }
    };

    const subTag = canSetPermissions ? (
        <BdsButton
            iconName="SubsVendors"
            onClick={handleClick}
            tooltip={subsText}
            testid={`edit-${entityType}-permissions-sub-${entityId}`}
            displayType="tertiary"
        />
    ) : (
        <>
            <span
                ref={subsViewRef}
                className="MediaViewingPermissionsReadonly-icon"
                data-testId={`${entityType}-permissions-subs-icon-${entityId}`}
            >
                <BdsIcon iconName="SubsVendors" />
            </span>
            <BdsTooltip
                id={`${entityType}-permissions-subs-tooltip-${entityId}`}
                testid={`${entityType}-permissions-subs-tooltip-${entityId}`}
                type="tooltip"
                sourceRef={subsViewRef}
                wait={0}
            >
                {subsText}
            </BdsTooltip>
        </>
    );
    const ownerTag = canSetPermissions ? (
        <BdsButton
            iconName="Homeowners"
            onClick={handleClick}
            tooltip={ownersText}
            testid={`edit-${entityType}-permissions-owners-${entityId}`}
            displayType="tertiary"
        />
    ) : (
        <>
            <span
                ref={homeownerViewRef}
                className="MediaViewingPermissionsReadonly-icon"
                data-testId={`${entityType}-permissions-owners-icon-${entityId}`}
            >
                <BdsIcon iconName="Homeowners" />
            </span>
            <BdsTooltip
                id={`${entityType}-permissions-owners-tooltip-${entityId}`}
                testid={`${entityType}-permissions-owners-tooltip-${entityId}`}
                type="tooltip"
                sourceRef={homeownerViewRef}
                wait={0}
            >
                {ownersText}
            </BdsTooltip>
        </>
    );

    return (
        <BTRow
            className={classNames(className, "MediaViewingPermissionsReadonly", {
                "MediaViewingPermissionsReadonly-nowrap": noWrap,
            })}
            gutter={8}
            align="middle"
            justify={justify}
        >
            {showSubs && subTag}
            {showOwner && ownerTag}
            {!showSubs && !showOwner && showCanAdd && canSetPermissions && (
                <BdsButton
                    iconName="Plus"
                    onClick={handleClick}
                    tooltip="Add permissions"
                    testid={`add-${entityType}-permissions-${entityId}`}
                    disabled={!canSetPermissions}
                    text="Add"
                    displayType="tertiary"
                />
            )}
        </BTRow>
    );
}

export default MediaViewingPermissionReadonly;
