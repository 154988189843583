import { IntegrationUserProfile } from "helpers/AppProvider.types";

import { AnalyticsBuilderTraits } from "utilities/analytics/analytics.api.types";

const isPermittedForUnauthenticatedPageViewEvent = (url: string) => {
    // match entity link URLs such as:
    // - /app/link/d6f182a6-a040-4025-8d54-17cb9e44ee94?type=34
    // - /?lpr=/app/link/d6f182a6-a040-4025-8d54-17cb9e44ee94?type=34 (Login Page)
    return /^https:\/\/[^/]+\/([?]lpr=\/)?app\/link\/[a-fA-F0-9-]{36}[?]type=34/.exec(url);
};

function identify(params: any) {
    const { userId } = params.payload;
    const builderTraits: AnalyticsBuilderTraits = params.payload.traits.builderTraits;
    const integrationUserProfile: IntegrationUserProfile =
        params.payload.traits.integrationUserProfile;
    // eslint-disable-next-line no-restricted-syntax
    const mixpanel = (window as any).mixpanel;

    if (typeof userId === "string") {
        mixpanel.register({ builderId: builderTraits?.builderId });
        mixpanel.identify(userId);
    }
    if (builderTraits) {
        const mpTraits = {
            builder_name: builderTraits.builderName,
            builderId: builderTraits.builderId,
            flags: builderTraits.flags,
            canEditFiles: integrationUserProfile.canEditFiles,
        };
        mixpanel.people.set(mpTraits);
        try {
            mixpanel.get_group("builderId", builderTraits.builderId.toString()).set(mpTraits);
        } catch (e) {
            // Tracking blocker extensions cause this method to fail because the mixpanel source script fails to load.
            // Swallow them to prevent Sentry errors from being generated
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }
}

// When overriding this, we want to use the same track() call as the standard plugin
function trackEvent(trackFunc: any) {
    return function page(params: any) {
        const { userId } = params.payload;
        if (userId != null) {
            trackFunc(params);
        }
    };
}

// When overriding this, we want to use the same page() call as the standard plugin
function pageEvent(pageFunc: any) {
    return function page(params: any) {
        const { properties, userId } = params.payload;
        if (userId != null || isPermittedForUnauthenticatedPageViewEvent(properties.url)) {
            pageFunc(params);
        }
    };
}

export function getMixpanelOverrides(plugin: any) {
    return {
        identify: identify,
        track: trackEvent(plugin.track),
        page: pageEvent(plugin.page),
    };
}
