import { BdsText } from "@buildertrend/components";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";

export interface IUserActivationWelcomeMessageProps {
    userDisplayName: string;
    builderName: string;
    isLinkingAccount?: boolean;
}

export const UserActivationWelcomeMessage: React.FunctionComponent<
    IUserActivationWelcomeMessageProps
> = ({ userDisplayName, builderName, isLinkingAccount }) => {
    const newAccountText = `Track your project with ${builderName} from start to finish. Enter your email address to get started.`;
    const existingAccountText = `It looks like you already have an account. Enter your details below, and start tracking your project with ${builderName}.`;
    return (
        <BTRow gutter={[16, 16]}>
            <BTCol>
                <BdsText
                    size="heavy-sm"
                    text={`Welcome${isLinkingAccount ? " back" : ""}, ${userDisplayName}`}
                />
            </BTCol>
            <BTCol>
                <BdsText
                    size="normal-md"
                    text={isLinkingAccount ? existingAccountText : newAccountText}
                />
            </BTCol>
        </BTRow>
    );
};
