import { FormikErrors, FormikTouched } from "formik";
import { LineItemType } from "legacyComponents/LineItemContainer.types";
import { ReactNode } from "react";

import { CostTypes, MarkedAs } from "types/enum";

import { ICellField } from "commonComponents/utilities/Grid/Grid.types";
import {
    IGridLineItemActionRow,
    IGridLineItemExpandRow,
    ILineItemColumn,
    ILineItemGroup,
    ILineItemRow,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import { WorksheetLineItemContainerRow } from "commonComponents/utilities/ProposalBaseLineItemContainer/ProposalBaseLineItemContainer.types";

import { IJumpToQuickFindItem } from "entity/estimate/common/LineItemQuickFind/LineItemQuickFind.types";
import { ProposalLineItemContainerRow } from "entity/leadProposal/LeadProposal/DeprecatedLeadProposalFiles/ProposalBaseLineItemContainer.types";
import { TaxMethod } from "entity/tax/common/tax.types";
import { IExternalProductLink, VendorTypes } from "entity/vendor/common/vendor.types";

export const LineItemConstants = {
    // the database type for quantity is decimal(18,4)
    // 18 total characters, 14 before the decimal, 4 after
    Quantity: {
        MIN: -100_000_000_000_000,
        MAX: 100_000_000_000_000,
    },
    Currency: {
        MIN: -1_000_000_000,
        MAX: 1_000_000_000,
    },
    // the database type for percentage is decimal (18, 2)
    // 18 total characters, 16 before the decimal, 4 after
    Percentage: {
        MIN: -10_000_000_000_000_000,
        MAX: 10_000_000_000_000_000,
    },
    Title: {
        MAX: 150,
    },
    Description: {
        MAX: 2500,
    },
    InternalNotes: {
        MAX: 2500,
    },
    UnitType: {
        MAX: 8,
    },
};

export enum MarkupType {
    percent = 1,
    flat = 2,
    none = 3,
    perUnit = 4,
}

export enum GroupingType {
    None = 0,
    GroupedLineItems = 1,
    GroupsWithoutLineItems = 2,
}

export enum LineItemColumns {
    TitleAndCostCode = 1,
    CostTypes = 2,
    UnitCost = 3,
    Quantity = 4,
    Unit = 5,
    BuilderCost = 6,
    Markup = 7,
    OwnerPrice = 8,
    PercentInvoiced = 9,
    Variance = 10,
    RelatedOwnerInvoice = 11,
    PaymentSummary = 12,
    AmountPaid = 13,
    AmountOutstanding = 14,
    PaymentPercent = 15,
    PaymentAmount = 16,
    DeleteAction = 17,
    Tax = 18,
    Margin = 19,
    UnitPrice = 20,
    MarkupAmount = 20,
    MarkedAs = 21,
}

// These first few interfaces are Forked from Kendo's GridCellProps and other items. Since we'll use on antV4, we want to remain agnostic from kendo
interface ISortDescriptor {
    field: string;
    dir?: "asc" | "desc";
}

interface IGridDetailRowProps {
    dataItem: any;
}

interface IGroupDescriptor {
    field: string;
    dir?: "asc" | "desc";
}

declare type GridRowType = "groupFooter" | "groupHeader" | "data";

interface ILineItemContainerCellProps {
    /**
     * The field to which the cell is bound ([see example]({% slug sorting_grid %})).
     */
    field?: string;
    /**
     * The data object that represents the current row.
     */
    dataItem: any;
    /**
     * Zero-based index of the dataItem.
     */
    dataIndex: number;
    /**
     * The format that is applied to the value before the value is displayed. Takes the `{0:format}` form where `format` is a standard number format, a custom number format, a standard date format, or a custom date format. For more information on the supported date and number formats, refer to the [kendo-intl](https://github.com/telerik/kendo-intl/blob/develop/docs/index.md) documentation.
     */
    format?: string;
    /**
     * The custom CSS classes of the cells.
     */
    className?: string;
    /**
     * The index of the column in the rendered columns collection.
     */
    columnIndex?: number;
    /**
     * The number of rendered columns in the Grid.
     */
    columnsCount?: number;
    /**
     * The type of the row.
     */
    rowType?: GridRowType;
    /**
     * @hidden
     */
    level?: number;
    /**
     * The expanded value of the cell when hierarchy or grouping are used.
     */
    expanded?: boolean;
    /**
     * The event that is fired when the cell is selected.
     */
    selectionChange?: (event: { syntheticEvent: React.SyntheticEvent<any> }) => void;
    /**
     * The event that is fired when the cell value is changed.
     */
    onChange?: (event: {
        dataItem: any;
        syntheticEvent: React.SyntheticEvent<any>;
        field?: string;
        value?: any;
    }) => void;
    /**
     * The type of the editor which will be used when the cell is in edit mode.
     */
    editor?: "text" | "numeric" | "boolean" | "date";
    /**
     * A function for overriding the default rendering of the cell.
     */
    render?: (
        defaultRendering: React.ReactElement<HTMLTableCellElement> | null,
        props: ILineItemContainerCellProps
    ) => React.ReactElement<HTMLTableCellElement> | null;
    /**
     * The styles for the cell
     */
    style: React.CSSProperties;
    /**
     * The column span of the cell.
     */
    colSpan?: number;
}

export interface ILineItemCostCodeApi {
    id: number;
    name: string;
    isFlatRateCostCode: boolean;
    isCustomerVariance: boolean;
}

export interface IBaseLineItem {
    id: number;
    costCodeId: number | null;
    itemTitle: string | null;
    quantity: number | null;
    unit: string;
    description: string;
}

export interface ICostLineItem extends IBaseLineItem {
    costItemId: number | null;
    unitCost: number;
    builderCost: number;
    quantity: number;
    internalNotes: string | null;
    costTypes: CostTypes[] | null;
    parentId?: number;
    relatedGeneralItemId?: number | null;
    // vendor product
    vendorProductId?: number;
    vendorType?: VendorTypes;
    isDiscontinued?: boolean;
    productLink?: IExternalProductLink;
    varianceCodeId?: number | null;
    varianceCodeTitle?: string;
    totalEffectiveRate?: number;
    taxMethod?: TaxMethod;
    markedAs: MarkedAs;
}

export interface IMarkupLineItem extends ICostLineItem, ITaxLineItem {
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    margin?: number;
    totalWithTax?: number;
    ellipsis?: boolean;
}

export interface ITaxLineItem {
    taxGroupId?: number;
    totalWithTax?: number;
}

export interface IAssemblyLineItemGroup {
    assemblyId: number | null;
}

export class LineItemMarkupSaveRequest {
    constructor(lineItem: IMarkupLineItem, lineItemType: LineItemType) {
        this.id = lineItem.id;
        this.lineItemType = lineItemType;
        this.markupType = lineItem.markupType;
        switch (this.markupType) {
            case MarkupType.percent:
                this.value = lineItem.markupPercent;
                break;
            case MarkupType.flat:
                this.value = lineItem.markupAmount;
                break;
            case MarkupType.perUnit:
                this.value = lineItem.markupPerUnit;
                break;
            case MarkupType.none:
                this.value = lineItem.ownerPrice;
                break;
        }
    }

    id: number;
    lineItemType: LineItemType;
    markupType: MarkupType;
    value: number;
}

export interface IDetailGridRowParams extends IGridDetailRowProps {
    onCellClick: (fieldPath: string, fieldName: string, lineItemId: number) => Promise<void>;
    onBlur: (e: React.FocusEvent<any>) => void;
}

export interface IRenderGroupRowParams {
    item: ILineItemGroup<ILineItemRow>;
    isEditable: boolean | undefined;
    cellProps: React.PropsWithChildren<ILineItemContainerCellProps>;
    /**
     * @returns Returns an empty list if unable to set readonly, else the updated list
     */
    setLineItemReadonly: () => Promise<
        | (ILineItemRow | ILineItemGroup)[]
        | WorksheetLineItemContainerRow[]
        | ProposalLineItemContainerRow[]
    >;
    rowHasErrors: boolean;
    handleCostGroupClick?: (fieldPath: string, groupId: number, fieldName?: string) => void;
    isTitleColumn: boolean;
    isActionColumn: boolean;
    field: ICellField;
    column: ILineItemColumn<any, any>;
}

export interface IGridLineItemPresentationalProps {
    minWidth?: number | string;
    columnsList: ILineItemColumn<any, any>[];
    isEditable?: boolean;
    hasSelectableRows?: boolean;
    disableSelectionRows?: boolean;
    sort?: ISortDescriptor[];
    groups?: IGroupDescriptor[];
    allRowsInEdit?: boolean;
    canAdd?: boolean;
    containerName: string; // used for the document selectors. Required because of issues this can cause if there is another ant grid on the page
    groupingType?: GroupingType;
    indexOffset?: number; // used if there is a row added at the beginning that is not a part of formvalues
    /** field in the formik form. defaults to lineItems */
    dataField?: string;
    canReorderRows?: boolean;

    /** pass formik setFieldValue */
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;

    /** pass formik setFieldTouched */
    setFieldTouched: (field: string, touched: boolean) => void;

    /**
     * function used to override the current lineItems value inside the touched object
     * @param lineItemsTouched - all line items and what fields have been touched
     */
    setLineItemsTouched: (lineItemsTouched: FormikTouched<any>[]) => void;

    /** pass formik handled row data
     *
     * WARNING: this is not typed correctly, we are also passing in IGridLineItemActionRow as well
     * as maybe IGridLineItemExpandRow 😥
     *
     * code defensively and fix if possible
     */
    data: (ILineItemRow | ILineItemGroup)[];
    lineItemErrors?: (FormikErrors<any> | undefined)[];
    /**
     * this is the line items touched information
     */
    touchedFields?: (FormikTouched<any> | undefined)[];
    isJumpingToLineItem?: (lineItem: ILineItemRow) => boolean;
    lineItemToJumpTo?: IJumpToQuickFindItem;
    isLineItemDirty: (lineItem: ILineItemRow) => boolean;

    // TODO: modify this to provide what the server needs to paging.
    // TODO: also evaluate if it makes sense to make some of these optional and toggle the feature.
    onSortChange?: (sortOrder: ISortDescriptor[]) => void;
    onGroupChange?: (groups: IGroupDescriptor[]) => void;
    onLineItemEditFinished?: (
        lineItem: ILineItemRow,
        lineItemPath: string
    ) => Promise<(ILineItemRow | ILineItemGroup)[]>;
    // TODO: When estimates no longer uses the line item container, this should be extracted to proposalBaseLineItemContainer
    onGroupEditFinished?: (group: ILineItemGroup, groupPath: string) => Promise<void>;
    onMoveRow?: (dragIndex: number, hoverIndex: number) => void;
    onMoveRowWithGrouping?: (draggedRow: IDragProps, hoveredRow: IAntGridRowProps) => void;
    onCanDrop?: (draggedRow: IDragProps, hoveredRow: IAntGridRowProps) => boolean;
    onDragStarted?: (id: number) => boolean;
    onDragEnded?: (draggedRow: IDragProps, expanded: boolean) => void;
    onRenderGroupRow?: (params: IRenderGroupRowParams) => ReactNode;
    onValidateLineItem: (index: number) => Promise<boolean>;
    renderDetail?: (params: IDetailGridRowParams) => ReactNode;
    scrollable?: boolean;
    rounded?: true | undefined;
    isInModal?: boolean | undefined;
    maxHeight?: number;
    onColumnResize?: (key: React.Key, width: number) => void;
    renderToolbarContent?: (checkedActionChildren?: JSX.Element) => JSX.Element;
    emptyState?: ReactNode;
    additionalClasses?: string;
}

export enum RowType {
    LineItem = "LineItem",
    Action = "Action",
    Group = "Group",
    Expand = "Expand",
    SubGroup = "SubGroup",
}

export interface IDragProps {
    type: string;
    index: number;
    /**
     * Can be used to add uniqueness to the dragged item
     *
     * Different line item containers could use the same ids
     */
    containerId?: string;
    id: number | string;
}

export interface IAntGridRowProps extends React.HtmlHTMLAttributes<HTMLTableRowElement> {
    index: number;
    canReorder: boolean;
    moveRow?: (dragIndex: number, hoverIndex: number) => void;
    moveRowWithGrouping?: (draggedRow: IDragProps, hoveredRow: IAntGridRowProps) => void;
    rowType: RowType;
    /**
     * Can be used to add uniqueness to the dragged item
     *
     * Different line item containers could use the same ids
     */
    rowContainerId?: string;
    rowId: number | string;
    isInEdit: boolean;
    showDefaultDragPreviewImage?: boolean;
    canDrop?: (draggedRow: IDragProps, hoveredRow: IAntGridRowProps) => boolean;
    dragStarted?: (id: number) => boolean;
    dragEnded?: (draggedRow: IDragProps, expanded: boolean) => void;
    debounceHoverEvents?: boolean;
}

export function isLineItemRow<T extends IBaseLineItem = ILineItemRow>(
    item: T | ILineItemGroup<T>
): item is T {
    return (item as IBaseLineItem).costCodeId !== undefined;
}

export function isLineItemGroupRow<T extends IBaseLineItem = ILineItemRow>(
    item: T | ILineItemGroup<T>
): item is ILineItemGroup<T> {
    return !isLineItemRow(item) && item.items !== undefined;
}

export function isLineItemActionRow<T extends ILineItemRow = ILineItemRow>(
    item: T | ILineItemGroup<T> | IGridLineItemActionRow
): item is IGridLineItemActionRow {
    return (item as IGridLineItemActionRow).isActionRow;
}

export function isLineItemExpandRow<T extends ILineItemRow = ILineItemRow>(
    item: T | ILineItemGroup<T> | IGridLineItemActionRow | IGridLineItemExpandRow<T>
): item is IGridLineItemActionRow {
    return (item as IGridLineItemExpandRow).isExpandRow;
}

/**
 * Strips `IGridLineItemViewRow` fields from a line item leaving its data. Useful for comparing whether
 * the relevant line item data has changed
 * @param item any grid line item
 */
export function getLineItemData(item: ILineItemRow) {
    const { _isInEdit, isSelected, isExpanded, isEditable, autoFocusedField, id, ...rest } = item;
    return rest;
}

export function isCostLineItem(lineItem: IBaseLineItem | ICostLineItem): lineItem is ICostLineItem {
    return (lineItem as ICostLineItem).unitCost !== undefined;
}

export function isMarkupLineItem(
    lineItem: IBaseLineItem | IMarkupLineItem
): lineItem is IMarkupLineItem {
    return (lineItem as IMarkupLineItem).markupAmount !== undefined;
}

export const getDefaultActionRow = (onClick: () => void) =>
    ({
        id: "actionRow",
        isActionRow: true,
        onClick: onClick,
        rowText: "Item",
        borderStyle: "none",
        icon: "plus-circle",
    } as IGridLineItemActionRow);
