import { Divider } from "antd";

import { BdsButton, BdsCol, BdsRow, BdsText } from "@buildertrend/components";

import { ActivationErrorTypes, BTLoginTypes } from "types/enum";

import { externalPages } from "utilities/externalPages";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconPlus } from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import AppStore from "commonComponents/entity/appStore/AppStore";
import SocialMedia from "commonComponents/entity/SocialMedia/SocialMedia";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";

import { UserActivationPageValuePropositions } from "entity/UserActivationPage/ValuePropositions/ValuePropositions";

import "./InvalidInvite.less";

export interface IInvalidInviteProps {
    loginType: BTLoginTypes | undefined;
    errorType?: ActivationErrorTypes;
    logoSrc?: string;
    sessionLoginType?: BTLoginTypes;
    builderName?: string;
    shareToken?: string;
}

interface IAccountTypeMismatchMessageProps {
    sessionLoginType: BTLoginTypes;
    loginType: BTLoginTypes;
    builderName: string;
    shareToken: string;
    onCreateAccount: (shareToken: string, loginType: BTLoginTypes) => void;
}

function getLoginTypeDescription(loginType: BTLoginTypes): string {
    switch (loginType) {
        case BTLoginTypes.ALL:
            return "All";
        case BTLoginTypes.BUILDER:
            return "Builder";
        case BTLoginTypes.OWNER:
            return "Owner";
        case BTLoginTypes.SUBS:
            return "Subcontractor";
        case BTLoginTypes.LEAD:
            return "Lead";
        case BTLoginTypes.BTADMIN:
            return "BT Admin";
        case BTLoginTypes.CONTACT:
            return "Contact";
        default:
            return "Unknown";
    }
}

export function GenericInviteMessage() {
    return (
        <BTCol>
            <BTRow justify="center" gutter={[16, 16]}>
                <BTCol>
                    <BdsText size="heavy-sm" text=" We're sorry, this invite has expired." />
                </BTCol>
                <BTCol>
                    <BdsText
                        size="normal-md"
                        text="You may want to ask the person who invited you to resend the
                        invitation. If you're still having trouble, reach out to our "
                    />
                    <BTLink href={externalPages.BuildertrendContactUs} to="">
                        <BdsText size="normal-md" text="Support Team" />
                    </BTLink>
                    <BdsText size="normal-md" text="." />
                </BTCol>
            </BTRow>
        </BTCol>
    );
}

export function InviteAlreadyAcceptedMessage() {
    return (
        <BTCol>
            <BTRow justify="center" className="padding-bottom-lg">
                <BTCol>
                    <BdsText
                        size="heavy-sm"
                        text="Looks like you've already accepted this invitation"
                    />
                </BTCol>
            </BTRow>
            <BTRow justify="start">
                <BTCol>
                    <BdsButton
                        style={{ minWidth: "100%" }}
                        displayType="primary"
                        id="logInButton"
                        testid="logInButton"
                        text="Log in"
                        onClick={() => {
                            window.location.assign("/");
                        }}
                    />
                </BTCol>
            </BTRow>
        </BTCol>
    );
}

export function UserDeletedOrDisabledOrNonExistingBuilderMessage() {
    return (
        <BTCol>
            <BTRow justify="start" className="padding-bottom-lg" style={{ whiteSpace: "nowrap" }}>
                <BTCol>
                    <BdsText
                        size="heavy-sm"
                        text="It looks like this invitation is no longer valid"
                    />
                </BTCol>
            </BTRow>
            <BTRow justify="start" className="padding-top-sm">
                <BTCol>
                    <BdsText
                        size="normal-md"
                        text="Please contact the person who sent you the invite"
                    />
                </BTCol>
            </BTRow>
        </BTCol>
    );
}

export function AccountTypeMismatchMessage(props: IAccountTypeMismatchMessageProps) {
    const { sessionLoginType, loginType, builderName, shareToken, onCreateAccount } = props;
    return (
        <BTCol>
            <BTRow justify="start" className="padding-bottom-md">
                <BdsCol>
                    <BdsText
                        size="heavy-sm"
                        text={
                            sessionLoginType === BTLoginTypes.ALL
                                ? "It looks like you currently have a mismatched account type."
                                : `It looks like you currently have a ${getLoginTypeDescription(
                                      sessionLoginType
                                  )} account.`
                        }
                    />
                </BdsCol>
            </BTRow>
            <BTRow justify="start" className="padding-top-sm padding-bottom-md">
                <BTCol>
                    <BdsText
                        size="normal-md"
                        text={`Your invitation from ${builderName} requires you to have a ${getLoginTypeDescription(
                            loginType
                        )} account.`}
                    />
                </BTCol>
            </BTRow>
            <BdsRow>
                <BdsCol className="InvalidInviteColumnRight">
                    <BdsText
                        size="normal-md"
                        text={`If you would like to delete your ${getLoginTypeDescription(
                            sessionLoginType
                        )} and create a new one with your existing email: `}
                    />
                    <BTLink href={externalPages.BuildertrendContactUs} to="" isUnderline={false}>
                        <BdsText size="normal-md" text="Contact the team" />
                    </BTLink>
                </BdsCol>
                <Divider type="vertical" style={{ height: "100px" }} />
                <BdsCol className="InvalidInviteColumnLeft">
                    <BdsText
                        size="normal-md"
                        text={`If you would like to create an account with a different email address: `}
                    />
                    <BTLink
                        to=""
                        data-testid="createNewAccount"
                        onClick={() => onCreateAccount(shareToken, loginType)}
                        isUnderline={false}
                    >
                        <BdsText size="normal-md" text="Create a new account" />
                    </BTLink>
                </BdsCol>
            </BdsRow>
        </BTCol>
    );
}

export function InvalidInvitePage({
    loginType = BTLoginTypes.BUILDER,
    errorType = ActivationErrorTypes.None,
    logoSrc = "",
    sessionLoginType = BTLoginTypes.ALL,
    builderName = "your builder",
    shareToken,
}: IInvalidInviteProps) {
    const errorTypeToComponentMap = {
        [ActivationErrorTypes.InviteAlreadyAccepted]: InviteAlreadyAcceptedMessage,
        [ActivationErrorTypes.UserIsDisabledOrDeleted]:
            UserDeletedOrDisabledOrNonExistingBuilderMessage,
        [ActivationErrorTypes.AccountOwnerDoesNotExistOrIsClosed]:
            UserDeletedOrDisabledOrNonExistingBuilderMessage,
        [ActivationErrorTypes.DefaultInvalidInvite]: GenericInviteMessage,
        [ActivationErrorTypes.None]: GenericInviteMessage,
    };

    const onCreateAccount = async (shareToken: string, loginType: BTLoginTypes) => {
        const logoutUrl = routesWebforms.Login.GetLogoutUrl();
        const redirectUrl = `/app${routes.user.getCreateAccountLink(shareToken, loginType)}`;
        const updatedLogoutUrl = `${logoutUrl}?redirectUrl=${encodeURIComponent(redirectUrl)}`;
        window.location.assign(updatedLogoutUrl);
    };

    const renderErrorMessage = () => {
        if (errorType === ActivationErrorTypes.CrossLoginTypeActivation) {
            return (
                <AccountTypeMismatchMessage
                    sessionLoginType={sessionLoginType}
                    loginType={loginType}
                    builderName={builderName}
                    shareToken={shareToken!}
                    onCreateAccount={onCreateAccount}
                />
            );
        }

        const ErrorMessageComponent = errorTypeToComponentMap[errorType] || GenericInviteMessage;
        return <ErrorMessageComponent />;
    };

    const renderLogo = () => {
        if (logoSrc && logoSrc.trim() !== "") {
            return (
                <>
                    <BTLogo
                        layout="vertical"
                        color="dark-blue-blue"
                        size="sm"
                        className="padding-right-lg"
                    />
                    <BTIconPlus className="padding-left-lg padding-right-lg" />
                    <img
                        src={logoSrc}
                        alt="Builder Logo"
                        className="padding-left-lg"
                        width="200px"
                    />
                </>
            );
        }
        return <BTLogo layout="vertical" color="dark-blue-blue" size="sm" />;
    };

    return (
        <BTRow className="InvalidInvitePage" justify="center">
            <BTCol md={24} lg={8}>
                <BTRow justify="center" className="LeftPanelWrapper">
                    <BTCol span={16}>
                        <BTRow
                            justify="center"
                            align="middle"
                            className="flex-column"
                            style={{ height: "100%" }}
                            gutter={[64, 64]}
                        >
                            <BTCol>{renderLogo()}</BTCol>
                            <BTCol>{renderErrorMessage()}</BTCol>
                            <BTCol>
                                <BTRow
                                    justify="center"
                                    align="middle"
                                    gutter={[16, 16]}
                                    className="flex-column"
                                >
                                    <BTCol>
                                        <AppStore />
                                    </BTCol>
                                    <BTCol>
                                        <SocialMedia theme="blueprint" />
                                    </BTCol>
                                </BTRow>
                            </BTCol>
                        </BTRow>
                    </BTCol>
                </BTRow>
            </BTCol>
            <BTCol md={24} lg={16}>
                <UserActivationPageValuePropositions loginType={loginType} />
            </BTCol>
        </BTRow>
    );
}

export default InvalidInvitePage;
