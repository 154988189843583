import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { PureComponent } from "react";

import {
    BTIconInfoCircleFilled,
    BTIconInfoCircleOutlined,
} from "commonComponents/btWrappers/BTIcon";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";

import "./Status.less";

import { StatusTypes } from "./StatusDisplay";

export interface IStatusTagDisplayProps {
    statusType: StatusTypes;
    statusText: React.ReactNode;
    className?: string;
    popoverContent?: React.ReactNode;
    popoverPlacement?: TooltipPlacement;
    "data-testid"?: string;
    disablePopover?: boolean;
    disableIcon?: boolean;
}

export class StatusTagDisplay extends PureComponent<IStatusTagDisplayProps> {
    render = () => {
        const component = (
            <span
                className={classNames(
                    "status-text",
                    "badge",
                    this.props.statusType,
                    this.props.className
                )}
                data-testid={this.props["data-testid"]}
            >
                {this.props.statusText}
                {this.props.popoverContent &&
                    !this.props.disablePopover &&
                    !this.props.disableIcon && (
                        <>
                            <BTIconInfoCircleOutlined className="status-icon unfilled-icon" />
                            <BTIconInfoCircleFilled className="status-icon filled-icon" />
                        </>
                    )}
            </span>
        );

        if (this.props.popoverContent) {
            return (
                <BTPopover
                    disabled={this.props.disablePopover}
                    content={this.props.popoverContent}
                    mouseEnterDelay={0.3}
                    placement={this.props.popoverPlacement}
                    data-testid={this.props["data-testid"]}
                >
                    {component}
                </BTPopover>
            );
        }

        return component;
    };
}
