import { Alert } from "antd";
import classNames from "classnames";
import { Component } from "react";

import { isNullOrUndefined } from "utilities/object/object";

import {
    BTIconCheckCircleSuccess,
    BTIconCloseCircleError,
    BTIconInfoCircleFilled,
    BTIconWarningCircleWarning,
} from "commonComponents/btWrappers/BTIcon";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";

import "./BTAlert.less";

export const alertTypes = ["default", "info", "success", "warning", "error"] as const;
export type AlertTypes = (typeof alertTypes)[number];

const alertIcon = (type: AlertTypes) => {
    const icons = {
        success: <BTIconCheckCircleSuccess />,
        info: <BTIconInfoCircleFilled type="info" />,
        warning: <BTIconWarningCircleWarning />,
        error: <BTIconCloseCircleError />,
    };
    return icons[type];
};

export interface IBTAlertProps {
    /**
     * The title of the alert, displays in larger font at the top
     * @default undefined
     */
    title?: React.ReactNode;
    /**
     * The type of alert to display, controls coloring and default icon
     * @default "default"
     */
    type: AlertTypes;
    /**
     * The data-testid attribute for testing
     */
    "data-testid": string;

    /** Whether Alert can be closed */
    closable?: boolean;
    /** Close text to show */
    closeText?: React.ReactNode;
    /** Content of Alert */
    message: React.ReactNode;
    /** Callback when close Alert */
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
    /** Whether to show icon */
    showIcon?: boolean;
    style?: React.CSSProperties;
    className?: string;
    banner?: boolean;
    icon?: React.ReactNode;
    action?: React.ReactNode;
}

export class BTAlert extends Component<IBTAlertProps> {
    render() {
        const { className, message, type, showIcon, title, ...rest } = this.props;
        const antType = type === "default" ? undefined : type;
        let antMessage = message;
        let antDescription = undefined;

        if (!isNullOrUndefined(title)) {
            antDescription = message;
            antMessage = <BTTitle level={3}>{title}</BTTitle>;
        }

        return (
            // eslint-disable-next-line react/forbid-elements
            <Alert
                className={classNames("BTAlert", className, { BTAlertDefault: type === "default" })}
                message={antMessage}
                description={antDescription}
                type={antType}
                icon={alertIcon(type)}
                showIcon={antType && showIcon}
                {...rest}
            />
        );
    }
}
