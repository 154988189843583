import { FormikErrors } from "formik";

import { BTLocalStorage } from "types/btStorage";

import { ISetLoadingActionOptions } from "utilities/form/form";

import { IAuthStoreDiscoveryHandler } from "commonComponents/auth/AuthStoreDiscovery.api.handler";
import {
    AuthStoreDiscoveryActionType,
    AuthStoreTypes,
    IAuthStoreDiscoveryFormValues,
    IOAuthStateParamState,
} from "commonComponents/auth/AuthStoreDiscovery.types";

export const redirectToAuth0User = (userName: string) => {
    const oAuthStateKeyValue = BTLocalStorage.get("bt-object-oAuthStateParam");
    const baseUrl = `/api/Login/LoginWithAuth0?username=${encodeURIComponent(userName)}`;
    const stateParam = oAuthStateKeyValue ? `&state=${oAuthStateKeyValue.stateIdentifier}` : "";
    const url = baseUrl + stateParam;
    window.location.assign(url);
};

export const handleRedirect = async (
    discoveryHandler: IAuthStoreDiscoveryHandler,
    username: string,
    shareToken: string,
    recaptchaToken: string,
    oAuthStateFlag: boolean | undefined,
    setErrors: (errors: FormikErrors<IAuthStoreDiscoveryFormValues>) => void,
    redirectToBTUser: (username: string) => void
) => {
    const resp = await discoveryHandler?.getAuthStoreForUsername(username, recaptchaToken);

    if (!resp?.userExists) {
        setErrors({ username: "User does not exist" });
    } else if (resp?.authStore === AuthStoreTypes.Auth0) {
        const stateIdentifier = oAuthStateFlag ? crypto.randomUUID().toUpperCase() : shareToken;
        const oAuthState: IOAuthStateParamState = {
            stateIdentifier: stateIdentifier,
            shareToken: shareToken,
        };

        BTLocalStorage.set("bt-object-oAuthStateParam", oAuthState);
        redirectToAuth0User(username);
    } else {
        redirectToBTUser(username);
    }
};

export async function handleLinkInternal(
    values: IAuthStoreDiscoveryFormValues,
    setErrors: (errors: FormikErrors<IAuthStoreDiscoveryFormValues>) => void,
    setActionBeingPerformed: ({
        actionBeingPerformed,
        callback,
        callbackNavigates,
    }: ISetLoadingActionOptions<AuthStoreDiscoveryActionType>) => Promise<void>,
    authStoreDiscoveryHandler: IAuthStoreDiscoveryHandler,
    showAPIErrorMessage: (e: unknown, customMessage?: string) => void,
    redirectToBTUser: (username: string) => void,
    shareToken: string,
    oAuthStateFlag: boolean | undefined
) {
    try {
        await setActionBeingPerformed({
            actionBeingPerformed: "submit",
            callback: async () => {
                await handleRedirect(
                    authStoreDiscoveryHandler,
                    values.username,
                    shareToken,
                    values.recaptchaToken!,
                    oAuthStateFlag,
                    setErrors,
                    redirectToBTUser
                );
            },
            callbackNavigates: true,
        });
    } catch (e) {
        showAPIErrorMessage(e);
    }
}
