import { notification } from "antd";
import { useCallback, useContext, useEffect, useRef } from "react";

import { BdsButton } from "@buildertrend/components";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

import { BTLocalStorage } from "types/btStorage";

import "./RefreshPrompt.less";

const useRefreshPrompt = () => {
    const appDefaultValues = useContext(AppDefaultInfoContext);
    const timeoutHandler = useRef<number | null>(0);

    const alertMessage =
        "A new version of Buildertrend is now available. To ensure the best experience, please refresh the page at your convenience.";

    const fireRefreshPrompt = useCallback(() => {
        const notificationKey = "newUpdateAvailable";

        void notification.info({
            key: notificationKey,
            message: "New update available",
            className: "RefreshPrompt",
            onClick: () => {
                notification.close(notificationKey);
            },
            description: (
                <div>
                    <span>{alertMessage}</span>
                    <div className="ButtonArea">
                        <BdsButton
                            className="RefreshButton"
                            testid="refreshPromptLink"
                            text="Refresh Now"
                            onClick={() => {
                                window.location.reload();
                            }}
                        />
                    </div>
                </div>
            ),
            placement: "bottomRight",
            duration: 0,
        });
    }, [alertMessage]);

    const onAppVersionUpdate = useCallback(
        (_oldValue: number | null, _newValue: number | null) => {
            if (!timeoutHandler.current && appDefaultValues?.deployRefreshPromptWaitTime) {
                timeoutHandler.current = setTimeout(() => {
                    fireRefreshPrompt();
                    timeoutHandler.current = null;
                }, appDefaultValues?.deployRefreshPromptWaitTime);
            }
        },
        [appDefaultValues?.deployRefreshPromptWaitTime, fireRefreshPrompt]
    );

    useEffect(() => {
        BTLocalStorage.addChangeListener("bt-string-newAppDetectedDate", onAppVersionUpdate);
        return () => {
            BTLocalStorage.removeChangeListener("bt-string-newAppDetectedDate", onAppVersionUpdate);
        };
    }, [onAppVersionUpdate]);
};

export default useRefreshPrompt;
