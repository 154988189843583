export const externalPages = {
    AppleStoreBTApp: "https://apps.apple.com/us/app/buildertrend/id504370616",
    BuildertrendCom: "https://www.buildertrend.com/",
    BuildertrendContactSupport: "https://buildertrend.com/contact-customer-support/",
    BuildertrendContactUs: "https://buildertrend.com/company/contact-us/",
    BuildertrendPricing: "https://buildertrend.com/pricing/",
    BuildertrendPrivacyPolicy: "https://buildertrend.com/privacy.aspx",
    BuildertrendSoftwareAttribution: "/app/softwareAttributions.txt",
    // hack needed for local dev env because files are not copied to /app/ folder locally
    BuildertrendSoftwareAttributionDEV: "/forceReverseProxy/softwareAttributions.txt",
    BuildertrendSupportTraining: "https://buildertrend.com/how-it-works/platform/support-training/",
    BuildertrendTermsAndConditions: "https://buildertrend.com/hostingagreement.aspx",
    BuildertrendU: "https://BuildertrendU.com",
    Facebook: "https://www.facebook.com/buildertrend/",
    GooglePlayStoreBTApp:
        "https://play.google.com/store/apps/details?id=com.BuilderTREND.btMobileApp",
    HomeDepotPrivacyPolicy: "https://www.homedepot.com/privacy/privacy-and-security-statement",
    HomeDepotTermsAndConditions: "https://www.homedepot.com/c/Terms_of_Use",
    Instagram: "https://www.instagram.com/buildertrend/",
    Linkedin: "https://www.linkedin.com/company/buildertrend",
    OnsiteConsultingForm:
        "https://buildertrend.com/how-it-works/additional-services/onsite-consulting/",
    X: "https://x.com/Buildertrend",
    XeroMFAHelpDoc: "https://helpcenter.buildertrend.net/en/articles/4367752-xero-mfa-doc",
    PrintOutInformation:
        "https://helpcenter.buildertrend.net/en/articles/4724013-updating-company-information",
    BuildertrendTermsOfUse: "https://buildertrend.com/terms-of-use/",
    CaseStudyKnezHomes: "https://buildertrend.com/case-study/knez-homes/",
    LaunchNotes: "https://buildertrend-publicroadmap.launchnotes.io/board",
    LearningAcademy: {
        getBLAUrl() {
            return "https://learn.buildertrend.net/";
        },
        getProjectManagementLink() {
            return `${this.getBLAUrl()}page/education#role_project-manager`;
        },
        getTakeOffLink() {
            return `${this.getBLAUrl()}buildertrend-takeoff`;
        },
        getCostCodesBestPracticesLink() {
            return `${this.getBLAUrl()}cost-codes-best-practice`;
        },
    },
};
