import { Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import classnames from "classnames";
import { memo } from "react";

import { ITrackingProp, track } from "utilities/analytics/analytics";

import "./BTSwitch.less";

export declare type BTSwitchSize = "small" | "default" | "large";

interface IBTSwitchProps extends Omit<SwitchProps, "onChange" | "size"> {
    id: string;
    "data-testid": string;

    label?: string | JSX.Element;

    /** Pass setFieldValue */
    onCheckedChange: (field: string, value: boolean) => void;

    /**
     * @default light
     */
    theme?: "light" | "dark";

    /**
     * @default default
     */
    size?: BTSwitchSize;
}

export const BTSwitch: React.FC<IBTSwitchProps & ITrackingProp> = memo(
    track((props) => ({
        element: "Switch",
        uniqueId: props["data-testid"],
    }))(
        ({
            id,
            label,
            onCheckedChange,
            tracking,
            disabled,
            size = "default",
            theme = "light",
            className,
            ...restProps
        }) => {
            const handleChange = (checked: boolean) => {
                onCheckedChange(id, checked);
                tracking?.trackEvent({
                    event: "ValueChange",
                    extraInfo: {
                        checked: checked,
                    },
                });
            };

            const isLarge = size === "large";

            return (
                <div
                    className={classnames(
                        "BTSwitch",
                        {
                            "BTSwitch-disabled": disabled,
                            "BTSwitch-dark": theme === "dark",
                        },
                        `BTSwitch-${size}`,
                        className
                    )}
                >
                    <label>
                        {/* eslint-disable-next-line react/forbid-elements */}
                        <Switch
                            className="BTSwitch-input"
                            onChange={handleChange}
                            disabled={disabled}
                            size={isLarge ? "default" : size}
                            {...restProps}
                        />
                        {label}
                    </label>
                </div>
            );
        }
    )
);
