import { TooltipPlacement } from "antd/lib/tooltip";
import classNames from "classnames";
import { throttle } from "lodash-es";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";
import { useHasValueChanged } from "utilities/useHasValueChanged";

import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";

export interface ITextTruncateProps {
    showPopover?: boolean;
    alwaysShowPopover?: boolean;
    popoverPlacement?: TooltipPlacement | undefined;
    className?: string;
    style?: React.CSSProperties;
    popoverContent?: React.ReactNode;
    getPopoverContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
    "data-testid"?: string;
}

export const TextTruncate: React.FunctionComponent<ITextTruncateProps> = ({
    showPopover = true,
    alwaysShowPopover,
    "data-testid": dataTestId,
    ...props
}) => {
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const isTruncatedRef = useRef(false);
    const isMounted = useRef(true);

    const classes = classNames("text-overflow-auto", props.className);

    const getIsTruncated = useCallback(
        (entry: HTMLElement | null) => {
            throttle((entry: HTMLElement | null) => {
                if (entry && showPopover && isMounted.current) {
                    const { scrollWidth, clientWidth } = entry;
                    const isTruncated = clientWidth < scrollWidth;
                    isTruncatedRef.current = isTruncated;
                }
            }, 200)(entry);
        },
        [showPopover, isMounted]
    );

    const getIsTruncatedResizeObserver = useCallback(
        (entry: ResizeObserverEntry | undefined) => {
            if (entry && entry.target && showPopover && isMounted.current) {
                getIsTruncated(entry.target as HTMLElement);
            }
        },
        [getIsTruncated, showPopover]
    );

    const [divRef, onDivRefChange] = useResizeObserver<HTMLDivElement>(
        getIsTruncatedResizeObserver
    );

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [isContentChanged] = useHasValueChanged(props.children);

    // Wait for divNode to update layout properties like scrollWidth & clientWidth
    useLayoutEffect(() => {
        // skip if alwaysShowPopover
        if (!alwaysShowPopover && isContentChanged) {
            getIsTruncated(divRef.current);
        }
    }, [alwaysShowPopover, isContentChanged, divRef, getIsTruncated]);

    const isPopoverEnabled = alwaysShowPopover || showPopover;

    useEffect(() => {
        // TODO: write tests to verify that the popover closes when either of these two props change.
        if (isPopoverVisible && !isPopoverEnabled) {
            setIsPopoverVisible(false);
        }
    }, [isPopoverEnabled, isPopoverVisible]);

    const handlePopoverIsVisibleChange = useCallback(
        (visible) => {
            if (
                alwaysShowPopover ||
                (isPopoverEnabled && !visible) ||
                (isPopoverEnabled && isTruncatedRef.current)
            ) {
                setIsPopoverVisible(visible);
            }
        },
        [alwaysShowPopover, isPopoverEnabled]
    );

    return (
        <div ref={onDivRefChange} className={classes} style={props.style} data-testid={dataTestId}>
            <BTPopover
                content={props.popoverContent ?? props.children}
                placement={props.popoverPlacement ?? "topLeft"}
                mouseEnterDelay={0.3}
                visible={isPopoverVisible}
                onVisibleChange={handlePopoverIsVisibleChange}
                getPopupContainer={props.getPopoverContainer}
            >
                {props.children}
            </BTPopover>
        </div>
    );
};
export default TextTruncate;
