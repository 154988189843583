import moment, { Moment } from "moment";

import { routes } from "utilities/routes";
import { isNullOrWhitespace } from "utilities/string/string";

import { RelatedItemType } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { IRelatedContextMenuItem } from "commonComponents/entity/relatedItem/RelatedItemContextMenu/RelatedItemContextMenu.api.types";

export function getRelatedItemLink(
    entityId: number,
    relatedItemType: RelatedItemType,
    jobId?: number
) {
    if (entityId === 0) {
        return "";
    }

    switch (relatedItemType) {
        case RelatedItemType.CostGroup:
            return routes.costCatalog.getCostGroupDetailsLink(entityId);
        case RelatedItemType.CostItem:
            return routes.costCatalog.getCostItemDetailsLink(entityId);
        case RelatedItemType.Lead:
            return routes.lead.getDetailsLink(entityId);
        case RelatedItemType.Rebate:
            return routes.rebates.getProofOfPurchaseDetailsLink(entityId);
        case RelatedItemType.SentMessage:
            return routes.messages.getMessageDetailsLink(entityId);
        case RelatedItemType.OnlinePayment:
            return routes.onlinePayments.getOnlinePaymentDetailsLink(entityId);
    }

    if (!jobId) {
        return "";
    }

    switch (relatedItemType) {
        case RelatedItemType.Allowance:
            return routes.allowance.getDetailsLink(entityId, jobId);
        case RelatedItemType.Bid:
            return routes.bid.getDetailsLink(entityId, jobId);
        case RelatedItemType.BidPackage:
            return routes.bidPackage.getDetailsLink(entityId, jobId);
        case RelatedItemType.Bill:
            return routes.bill.getDetailsLink(entityId, jobId);
        case RelatedItemType.BillLienWaiver:
            return routes.bill.getDetailsLink(entityId, jobId, "lienWaiver");
        case RelatedItemType.ChangeOrder:
            return routes.changeOrder.getChangeOrderDetailsLink(entityId, jobId);
        case RelatedItemType.CreditMemo:
            return routes.creditMemos.getDetailsLink(entityId, jobId);
        case RelatedItemType.CustomerInvoice:
            return routes.ownerInvoice.getDetailsLink(entityId, jobId);
        case RelatedItemType.DailyLog:
            return routes.dailyLog.getDetailsLink(entityId, jobId);
        case RelatedItemType.Estimate:
            return routes.appBase + routes.estimate.getListLink();
        case RelatedItemType.Jobsite:
            return routes.job.getDetailsLink(jobId);
        case RelatedItemType.PurchaseOrder:
            return routes.purchaseOrder.getDetailsLink(entityId, jobId);
        case RelatedItemType.PurchaseOrderPayment:
            return routes.purchaseOrderPayment.getDetailsLink(entityId, jobId);
        case RelatedItemType.PurchaseOrderPaymentLienWaiver:
            return routes.purchaseOrderPayment.getDetailsLink(
                entityId,
                jobId,
                undefined,
                "lienWaiver"
            );
        case RelatedItemType.Rfi:
            return routes.rfi.getDetailsLink(entityId, jobId);
        case RelatedItemType.ScheduleItem:
            return routes.schedule.getDetailsLink(entityId, jobId);
        case RelatedItemType.Selection:
            return routes.selection.getDetailsLink(entityId, jobId);
        case RelatedItemType.SelectionChoice:
            return routes.selectionChoice.getDetailsLink(entityId, jobId);
        case RelatedItemType.ToDo:
            return routes.toDo.getDetailsLink(entityId, jobId);
        case RelatedItemType.UnsentMessage:
            return routes.messages.getComposeLink(entityId, jobId);
        case RelatedItemType.Warranty:
            return routes.warranty.getDetailsLink(entityId, jobId);
        case RelatedItemType.Deposit:
            return routes.deposits.getDetailsLink(entityId, jobId);
        case RelatedItemType.JobProposal:
            return routes.estimate.getProposalLink(jobId, entityId);
        default:
            throw new Error(
                `RelatedItemType used with no implemented case for ${RelatedItemType[relatedItemType]}.`
            );
    }
}

export interface IRelatedItem {
    id: number;
    jobId: number;
    title: string;
}

export class RelatedPOResponse implements IRelatedContextMenuItemWithCreatedDate {
    constructor(data: any) {
        this.poId = data.poId;
        this.poTitle = data.poTitle;
        this.poName = data.poName;
        this.jobId = data.jobId;
        this.createdDate = moment(data.createdDate);

        this.id = this.poId;
        const itemTitle = !isNullOrWhitespace(this.poTitle) ? this.poTitle : this.poName;
        this.title = `Purchase Order - ${itemTitle}`;
        this.linkRoute = routes.purchaseOrder.getDetailsLink(this.id, this.jobId);
        this.isReadonly = data.isReadonly;
        this.type = RelatedItemType.PurchaseOrder;
    }

    poId: number;
    poTitle: string;
    poName: string;
    jobId: number;
    createdDate: Moment;

    id: number;
    title: string;
    linkRoute: string;
    isReadonly?: boolean;
    type: RelatedItemType;
}

export class RelatedCOResponse implements IRelatedContextMenuItemWithCreatedDate {
    constructor(data: any) {
        this.coId = data.coId;
        this.coTitle = data.coTitle;
        this.coJobId = data.coJobId;
        this.createdDate = moment(data.createdDate);

        this.id = this.coId;
        this.title = this.coTitle;
        this.title = `Change Order - ${this.coTitle}`;
        this.linkRoute = routes.changeOrder.getChangeOrderDetailsLink(this.id, this.coJobId);
        this.isReadonly = data.isReadonly;
        this.type = RelatedItemType.ChangeOrder;
    }

    coId: number;
    coTitle: string;
    coJobId: number;
    createdDate: Moment;

    id: number;
    title: string;
    linkRoute: string;
    isReadonly?: boolean;
    type: RelatedItemType;
}

export interface IRelatedContextMenuItemWithCreatedDate extends IRelatedContextMenuItem {
    createdDate: Moment;
}
