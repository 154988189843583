import yup from "utilities/form/yup";

import {
    IBaseLineItem,
    LineItemConstants,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

const { Currency, Quantity, Title, Description, InternalNotes, UnitType } = LineItemConstants;

export const baseLineItemSchemaDefinition = {
    id: yup.number().required().label("Line Item"),
    costCodeId: yup.number().requiredDropdown().nullable().label("Cost Code"),
    itemTitle: yup
        .string()
        .nullable()
        .when("includeItemInCatalog", { is: true, then: yup.string().required() })
        .max(Title.MAX)
        .label("Title"),
    unitCost: yup.number().moreThan(Currency.MIN).lessThan(Currency.MAX).label("Unit Cost"),
    quantity: yup.number().moreThan(Quantity.MIN).lessThan(Quantity.MAX).label("Quantity"),
    unit: yup.string().max(UnitType.MAX).nullable().label("Unit"),
    builderCost: yup.number().moreThan(Currency.MIN).lessThan(Currency.MAX).label("Builder Cost"),
    description: yup.string().nullable().max(Description.MAX).label("Description"),
    internalNotes: yup.string().nullable().max(InternalNotes.MAX).label("Internal Notes"),
    includeItemInCatalog: yup.boolean().nullable().label("Include Item in Catalog"),
    costTypes: yup.array().nullable().label("Cost Type"),
    markedAs: yup.number().nullable().label("Mark As"),
};

export const markupLineItemSchemaDefinition = {
    markupType: yup.number().label("Markup"),
    markupPercent: yup.number().label("Percentage"),
    markupPerUnit: yup.number().moreThan(Currency.MIN).lessThan(Currency.MAX).label("$/Unit"),
    markupAmount: yup.number().moreThan(Currency.MIN).lessThan(Currency.MAX).label("Total Markup"),
    ownerPrice: yup.number().moreThan(Currency.MIN).lessThan(Currency.MAX).label("Client Price"),
    margin: yup.number().nullable().label("Margin"),
};

export const taxLineItemSchemaDefinition = {
    taxGroupId: yup.number().nullable().label("Tax"),
};

export type BaseLineItemSchemaType = typeof baseLineItemSchemaDefinition;
export function getLineItemSchema(
    getExtendedSchema: (baseSchema: BaseLineItemSchemaType) => BaseLineItemSchemaType = (s) => s
) {
    return yup.object<IBaseLineItem>(getExtendedSchema(baseLineItemSchemaDefinition));
}
