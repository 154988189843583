import { useContext } from "react";
import { Redirect, useRouteMatch } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { routes } from "utilities/routes";

const RedirectToSetupSummary = () => {
    const match = useRouteMatch();

    const userContext = useContext(UserInfoContext);
    const builderInfoContext = useContext(BuilderInfoContext);
    // If the user is trying to get to the subscription manager or frozen page, we don't want to redirect them to the setup experience
    const isSubscriptionRoute = match.path === routes.subscriptionManager.settings;

    // Don't redirect if the user is already on the setup route
    const isSetupRoute = match.path === routes.builderSetup.setup;

    if (
        userContext?.shouldRedirectToSetupExperience &&
        !isSubscriptionRoute &&
        !isSetupRoute &&
        !builderInfoContext?.loginsDisabled
    ) {
        return <Redirect to={routes.builderSetup.setup} />;
    }
    return null;
};

export default RedirectToSetupSummary;
