import { Collapse, Divider, message, Typography } from "antd";
import classNames from "classnames";
import { InjectedFormikProps, withFormik, WithFormikConfig } from "formik";
import { groupBy } from "lodash-es";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { Component, useMemo } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import {
    ClosedAccountTypes,
    DateRangeFilterOptionItem,
    FilterType,
    NumberRangeOptionItem,
} from "types/enum";

import { isNullOrUndefined } from "utilities/object/object";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTCollapse } from "commonComponents/btWrappers/BTCollapse/BTCollapse";
import { btDeleteConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTDatePicker } from "commonComponents/btWrappers/BTDatePicker/BTDatePicker";
import { BTDrawer } from "commonComponents/btWrappers/BTDrawer/BTDrawer";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTFormattedPlural } from "commonComponents/btWrappers/BTFormattedPlural/BTFormattedPlural";
import {
    BTIconCaretRightOutlined,
    BTIconCloseOutlined,
    BTIconEllipsisOutlined,
    BTIconSettingFilled,
} from "commonComponents/btWrappers/BTIcon";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTInputNumber } from "commonComponents/btWrappers/BTInputNumber/BTInputNumber";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTSelectDuplicate } from "commonComponents/btWrappers/BTSelect/BTSelectDuplicate";
import { BTTimePicker } from "commonComponents/btWrappers/BTTimePicker/BTTimePicker";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";
import { BuilderSearch } from "commonComponents/entity/builder/BuilderSearch/BuilderSearch";
import {
    DateRange,
    IDateRangeEntity,
    IDateRangeFormValues,
} from "commonComponents/entity/filters/DateRange/DateRange";
import { FilterSelectCustomItem } from "commonComponents/entity/filters/FilterSelectCustomItem/FilterSelectCustomItem";
import { Currency } from "commonComponents/financial/Currency/Currency";
import {
    INumberRangeEntity,
    INumberRangeFormValues,
    NumberRange,
} from "commonComponents/utilities/NumberRange/NumberRange";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { FilterCount } from "entity/filters/Filter/FilterCount";
import {
    getAppliedFilterCount,
    getFilterString,
    isMonthDateDisabled,
    isSemiMonthDateDisabled,
    isWeekDateDisabled,
} from "entity/filters/filters.utils";
import SavedFilter from "entity/filters/SavedFilter/SavedFilter";
import {
    ISavedFilterFormValues,
    SavedFilterEntity,
    SavedFilterItem,
} from "entity/filters/SavedFilter/SavedFilter.api.types";
import {
    ISavedViewsAndFiltersCardFormValues,
    SavedViewsAndFiltersSettingsState,
} from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard.api.types";
import { SavedViewsAndFiltersSettings } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersSettings/SavedViewsAndFiltersSettings";

import "./Filter.less";

import {
    DateRangeValue,
    FilterEntity,
    FilterEntityType,
    FilterFormActions,
    FilterItem,
    getMultiSelectValue,
    getSelectedFilterItemsFromSavedFilter,
    IFilterFormValues,
    isStandardFilter,
    KeywordValue,
    mapFilterEntityToFormValues,
    NumberRangeValue,
    SelectedFilterItem,
    SelectedLazyFormValue,
    StandardFilterId,
} from "./Filter.api.types";

const { Panel } = Collapse;
const { Text } = Typography;

interface IFilterProps {
    actionBeingPerformed: FilterFormActions;
    appliedFilter?: SelectedFilterItem[];
    defaultOpen: boolean;
    entity: FilterEntity;
    selectedFilterId?: number;
    setSelectedFilterId: (id: number) => void;
    jobPickerFilterOverride: SelectedFilterItem[] | undefined;
    onSubmit: (values: IFilterFormValues) => Promise<void>;
    setFilterPreferences: (isPanelOpen: boolean) => void;
    filterTypeEntity: FilterEntityType;
    onSavedFilterAdded: (
        id: number,
        savedFilterValues: ISavedFilterFormValues,
        values: IFilterFormValues
    ) => Promise<void>;
    onSavedFilterDeleted: (
        id: number,
        newValues: IFilterFormValues,
        wasDefaultDeleted?: boolean
    ) => Promise<void>;
    onSavedFilterUpdated: (
        id: number,
        savedFilterValues: ISavedFilterFormValues,
        values: IFilterFormValues,
        canEdit: boolean
    ) => Promise<void>;
    onSavedFilterUpdatedClick: (id: number, newValues: ISavedFilterFormValues) => Promise<void>;
    isInPopup: boolean;
    isInForm?: boolean;
    showSaveButton: boolean;
    showSavedFilters: boolean;
    onSavedFilterVisibleChange?: (visible: boolean) => void;
    showHasUnsavedChanges: boolean;
    onSetDefaultFilter: (newDefault: SavedFilterItem) => Promise<void>;
    useDrawer?: boolean;
    drawerOpen?: boolean;
    setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    jobIDs?: number[];
    isDefaultFromSavedFilter: boolean;
    filterCount?: number;
    setFilterCount?: (count: number) => void;
    handleDisableFilterField?: (
        filterField: SelectedFilterItem,
        filterValues: SelectedFilterItem[]
    ) => boolean;
}

interface IFilterPresentationalState {
    showSavedFilterModal: boolean;
    filterSettingsState: SavedViewsAndFiltersSettingsState;
    hasUnappliedChanges: boolean;
}

interface IFilterItemHelperProps extends InjectedFormikProps<IFilterProps, IFilterFormValues> {
    filter: SelectedFilterItem | undefined;
    entityFilter: FilterItem;
    options: Record<string, BTSelectItem<undefined>[]>;
    isInPopup: boolean;
    isDisabled?: boolean;
    handleChangeCustom: (key: string, e: any) => void;
    handleChangeBuilderSearch: (key: string, selectedItem: BTSelectItem<any> | undefined) => void;
    handleNumberRange: (key: string, formValue: INumberRangeFormValues) => void;
    handleDateChange: (key: string, formValue: IDateRangeFormValues) => void;
    handleKeywordSearch: (key: string, selectValue: number, textValue: string) => void;
    onDateRangeChange: (
        field: string,
        value: any,
        selectedItem?: BTSelectItem<undefined> | undefined
    ) => void;
    isWeekDateDisabled: (currentDate: Moment | null) => boolean;
    isSemiMonthDateDisabled: (currentDate: Moment | null) => boolean;
}

/**
 * Returns individual filter components based on filter type
 * @param filter: filter to render
 * @param index: index for option lookup
 */
const FilterItemHelper: React.FunctionComponent<IFilterItemHelperProps> = (props) => {
    const {
        isInPopup,
        handleBlur,
        handleChangeCustom,
        handleChangeBuilderSearch,
        handleNumberRange,
        handleDateChange,
        handleKeywordSearch,
        filter,
        entityFilter,
        entity,
        isDisabled,
        useDrawer,
    } = props;
    const { options } = entity;
    const selectDropdownsCanExpand = useDrawer === undefined || useDrawer === false;

    if (!entityFilter || !entityFilter.isVisible || !filter) {
        return null;
    }

    // If the filter is disabled, reset the selected value to the default value
    const selectedValue = isDisabled ? entityFilter.defaultValue : filter.selectedValue;

    const label = entityFilter.showLabel && (
        <div>
            {entityFilter.title}
            {entityFilter.tooltipText && (
                <BTPopover
                    content={
                        // eslint-disable-next-line react/no-danger
                        <div dangerouslySetInnerHTML={{ __html: entityFilter.tooltipText }} />
                    }
                />
            )}
            {isDisabled && <div className="labelDescriptor">{entityFilter.disabledDescriptor}</div>}
        </div>
    );

    switch (filter.type) {
        case FilterType.Checkbox: {
            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <BTCheckbox
                            id={filter.key}
                            data-testid={filter.key}
                            checked={selectedValue as boolean}
                            disabled={isDisabled}
                            onChange={(e) => handleChangeCustom(filter.key, e.target.checked)}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.SingleSelect: {
            return (
                <BTCol
                    className="FilterPresentational-FilterElement"
                    span={isInPopup || useDrawer ? 24 : 3}
                >
                    <BTFormItem label={label}>
                        <BTSelect
                            id={filter.key}
                            data-testid={filter.key}
                            disabled={isDisabled}
                            onChange={(field, value) => handleChangeCustom(field, value.toString())}
                            onBlur={props.setFieldTouched}
                            value={Number(selectedValue)}
                            treeData={options[entityFilter.options]}
                            noTreeDataPlaceholder={entityFilter.placeholderText}
                            dropdownCanExpand={selectDropdownsCanExpand}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.Date: {
            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <BTDatePicker
                            id={filter.key}
                            data-testid={filter.key}
                            value={moment(selectedValue as string)}
                            disabled={isDisabled}
                            onChange={(field, value) =>
                                handleChangeCustom(
                                    field.toString(),
                                    value && value!.format("YYYY-MM-DD")
                                )
                            }
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.MultiSelect:
        case FilterType.GroupedMultiSelect: {
            const multiselectValue = getMultiSelectValue(selectedValue);
            const flattenedFilterOptions =
                filter.type === FilterType.GroupedMultiSelect
                    ? options[entityFilter.options].flatMap((x) => x.children)
                    : options[entityFilter.options];
            const groupedOptionsById = groupBy(flattenedFilterOptions, "id");
            const hasDuplicates = Object.keys(groupedOptionsById).some(
                (key) => key && key !== "undefined" && groupedOptionsById[key]?.length > 1
            );
            const selectComponent = hasDuplicates ? (
                <BTSelectDuplicate
                    id={filter.key}
                    data-testid={filter.key}
                    disabled={isDisabled}
                    onChange={handleChangeCustom}
                    onBlur={handleBlur}
                    treeData={options[entityFilter.options]}
                    value={multiselectValue as number[]}
                    noTreeDataPlaceholder={entityFilter.placeholderText}
                    filterMode={true}
                    dropdownCanExpand={selectDropdownsCanExpand}
                />
            ) : (
                <BTSelect
                    id={filter.key}
                    data-testid={filter.key}
                    disabled={isDisabled}
                    onChange={handleChangeCustom}
                    onBlur={handleBlur}
                    treeData={options[entityFilter.options]}
                    value={
                        !Array.isArray(multiselectValue)
                            ? multiselectValue
                            : multiselectValue.filter((val) => groupedOptionsById[val]?.length > 0)
                    }
                    noTreeDataPlaceholder={entityFilter.placeholderText}
                    multiple
                    filterMode={true}
                    dropdownCanExpand={selectDropdownsCanExpand}
                />
            );
            return (
                <BTCol
                    className="FilterPresentational-FilterElement"
                    span={isInPopup || useDrawer ? 24 : 3}
                >
                    <BTFormItem label={label}>{selectComponent}</BTFormItem>
                </BTCol>
            );
        }
        case FilterType.SearchField: {
            return (
                <BTCol
                    className="FilterPresentational-FilterElement"
                    span={isInPopup || useDrawer ? 24 : 3}
                >
                    <BTFormItem label={label}>
                        <BTInput<undefined>
                            id={filter.key}
                            data-testid={filter.key}
                            value={selectedValue as string}
                            disabled={isDisabled}
                            onChange={handleChangeCustom}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.TextField: {
            return (
                <BTCol
                    className="FilterPresentational-FilterElement"
                    span={isInPopup || useDrawer ? 24 : 3}
                >
                    <BTFormItem label={label}>
                        <BTInput<undefined>
                            data-testid={filter.key}
                            id={filter.key}
                            value={selectedValue as string}
                            disabled={isDisabled}
                            onChange={handleChangeCustom}
                            onBlur={() => {}}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.NumberField: {
            const numberValue = new NumberRangeValue(selectedValue);
            const numberRangeEntity: INumberRangeEntity = {
                value: numberValue.NumberValue ? numberValue.NumberValue : null,
                options: options[entityFilter.options],
            };
            const formValue: INumberRangeFormValues = {
                numberValue: numberValue.NumberValue,
                selectedItem: numberValue.SelectedValue,
            };

            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <NumberRange<IFilterFormValues>
                            id="items"
                            onChange={(key, value) => handleNumberRange(filter.key, value)}
                            onBlur={handleBlur}
                            value={formValue}
                            entity={numberRangeEntity}
                            title=""
                            useCurrency={entityFilter.useCurrency}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.DateGroups: {
            const dateValue = new DateRangeValue(selectedValue);
            const dateRangeEntity: IDateRangeEntity = {
                dateRange: [
                    dateValue.StartDate ? moment(dateValue.StartDate) : undefined,
                    dateValue.EndDate ? moment(dateValue.EndDate) : undefined,
                ] as RangeValue<moment.Moment>,
                options: options[entityFilter.options],
            };
            const formValue: IDateRangeFormValues = {
                dateRange: [
                    dateValue.StartDate ? moment(dateValue.StartDate) : undefined,
                    dateValue.EndDate ? moment(dateValue.EndDate) : undefined,
                ] as RangeValue<moment.Moment>,
                selectedItem: dateValue.SelectedValue,
            };

            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <DateRange
                            id={entityFilter.key}
                            title=""
                            onBlur={handleBlur}
                            isDisabled={isDisabled}
                            onChange={handleDateChange}
                            onDateRangeChange={props.onDateRangeChange}
                            isWeekDateDisabled={props.isWeekDateDisabled}
                            isSemiMonthDateDisabled={props.isSemiMonthDateDisabled}
                            entity={dateRangeEntity}
                            value={formValue}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.NumberFilter: {
            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <BTInputNumber
                            id={filter.key}
                            data-testid={filter.key}
                            min={0}
                            max={999}
                            className="numberRange--inputNumber"
                            value={selectedValue as number}
                            precision={0}
                            disabled={isDisabled}
                            onChange={(field: any, value: any) => handleChangeCustom(field, value)}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.KeywordSearch: {
            const x = selectedValue as KeywordValue;
            return (
                <BTCol className="FilterPresentational-FilterElement" span={useDrawer ? 24 : 5}>
                    <BTFormItem label={label}>
                        <BTSelect
                            id={filter.key}
                            data-testid={filter.key}
                            disabled={isDisabled}
                            onChange={(field, value) =>
                                handleKeywordSearch(filter.key, value, x.KeywordSearchText)
                            }
                            onBlur={props.setFieldTouched}
                            value={x.SelectedKeywordTypeIndex}
                            treeData={options[entityFilter.options]}
                            noTreeDataPlaceholder={entityFilter.placeholderText}
                            style={{ width: "calc(40%)" }}
                            addonBefore={
                                <BTInput
                                    id="keywordSearch"
                                    data-testid="keywordSearch"
                                    style={{ width: "calc(60%)" }}
                                    value={x.KeywordSearchText}
                                    onChange={(field, value) =>
                                        handleKeywordSearch(
                                            filter.key,
                                            x.SelectedKeywordTypeIndex,
                                            value
                                        )
                                    }
                                    onBlur={() => {}}
                                />
                            }
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.Currency: {
            return (
                <BTCol
                    className="FilterPresentational-FilterElement"
                    span={isInPopup || useDrawer ? 24 : 3}
                >
                    <BTFormItem label={label}>
                        <Currency
                            id={filter.key}
                            data-testid={filter.key}
                            value={+selectedValue!}
                            disabled={isDisabled}
                            onChange={handleChangeCustom}
                            onBlur={props.setFieldTouched}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.Time: {
            const format = "HH:mm:ss a";
            const time = selectedValue ? moment(selectedValue as string, format) : undefined;
            return (
                <BTCol className="FilterPresentational-FilterElement">
                    <BTFormItem label={label}>
                        <BTTimePicker<IFilterFormValues>
                            id="items"
                            data-testid={filter.key}
                            disabled={isDisabled}
                            onChange={(key, value) =>
                                handleChangeCustom(filter.key, value && value.format(format))
                            }
                            value={time}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        case FilterType.BuilderSelect: {
            const builderValue = selectedValue as SelectedLazyFormValue | undefined;
            return (
                <BTCol className="FilterPresentational-FilterElement" span={useDrawer ? 24 : 4}>
                    <BTFormItem label={label}>
                        <BuilderSearch
                            value={builderValue}
                            id={filter.key}
                            data-testid={filter.key}
                            onChange={(field, _, item) => handleChangeBuilderSearch(field, item)}
                            onBlur={() => {}}
                            accountStatus={ClosedAccountTypes.OpenOnly}
                            accountType={-1}
                        />
                    </BTFormItem>
                </BTCol>
            );
        }
        default: {
            return <Text>Error: Invalid Component Type</Text>;
        }
    }
};

const filterOptionsCustomItemRender = (item: BTSelectItem<any>) => (
    <FilterSelectCustomItem item={item} />
);

interface IFilterSelectProps {
    savedFilters: SavedFilterItem[];
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
    selectedFilterId: number;
}

const FilterSelect: React.FunctionComponent<IFilterSelectProps> = ({
    savedFilters,
    onChange,
    onBlur,
    selectedFilterId,
}) => {
    const getGroupedFilterData = (
        standardFilterItems: BTSelectItem<SavedFilterItem>[],
        suggestedFilterItems: BTSelectItem<SavedFilterItem>[],
        savedFilterItems: BTSelectItem<SavedFilterItem>[]
    ) => {
        let filterData = standardFilterItems;

        if (suggestedFilterItems.length > 0) {
            const suggestedFilterGroup = new BTSelectItem<SavedFilterItem>({
                name: "Suggested Filters",
                children: suggestedFilterItems,
            });
            filterData.push(suggestedFilterGroup);
        }

        if (savedFilterItems.length > 0) {
            const savedFilterGroup = new BTSelectItem<SavedFilterItem>({
                name: "Saved Filters",
                children: savedFilterItems,
            });
            filterData.push(savedFilterGroup);
        }

        return filterData;
    };

    const savedFilterData = useMemo(() => {
        const filterItems = savedFilters.map(
            (item) =>
                new BTSelectItem<SavedFilterItem>({
                    id: item.id,
                    name: item.name,
                    extraData: item,
                })
        );

        const groupedFilters: BTSelectItem<SavedFilterItem>[][] = filterItems.reduce(
            (acc: BTSelectItem<SavedFilterItem>[][], item) => {
                const itemId = parseInt(item.id);
                if (itemId === StandardFilterId) {
                    acc[0].push(item);
                } else if (itemId < StandardFilterId) {
                    acc[1].push(item);
                } else {
                    acc[2].push(item);
                }
                return acc;
            },
            [[], [], []]
        );

        const [standardFilterItems, suggestedFilterItems, savedFilterItems] = groupedFilters;
        // Only group filters if there are suggested filters
        if (suggestedFilterItems.length === 0) {
            return filterItems;
        }

        return getGroupedFilterData(standardFilterItems, suggestedFilterItems, savedFilterItems);
    }, [savedFilters]);

    return (
        <BTSelect
            id="savedFilterDropdown"
            data-testid="savedFilterDropdown"
            className="FilterPresentational-SavedFiltersDropdown"
            onChange={onChange}
            onBlur={onBlur}
            value={selectedFilterId}
            treeData={savedFilterData}
            customItemRender={filterOptionsCustomItemRender}
        />
    );
};

class FilterInternal extends Component<
    InjectedFormikProps<IFilterProps, IFilterFormValues>,
    IFilterPresentationalState
> {
    state: Readonly<IFilterPresentationalState> = {
        showSavedFilterModal: false,
        filterSettingsState: "closed",
        hasUnappliedChanges: false,
    };

    componentDidMount = () => {
        if (!this.props.isInPopup) {
            this.props.setSelectedFilterId(this.props.values.savedFilter.id);
        }
    };

    private handleSavedFilterVisibleChange(visible: boolean) {
        this.setState({
            showSavedFilterModal: visible,
        });
        if (this.props.onSavedFilterVisibleChange) {
            this.props.onSavedFilterVisibleChange(visible);
        }
    }

    private handleAppliedFilterClear = async (event: React.MouseEvent<any, MouseEvent>) => {
        event.stopPropagation();
        const clearedItems = this.props.entity.items.map((e) => new SelectedFilterItem(e));
        this.props.setFieldValue("items", clearedItems);
        void message.success("All filters cleared");
        await this.props.onSubmit({ ...this.props.values, items: clearedItems });
    };

    private handleCollapseToggle = (key: string | string[]) => {
        // Key will be an empty string/array when closing the panel since it is the only one
        this.props.setFilterPreferences(key.length > 0);
    };

    private handleChangeCustom = (key: string, e: any) => {
        const newItems = this.props.values.items.map((item) => new SelectedFilterItem(item));
        const indexOfReplaceItem = newItems.findIndex((x) => x.key === key);

        if (indexOfReplaceItem > -1) {
            const oldFilter = newItems[indexOfReplaceItem];
            newItems[indexOfReplaceItem] = { ...oldFilter, selectedValue: e };
        }
        this.setState({
            hasUnappliedChanges: true,
        });
        this.props.setFieldValue("items", newItems);
    };

    private handleChangeBuilderSearch = (
        key: string,
        selectedItem: BTSelectItem<any> | undefined
    ) => {
        const newItems = this.props.values.items.map((item) => new SelectedFilterItem(item));
        const indexOfReplaceItem = newItems.findIndex((x) => x.key === key);

        if (indexOfReplaceItem > -1) {
            const oldFilter = newItems[indexOfReplaceItem];
            newItems[indexOfReplaceItem] = {
                ...oldFilter,
                selectedValue: selectedItem,
            };
        }
        this.setState({
            hasUnappliedChanges: true,
        });
        this.props.setFieldValue("items", newItems);
    };

    private handleNumberRange = (key: string, formValue: INumberRangeFormValues) => {
        const newItems = this.props.values.items.map((item) => new SelectedFilterItem(item));
        const indexOfReplaceItem = newItems.findIndex((x) => x.key === key);

        if (indexOfReplaceItem > -1) {
            const newNumberRange = {
                SelectedValue: formValue.selectedItem,
                NumberValue:
                    formValue.selectedItem !== NumberRangeOptionItem.All
                        ? formValue.numberValue
                        : 0,
            };
            const oldFilter = newItems[indexOfReplaceItem];
            newItems[indexOfReplaceItem] = { ...oldFilter, selectedValue: newNumberRange };
        }
        this.setState({
            hasUnappliedChanges: true,
        });
        this.props.setFieldValue("items", newItems);
    };

    private handleKeywordSearch = (key: string, selectValue: number, textValue: string) => {
        const newItems = this.props.values.items.map((item) => new SelectedFilterItem(item));
        const indexOfReplaceItem = newItems.findIndex((x) => x.key === key);

        if (indexOfReplaceItem > -1) {
            const oldFilter = newItems[indexOfReplaceItem];
            newItems[indexOfReplaceItem] = new SelectedFilterItem({
                key: oldFilter.key,
                type: oldFilter.type,
                selectedValue: {
                    SelectedKeywordTypeIndex: selectValue,
                    KeywordSearchText: textValue,
                },
            });
        }
        this.setState({
            hasUnappliedChanges: true,
        });
        this.props.setFieldValue("items", newItems);
    };

    private handleDateChange = (
        key: string,
        formValue: IDateRangeFormValues,
        deferSetFieldValue: boolean = false
    ) => {
        const newItems = this.props.values.items.map((item) => new SelectedFilterItem(item));
        const indexOfReplaceItem = newItems.findIndex((x) => x.key === key);

        if (indexOfReplaceItem > -1) {
            const startDate =
                !isNullOrUndefined(formValue.dateRange) &&
                !isNullOrUndefined(formValue.dateRange![0])
                    ? formValue.dateRange![0]!.format("YYYY-MM-DD")
                    : null;
            const endDate =
                !isNullOrUndefined(formValue.dateRange) &&
                !isNullOrUndefined(formValue.dateRange![1])
                    ? formValue.dateRange![1]!.format("YYYY-MM-DD")
                    : null;
            const newDateRange = {
                SelectedValue: formValue.selectedItem,
                StartDate: startDate,
                EndDate: endDate,
            };
            const oldFilter = newItems[indexOfReplaceItem];
            newItems[indexOfReplaceItem] = { ...oldFilter, selectedValue: newDateRange };
        }

        if (!deferSetFieldValue) {
            this.props.setFieldValue("items", newItems);
            this.setState({
                hasUnappliedChanges: true,
            });
        }
        return newItems[indexOfReplaceItem];
    };

    private handleSavedFilterChange = async (field: string, value: any) => {
        if (!this.props.isInPopup) {
            this.props.setSelectedFilterId(value);
        }
        let selectedSavedFilter = this.props.entity.savedFilters.find((item) => item.id === value)!;
        let items = getSelectedFilterItemsFromSavedFilter(this.props.entity, selectedSavedFilter);
        this.props.setFieldValue("savedFilter", selectedSavedFilter);

        const newItems = this.setDefaultGroupDates(items);

        this.props.setFieldValue("items", newItems);

        await this.props.onSubmit({
            items: newItems,
            savedFilter: selectedSavedFilter,
        });
    };

    private handleDateRangeChange = (
        field: string,
        value: any,
        selectedItem?: BTSelectItem<undefined> | undefined,
        deferSetFieldValue: boolean = false
    ) => {
        let selectedDate;
        switch (value) {
            case DateRangeFilterOptionItem["Weekly"]:
            case DateRangeFilterOptionItem["BiWeekly"]:
                selectedDate = moment();
                while (isWeekDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            case DateRangeFilterOptionItem["SemiMonthly"]:
                selectedDate = moment();
                while (isSemiMonthDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            case DateRangeFilterOptionItem["Monthly"]:
                selectedDate = moment();
                while (isMonthDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            case DateRangeFilterOptionItem["Yearly"]:
                selectedDate = moment();
                break;
            case DateRangeFilterOptionItem["CustomDates"]:
                // If we switch the saved filter (deferSetFieldValue = true) we want to use the default Custom Date value. Otherwise we want to keep it blank
                if (deferSetFieldValue) {
                    return undefined;
                }
                selectedDate = undefined;
                break;
            default:
                selectedDate = undefined;
                break;
        }

        const newValue: IDateRangeFormValues = {
            ...this.props.values.items,
            dateRange: selectedDate ? [selectedDate, null] : null,
            selectedItem: value,
        };
        return this.handleDateChange(field, newValue, deferSetFieldValue);
    };

    private setDefaultGroupDates = (items: SelectedFilterItem[]) => {
        return items.map((i) => {
            // We need to set the default value for the Weekly, Bi-weekly, etc Date Groups
            if (i.type === FilterType.DateGroups) {
                return (
                    this.handleDateRangeChange(
                        i.key,
                        (i.selectedValue as DateRangeValue).SelectedValue,
                        undefined,
                        true
                    ) ?? i
                );
            }
            return i;
        });
    };

    private handleUpdateCurrentSavedFilter = async (values: IFilterFormValues) => {
        const newValues: ISavedFilterFormValues = {
            filterName: values.savedFilter.name,
            isDefault: values.savedFilter.isDefault,
            isPrivate: values.savedFilter.isPrivate,
            selectedFilter: values.savedFilter.id,
            value: getFilterString(values),
            filterType: this.props.filterTypeEntity,
        };

        await this.props.onSavedFilterUpdatedClick(values.savedFilter.id, newValues);
        this.setState({
            hasUnappliedChanges: false,
        });
        // remapping savedFilter to be updated version
        const updatedValues = mapFilterEntityToFormValues(
            this.props.entity,
            values.savedFilter.id,
            undefined
        );
        this.props.setFieldValue("savedFilter", updatedValues.savedFilter);
        await this.props.onSubmit({
            items: values.items,
            savedFilter: updatedValues.savedFilter,
        });
    };

    private handleAddNewSavedFilterClick = () => {
        this.handleSetFilterState("addingNew");
    };

    private getExtraActionsDropdown = () => {
        const selectedFilterId = this.props.entity.savedFilters.find(
            (x) => x.id === this.props.values.savedFilter.id
        )
            ? this.props.values.savedFilter.id
            : StandardFilterId;
        return (
            <BTMenu>
                <BTMenuItem
                    data-testid="updateFilter"
                    onClick={() => this.handleUpdateCurrentSavedFilter(this.props.values)}
                    disabled={!this.state.hasUnappliedChanges || isStandardFilter(selectedFilterId)}
                >
                    Update Saved Filter
                </BTMenuItem>
                <BTMenuItem
                    data-testid="saveAsNewFilter"
                    onClick={() => this.handleAddNewSavedFilterClick()}
                >
                    Add New Saved Filter
                </BTMenuItem>
            </BTMenu>
        );
    };

    private renderFilterHeader = (appliedFilterCount: number) => {
        const { isInPopup } = this.props;
        const { Text } = Typography;
        return (
            <BTRow className="FilterResults" align="middle" justify="space-between">
                <BTCol span={isInPopup ? 24 : undefined}>
                    <BTRow>
                        <BTCol>
                            <Text>FILTER YOUR RESULTS</Text>

                            {appliedFilterCount > 0 && (
                                <Text className="FilterPresentational-AppliedFilterCount">
                                    <Text className="Divider">|</Text>
                                    {appliedFilterCount}{" "}
                                    <BTFormattedPlural
                                        value={appliedFilterCount}
                                        one="FILTER"
                                        other="FILTERS"
                                    />{" "}
                                    APPLIED
                                    <BTButton
                                        type="link"
                                        data-testid="clear"
                                        onClick={(event: React.MouseEvent<any, MouseEvent>) =>
                                            this.handleAppliedFilterClear(event)
                                        }
                                    >
                                        clear
                                    </BTButton>
                                </Text>
                            )}
                        </BTCol>
                    </BTRow>
                </BTCol>
                {this.props.showSavedFilters && (
                    <BTCol span={isInPopup ? 24 : undefined}>
                        <BTRow
                            align="middle"
                            justify={isInPopup ? "start" : "end"}
                            gutter={10}
                            className={isInPopup ? "FilterPresentational-SavedFilter" : ""}
                        >
                            {this.props.showHasUnsavedChanges && (
                                <Text className="FilterPresentational-UnsavedChanges">
                                    Current filter has unsaved changes.
                                </Text>
                            )}
                            {this.renderSavedFilterDropdown()}
                            {!isInPopup && (
                                <BTCol onClick={(event) => event.stopPropagation()}>
                                    <BTPopover content="Filter Settings" breakWord={false}>
                                        <BTButton
                                            type="link"
                                            data-testid="gridSettings"
                                            onClick={() => {
                                                this.setState({
                                                    filterSettingsState: "open",
                                                });
                                            }}
                                            icon={<BTIconSettingFilled />}
                                        />
                                    </BTPopover>
                                    <BTDropdown overlay={this.getExtraActionsDropdown()}>
                                        <BTPopover content="More Actions" breakWord={false}>
                                            <BTButton
                                                type="link"
                                                data-testid=""
                                                className="padding-left-xs"
                                                onClick={() => {}}
                                                icon={<BTIconEllipsisOutlined />}
                                            />
                                        </BTPopover>
                                    </BTDropdown>
                                </BTCol>
                            )}
                        </BTRow>
                    </BTCol>
                )}
            </BTRow>
        );
    };

    private renderSavedFilterDropdown = () => {
        const { isInPopup, showSavedFilters } = this.props;
        const { Text } = Typography;
        const selectedValueForFilterDropdown = this.props.entity.savedFilters.find(
            (x) => x.id === this.props.values.savedFilter.id
        )
            ? this.props.values.savedFilter.id
            : StandardFilterId;

        if (!showSavedFilters) {
            return null;
        }

        let filterSelect = (
            <BTSelect
                id="savedFilterDropdown"
                data-testid="savedFilterDropdown"
                className="FilterPresentational-SavedFiltersDropdown"
                onChange={this.handleSavedFilterChange}
                onBlur={this.props.setFieldTouched}
                value={selectedValueForFilterDropdown}
                treeData={this.props.entity.savedFilters.map(
                    (item) =>
                        new BTSelectItem<SavedFilterItem>({
                            id: item.id,
                            name: item.name,
                            extraData: item,
                        })
                )}
                customItemRender={filterOptionsCustomItemRender}
            />
        );

        if (isInPopup) {
            filterSelect = <BTFormItem label="Saved Filters">{filterSelect}</BTFormItem>;
        }

        return (
            <>
                {!isInPopup && (
                    <BTCol>
                        <Text>
                            <strong>Saved Filters</strong>
                        </Text>
                    </BTCol>
                )}

                <BTCol
                    onClick={(event) => event.stopPropagation()}
                    span={isInPopup ? 24 : undefined}
                >
                    {filterSelect}
                </BTCol>
            </>
        );
    };

    private renderFilterContent = () => {
        const { actionBeingPerformed, handleSubmit } = this.props;
        const selectedFilterId = this.props.entity.savedFilters.find(
            (x) => x.id === this.props.values.savedFilter.id
        )
            ? this.props.values.savedFilter.id
            : StandardFilterId;

        return (
            <>
                <BTRow gutter={10} align="top">
                    {this.mapFilterItems()}
                </BTRow>
                <div className="flex flex-wrap">
                    <BTButton<FilterFormActions>
                        className="margin-right-xs margin-top-xs"
                        actionBeingPerformed={actionBeingPerformed}
                        htmlType="submit"
                        data-testid="updateResults"
                        loadingAction="update"
                        onClick={handleSubmit}
                        type="primary"
                        disabled={!this.state.hasUnappliedChanges}
                    >
                        Update Results
                    </BTButton>
                    <BTButton<FilterFormActions>
                        className="margin-right-xs margin-top-xs"
                        actionBeingPerformed={actionBeingPerformed}
                        data-testid="resetFilters"
                        loadingAction="reset"
                        onClick={this.handleReset}
                        type="secondary"
                    >
                        Reset Filters
                    </BTButton>
                    {this.props.showSaveButton && this.props.isInPopup && (
                        <>
                            <BTButton
                                className="margin-right-xs margin-top-xs"
                                disabled={actionBeingPerformed !== undefined}
                                type="secondary"
                                data-testid="save"
                                onClick={() => this.handleSavedFilterVisibleChange(true)}
                            >
                                Save Filter
                            </BTButton>
                            {!isStandardFilter(selectedFilterId) && (
                                <BTButton
                                    className="margin-right-xs margin-top-xs"
                                    disabled={actionBeingPerformed !== undefined}
                                    type="secondary"
                                    data-testid="delete-filter"
                                    onClick={() =>
                                        btDeleteConfirm({
                                            entityName: "Saved Filter",
                                            onOk: async () =>
                                                this.handleSavedFilterDeleted(selectedFilterId),
                                        })
                                    }
                                >
                                    Delete Filter
                                </BTButton>
                            )}
                        </>
                    )}
                </div>
            </>
        );
    };

    private mapFilterItems = (): JSX.Element[] => {
        return this.props.entity.items.map((entityFilter) => {
            const selectedFilterItem = this.props.values.items.find(
                (i) => i.key === entityFilter.key
            );
            const isDisabled =
                selectedFilterItem &&
                this.props.handleDisableFilterField?.(selectedFilterItem, this.props.values.items);
            return (
                <FilterItemHelper
                    {...this.props}
                    key={entityFilter.key}
                    entityFilter={entityFilter}
                    options={this.props.entity.options}
                    filter={selectedFilterItem}
                    onDateRangeChange={this.handleDateRangeChange}
                    isWeekDateDisabled={isWeekDateDisabled}
                    isSemiMonthDateDisabled={isSemiMonthDateDisabled}
                    handleChangeCustom={this.handleChangeCustom}
                    handleDateChange={this.handleDateChange}
                    handleKeywordSearch={this.handleKeywordSearch}
                    handleNumberRange={this.handleNumberRange}
                    handleChangeBuilderSearch={this.handleChangeBuilderSearch}
                    isDisabled={isDisabled}
                />
            );
        });
    };

    private renderFilterArea = (appliedFilterCount: number) => {
        const { isInPopup, defaultOpen } = this.props;
        const filterClass = classNames("FilterCollapse", {
            FilterApplied: appliedFilterCount > 0,
        });

        if (isInPopup) {
            return (
                <>
                    {this.renderFilterHeader(appliedFilterCount)}
                    <Divider className="FilterPresentational-Divider" />
                    {this.renderFilterContent()}
                </>
            );
        } else {
            return (
                <BTCollapse
                    data-testid="filterCollapsible"
                    bordered={false}
                    onChange={this.handleCollapseToggle}
                    defaultActiveKey={defaultOpen ? 1 : undefined}
                    expandIcon={({ isActive }) => (
                        <BTIconCaretRightOutlined rotateRight={isActive} />
                    )}
                >
                    <Panel
                        className={filterClass}
                        header={this.renderFilterHeader(appliedFilterCount)}
                        key="1"
                        data-testid="FilterPresentational-Collapsible"
                    >
                        {this.renderFilterContent()}
                    </Panel>
                </BTCollapse>
            );
        }
    };

    private renderFilterDrawer = (
        appliedFilterCount: number,
        formWrapper: (children?: React.ReactNode) => React.ReactNode
    ) => {
        const selectedFilterId =
            !this.props.showSavedFilters ||
            !this.props.entity.savedFilters.some((x) => x.id === this.props.values.savedFilter.id)
                ? StandardFilterId
                : this.props.values.savedFilter.id;

        const setDrawerOpen = this.props.setDrawerOpen ? this.props.setDrawerOpen : () => {};

        return (
            <>
                <BTDrawer
                    className="FilterDrawer"
                    placement="right"
                    forceRender
                    width={292}
                    closable={false}
                    keyboard={false}
                    onClose={() => setDrawerOpen(false)}
                    visible={this.props.drawerOpen}
                    title={
                        <>
                            <FilterCount
                                count={appliedFilterCount}
                                setFilterCount={this.props.setFilterCount}
                            />
                            <div className="flex justify-content-between">
                                <BTTitle
                                    bold
                                    level={3}
                                    className="margin-top-zero margin-bottom-md"
                                >
                                    Filter
                                </BTTitle>
                                <BTButton
                                    data-testid="closeFilter"
                                    onClick={() => setDrawerOpen(false)}
                                    type="tertiary"
                                    hotkey="close"
                                    icon={<BTIconCloseOutlined />}
                                />
                            </div>
                            {this.props.showSavedFilters && (
                                <>
                                    <div className="FilterDrawerSavedDropdown margin-bottom-md">
                                        <FilterSelect
                                            savedFilters={this.props.entity.savedFilters}
                                            selectedFilterId={selectedFilterId}
                                            onChange={this.handleSavedFilterChange}
                                            onBlur={this.props.setFieldTouched}
                                        />
                                        <BTPopover
                                            content="Manage Saved Filters"
                                            placement="topLeft"
                                            breakWord={false}
                                        >
                                            <BTButton
                                                type="link"
                                                noShadow
                                                data-testid="openFilterSettings"
                                                className="FilterDrawerSettings margin-left-xs"
                                                onClick={() => {
                                                    this.setState({
                                                        filterSettingsState: "open",
                                                    });
                                                }}
                                                icon={<BTIconEllipsisOutlined />}
                                            />
                                        </BTPopover>
                                    </div>
                                    {this.props.showHasUnsavedChanges && (
                                        <>
                                            <div className="margin-bottom-md">
                                                <Text className="FilterPresentational-UnsavedChanges margin-bottom-md">
                                                    Your filter has unsaved changes.
                                                </Text>
                                            </div>
                                            <div className="margin-bottom-md">
                                                {!isStandardFilter(selectedFilterId) && (
                                                    <BTButton
                                                        type="primary"
                                                        data-testid="saveFilterChanges"
                                                        className="margin-right-xs"
                                                        onClick={() =>
                                                            this.handleUpdateCurrentSavedFilter(
                                                                this.props.values
                                                            )
                                                        }
                                                        disabled={
                                                            this.props.isSubmitting ||
                                                            (!this.state.hasUnappliedChanges &&
                                                                !this.props
                                                                    .isDefaultFromSavedFilter)
                                                        }
                                                    >
                                                        Save
                                                    </BTButton>
                                                )}
                                                <BTButton
                                                    data-testid="newSavedFilter"
                                                    className="margin-right-xs"
                                                    onClick={() =>
                                                        this.handleAddNewSavedFilterClick()
                                                    }
                                                    disabled={this.props.isSubmitting}
                                                >
                                                    Save As
                                                </BTButton>
                                                <BTButton
                                                    data-testid="resetFilterChanges"
                                                    onClick={this.handleReset}
                                                    disabled={this.props.isSubmitting}
                                                >
                                                    Reset
                                                </BTButton>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    }
                    footer={
                        <div className="text-right">
                            <BTButton<FilterFormActions>
                                className="margin-right-xs"
                                data-testid="clearAllFilters"
                                onClick={this.handleAppliedFilterClear}
                                disabled={this.props.isSubmitting}
                            >
                                Clear All
                            </BTButton>
                            <BTButton<FilterFormActions>
                                htmlType="submit"
                                onClick={() => this.props.onSubmit(this.props.values)}
                                data-testid="applyFilters"
                                type="primary"
                                disabled={
                                    !this.state.hasUnappliedChanges || this.props.isSubmitting
                                }
                            >
                                Apply Filter
                            </BTButton>
                        </div>
                    }
                >
                    {formWrapper(<PageSection>{this.mapFilterItems()}</PageSection>)}
                </BTDrawer>
            </>
        );
    };

    private handleReset = async () => {
        const defaultFilterId = this.props.entity.savedFilters.find((item) => item.isDefault)!.id;
        if (!this.props.isInPopup) {
            this.props.setSelectedFilterId(defaultFilterId);
        }
        const values = this.updateFiltersBeforeSubmit({
            ...this.props,
            selectedFilterId: defaultFilterId,
        });
        await this.props.onSubmit(values);
        this.setState({
            hasUnappliedChanges: false,
        });
    };

    private handleSavedFilterDeleted = async (id: number) => {
        const deletedFilter = this.props.entity.savedFilters.find((x) => x.id === id);
        const newSavedFilters = this.props.entity.savedFilters.filter((x) => x.id !== id);
        const wasDefaultDeleted = deletedFilter?.isDefault;
        if (wasDefaultDeleted) {
            const standardFilter = newSavedFilters.find((x) => x.id === StandardFilterId);
            standardFilter!.isDefault = true;
        }
        const newProps = {
            ...this.props,
            entity: {
                ...this.props.entity,
                savedFilters: newSavedFilters,
            },
        };
        const updatedFilters =
            id === this.props.values.savedFilter.id
                ? this.updateFiltersBeforeSubmit(newProps)
                : this.props.values;
        await this.props.onSavedFilterDeleted(id, updatedFilters, wasDefaultDeleted);
    };

    private updateFiltersBeforeSubmit(props: IFilterProps) {
        const values = mapFilterEntityToFormValues(props.entity, props.selectedFilterId, undefined);
        this.props.setFieldValue("items", values.items);
        this.props.setFieldValue("savedFilter", values.savedFilter);
        return values;
    }

    private handleSetFilterState = (state: SavedViewsAndFiltersSettingsState) => {
        this.setState({
            filterSettingsState: state,
        });
    };

    private handleSaveFilter = async (
        id: number,
        savedFilterValue: ISavedViewsAndFiltersCardFormValues
    ) => {
        const { onSavedFilterAdded, values } = this.props;
        await onSavedFilterAdded(id, savedFilterValue, values);
        this.setState({
            filterSettingsState: "open",
        });
    };

    private handleUpdateFilter = async (
        id: number,
        savedFilterValue: ISavedViewsAndFiltersCardFormValues,
        canEdit: boolean = true
    ) => {
        const { onSavedFilterUpdated, values } = this.props;
        await onSavedFilterUpdated(id, savedFilterValue, values, canEdit);
        this.setState({
            filterSettingsState: "open",
        });
    };

    private handleCloseSettingsModal = () => {
        this.handleSavedFilterVisibleChange(false);
        this.setState({
            filterSettingsState: "closed",
        });
    };

    // Once you edit a filter, saving that from the modal will save the updated filter string as well
    private getCurrentFilterData = () => {
        const { entity, values } = this.props;
        if (this.state.hasUnappliedChanges) {
            return entity.savedFilters.map((f) => {
                if (f.id !== values.savedFilter.id) {
                    return f;
                } else {
                    return new SavedFilterItem({ ...f, value: getFilterString(values) });
                }
            });
        } else {
            return entity.savedFilters;
        }
    };

    render() {
        const {
            entity,
            filterTypeEntity,
            handleSubmit,
            onSavedFilterAdded,
            onSavedFilterUpdated,
            setFieldValue,
            values,
        } = this.props;

        const selectedSavedFilter = entity.savedFilters.find((x) => x.id === values.savedFilter.id);

        const appliedFilterCount = getAppliedFilterCount(
            this.props.appliedFilter ||
                getSelectedFilterItemsFromSavedFilter(this.props.entity, selectedSavedFilter),
            this.props.entity
        );

        // BTDrawer cannot be wrapped in a Form, so we must wrap its content in the form instead.
        const formWrapper = (content?: React.ReactNode) => (
            <BTForm
                onSubmit={handleSubmit}
                className="FilterContainer"
                data-testid="FilterContainer"
            >
                {!this.props.isInPopup ? (
                    <BTModal
                        data-testid="btModalFiltersContainer"
                        title="Manage Saved Filters"
                        setPageTitle={false}
                        width="840px"
                        visible={this.state.filterSettingsState !== "closed"}
                        beforeClose={this.handleCloseSettingsModal}
                        useModalLayout
                        removeBodyPadding
                    >
                        <SavedViewsAndFiltersSettings
                            dataType="filter"
                            filterData={this.getCurrentFilterData()}
                            filterTypeEntity={filterTypeEntity}
                            currentFilterValue={getFilterString(values)}
                            onSaveFilter={this.handleSaveFilter}
                            onUpdateFilter={this.handleUpdateFilter}
                            onDeleteFilter={this.handleSavedFilterDeleted}
                            onSetDefaultFilter={this.props.onSetDefaultFilter}
                            gridSettingsState={this.state.filterSettingsState}
                            setGridSettingsState={this.handleSetFilterState}
                            jobIDs={this.props.jobIDs}
                            beforeClose={this.handleCloseSettingsModal}
                        />
                    </BTModal>
                ) : (
                    <SavedFilter
                        selectedFilterId={this.props.values.savedFilter.id}
                        entity={new SavedFilterEntity({ savedFilters: entity.savedFilters })}
                        filterTypeEntity={filterTypeEntity}
                        currentValue={getFilterString(values)}
                        modalConfig={{
                            beforeClose: () => this.handleSavedFilterVisibleChange(false),
                            parentRoute: "",
                        }}
                        visible={this.state.showSavedFilterModal}
                        onFilterAdded={async (id, savedFilterValue) => {
                            await onSavedFilterAdded(id, savedFilterValue, values);
                            setFieldValue(
                                "savedFilter",
                                new SavedFilterItem({
                                    id,
                                    value: savedFilterValue.value,
                                    name: savedFilterValue.filterName,
                                })
                            );
                        }}
                        onFilterUpdated={async (id, savedFilterValues, canEdit) =>
                            await onSavedFilterUpdated(id, savedFilterValues, values, canEdit)
                        }
                        jobIDs={this.props.jobIDs}
                    />
                )}
                {content}
            </BTForm>
        );

        if (this.props.useDrawer) {
            return this.renderFilterDrawer(appliedFilterCount, formWrapper);
        }

        return formWrapper(this.renderFilterArea(appliedFilterCount));
    }
}

const withFormikConfig: WithFormikConfig<IFilterProps, IFilterFormValues> = {
    mapPropsToValues: (props) => {
        return mapFilterEntityToFormValues(
            props.entity,
            props.selectedFilterId || props.entity.savedFilters.find((f) => f.isDefault)?.id,
            // When the component is remounted, these values may be reinitialized from values stored in context
            props.appliedFilter ?? props.jobPickerFilterOverride
        );
    },

    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: async (values: IFilterFormValues, { props, setSubmitting }) => {
        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
    },
};
export const FilterPresentational = withFormik<IFilterProps, IFilterFormValues>(withFormikConfig)(
    FilterInternal
);
