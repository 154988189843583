import { BdsText } from "@buildertrend/components";

import { BTLoginTypes } from "types/enum";

import { externalPages } from "utilities/externalPages";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import AppStore from "commonComponents/entity/appStore/AppStore";
import SocialMedia from "commonComponents/entity/SocialMedia/SocialMedia";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";

import { UserActivationPageValuePropositions } from "entity/UserActivationPage/ValuePropositions/ValuePropositions";

import "./CrossLoginTypeActivationError.less";

export interface IInvalidInviteProps {
    loginType: BTLoginTypes | undefined;
}

export function CrossLoginTypeActivationErrorPage({
    loginType = BTLoginTypes.BUILDER,
}: IInvalidInviteProps) {
    return (
        <BTRow className="CrossLoginTypeActivationErrorPage" justify="center">
            <BTCol md={24} lg={8}>
                <BTRow justify="center" className="LeftPanelWrapper">
                    <BTCol span={16}>
                        <BTRow
                            justify="center"
                            align="middle"
                            className="flex-column"
                            style={{ height: "100%" }}
                            gutter={[64, 64]}
                        >
                            <BTCol>
                                <BTLogo layout="vertical" color="dark-blue-blue" size="sm" />
                            </BTCol>
                            <BTCol>
                                <BTRow justify="center" gutter={[16, 16]}>
                                    <BTCol>
                                        <BdsText
                                            size="heavy-sm"
                                            text="Your account cannot be linked because this invitation is intended for a different account type"
                                        />
                                    </BTCol>
                                    <BTCol>
                                        <BdsText
                                            size="normal-md"
                                            text="You can create a new account using a different email address or contact our "
                                        />
                                        <BTLink href={externalPages.BuildertrendContactUs} to="">
                                            <BdsText size="normal-md" text="Support Team" />
                                        </BTLink>
                                        <BdsText size="normal-md" text="." />
                                    </BTCol>
                                </BTRow>
                            </BTCol>

                            <BTCol>
                                <BTRow
                                    justify="center"
                                    align="middle"
                                    gutter={[16, 16]}
                                    className="flex-column"
                                >
                                    <BTCol>
                                        <AppStore />
                                    </BTCol>
                                    <BTCol>
                                        <SocialMedia theme="blueprint" />
                                    </BTCol>
                                </BTRow>
                            </BTCol>
                        </BTRow>
                    </BTCol>
                </BTRow>
            </BTCol>
            <BTCol md={24} lg={16}>
                <UserActivationPageValuePropositions loginType={loginType} />
            </BTCol>
        </BTRow>
    );
}

export default CrossLoginTypeActivationErrorPage;
