// eslint-disable-next-line no-restricted-imports
import { groupBy, GroupDescriptor, GroupResult, SortDescriptor } from "@progress/kendo-data-query";

import { BTSelectItem, IBaseEntity, IIsSelectable } from "types/apiResponse/apiResponse";
import { CustomFieldDataType } from "types/enum";

import yup from "utilities/form/yup";
import {
    CustomField,
    CustomFieldMetadata,
    getPagingData,
    GridRequest,
    IListEntity,
    ListEntityType,
    ListMetadata,
    PagingData,
    ServerGridColumn,
} from "utilities/list/list.types";

import { RelatedItemType } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { IBTGridExpanableCellProps } from "commonComponents/utilities/Grid/common/BTGridExpandableCell";
import { IFooterCellProps } from "commonComponents/utilities/Grid/common/BTGridFooterCell/BTGridFooterCell";
import { IHeaderCellProps } from "commonComponents/utilities/Grid/common/BTGridHeaderCell/BTGridHeaderCell";
import { IGridSettingsExternalHandler } from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.handler";
import {
    GridViewColumn,
    GridViewItem,
    IHasSavedViews,
    IHasSavedViewsState,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { IGridCellRenderProps } from "commonComponents/utilities/Grid/Grid.types";

import { IFilterHandler } from "entity/filters/Filter/Filter.api.handler";
import {
    FilterEntity,
    IFilterFormValues,
    SystemFilterType,
} from "entity/filters/Filter/Filter.api.types";

// This type should only restrictively extend IGridCellRenderProps.  If this no longer works, we will need to change the default handling for FilterCell = Cell
export type IGridFilterCellRenderProps<CellJSON, CellType = CellJSON> = Omit<
    IGridCellRenderProps<CellType>,
    "isInEdit" | "onBlur"
>;

/**
 * CellType - Type for data used in the cell
 * CellJSON - Type that comes down from server
 */
export interface IClientGridColumn<CellJSON, CellType = CellJSON> {
    /**
     * Defines the type of data the column will render. Ties client-side & server-side together.
     */
    columnType: GridColumnType;

    className?: string;

    /**
     * JSON doesn't support all js types (example: date)
     * This mapper function allows you to convert the JSON response from the server to a JS object
     * @example
     * mapper: (cellValue: CellJSON) => { ...cellValue, date: moment(cellValue.date) }
     */
    mapper?: (cellValue: CellJSON) => CellType;
    validationSchema?: yup.Schema<CellType>;

    /** Runs whenever a cell is clicked or focused on */
    onCellFocus?: (cellValue: CellType) => void;

    onSort?: () => void;

    /**
     * Determines whether to incorporate column expand/collapse
     * When set to 'insideCell', the cell will also be provided IBTGridExpanableCellProps
     */
    isExpandable?: boolean | "insideCell";

    /**
     * Custom JSX to display in the header
     * @default name from server
     */
    HeaderCell?: React.ComponentType<IHeaderCellProps>;

    /**
     * Custom JSX to display in the filter row
     * @default Cell Implementation
     */
    FilterCell?: React.ComponentType<IGridFilterCellRenderProps<CellJSON, CellType>>;

    /**
     * Custom JSX to display in the footer
     * @default value from server
     */
    FooterCell?: React.ComponentType<IFooterCellProps>;
    Cell?:
        | React.ComponentType<IGridCellRenderProps<CellType>>
        | React.ComponentType<IGridCellRenderProps<CellType> & IBTGridExpanableCellProps>;

    /**
     * If a custom header is applied, this can be used to override min-width which is calculated client-side
     */
    minWidth?: number;
}

export enum GridColumnType {
    id = "id",
    dangerousHTML = "dangerousHTML",
    text = "text",
    textList = "textList",
    number = "number",
    currency = "currency",
    currencyAccounting = "currencyAccounting",
    currencyLimit = "currencyLimit",
    currencyVariableScale = "currencyVariableScale",
    date = "date",
    time = "time",
    dateTime = "dateTime",
    files = "files",
    fileIconLink = "fileIconLink",
    url = "url",
    phoneNumber = "phoneNumber",
    expand = "expand",
    singleLineAddress = "singleLineAddress",
    unitCost = "unitCost",
    area = "area",
    mappingStatus = "mappingStatus",
    percentage = "percentage",
    user = "user",
    users = "users",
    userDate = "userDate",

    // custom types below (ex: RFIStatus, RFILink, CommentLink, PurchaseOrderLink, PurchaseOrderStatus, etc...)
    accountingConnectionStatus = "accountingConnectionStatus",
    accountingStatus = "accountingStatus",
    activityPerformedBy = "activityPerformedBy",
    activityStatus = "activityStatus",
    activityType = "activityType",
    appliedStatus = "appliedStatus",
    billAccountingStatus = "billAccountingStatus",
    billLink = "billLink",
    billPayTo = "billPayTo",
    billPurchaseOrderLink = "billPurchaseOrderLink",
    billPurchaseOrderPaymentLink = "billPurchaseOrderPaymentLink",
    billStatus = "billStatus",
    changeOrderApprovalStatus = "changeOrderApprovalStatus",
    changeOrderBidPackagesLinks = "changeOrderBidPackagesLinks",
    changeOrderIdLink = "changeOrderIdLink",
    changeOrderJobName = "changeOrderJobName",
    changeOrderRelatedPOs = "changeOrderRelatedPOs",
    changeOrderSubsList = "changeOrderSubsList",
    checkbox = "checkbox",
    colorSwatch = "colorSwatch",
    contactActivationStatus = "contactActivationStatus",
    contactDisplayName = "contactDisplayName",
    contactItemCount = "contactItemCount",
    contactLinks = "contacts",
    costCodeLink = "costCodeLink",
    costGroupLink = "costGroupLink",
    costItemCostTypes = "costItemCostTypes",
    costItemMarkedAs = "costItemMarkedAs",
    costItemLink = "costItemLink",
    costItemsList = "costItemsList",
    costTitleAndCodeLink = "costTitleAndCodeLink",
    createNewActivity = "createNewActivity",
    creditMemoLink = "creditMemoLink",
    customerContact = "customerContact",
    dailyLogLink = "dailyLogLink",
    dateAsAgeInDays = "dateAsAgeInDays",
    dateAsYesterdayTodayTomorrow = "dateAsYesterdayTodayTomorrow",
    discussion = "discussion",
    discussionLarge = "discussionLarge",
    depositApplyToInvoice = "depositApplyToInvoice",
    depositLink = "depositLink",
    depositPayOnlineDetails = "depositPayOnlineDetails",
    depositAccountingStatus = "depositAccountingStatus",
    dueDate = "dueDate",
    duration = "duration",
    emailNotificationDeliveryMethod = "emailNotificationDeliveryMethod",
    emailNotificationSubjectLink = "emailNotificationSubjectLink",
    emailNotificationType = "emailNotificationType",
    estimateStatus = "estimateStatus",
    estimatedRevenue = "estimatedRevenue",
    settlementDetails = "settlementDetails",
    settlementAmountPaid = "settlementAmountPaid",
    settlementStatus = "settlementStatus",
    internalUserActivationStatus = "internalUserActivationStatus",
    internalUserGustoSyncStatus = "internalUserGustoSyncStatus",
    internalUserLink = "internalUserLink",
    invoicePaymentStatus = "invoicePaymentStatus",
    jobLink = "jobLink",
    jobStatus = "jobStatus",
    leadActivityTemplateNextActivity = "leadActivityTemplatesNextActivity",
    leadActivityTemplatesNameLink = "leadActivityTemplatesNameLink",
    leadConfidence = "leadConfidence",
    leadEmailAddress = "leadEmailAddress",
    leadEmailWizard = "leadEmailWizard",
    leadHasBeenContacted = "leadHasBeenContacted",
    leadLastContacted = "leadLastContacted",
    leadLink = "leadLink",
    leadMapStatus = "leadMapStatus",
    leadProposalTemplatesNameLink = "leadProposalTemplatesNameLink",
    leadRelatedJobsite = "leadRelatedJobsite",
    leadStatus = "leadStatus",
    lienWaiverStatus = "lienWaiverStatus",
    linkedScheduleItemStatus = "linkedScheduleItemStatus",
    mailToOpportunity = "mailToOpportunity",
    microdepositPaymentMethod = "microdepositPaymentMethod",
    microdepositStatus = "microdepositStatus",
    nonWorkdayException = "nonWorkdayException",
    onlinePaymentAccountAccountName = "onlinePaymentAccountAccountName",
    onlinePaymentBuilderLink = "onlinePaymentBuilderLink",
    onlinePaymentDetails = "onlinePaymentDetails",
    onlinePaymentJob = "onlinePaymentJob",
    onlinePaymentLink = "onlinePaymentLink",
    onlinePaymentRefresh = "onlinePaymentRefresh",
    onlinePaymentStatus = "onlinePaymentStatus",
    onlinePaymentSub = "onlinePaymentSub",
    onlinePaymentRelatedItem = "onlinePaymentRelatedItem",
    onlinePaymentAccountingStatus = "onlinePaymentAccountingStatus",
    onlinePaymentFeesNetDeposit = "onlinePaymentFeesNetDeposit",
    opportunityTitle = "opportunityTitle",
    ownerActivation = "ownerActivation",
    ownerInvoiceAccountingStatus = "ownerInvoiceAccountingStatus",
    amountPaid = "amountPaid",
    ownerInvoiceLink = "ownerInvoiceLink",
    ownerInvoicePayOnlineDetails = "ownerInvoicePayOnlineDetails",
    ownerInvoicePaymentStatus = "ownerInvoicePaymentStatus",
    ownerInvoiceRelatedChangeOrders = "ownerInvoiceRelatedChangeOrders",
    ownerInvoiceTransactionDetails = "ownerInvoiceTransactionDetails",
    paymentDate = "paymentDate",
    phaseLink = "phaseLink",
    phaseColor = "phaseColor",
    proposalPaymentStatus = "proposalPaymentStatus",
    proposalStatus = "proposalStatus",
    proposalStatusList = "proposalStatusList",
    proposalTemplateTitle = "proposalTemplateTitle",
    proposalTitle = "proposalTitle",
    purchaseOrderApprovalStatus = "purchaseOrderApprovalStatus",
    purchaseOrderBids = "purchaseOrderBids",
    purchaseOrderChangeOrders = "purchaseOrderChangeOrders",
    purchaseOrderLienWaivers = "purchaseOrderLienWaivers",
    purchaseOrderLink = "purchaseOrderLink",
    purchaseOrderOwnerVariance = "purchaseOrderOwnerVariance",
    purchaseOrderPaidStatus = "purchaseOrderPaidStatus",
    purchaseOrderPaymentAccountingStatus = "purchaseOrderPaymentAccountingStatus",
    purchaseOrderPaymentPayTo = "purchaseOrderPaymentPayTo",
    purchaseOrderPaymentStatus = "purchaseOrderPaymentStatus",
    purchaseOrderPerformedBy = "purchaseOrderPerformedBy",
    purchaseOrderRfis = "purchaseOrderRfis",
    purchaseOrderSelectionChoices = "purchaseOrderSelectionChoices",
    purchaseOrderWorkStatus = "purchaseOrderWorkStatus",
    receiptLink = "receiptLink",
    receiptRelatedItems = "receiptRelatedItems",
    receiptStatus = "receiptStatus",
    receiptTotalAmount = "receiptTotalAmount",
    receiptThumbnail = "receiptThumbnail",
    relatedOwnerInvoicesLink = "relatedOwnerInvoicesLink",
    relatedPurchaseOrderLinks = "relatedPurchaseOrderLinks",
    relatedItems = "relatedItems",
    relatedItemsContextMenu = "relatedItemsContextMenu",
    rfi = "rfi",
    rfiIsNew = "rfiIsNew",
    rfiNumber = "rfiNumber",
    rfiRelatedItem = "rfiRelatedItem",
    rfiStatus = "rfiStatus",
    riskInsurance = "riskInsurance",
    scheduleOnlineStatus = "scheduleOnlineStatus",
    select = "select",
    selectUser = "selectUser",
    selectionAllowance = "selectionAllowance",
    selectionChoices = "selectionChoices",
    selectionDeadline = "selectionDeadline",
    selectionStatus = "selectionStatus",
    selectionTitle = "selectionTitle",
    selectionVendorsInstallers = "selectionVendorsInstallers",
    shiftAccounting = "shiftAccounting",
    shiftApproval = "shiftApproval",
    shiftBreak = "shiftBreak",
    shiftCostCodes = "shiftCostCodes",
    shiftEdited = "shiftEdited",
    shiftGustoSyncStatus = "shiftGustoSyncStatus",
    shiftLink = "shiftLink",
    shiftTimestamp = "shiftTimestamp",
    signupCodeLink = "signupCodeLink",
    specificationTitleAndSummary = "specificationTitleAndSummary",
    specificationSharing = "specificationSharing",
    specificationRowActions = "specificationRowActions",
    subActivationStatus = "subActivationStatus",
    subCertificateExpiration = "subCertificateExpiration",
    subCompanyNameLink = "subCompanyNameLink",
    subPaymentStatus = "subPaymentStatus",
    surveyAverageRating = "surveyAverageRating",
    surveyIndividualStatus = "surveyIndividualStatus",
    surveyNameLink = "surveyNameLink",
    surveyNumberUnreleased = "surveyNumberUnreleased",
    surveyOnWebsite = "surveyOnWebsite",
    surveyResponses = "surveyResponses",
    surveyStarRating = "surveyStarRating",
    takeoffStatus = "takeoffStatus",
    tags = "tags",
    taxGroupLink = "taxGroupLink",
    taxGroupStatus = "taxGroupStatus",
    taxGroupTaxAgencies = "taxAgencies",
    taxRateLink = "taxRateLink",
    taxRateStatus = "taxRateStatus",
    taxRateTaxAgency = "taxAgency",
    templateLink = "templateLink",
    // todo delete
    todoAssignedUser = "todoAssignedUser",
    todoHasChecklist = "todoHasChecklist",
    todoLink = "todoLink",
    todoPriority = "todoPriority",
    todoStatus = "todoStatus",
    todoJobName = "todoJobName",
    transactionLimit = "transactionLimit",
    userActivationStatusInfo = "userActivationStatusInfo",
    warrantyAddedDate = "warrantyAddedDate",
    warrantyFeedback = "warrantyFeedback",
    warrantyLink = "warrantyLink",
    warrantyPriority = "warrantyPriority",
    warrantyScheduleList = "warrantyScheduleList",
    warrantyServiceAction = "warrantyServiceAction",
    warrantyServiceAppointmentDate = "warrantyServiceAppointmentDate",
    warrantyServiceAppointmentStatus = "warrantyServiceAppointmentStatus",
    warrantyServiceFeedback = "warrantyServiceFeedback",
    warrantyServiceJobNameWithColor = "warrantyServiceJobNameWithColor",
    wdeRecurrence = "wdeRecurrence",
}

type ICombinedGridColumn<CellJSON, CellType> = Omit<ServerGridColumn, "isExpandable"> &
    IClientGridColumn<CellJSON, CellType>;
export class GridColumn<CellJSON, CellType = CellJSON>
    implements ICombinedGridColumn<CellJSON, CellType>
{
    constructor(data: ICombinedGridColumn<CellJSON, CellType>) {
        for (let key in data) {
            this[key] = data[key];
        }
    }

    columnType: GridColumnType;

    // ServerGridColumn
    id: string;
    value: CellType;
    name: string;
    jsonKey: string;
    type: string;
    title: string;
    width: number;
    defaultWidth: number;
    order: number;
    enabled: boolean;
    align: "left" | "right" | "center";
    isRemovableFromGrid: boolean;
    isResizeable: boolean;
    isSortable: boolean;
    isSearchable: boolean;
    isFixedWidth: boolean;
    isFrozen: boolean;
    isHidden: boolean;
    isAlwaysVisible: boolean;
    isExpandable?: boolean | "insideCell";
    printHeaderDisplayAs: string;
    className?: string;
    isCustomField: boolean;
    isCustomFieldRelated: boolean;
    isCustomFieldTransform: boolean;
    toolTipHeader: string;

    // IClientGridColumn
    mapper?: (cellValue: CellJSON) => CellType;
    validationSchema?: yup.Schema<CellType>;
    HeaderCell?: React.FunctionComponent<IHeaderCellProps>;
    FooterCell?: React.FunctionComponent<IFooterCellProps>;
    FilterCell?: React.FunctionComponent<IGridFilterCellRenderProps<CellJSON, CellType>>;
    onCellFocus?: (cellValue: CellType) => void;

    Cell?: React.FunctionComponent<IGridCellRenderProps<CellType>>;
    customFieldMetadata: CustomFieldMetadata;
}

export interface IEntityLinkResponse extends IBaseEntity {
    title: string;
}

export interface IRelatedItemsResponse extends IBaseEntity {
    relatedItemsCounts: Record<RelatedItemType, number>;
}

export const getGroupedData = (
    values: (IGridRowData | IGroupedRowData)[],
    groupData: GroupDescriptor[],
    defaultExpanded: boolean = false
) => {
    const result = (groupBy(values, groupData) as (IGridRowData | IGroupedRowData)[]).map((r) => {
        if (isGridGroupRow(r)) {
            r.isExpanded = defaultExpanded;
        }
        r.isSelected = false;
        return r;
    });
    return result;
};

export interface IGroupedRowData extends GroupResult, IIsSelectable {
    items: (IGroupedRowData | IGridRowData)[];
    isExpanded: boolean;
}

export interface IGridRowData extends IBaseEntity, IIsSelectable {
    [key: string]: unknown;
    isInEdit?: boolean;
    isExpanded?: boolean;
}

export const isGridRowDataArray = <T = IGroupedRowData[]>(
    data: IGridRowData[] | T
): data is IGridRowData[] => {
    return data && isGridRowData(data[0]);
};
export const isGridRowData = (data: IGridRowData | IGroupedRowData): data is IGridRowData => {
    const entityId = data && (data as IGridRowData).id;
    return !!entityId || entityId === 0;
};
export const isGridGroupRow = <T = IGridRowData>(data: T | GroupResult): data is GroupResult => {
    return data && !!(data as GroupResult).items;
};

const getColumnInfo = (
    id: string,
    minWidth: number,
    columns: GridColumn<unknown, unknown>[] | GridViewColumn[] | undefined,
    alwaysVisibleColumnCount: number
) => {
    const columnIndex = columns?.findIndex((c) => c.id === id) ?? -1;
    if (columnIndex !== -1) {
        return {
            width: Math.max(minWidth, columns![columnIndex].width),
            isFrozen: columns![columnIndex].isFrozen,
            order: columnIndex + alwaysVisibleColumnCount,
        };
    }
    return undefined;
};

/**
 * Handlers should expose this type if performing the transform.
 */
type DefaultRowType = IGridRowData | IGroupedRowData;
export interface IGridEntity<TFooterRow = {}, TData extends DefaultRowType = DefaultRowType>
    extends IListEntity<TData, TFooterRow> {
    columnsList: GridColumn<unknown>[];
}
export class GridEntity<TFooterRow = {}, TData extends DefaultRowType = DefaultRowType>
    implements IGridEntity<TFooterRow, TData>
{
    constructor(data: any) {
        this.columnsList = [];
        this.columnsListAll = [];
        this.serverColumnsList =
            data.columnsList && data.columnsList.map((c: any) => new ServerGridColumn(c));
        if (data.data) {
            this.data = data.data.map((x: any) => {
                const { customFields, ...otherRows } = x;
                let mappedCustomFields: object[] = this.transformCustomFields(customFields);
                return Object.assign({ ...otherRows }, ...mappedCustomFields);
            });
        }

        // NOTE: There are atleast 3 components
        // ChangeOrderlist.api.types.ts, Joblist.types.ts, SelectionList.api.types.ts
        // that override data with their own type.
        // So, if you do any changes here, please check that those components also work fine

        if (data.footerData && data.footerData[0] !== undefined) {
            const { customFields, ...otherData } = data.footerData[0];
            this.footerData = Object.assign(
                { ...otherData },
                ...this.transformCustomFieldFooterData(customFields)
            );
        }
        this.columnType = data.columnType;
        this.totalPages = data.totalPages;
        this.records = data.records;
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.infiniteScrollStatus = data.infiniteScrollStatus;
        this.isLoaded = data.isLoaded;
        this.canAdd = data.canAdd;
        this.canUpdate = data.canUpdate;
        this.canDelete = data.canDelete;
        this.pagingData = getPagingData(data.page, data.pageSize, data.records);
        this.listMetadata = new ListMetadata(data.listMetaData);
    }

    serverColumnsList: ServerGridColumn[];
    columnsList: GridColumn<unknown>[];
    columnsListAll: GridColumn<unknown>[];
    data: TData[];
    footerData: TFooterRow | null;
    columnType: GridColumnType;
    totalPages: number;
    records: number;
    pageSize: number;
    page: number;
    infiniteScrollStatus: number;
    isLoaded: boolean;
    canAdd: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    pagingData: PagingData;
    listMetadata: ListMetadata;

    public transformEntity = (
        clientColumns: IClientGridColumn<unknown, unknown>[],
        gridView?: GridViewItem,
        columnConfigs?: GridColumn<unknown, unknown>[]
    ) => {
        if (this.serverColumnsList) {
            // find all the columns the server marks to show even when they aren't a part of the saved view
            const serverAlwaysVisibleColumns = this.serverColumnsList.filter(
                (c) => c.isAlwaysVisible && !c.enabled
            );

            this.columnsList = this.serverColumnsList.map((serverColumn) => {
                const clientColumn = clientColumns.find(
                    (x) => x.columnType === serverColumn.columnType
                );

                const newColumn = new GridColumn({ ...serverColumn, ...clientColumn });

                if (!newColumn.FilterCell) {
                    newColumn.FilterCell = newColumn.Cell as React.FunctionComponent<
                        IGridFilterCellRenderProps<unknown>
                    >;
                }

                // if column is hidden, we should not be enabling it
                if (newColumn.isHidden && gridView) {
                    newColumn.enabled = false;

                    // find and remove column from gridView.displayColumns
                    // we aren't displaying the column and need to filter against displayed columns only
                    gridView.displayColumns = gridView.displayColumns.filter(
                        (c) => c.id !== newColumn.id
                    );
                }

                const mapper = newColumn.mapper;
                if (this.data && mapper) {
                    this.data.forEach((row: TData) => {
                        // a JSON -> JS object mapping function was specified for this column type, run it on the cell
                        row[serverColumn.jsonKey] = mapper(row[serverColumn.jsonKey]);
                    });
                }

                // spread operations here mean we will prefer column configuration sources in the following order:
                // 1) local adjustments
                // 2) currently selected Grid View settings
                // 3) server-supplied defaults
                return {
                    ...newColumn,
                    ...getColumnInfo(
                        newColumn.id,
                        newColumn.defaultWidth,
                        gridView?.columns,
                        serverAlwaysVisibleColumns.length
                    ),
                    ...getColumnInfo(
                        newColumn.id,
                        newColumn.defaultWidth,
                        columnConfigs,
                        serverAlwaysVisibleColumns.length
                    ),
                };
            });
        }
        this.columnsListAll = this.columnsList.sort((p, c) => p.order - c.order);
    };

    public transformCustomFields = (customFields: any) => {
        let mappedCustomFields: object[] = [];
        if (customFields) {
            const customFieldColumns = this.serverColumnsList.filter((x) => x.isCustomField);
            mappedCustomFields = customFields.map((customField: CustomField) => {
                const relatedColumn = customFieldColumns.find(
                    (y) => parseInt(y.jsonKey) === customField.customFieldId
                )!; // we are currently sending down the id for columns as a string
                let newValue;

                function renderDropdown() {
                    const selectedValues: number[] = customField.value;
                    if (selectedValues.includes(-1)) {
                        newValue = [];
                    } else {
                        newValue = relatedColumn
                            .customFieldMetadata!.options.filter((o: BTSelectItem) =>
                                selectedValues.includes(o.value)
                            )
                            .map((i) => i.title);
                    }
                }

                switch (customField.type) {
                    case CustomFieldDataType.MultiSelectSubs:
                    case CustomFieldDataType.MultiSelectUsers:
                    case CustomFieldDataType.ListOfSubs:
                    case CustomFieldDataType.ListOfUsers:
                        const isNewUsersObject =
                            Array.isArray(customField.value) &&
                            customField.value.length > 0 &&
                            typeof customField.value[0].id === "number";
                        if (!isNewUsersObject) {
                            renderDropdown();
                            break;
                        }

                        const selectedUserValues: number[] = customField.value;
                        if (selectedUserValues.includes(-1)) {
                            newValue = [];
                        } else {
                            newValue = relatedColumn
                                .customFieldMetadata!.options.filter((o: BTSelectItem) =>
                                    selectedUserValues.includes(o.value)
                                )
                                .map((i) => ({ globalUserId: i.value, displayName: i.title }));
                        }
                        break;

                    case CustomFieldDataType.DropDownCheckList:
                    case CustomFieldDataType.DropDownList:
                        renderDropdown();
                        break;
                    case CustomFieldDataType.File:
                        newValue = relatedColumn.customFieldMetadata.documents[customField.value];
                        break;
                    default:
                        newValue = customField.value;
                }
                return { [relatedColumn.jsonKey]: newValue };
            });
        }
        return mappedCustomFields;
    };

    public transformCustomFieldFooterData = (customFields: CustomField[] | undefined) => {
        let mappedCustomFields: object[] = [];
        if (customFields) {
            const customFieldColumns = this.serverColumnsList.filter((x) => x.isCustomField);
            mappedCustomFields = customFields.map((customField) => {
                const relatedColumn = customFieldColumns.find(
                    (y) => parseInt(y.jsonKey) === customField.customFieldId
                )!;
                return { [relatedColumn.jsonKey]: customField.value };
            });
        }
        return mappedCustomFields;
    };
}

export interface IGridState {
    dataState: "loading" | "loaded" | "error";
    data?: (IGridRowData | IGroupedRowData)[];
    selectedData: IBaseEntity[];
    columnsList?: GridColumn<unknown>[];
    sortingData: SortDescriptor[];
    footerData?: {
        [key: string]: any;
    } | null;
    groups?: GroupDescriptor[];
    hasUnsavedChanges: boolean;
    persistRowSelection?: boolean;
}

export interface IListWithGridProps<T extends IListWithGridHandler<GridEntity>>
    extends IHasSavedViews {
    /**
     * List entity type
     */
    entityType: ListEntityType;
    /**
     * System filter type
     */
    systemFilter?: SystemFilterType;
    /**
     * Checked actions
     */
    checkedActions?: (selectedItems: IBaseEntity[]) => JSX.Element;
    /**
     * Custom columns
     */
    customColumns?: any;
    /**
     * Filter handler
     */
    filterHandler?: IFilterHandler;
    /**
     * List handler
     */
    listHandler?: T;
    /**
     * External Grid settings handler
     */
    externalGridSettingsHandler?: IGridSettingsExternalHandler;
    /**
     * Is template mode flag
     */
    isTemplateMode: boolean;
    /**
     * Job ids list
     */
    jobIds?: number[];
}

export interface IListWithGridState<T extends GridEntity> extends IHasSavedViewsState {
    filterEntity?: FilterEntity;
    previousFilters?: IFilterFormValues;
    gridState: IGridState;
    gridEntity?: T;
}

export interface IHasSelectAllAcrossGridPagesState {
    displaySelectAllBanner: boolean;
    allEntityIds?: number[];
}

export interface IListWithGridHandler<T extends GridEntity> {
    get(
        entityType: ListEntityType,
        gridRequest?: GridRequest,
        selectedJobIds?: number[],
        filterValues?: IFilterFormValues,
        pagingData?: PagingData
    ): Promise<T>;
}

export type ToolbarDownloadAction = "download" | undefined;

export class GetFullIdsListFromFiltersResponse {
    constructor(data: any) {
        this.idList = data.idList;
    }
    idList: number[];
}
