import { BTServiceFileUpload } from "legacyComponents/FileUploadContainer.types";
import moment, { Moment } from "moment";

import { BTSelectItem, ServiceValidation } from "types/apiResponse/apiResponse";
import { Countries } from "types/countries";
import {
    CheckAllState,
    CustomFieldAssociatedType,
    InternalUserActivationStatus,
    NotificationTypes,
} from "types/enum";
import { TabPermissions } from "types/enum";

import { getCheckAllState } from "commonComponents/btWrappers/BTTable/BTTable.utils";
import { LinkingAction } from "commonComponents/entity/accounting/AccountingLinkingOptions/AccountingLinkingOptions.api.types";
import { AccountingEntity } from "commonComponents/entity/accounting/LinkedAccountingEntity/LinkedAccountingEntity.api.types";
import { CellEmailLookupEntity } from "commonComponents/entity/cellEmailLookup/CellEmailLookup/CellEmailLookup.api.types";
import {
    ICellPhoneInputFormValues,
    SmsOptInStatus,
    VerificationMessageType,
} from "commonComponents/entity/cellPhone/CellPhoneInput/CellPhoneInput.types";
import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import { Email, MinimalEmail } from "commonComponents/entity/email/InputEmail/InputEmail.types";
import { IEmailEntryFormValues } from "commonComponents/entity/emailFormEntry/EmailFormEntry";
import { ExternalEmailLookupFormValues } from "commonComponents/entity/externalEmailLookup/ExternalEmailLookup/ExternalEmailLookup.api.types";
import {
    IJobAccess,
    JobAccessEntity,
} from "commonComponents/entity/jobAccess/JobAccess/JobAccess.api.types";
import {
    INotificationPreferenceData,
    INotificationPreferenceGroupViewModel,
    INotificationPreferenceRow,
} from "commonComponents/entity/notificationPreferences/NotificationPreferencesTable.api.types";
import { JobsiteLinkingTypes } from "commonComponents/financial/Common/Accounting.types";
import { StatusTypes } from "commonComponents/utilities/Status/StatusDisplay";

import { AccountingSetupDropdownData } from "entity/accounting/AccountingSettings/AccountingSettings.api.types";
import {
    IRolePermissionFormValues,
    Role,
} from "entity/internalUser/RolePermissions/RolePermissions.api.types";
import { VendorTypes } from "entity/vendor/common/vendor.types";

import type { default as TakeoffInfoExample } from "./InternalUser.api.takeoffs.json";
import type { default as VendorInfoExample } from "./InternalUser.api.vendorInfo.json";

export class JobsiteGroup {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
    }

    id: number;
    name: string;
}

export const unselectedRoleDropdownId = -1;
export type InternalUserFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "saveAndNew"
    | "delete"
    | "setCredentials"
    | "connect"
    | "disconnect";

export interface IInternalUserFormValues extends IEmailEntryFormValues, ICellPhoneInputFormValues {
    firstName: string;
    lastName: string;
    phone?: string;
    cell: string;
    role: number;
    loginEnabled: boolean;
    userAccessNewJobs: boolean;
    addToAllExistingJobs?: boolean;
    sendInvitation: boolean;
    userEnabled: boolean;
    roles: IRolePermissionFormValues[];
    roleTreeData: BTSelectItem<IRoleExtraData>[];
    notificationPreferences: INotificationPreferenceRow[];
    jobAccess: JobAccessEntity | null;
    accountingEntityLinkingType: JobsiteLinkingTypes;
    createNewAccountingEntityOption: LinkingAction;
    username: string;
    isLinkedToSub: boolean;
    linkedSubId?: number;
    subUsername: string;
    subPassword: string;
    enableConflictAlert: boolean;
    conflictAlertMaxCount: number;
    scheduleItems: number[];
    scheduleItemsFeed?: CalendarFeedItem;
    leadActivities: number[];
    leadActivitesFeed?: CalendarFeedItem;
    todos: number[];
    todosFeed?: CalendarFeedItem;
    geofencing: boolean;
    defaultCostCodeId?: number;
    defaultLaborCost: number;
    messageSignature?: string;
    signatureImageTempId?: number;
    signatureImage?: string | null;
    messageSignatureImage?: string | null;
    tempFile?: BTServiceFileUpload;
    contactInfo: string;
    customFields: ICustomFieldFormValues[];
    payrollEmployeeId?: string;
    payrollJobID?: string;
    defaultPayrollItem?: string;
    condensedEmail?: string;
    isOutOfOffice: boolean;
    phoneCell: string;
    smsOptInStatus: SmsOptInStatus;
}

export class InternalUserEntity {
    constructor(data: any) {
        this.canView = data.canView;
        this.canEdit = data.canEdit;
        this.canAdd = data.canAdd;
        this.canDelete = data.canDelete;
        this.canEditJobAccess = data.canEditJobAccess;
        this.canUpdatePassword = data.canUpdatePassword;
        this.canViewNotifications = data.canViewNotifications;
        this.internalUser = new InternalUser(data.internalUser);
        this.jobGroups = data.jobGroups;
        this.failedFields = data.failedFields;
        this.notificationPreferences = data.notificationPreferences;
        this.isConnectedToGusto = data.isConnectedToGusto;
        this.hasTimeClockCostViewing = data.hasTimeClockCostViewing;

        if (data.vendorAccountUserDetails) {
            this.vendorAccountUserDetails = new VendorAccountUserDetails(
                data.vendorAccountUserDetails
            );
        }
        if (data.takeoffUserDetails) {
            this.takeoffUserDetails = new TakeoffUserDetails(data.takeoffUserDetails);
        }

        this.payrollItems = data.payrollItems
            ? new AccountingSetupDropdownData(data.payrollItems)
            : undefined;

        this.isMfaEnabled = data.isMfaEnabled;

        this.hasCostCodesFeature = data.hasCostCodesFeature;
        this.isImpersonatingBuilder = data.isImpersonatingBuilder;
        this.isLinkedToAuth0 = data.isLinkedToAuth0;
        this.canStartChatConversation = data.canStartChatConversation;
    }

    canView: boolean;
    canEdit: boolean;
    canAdd: boolean;
    canDelete: boolean;
    canEditJobAccess: boolean;
    canUpdatePassword?: boolean;
    canViewNotifications: boolean;
    internalUser: InternalUser;
    jobGroups: JobsiteGroup[];
    failedFields?: ServiceValidation[];
    notificationPreferences: INotificationPreferenceData;
    accountingEntity?: AccountingEntity;
    payrollSettingsEntity?: InternalUsersPayrollSettingsResponse;
    vendorAccountUserDetails?: VendorAccountUserDetails;
    takeoffUserDetails?: TakeoffUserDetails;
    isConnectedToGusto?: boolean;
    payrollItems?: AccountingSetupDropdownData;
    hasTimeClockCostViewing?: boolean;
    isMfaEnabled?: boolean;
    hasCostCodesFeature: boolean;
    isImpersonatingBuilder: boolean;
    isLinkedToAuth0: boolean;
    canStartChatConversation: boolean;
}

export class InternalUser implements IHasCustomFieldContainer {
    constructor(data: any) {
        this.id = data.loginId;
        this.firstName = data.firstName.value;
        this.lastName = data.lastName.value;
        this.primaryEmail = data.primaryEmail.value.emailAddress
            ? new Email(data.primaryEmail.value)
            : null;
        this.additionalEmails = data.additionalEmails.value.map(
            (email: string | MinimalEmail) => new Email(email)
        );
        this.cell = data.cell.value;
        this.phone = data.phone.value;
        this.countryCode = data.countryCode;
        this.smsOptInStatus = data.smsOptInStatus;
        this.cellPhoneNumber = data.cellPhoneNumber;
        this.loginEnabled = data.loginEnabled.value;
        this.activationStatus =
            data.activationStatus != null ? data.activationStatus.statusId : undefined;
        this.autoAddJobsites = data.autoAddJobsites?.value ?? false;
        this.addToAllExistingJobs = data.addToAllExistingJobs?.value ?? false;
        this.sendInvitation = data.sendInvitation;
        this.accountConfirmationDate = data.accountConfirmationDate
            ? moment(data.accountConfirmationDate)
            : undefined;
        this.allowSubAccountAccess = data.allowSubAccountAccess;
        this.invitationDate = data.invitationDate ? moment(data.invitationDate) : undefined;
        this.jobAccessCount = data.jobAccessCount;
        this.username = data.username;
        this.password = data.password;
        this.userEnabled = data.userEnabled.value;
        this.isSelf = data.isSelf;
        this.dedicatedSubIsLinked = data.dedicatedSubIsLinked;
        this.dedicatedSubId = data.dedicatedSubId;
        this.dedicatedSubUserName = data.dedicatedSubUserName;
        this.dedicatedSubPassword = data.dedicatedSubPassword;
        this.enableConflictAlert = data.enableConflictAlert;
        this.conflictAlertMaxCount = data.conflictAlertMaxCount;
        this.calendarFeedOptions = data.calendarFeedSummaryViewModel
            ? new CalendarFeedOptions(data.calendarFeedSummaryViewModel)
            : undefined;
        this.geofencingEnabled = data.geoFencingEnabled;
        this.defaultCostCodeId = data.defaultCostCodeId;
        this.defaultCostCodes = data.defaultLaborCostCodes.map(
            (cc: any) =>
                new BTSelectItem({
                    name: cc.title,
                    id: cc.costCodeId,
                    selected: cc.costCodeId === data.defaultLaborCostCodeId,
                })
        );
        this.defaultLaborCost = Number(data.defaultLaborCost);
        this.byCostCodeHourlyTracking = data.byCostCodeHourlyTracking;
        this.messageSignature = data.messageSignature;
        this.signatureImage = data.messageSignatureImageUrl;
        this.contactInfo = data.contactInfo;
        this.customFields = data.customFields;
        this.customFieldOptions = data.customFieldOptions;
        this.customFieldAssociatedType = data.customFieldAssociatedType;
        this.customFieldsCanConfigure = data.customFieldsCanConfigure;

        this.cellEmailLookupLoadData = new CellEmailLookupEntity(data.cellEmailLookupLoadData);

        const selectedRoleId: number | undefined = data.role.value![0];

        const unselectedOption = new BTSelectItem<IRoleExtraData>({
            id: unselectedRoleDropdownId,
            name: "-- Choose Role --",
            selected: selectedRoleId === unselectedRoleDropdownId,
            extraData: undefined,
        });
        const roleSelectItems = data.role.options![0].options.map(
            (x: any) =>
                new BTSelectItem<IRoleExtraData>({
                    ...x,
                    selected: x.id === selectedRoleId,
                })
        );
        roleSelectItems.unshift(unselectedOption);
        this.role = roleSelectItems;

        if (selectedRoleId) {
            const selectedRole = this.role.find(
                // e.key is falsely typed as string, when both e.key and selectedRoleId are numbers
                (e) => Number(e.key) === selectedRoleId
            );
            this.roleName = selectedRole && selectedRole.title;
            this.roleJobStatusAccess = selectedRole?.extraData?.jobStatusAccess
                ? selectedRole.extraData?.jobStatusAccess
                : [];
        }

        this.roles = data.roles ? data.roles.map((role: any) => new Role(role)) : [];

        if (data.inviteStatus) {
            this.inviteStatus = data.inviteStatus.value;
        } else {
            this.inviteStatus = "User has not been invited";
        }
        if (data.inviteButton) {
            this.inviteButtonText = data.inviteButton.buttonName;
        } else if (this.inviteStatus!.startsWith("Invite Accepted")) {
            this.inviteButtonText = undefined;
        } else {
            this.inviteButtonText = "Invite User";
        }

        this.isOutOfOffice = data.isOutOfOffice;
    }

    id: number;
    firstName: string;
    lastName: string;
    primaryEmail: Email | null;
    additionalEmails: Email[];
    cell: string;
    phone: string;
    loginEnabled: boolean;
    activationStatus: InternalUserActivationStatus;
    autoAddJobsites: boolean;
    addToAllExistingJobs: boolean;
    sendInvitation: boolean;
    accountConfirmationDate?: moment.Moment;
    allowSubAccountAccess: boolean;
    invitationDate?: moment.Moment;
    cellEmailLookupLoadData: CellEmailLookupEntity;
    role: BTSelectItem<IRoleExtraData>[];
    roles: Role[];
    roleName?: string;
    roleJobStatusAccess: number[];
    inviteStatus: string;
    inviteButtonText?: string;
    jobAccessCount: number;
    username: string;
    password: string;
    userEnabled: boolean;
    isSelf: boolean;
    dedicatedSubIsLinked: boolean;
    dedicatedSubId?: number;
    dedicatedSubUserName: string;
    dedicatedSubPassword: string;
    enableConflictAlert: boolean;
    conflictAlertMaxCount: number;
    calendarFeedOptions?: CalendarFeedOptions;
    geofencingEnabled: boolean;
    defaultCostCodeId?: number;
    defaultCostCodes: BTSelectItem[];
    defaultLaborCost: number;
    byCostCodeHourlyTracking: boolean;
    messageSignature?: string;
    messageSignatureImage?: string;
    signatureImage?: string | null;
    contactInfo: string;
    customFields: ICustomFieldResponse[];
    customFieldOptions: ICustomFieldOptionsList;
    customFieldAssociatedType: CustomFieldAssociatedType;
    customFieldsCanConfigure: boolean;
    isOutOfOffice: boolean;
    cellPhoneNumber: string;
    smsOptInStatus: SmsOptInStatus;
    countryCode: Countries;
}

export enum FeedType {
    ScheduleItems = 0,
    LeadActivities,
    Todos,
}

export class CalendarFeedOptions {
    constructor(data: any) {
        this.calendarFeedItems =
            data?.CalendarFeedItems.map((fi: any) => new CalendarFeedItem(fi)) ?? [];

        const schedOpts = Object.values(data.ScheduleItemOptions).map((value, index) => {
            return new BTSelectItem({
                name: value,
                id: index,
                selected: this.calendarFeedItems
                    .find((cfi) => cfi.feedType === FeedType.ScheduleItems)
                    ?.feedOptions.includes(index),
            });
        });
        this.scheduleFeedOptions = schedOpts;

        const leadFeedOpts = Object.values(data.LeadActivityOptions).map((value, index) => {
            return new BTSelectItem({
                name: value,
                id: index,
                selected: this.calendarFeedItems
                    .find((cfi) => cfi.feedType === FeedType.LeadActivities)
                    ?.feedOptions.includes(index),
            });
        });
        this.leadActivitiesFeedOptions = leadFeedOpts;

        const todoOpts = Object.values(data.ToDosOptions).map((value, index) => {
            return new BTSelectItem({
                name: value,
                id: index,
                selected: this.calendarFeedItems
                    .find((cfi) => cfi.feedType === FeedType.Todos)
                    ?.feedOptions.includes(index),
            });
        });
        this.todoFeedOptions = todoOpts;
    }
    calendarFeedItems: CalendarFeedItem[];
    scheduleFeedOptions: BTSelectItem[];
    leadActivitiesFeedOptions: BTSelectItem[];
    todoFeedOptions: BTSelectItem[];
}

export class CalendarFeedItem {
    constructor(data: any) {
        this.feedId = data.FeedId;
        this.feedOptions = data.FeedOptions;
        this.feedType = data.FeedType;
        this.feedUrl = data.FeedUrl;
    }
    feedId: number;
    feedOptions: number[];
    feedType: FeedType;
    feedUrl: string;
}

export class InternalUserEntitySaveRequest {
    constructor(values: IInternalUserFormValues, entity: InternalUserEntity) {
        this.internalUser = new InternalUserSaveRequest(values);

        let prefs: Record<string, INotificationPreferenceGroupViewModel> = {};
        if (entity.notificationPreferences) {
            entity.notificationPreferences.categories.forEach((category) => {
                category.groups.forEach((group) => {
                    prefs[TabPermissions[group.tabType]] = group;
                    group.preferences.forEach((pref) => {
                        let notificationPrefRow = values.notificationPreferences.find(
                            (row) => row.name === pref.name && row.type === pref.type
                        )!;
                        pref.emailEnabled = notificationPrefRow.emailEnabled;
                        pref.textEnabled = notificationPrefRow.textEnabled;
                        pref.pushEnabled = notificationPrefRow.pushEnabled;
                        pref.allUsersEnabled = notificationPrefRow.allUsersEnabled;
                    });
                });
            });
        }
        this.pushEnabled =
            getCheckAllState(values.notificationPreferences, "pushEnabled") !== CheckAllState.None;

        this.notificationPrefs = prefs;
    }

    internalUser: InternalUserSaveRequest;
    notificationPrefs: Record<string, INotificationPreferenceGroupViewModel>;
    pushEnabled: boolean;
}

export class InternalUserSaveRequest {
    constructor(values: IInternalUserFormValues) {
        this.firstName = values.firstName;
        this.lastName = values.lastName;
        this.username = values.username;
        this.userEnabled = values.userEnabled;
        this.dedicatedSubIsLinked = values.isLinkedToSub;
        this.dedicatedSubId = values.linkedSubId;
        this.dedicatedSubUserName = values.subUsername;
        this.dedicatedSubPassword = values.subPassword;
        if (values.condensedEmail) {
            this.email = [{ emailAddress: values.condensedEmail, friendlyName: "" }];
        } else {
            this.email = (
                values.primaryEmail ? [values.primaryEmail, ...values.additionalEmails] : []
            ).map((e) => {
                return { emailAddress: e.emailAddress, friendlyName: e.friendlyName };
            });
        }

        this.cell = values.cell;
        this.phone = values.phone ?? "";
        this.loginEnabled = values.loginEnabled;
        this.autoAddJobsites = values.userAccessNewJobs;
        this.addToAllExistingJobs = values.addToAllExistingJobs ?? false;
        this.sendInvitation = values.sendInvitation;
        this.enableConflictAlert = values.enableConflictAlert;
        this.conflictAlertMaxCount = values.conflictAlertMaxCount;
        this.geofencingEnabled = values.geofencing;
        this.defaultCostCodeId = values.defaultCostCodeId;
        this.defaultLaborCost = values.defaultLaborCost;
        this.messageSignature = values.messageSignature;
        this.messageSignatureImage =
            values.tempFile?.fileName ?? values.messageSignatureImage ?? "";
        this.messageSignatureImageUrl = values.tempFile?.url ?? "";
        this.hasSignatureImageChanged = values.tempFile?.hasChanged ?? false;
        this.signatureImageTempId = values.tempFile?.tempId ?? values.signatureImageTempId ?? 0;
        this.signatureImageExt = values.tempFile?.ext ?? "";
        this.contactInfo = values.contactInfo;
        this.customFields = values.customFields;
        this.role = values.role;
        this.jobs = values.jobAccess?.jobs;
        this.payrollEmployeeId = values.payrollEmployeeId;
        this.payrollJobID = values.payrollJobID;
        this.defaultPayrollItem = values.defaultPayrollItem;
        this.isOutOfOffice = values.isOutOfOffice;
        this.cellPhoneNumber = values.phoneCell;
        this.smsOptInStatus = values.smsOptInStatus;
        this.verificationRequest = values.verificationRequest;
    }

    firstName: string;
    lastName: string;
    username: string;
    userEnabled: boolean;
    dedicatedSubIsLinked: boolean;
    dedicatedSubId?: number;
    dedicatedSubUserName: string;
    dedicatedSubPassword: string;
    email: ExternalEmailLookupFormValues;
    cell: string;
    phone: string;
    loginEnabled: boolean;
    autoAddJobsites: boolean;
    addToAllExistingJobs: boolean;
    sendInvitation: boolean;
    enableConflictAlert: boolean;
    conflictAlertMaxCount: number;
    geofencingEnabled: boolean;
    defaultCostCodeId?: number;
    defaultLaborCost: number;
    messageSignature?: string;
    messageSignatureImage: string;
    messageSignatureImageUrl: string;
    hasSignatureImageChanged: boolean;
    signatureImageTempId: number;
    signatureImageExt: string;
    contactInfo: string;
    customFields: ICustomFieldFormValues[];
    role: number;
    jobs?: IJobAccess[];
    payrollEmployeeId?: string;
    payrollJobID?: string;
    defaultPayrollItem?: string;
    isOutOfOffice: boolean;
    cellPhoneNumber: string;
    smsOptInStatus: SmsOptInStatus;
    verificationRequest: VerificationMessageType;
}

export class PasswordValidationResponse {
    constructor(data: any) {
        this.passwordStatus = data.passwordStatus;
        this.passwordStatusMessage = data.passwordStatusMessage;
        this.hasErrors = data.hasErrors ?? false;
    }
    passwordStatus: PasswordStatusType;
    passwordStatusMessage: string;
    hasErrors: boolean;
}

type PartnerInfoExampleType = typeof VendorInfoExample | typeof TakeoffInfoExample;

class PartnerUserDetails {
    constructor(data: PartnerInfoExampleType) {
        this.isBuilderConnected = data.isBuilderConnected;
        this.isConnected = data.isConnected;
        this.isReadOnly = data.isReadOnly;
        this.canConnect = data.canConnect;
        if (data.connectionDate) {
            this.connectionDate = moment(data.connectionDate);
        }
        this.isOnlyConnectedUser = data.isOnlyConnectedUser;
    }
    isBuilderConnected?: boolean;
    isConnected?: boolean;
    isReadOnly?: boolean;
    canConnect?: boolean;
    connectionDate?: moment.Moment;
    isOnlyConnectedUser?: boolean;
}

export class VendorAccountUserDetails extends PartnerUserDetails {
    constructor(data: typeof VendorInfoExample) {
        super(data);
        this.vendorType = data.vendorType;
        this.accountName = data.accountName;
        this.vendorAccountUserId = data.vendorAccountUserId;
        this.isOnlyConnectedUser = data.isOnlyConnectedUser;
    }
    vendorType?: VendorTypes;
    accountName?: string;
    vendorAccountUserId?: string;
    isOnlyConnectedUser?: boolean;
}

export class TakeoffUserDetails extends PartnerUserDetails {
    constructor(data: typeof TakeoffInfoExample) {
        super(data);
        this.partnerId = data.partnerId;
        this.externalUserId = data.externalUserId;
    }
    partnerId?: number;
    externalUserId?: string;
}

export class AccountingUserSyncResponse {
    constructor(data: any) {
        this.methodSuccessful = data.MethodSuccessful;
        this.message = data.Message;
    }
    methodSuccessful: boolean;
    message: string;
}

export enum PasswordStatusType {
    Success = 0,
    ConfirmationMismatch,
    MinimumLength,
    ExceedsMaximumLength,
    InvalidComposition,
    InvalidAuthentication,
    OwnerMatchesFriendsAndFamily,
    UnAuthorized,
}

export enum InternalUserTabKeys {
    Overview = "1",
    Permissions = "2",
    Notifications = "3",
    JobAccess = "4",
    Other = "5",
    Accounting = "6",
    Vendors = "7",
    Payroll = "8",
    Takeoff = "9",
    Messaging = "10",
    SecurityLogins = "11",
}

export const InternalUserTabNames = {
    [InternalUserTabKeys.Overview]: "Overview",
    [InternalUserTabKeys.Permissions]: "Permissions",
    [InternalUserTabKeys.Notifications]: "Notifications",
    [InternalUserTabKeys.JobAccess]: "Job Access",
    [InternalUserTabKeys.Other]: "Other",
    [InternalUserTabKeys.Accounting]: "Accounting",
    [InternalUserTabKeys.Vendors]: "Vendors",
    [InternalUserTabKeys.Payroll]: "Payroll",
    [InternalUserTabKeys.SecurityLogins]: "Security & Login",
};

export class UpdateCredentialsResponse {
    constructor(data: any) {
        this.failedFields = data.failedFields;
    }
    failedFields?: ServiceValidation[];
}

export interface IRoleExtraData {
    description: string;
    isSystemDefault: boolean;
    isHidden: boolean;
    jobStatusAccess: number[];
}

export class InviteProps {
    inviteSent: boolean;
    inviteStatus: string;
    inviteBtnText?: string;
}

export type PayrollEmployeeSyncStatus =
    | undefined
    | "Synced"
    | "Pending Setup in Gusto"
    | "Onboarding"
    | "Pending Setup";

export class InternalUsersPayrollSettingsResponse {
    constructor(data: any) {
        this.syncStatus = data.syncStatus;
        this.payrollIntegratioName = data.payrollIntegratioName;
        this.payrollEmployeeId = data.payrollEmployeeId;
        this.canView = data.canView;
        this.canUpdate = data.canUpdate;
        this.payrollLogoUrl = data.payrollLogoUrl;
        this.payrollJobID = data.payrollJobID;

        if (
            data.payrollEmployees &&
            data.payrollEmployees.value &&
            data.payrollEmployees.value.length > 0
        ) {
            this.payrollEmployees = data.payrollEmployees.value.map(
                (j: any) => new BTSelectItem(j)
            );
        }
        if (data.availableJobs && data.availableJobs.value && data.availableJobs.value.length > 0) {
            this.availableJobs = data.availableJobs.value.map((j: any) => new BTSelectItem(j));
        }
    }

    syncStatus: PayrollEmployeeSyncStatus;
    payrollIntegratioName: string;
    payrollEmployees?: BTSelectItem[];
    payrollEmployeeId: string;
    payrollLogoUrl: string;
    canUpdate: boolean;
    canView: boolean;
    availableJobs?: BTSelectItem[];
    payrollJobID: string;
}

export function getCategoryKey(categoryName: string): string {
    switch (categoryName) {
        case "Administrative":
        case "ADMINISTRATIVE":
            return "administrative";
        case "Files":
        case "FILES":
            return "files";
        case "Financial":
        case "FINANCIAL":
            return "financial";
        case "Messaging":
        case "MESSAGING":
            return "messaging";
        case "Project Management":
        case "PROJECT MANAGEMENT":
            return "projectManagement";
        case "Sales":
        case "SALES":
            return "sales";
        default:
            return "";
    }
}

export function getSubCategoryKey(subCategoryName: string): string {
    switch (subCategoryName) {
        case "Leads":
            return "leadPerms";
        case "Schedule":
            return "calendarPerms";
        case "Change Orders":
            return "changeOrderPerms";
        case "Daily Logs":
            return "dailyLogPerms";
        case "Selections":
            return "selectionPerms";
        case "Specifications":
            return "specificationsPerms";
        case "Time Clock":
            return "timeClockPerms";
        case "To-Do's":
            return "toDoPerms";
        case "Warranty":
            return "warrantyPerms";
        case "Admin":
        case "Internal User Information":
            return "adminPerms";
        case "Internal Users":
            return "contactPerms";
        case "Job Info":
        case "Job Information":
            return "jobsitePerms";
        case "Subs/Vendors":
        case "Sub/Vendor Information":
            return "subsPerms";
        case "Documents":
            return "documentPerms";
        case "Photos":
            return "photoPerms";
        case "Videos":
            return "videoPerms";
        case "Bidding":
        case "Bids":
            return "biddingPerms";
        case "Estimate General Items":
            return "estimatePerms";
        // Remove "Owner Invoices" case after ado-223723 is in prod. Temporarily here so this does not break during canary.
        case "Owner Invoices":
        case "Invoices":
            return "paymentPerms";
        case "Estimate":
        case "Estimates":
            return "proposalPerms";
        case "Purchase Orders":
            return "purchaseOrderPerms";
        case "Receipts":
            return "receiptPerms";
        case "Messages":
            return "emailPerms";
        case "RFIs":
            return "rfiPerms";
        case "Surveys":
            return "surveyPerms";
        case "Chat":
            return "chatPerms";
        default:
            return "";
    }
}

export function showAllUserActivityCheckbox(
    type: NotificationTypes,
    role: IRolePermissionFormValues
) {
    switch (type) {
        case NotificationTypes.LeadNotifyActivityReminder:
        case NotificationTypes.LeadProposal_AcceptDecline:
            return role.categories["sales"].subCategories["leadPerms"].permissions[
                "viewAllSalespersonLeadsAndActivities"
            ].enabled;
        case NotificationTypes.WARRANTYNEEDSFOLLOWUP:
            return role.categories["projectManagement"].subCategories["warrantyPerms"].permissions
                .view.enabled;
        case NotificationTypes.TimeClock_Adjustment:
        case NotificationTypes.TimeClock_OvertimeReached:
            return role.categories["projectManagement"].subCategories["timeClockPerms"].permissions[
                "viewOtherUsers"
            ].enabled;
        case NotificationTypes.NEWMESSAGE:
            return role.categories["messaging"].subCategories["emailPerms"].permissions["isGlobal"]
                .enabled;
        default:
            return false;
    }
}

export const blankSignatureImage = "/images/Common/claimNoPhoto.gif";

export type PayrollTabFormActions = undefined | "sync" | "add";

export interface IUserStatus extends IInternalUserStatus {
    statusDate?: Moment;
}

export interface IInternalUserStatus {
    statusType: StatusTypes;
    statusMessage: string;
}

export interface IChangePasswordRequest {
    oldPassword: string;
    newPassword: string;
}
