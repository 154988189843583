import { WarningOutlined } from "@ant-design/icons";
import { message, ModalFuncProps, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SorterResult } from "antd/lib/table/interface";
import classNames from "classnames";
import { InjectedFormikProps, withFormik } from "formik";
import { LineItemType } from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { useContext, useMemo, useState } from "react";
import { useVT } from "virtualizedtableforantd4";

import { AppProvider } from "helpers/AppProvider";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { DateRangeFilterOptionItem } from "types/enum";

import yup from "utilities/form/yup";
import { displayWithFriendlyDefault, friendlyDefaultDisplayValue } from "utilities/helpers";
import { getEntityNameForLineItemType } from "utilities/lineItem/lineItemHelper";
import { divide, multiply, round, subtract } from "utilities/math/math";
import { isNullOrUndefined, nameOf } from "utilities/object/object";
import { useResizeObserver } from "utilities/resizeObserver/useResizeObserver";
import { lexicographicSorter } from "utilities/sort/sort";
import { formattedPlural } from "utilities/string/string";
import { isBuilderTaxPrefActive } from "utilities/tax/tax.utilities";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { btConfirm, btError } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { BTForm, BTFormItem } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTTable } from "commonComponents/btWrappers/BTTable/BTTable";
import {
    DateRange,
    IDateRangeFormValues,
} from "commonComponents/entity/filters/DateRange/DateRange";
import { StackLineItemsConfirm } from "commonComponents/entity/invoicing/LineItemsToInvoice/StackInvoiceLineItemsConfirm";
import { CostTypeList } from "commonComponents/financial/CostTypeList/CostTypeList";
import { Currency } from "commonComponents/financial/Currency/Currency";
import { CurrencyDisplay } from "commonComponents/financial/CurrencyDisplay/CurrencyDisplay";
import { Percentage } from "commonComponents/financial/Percentage/Percentage";
import { HighlightUpdates } from "commonComponents/utilities/HighlightUpdates/HighlightUpdates";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import { PercentageDisplayBar } from "commonComponents/utilities/PercentageDisplayBar/PercentageDisplayBar";
import { PercentageSection } from "commonComponents/utilities/PercentageDisplayBar/PercentageDisplayBar.types";

import {
    isMonthDateDisabled,
    isSemiMonthDateDisabled,
    isWeekDateDisabled,
} from "entity/filters/filters.utils";

import "./LineItemsToInvoice.less";

import {
    ILineItemsToInvoiceColumns,
    ILineItemsToInvoiceFormValues,
    LineItemAncestorEntity,
    LineItemForInvoice,
    LineItemsToInvoiceEntity,
    LineItemsToInvoiceFormActions,
    TableDataType,
} from "./LineItemsToInvoice.api.types";

function isLineItemRow(
    item: LineItemForInvoice | LineItemAncestorEntity
): item is LineItemForInvoice {
    return item.type === TableDataType.LineItem;
}

function isAncestorRow(
    item: LineItemForInvoice | LineItemAncestorEntity
): item is LineItemAncestorEntity {
    return item.type === TableDataType.Parent || item.type === TableDataType.GrandParent;
}

function isGrandparentRow(item: LineItemForInvoice | LineItemAncestorEntity) {
    return item.type === TableDataType.GrandParent;
}

function isParentRow(item: LineItemForInvoice | LineItemAncestorEntity) {
    return item.type === TableDataType.Parent;
}

function isFullyInvoiced(lineItem: LineItemForInvoice) {
    return (
        lineItem.amountRemaining === 0 ||
        (lineItem.amountRemaining < 0 && lineItem.amountInvoiced > 0) ||
        (lineItem.amountRemaining > 0 && lineItem.amountInvoiced < 0)
    );
}

function getDeletedItemVerbiage(itemType: LineItemType) {
    if (itemType === LineItemType.EstimateLineItem) {
        return `This line item no longer exists on the Revised Costs. 
            Invoicing for it from the original Estimate may result in overbilling.`;
    } else {
        return `This line item no longer exists on the original ${getEntityNameForLineItemType(
            itemType
        )}. Invoicing for it may result in overbilling.`;
    }
}

export const getUniqueParents = (lineItems: LineItemForInvoice[]): LineItemAncestorEntity[] => {
    let parents = lineItems
        .map((li) => li.parent)
        .filter((g): g is LineItemAncestorEntity => g !== undefined);
    return parents.filter((g, index, arr) => {
        return arr.map((mapObj) => mapObj.id).indexOf(g.id) === index;
    });
};

export const getUniqueGrandparents = (
    lineItems: LineItemForInvoice[]
): LineItemAncestorEntity[] => {
    let grandparents = lineItems
        .map((li) => li.grandparent)
        .filter((g): g is LineItemAncestorEntity => g !== undefined);
    return grandparents.filter((g, index, arr) => {
        return arr.map((mapObj) => mapObj.id).indexOf(g.id) === index;
    });
};

export interface ILineItemsToInvoiceProps {
    entity: LineItemsToInvoiceEntity;
    title?: string;
    actionBeingPerformed: LineItemsToInvoiceFormActions;
    onSubmit: (values: ILineItemsToInvoiceFormValues) => Promise<void>;
    buttonText?: string;
    useVirtualScrolling?: boolean;
    showParentTotalAmount?: boolean;
    onSelect?: (isChecked: boolean, uniqueId: string) => void;
    modalConfig?: IModalConfiguration;
    showAttachmentsCheckbox: boolean;
}

const LineItemsToInvoiceInternal: React.FunctionComponent<
    InjectedFormikProps<ILineItemsToInvoiceProps, ILineItemsToInvoiceFormValues>
> = (props) => {
    const context = useContext(BuilderInfoContext);
    const {
        entity,
        title,
        values,
        useVirtualScrolling,
        setFieldValue,
        setFieldTouched,
        actionBeingPerformed,
        buttonText,
        showParentTotalAmount = false,
        onSubmit,
        setSubmitting,
        onSelect,
        showAttachmentsCheckbox,
    } = props;

    // Resize the virtual scrolling table to maintain a single scrollbar
    const [scrollHeight, setScrollHeight] = useState(window.innerHeight - 550);
    const [, resizeObserver] = useResizeObserver(() => setScrollHeight(window.innerHeight - 550));

    const { isCostPlusWorkflow, shouldDisplayParentRows } = entity;
    const [selectedLineItemsForStack, setSelectedLineItemsForStack] = useState<
        LineItemForInvoice[]
    >([]);

    const handleAddLineItems = async () => {
        let invalidQuantityLineItemsCount = 0;
        const isCostPlusWorkflow = entity.isCostPlusWorkflow;
        const activeLineItems = values.lineItems.filter(
            (li) => li.invoicedAmount !== 0 && li.isSelected
        );

        if (activeLineItems.length === 0) {
            void message.error(
                "Must have at least one line item with a New Invoice % greater than 0.00%"
            );
            return;
        }

        const validLineItems = activeLineItems.filter((li) => {
            const newQuantity = isCostPlusWorkflow ? 1 : li.quantity * (li.invoicedPercent! / 100);
            const isValidQuantity = 0.0001 < newQuantity || li.shouldApplyRemaining;
            if (!isValidQuantity) {
                invalidQuantityLineItemsCount++;
            }
            return isValidQuantity;
        });

        const hasDeletedItems = activeLineItems.some((li) => li.isDeleted);

        let confirmModalProps: ModalFuncProps[] = [];
        let errorModalProps: ModalFuncProps | undefined;

        let filteredValues = { ...values };
        filteredValues.lineItems = validLineItems;

        if (invalidQuantityLineItemsCount > 0) {
            if (validLineItems.length === 0) {
                errorModalProps = {
                    content: "Line items with a quantity below .0001 cannot be invoiced",
                };
            } else {
                confirmModalProps.push({
                    title: "We are unable to invoice some items",
                    content: `${invalidQuantityLineItemsCount} line ${formattedPlural({
                        value: invalidQuantityLineItemsCount,
                        one: "item has a quantity below .0001, this item",
                        other: "items have a quantity below .0001, these items",
                    })} won't be invoiced.`,
                    okText: `Continue to invoice`,
                });
            }
        }

        if (hasDeletedItems) {
            confirmModalProps.push({
                title: "Add deleted items to invoice?",
                content:
                    "Some of the selected line items are no longer included in your revised costs, but will not be removed from the original estimate unless it is unlocked for editing. Continuing to invoice for these line items may result in overbilling your client.",
                okText: "Add line items to invoice",
            });
        }

        await confirmInvalidItemsAndSubmit(filteredValues, confirmModalProps, errorModalProps);
    };

    const confirmInvalidItemsAndSubmit = async (
        filteredValues: ILineItemsToInvoiceFormValues,
        confirmModalProps: ModalFuncProps[],
        errorModalProps: ModalFuncProps | undefined
    ) => {
        if (!errorModalProps && confirmModalProps.length === 0) {
            await stackLineItemConfirmationAndSubmit(filteredValues);
        }

        if (errorModalProps) {
            btError(errorModalProps);
            return;
        }

        if (confirmModalProps.length > 0) {
            btConfirm({
                ...confirmModalProps.shift()!,
                onOk: async () => {
                    await confirmInvalidItemsAndSubmit(
                        filteredValues,
                        confirmModalProps,
                        errorModalProps
                    );
                },
            });
        }
    };

    const stackLineItemConfirmationAndSubmit = async (
        filteredValues: ILineItemsToInvoiceFormValues
    ) => {
        if (!entity.supportsLineItemStacking) {
            await handleSubmit(filteredValues);
            return;
        }

        // check if any of the line items have the same cost code
        const hasLineItemsWithSameCostCode = filteredValues.lineItems.some(
            (lineItem) =>
                filteredValues.lineItems.filter((li) => li.costCode === lineItem.costCode).length >
                1
        );
        if (hasLineItemsWithSameCostCode) {
            setSelectedLineItemsForStack(filteredValues.lineItems);
        } else {
            await handleSubmit(filteredValues);
        }
    };

    const handleSubmit = async (filteredValues: ILineItemsToInvoiceFormValues) => {
        setSubmitting(true);
        await onSubmit(filteredValues);
        setSubmitting(false);
    };

    const handleStackLineItemsConfirm = async () => {
        let filteredFormValues = { ...values, lineItems: selectedLineItemsForStack };
        setSelectedLineItemsForStack([]);
        await handleSubmit(filteredFormValues);
    };

    const getTotalInvoicedAmount = (lineItems: LineItemForInvoice[]) => {
        return lineItems.reduce(
            (amount, lineItem) => amount + (lineItem.isSelected ? lineItem.invoicedAmount || 0 : 0),
            0
        );
    };

    const validateTotalInvoicedAmount = (lineItems: LineItemForInvoice[]) => {
        const maxInvoiceAmount = 1000000000;
        if (getTotalInvoicedAmount(lineItems) > maxInvoiceAmount) {
            void message.error(
                <AppProvider isRoot={false}>
                    New Invoice Total cannot exceed <CurrencyDisplay value={maxInvoiceAmount} />
                </AppProvider>
            );
            return false;
        }
        return true;
    };

    const calculateLineItemPercent = (
        lineItem: LineItemForInvoice,
        isCostPlusWorkflow: boolean,
        value: number | undefined
    ) => {
        let calculatedValue;
        if (isCostPlusWorkflow) {
            calculatedValue =
                value != null
                    ? multiply(
                          divide(subtract(value, lineItem.builderCost), lineItem.builderCost),
                          100
                      ).toNumber()
                    : 0;
        } else {
            calculatedValue = value
                ? multiply(divide(value, lineItem.ownerPrice), 100).toNumber()
                : 0;
        }

        return calculatedValue;
    };

    // Return value for this is unrounded, as it's used to find an exact percent markup.
    const calculateLineItemAmount = (
        lineItem: LineItemForInvoice,
        isCostPlusWorkflow: boolean,
        percent: number | undefined
    ) => {
        if (isCostPlusWorkflow) {
            return percent !== undefined
                ? (lineItem.builderCost * percent) / 100 + lineItem.builderCost
                : 0;
        }
        return percent ? (lineItem.ownerPrice * percent) / 100 : 0;
    };

    const getFooter = () => {
        const indexOffset = 1;

        return (
            <Table.Summary fixed>
                <Table.Summary.Row>
                    <Table.Summary.Cell key={0} index={0} />
                    {tableColumns.map((x, index) => {
                        return (
                            <Table.Summary.Cell
                                key={index + indexOffset}
                                index={index + indexOffset}
                                className={classNames("LineItemFooter", {
                                    LastColumnRightAlign: index === tableColumns.length - 1,
                                })}
                            >
                                {x.getFooterValue && x.getFooterValue()}
                            </Table.Summary.Cell>
                        );
                    })}
                </Table.Summary.Row>
            </Table.Summary>
        );
    };

    const memoDataSource = useMemo(() => {
        if (entity.shouldDisplayGrandparentRows) {
            let data: (LineItemForInvoice | LineItemAncestorEntity)[] = [];

            let grandparents = getUniqueGrandparents(values.lineItems);
            let parents = getUniqueParents(values.lineItems);
            grandparents.forEach((grandparent: LineItemAncestorEntity) => {
                data.push(grandparent);
                let relatedParents = parents.filter((parent) => parent.parentId === grandparent.id);
                relatedParents.forEach((parent) => {
                    let parentGroupedLineItems = values.lineItems.filter(
                        (li) => li.parent && li.parent.id === parent.id
                    );
                    data = [...data, parent, ...parentGroupedLineItems];
                });
            });
            return data;
        } else if (entity.shouldDisplayParentRows) {
            let data: (LineItemForInvoice | LineItemAncestorEntity)[] = [];
            let parents = getUniqueParents(values.lineItems);
            parents.forEach((parent: LineItemAncestorEntity) => {
                let parentGroupedLineItems = values.lineItems.filter(
                    (li) => li.parent && li.parent.id === parent.id
                );
                data = [...data, parent, ...parentGroupedLineItems];
            });
            return data;
        } else {
            return values.lineItems;
        }
    }, [entity, values.lineItems]);

    const correctPercentage = (lineItem: LineItemForInvoice) => {
        if (lineItem.invoicedPercent === undefined) {
            updatePercentage(lineItem, 0);
        }
        if (!entity.isCostPlusWorkflow) {
            if (lineItem.invoicedPercent && lineItem.invoicedPercent > 100) {
                void message.error("Cannot invoice for more than 100% of Client Price");
                updatePercentage(lineItem, 100);
                return;
            }
            if (lineItem.invoicedPercent && lineItem.invoicedPercent < 0) {
                void message.error("Cannot invoice for less than 0% of Client Price");
                updatePercentage(lineItem, 0);
                return;
            }
        } else {
            if (lineItem.invoicedPercent! < -100) {
                void message.error("Cannot invoice for less than -100% of Builder Cost");
                updatePercentage(lineItem, -100);
                return;
            }
        }
        validateTotalInvoicedAmount(values.lineItems);
    };

    const correctAmount = (lineItem: LineItemForInvoice) => {
        if (lineItem.invoicedAmount === undefined) {
            updateAmount(lineItem, 0);
        }
        if (!entity.isCostPlusWorkflow) {
            if (lineItem.ownerPrice < 0 && lineItem.invoicedAmount && lineItem.invoicedAmount > 0) {
                void message.error("Negative line items must be invoiced at a negative amount");
                updateAmount(lineItem, 0);
                return;
            }
            if (lineItem.ownerPrice > 0 && lineItem.invoicedAmount && lineItem.invoicedAmount < 0) {
                void message.error(
                    <AppProvider isRoot={false}>
                        Invoice Amount cannot be less than <CurrencyDisplay value={0} />
                    </AppProvider>
                );
                updateAmount(lineItem, 0);
                return;
            }
            if (
                lineItem.invoicedAmount &&
                Math.abs(lineItem.invoicedAmount) > Math.abs(lineItem.ownerPrice)
            ) {
                void message.error("Cannot invoice for more than 100% of Client Price");
                updateAmount(lineItem, 0);
                return;
            }
        }
        validateTotalInvoicedAmount(values.lineItems);
    };

    const updatePercentage = (lineItem: LineItemForInvoice, percent: number | undefined) => {
        const updatedLineItem = { ...lineItem };
        if (percent === undefined || isNaN(percent)) {
            updatedLineItem.invoicedPercent = undefined;
            updatedLineItem.invoicedAmount = 0;
        } else {
            updatedLineItem.invoicedPercent = round(percent, 2);
            let newInvoicedAmount = round(
                calculateLineItemAmount(lineItem, entity.isCostPlusWorkflow, percent),
                2
            );
            if (
                !entity.isCostPlusWorkflow &&
                ((updatedLineItem.ownerPrice > 0 &&
                    newInvoicedAmount > updatedLineItem.amountRemaining) ||
                    (updatedLineItem.ownerPrice < 0 &&
                        newInvoicedAmount < updatedLineItem.amountRemaining))
            ) {
                newInvoicedAmount = updatedLineItem.amountRemaining;
                updatedLineItem.invoicedPercent = calculateLineItemPercent(
                    lineItem,
                    entity.isCostPlusWorkflow,
                    newInvoicedAmount
                );
            }
            updatedLineItem.invoicedAmount = newInvoicedAmount;
        }
        updateLineItemValue(lineItem, updatedLineItem);
    };

    const updateLineItemValue = (
        oldLineItem: LineItemForInvoice,
        updatedLineItem: LineItemForInvoice
    ) => {
        const lineItems = [...values.lineItems];
        const ix = lineItems.indexOf(oldLineItem);
        lineItems[ix] = updatedLineItem;
        setFieldValue("lineItems", lineItems);
    };

    const updateAmount = (lineItem: LineItemForInvoice, value: number | undefined) => {
        const updatedLineItem = { ...lineItem };
        let updatedAmount = value !== undefined && !isNaN(value) ? round(value!, 2) : undefined;
        if (
            !entity.isCostPlusWorkflow &&
            updatedAmount &&
            ((updatedLineItem.ownerPrice > 0 && updatedAmount > updatedLineItem.amountRemaining) ||
                (updatedLineItem.ownerPrice < 0 && updatedAmount < updatedLineItem.amountRemaining))
        ) {
            updatedAmount = updatedLineItem.amountRemaining;
        }
        updatedLineItem.invoicedAmount = updatedAmount;
        updatedLineItem.invoicedPercent = calculateLineItemPercent(
            lineItem,
            entity.isCostPlusWorkflow,
            updatedAmount
        );
        updateLineItemValue(lineItem, updatedLineItem);
    };

    const handleTableSorted = (
        sorter: SorterResult<LineItemForInvoice | LineItemAncestorEntity>
    ) => {
        if (typeof sorter.column?.sorter === "function") {
            const sortFn = sorter.column!.sorter;
            let sortedLineItems = [...values.lineItems].sort(sortFn);
            if (sorter.order === "descend") {
                sortedLineItems.reverse();
            }
            setFieldValue("lineItems", sortedLineItems);
        }
    };

    const updateLineItemInvoiceOverview = (lineItem: LineItemForInvoice) => {
        let overview = [...lineItem.invoiceOverview!].filter((x) => !x.striped);
        overview.push(
            new PercentageSection({
                color: "green",
                isOutstanding: false,
                label: "New Invoice Amount",
                percentage: lineItem.invoicedPercent!,
                striped: true,
                value: lineItem.invoicedAmount!,
            })
        );
        return overview;
    };

    let tableColumns: ILineItemsToInvoiceColumns<LineItemForInvoice | LineItemAncestorEntity>[] =
        [];
    const emptyTableCell = {
        children: null,
        props: {},
    };

    tableColumns.push(
        {
            title: "Items",
            "data-testid": "items",
            sorter: !shouldDisplayParentRows
                ? (item1, item2) => {
                      if (isLineItemRow(item1) && isLineItemRow(item2)) {
                          const item1CostCodeTitle = `${item1.costCodeTitle}${item1.title}`;
                          const item2CostCodeTitle = `${item2.costCodeTitle}${item2.title}`;
                          return lexicographicSorter(item1CostCodeTitle, item2CostCodeTitle);
                      }
                      return 0;
                  }
                : undefined,
            render: (data, row) => {
                if (isAncestorRow(row)) {
                    if (showParentTotalAmount) {
                        // show the parent title in the items column of the group row
                        return {
                            children: (
                                <span
                                    className={classNames({
                                        "padding-left-md":
                                            entity.shouldDisplayGrandparentRows && isParentRow(row),
                                        "text-bold": isGrandparentRow(row),
                                    })}
                                >
                                    {row.title}
                                </span>
                            ),
                            props: {},
                        };
                    } else {
                        return emptyTableCell; // parent title will already be displayed in parent column
                    }
                }
                return (
                    <div className="flex">
                        <div
                            className={classNames({
                                "padding-left-md": entity.shouldDisplayGrandparentRows,
                            })}
                        >
                            <div>
                                <strong>{displayWithFriendlyDefault(row.title)}</strong>
                            </div>
                            <div>{row.costCodeTitle}</div>
                        </div>
                        {row.isDeleted && (
                            <BTPopover
                                className="WarningIcon"
                                content={getDeletedItemVerbiage(row.lineItemType)}
                            >
                                <WarningOutlined />
                            </BTPopover>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Cost Types",
            dataIndex: nameOf<LineItemForInvoice>("costTypes"),
            "data-testid": "costTypes",
            render: (_, row) => {
                if (row.type !== TableDataType.LineItem) {
                    return emptyTableCell;
                }
                return <CostTypeList costTypes={row.costTypes} />;
            },
        }
    );

    tableColumns.push({
        title: isCostPlusWorkflow ? "Builder Cost" : "Client Price",
        "data-testid": "ownerPrice",
        dataIndex: isCostPlusWorkflow
            ? nameOf<LineItemForInvoice>("builderCost")
            : nameOf<LineItemForInvoice>("ownerPrice"),
        align: "right",
        render: (data, row) => {
            if (isLineItemRow(row)) {
                return (
                    <CurrencyDisplay
                        value={isCostPlusWorkflow ? row.builderCost : row.ownerPrice}
                    />
                );
            } else if (isAncestorRow(row) && showParentTotalAmount) {
                return (
                    <CurrencyDisplay
                        className={classNames({ "text-bold": isGrandparentRow(row) })}
                        value={row.totalAmount!}
                    />
                );
            }
            return emptyTableCell;
        },
        sorter: !shouldDisplayParentRows
            ? (item1, item2) => {
                  if (isLineItemRow(item1) && isLineItemRow(item2)) {
                      if (isCostPlusWorkflow) {
                          return item1.builderCost - item2.builderCost;
                      } else {
                          return item1.ownerPrice - item2.ownerPrice;
                      }
                  }
                  return 0;
              }
            : undefined,
    });
    if (!isCostPlusWorkflow) {
        tableColumns.push({
            title: "Invoice Overview",
            "data-testid": "invoiceOverview",
            width: 210,
            render: (data, row) => {
                if (isLineItemRow(row)) {
                    return (
                        <PercentageDisplayBar
                            outstandingLabel="Not yet invoiced"
                            totalValue={row.ownerPrice}
                            percentageSections={updateLineItemInvoiceOverview(row)}
                        />
                    );
                }
                return emptyTableCell;
            },
        });
    }
    tableColumns.push(
        {
            title: isCostPlusWorkflow ? "% Markup" : "New Invoice %",
            "data-testid": "invoicedPercent",
            dataIndex: nameOf<LineItemForInvoice>("invoicedPercent"),
            width: 160,
            align: "right",
            render: (data, row, index) => {
                const id = `lineItems[${index}].invoicedPercent`;
                if (isLineItemRow(row)) {
                    if (isFullyInvoiced(row)) {
                        return friendlyDefaultDisplayValue;
                    }
                    return (
                        <HighlightUpdates value={data}>
                            <Percentage
                                id={id}
                                data-testid={id}
                                style={{ textAlign: "right" }}
                                value={data}
                                allowNegative
                                onChange={(field, value) => {
                                    updatePercentage(row, value);
                                }}
                                onBlur={() => correctPercentage(row)}
                                disabled={!row.isSelected}
                            />
                        </HighlightUpdates>
                    );
                }
                return emptyTableCell;
            },
            sorter: !shouldDisplayParentRows
                ? (item1, item2) => {
                      if (isLineItemRow(item1) && isLineItemRow(item2)) {
                          return (item1.invoicedPercent ?? 0) - (item2.invoicedPercent ?? 0);
                      }
                      return 0;
                  }
                : undefined,
            getFooterValue: () => {
                if (isBuilderTaxPrefActive(context, true)) {
                    return "Invoice Subtotal";
                } else {
                    return "Total Invoice Amount";
                }
            },
        },
        {
            title: "New Invoice Amount",
            "data-testid": "invoicedAmount",
            dataIndex: nameOf<LineItemForInvoice>("invoicedAmount"),
            width: 200,
            align: "right",
            render: (data, row, index) => {
                const id = `lineItems[${index}].invoicedAmount`;
                if (isLineItemRow(row)) {
                    if (isFullyInvoiced(row)) {
                        return friendlyDefaultDisplayValue;
                    }
                    return (
                        <HighlightUpdates value={data}>
                            <Currency
                                id={id}
                                data-testid={id}
                                value={data}
                                readOnly={isCostPlusWorkflow}
                                onChange={(field, value) => {
                                    updateAmount(row, value);
                                }}
                                onBlur={() => {
                                    correctAmount(row);
                                }}
                                disabled={!row.isSelected}
                            />
                        </HighlightUpdates>
                    );
                }
                return emptyTableCell;
            },
            sorter: !shouldDisplayParentRows
                ? (item1, item2) => {
                      if (isLineItemRow(item1) && isLineItemRow(item2)) {
                          return (item1.invoicedAmount ?? 0) - (item2.invoicedAmount ?? 0);
                      }
                      return 0;
                  }
                : undefined,
            getFooterValue: () => {
                return <CurrencyDisplay value={getTotalInvoicedAmount(values.lineItems)} />;
            },
        }
    );

    const updateOverallPercent = () => {
        validateOrResetAdjustInvoicePercent();

        let updatedLineItems = values.lineItems.map((value) => {
            const li: LineItemForInvoice = { ...value };
            if (!li.isSelected) {
                return li;
            }
            let amountInvoiced = calculateLineItemAmount(
                li,
                entity.isCostPlusWorkflow,
                values.adjustInvoicePercent || 0
            );
            if (!entity.isCostPlusWorkflow) {
                let percentRemaining = 100 - (li.amountInvoiced / li.ownerPrice) * 100;
                if (values.adjustInvoicePercent >= percentRemaining) {
                    amountInvoiced = li.amountRemaining;
                }
            }
            li.invoicedAmount = round(amountInvoiced, 2);
            li.invoicedPercent = calculateLineItemPercent(
                li,
                entity.isCostPlusWorkflow,
                amountInvoiced
            );
            return li;
        });
        setFieldValue("lineItems", updatedLineItems);
        validateTotalInvoicedAmount(updatedLineItems);
    };

    const validateOrResetAdjustInvoicePercent = () => {
        if (!entity.isCostPlusWorkflow && values.adjustInvoicePercent > 100) {
            void message.error("Cannot invoice for more than 100% of Client Price");
            setFieldValue("adjustInvoicePercent", 100);
        }
    };

    const handleOverallPercentageOnBlur = (field: string, touched: boolean) => {
        setFieldTouched(field, touched);
        validateOrResetAdjustInvoicePercent();
    };

    const handleSelectAll = (isSelected: boolean) => {
        const updatedLineItems = values.lineItems.map((x) => {
            if (!isSelected) {
                x.invoicedAmount = isCostPlusWorkflow ? x.ownerPrice : 0;
                x.invoicedPercent = 0;
            }
            return {
                ...x,
                isSelected: isSelected && !isFullyInvoiced(x),
            };
        });
        setFieldValue("lineItems", updatedLineItems);

        if (onSelect) {
            onSelect(isSelected, "selectAllLineItems");
        }
    };

    const handleSelectionChange = (
        row: LineItemForInvoice | LineItemAncestorEntity,
        isSelected: boolean
    ) => {
        if (isLineItemRow(row)) {
            const updatedLineItems = values.lineItems.map((x) => {
                if (x.lineItemId === row.lineItemId) {
                    x.isSelected = isSelected;
                    if (!isSelected) {
                        x.invoicedAmount = isCostPlusWorkflow ? x.ownerPrice : 0;
                        x.invoicedPercent = 0;
                    }
                }
                return x;
            });
            setFieldValue("lineItems", updatedLineItems);
            if (onSelect) {
                onSelect(isSelected, `lineItem[${row.lineItemId}].isSelected`);
            }
        } else if (isAncestorRow(row)) {
            const updatedLineItems = values.lineItems.map((x) => {
                if (
                    (isGrandparentRow(row) ? x.grandparent?.id : x.parent?.id) === row.id &&
                    !isFullyInvoiced(x)
                ) {
                    x.isSelected = isSelected;
                    if (!isSelected) {
                        x.invoicedAmount = isCostPlusWorkflow ? x.ownerPrice : 0;
                        x.invoicedPercent = 0;
                    }
                }
                return x;
            });
            setFieldValue("lineItems", updatedLineItems);
            if (onSelect) {
                onSelect(isSelected, `parentLineItem[${row.id}].isSelected`);
            }
        }
    };

    const filterLineItemsByDate = async (newDateValue: IDateRangeFormValues) => {
        // update visible line items based on selected date
        let visibleLineItems = props.entity.lineItems.filter((li) => li.ownerPrice !== 0);

        let startDate =
            !isNullOrUndefined(newDateValue.dateRange) &&
            !isNullOrUndefined(newDateValue.dateRange[0])
                ? newDateValue.dateRange[0]
                : moment();
        let endDate = moment();

        switch (newDateValue.selectedItem) {
            case DateRangeFilterOptionItem.CustomDates:
                startDate =
                    !isNullOrUndefined(newDateValue.dateRange) &&
                    !isNullOrUndefined(newDateValue.dateRange[0])
                        ? newDateValue.dateRange[0]
                        : startDate;
                endDate =
                    !isNullOrUndefined(newDateValue.dateRange) &&
                    !isNullOrUndefined(newDateValue.dateRange[1])
                        ? newDateValue.dateRange[1]
                        : endDate;
                break;
            case DateRangeFilterOptionItem.Weekly:
                endDate = moment(startDate).add(6, "d");
                break;
            case DateRangeFilterOptionItem.BiWeekly:
                endDate = moment(startDate).add(13, "d");
                break;
            case DateRangeFilterOptionItem.SemiMonthly:
                if (startDate.day() < 14) {
                    endDate = moment(startDate).add(14, "d");
                } else {
                    endDate = moment(startDate).endOf("M");
                }
                break;
            case DateRangeFilterOptionItem.Monthly:
                startDate = moment(startDate).startOf("M");
                endDate = moment(startDate).endOf("M");
                break;
            case DateRangeFilterOptionItem.Today:
            case DateRangeFilterOptionItem.TodayAndYesterday:
            case DateRangeFilterOptionItem.Past7Days:
            case DateRangeFilterOptionItem.Past14Days:
            case DateRangeFilterOptionItem.Past30Days:
            case DateRangeFilterOptionItem.Past45Days:
            case DateRangeFilterOptionItem.Past60Days:
            case DateRangeFilterOptionItem.Past90Days:
            case DateRangeFilterOptionItem.Past180Days:
            case DateRangeFilterOptionItem.Past365Days:
                // the enum value correlates to number of days to subtract
                startDate = moment(endDate).add(newDateValue.selectedItem, "d");
                break;
            default:
                // all dates selected or invalid range selected, return all line items
                setFieldValue("lineItems", visibleLineItems);
                await message.success("Results updated");
                return;
        }

        // use startOf/endOf to get all shifts on days regardless of time
        startDate = moment(startDate).startOf("d");
        endDate = moment(endDate).endOf("d");
        visibleLineItems = visibleLineItems.filter((li) =>
            li.dateToFilter?.isBetween(startDate, endDate)
        );
        setFieldValue("lineItems", visibleLineItems);
        await message.success("Results updated");
    };

    const shouldFilterLineItems = (updatedValues: IDateRangeFormValues) => {
        const dateDropdownSelectedValue = updatedValues.selectedItem;
        // only requires a start date
        const singleDateDropdownValues = [
            DateRangeFilterOptionItem.Weekly,
            DateRangeFilterOptionItem.BiWeekly,
            DateRangeFilterOptionItem.SemiMonthly,
            DateRangeFilterOptionItem.Monthly,
        ];
        const requireDateDropdownValues = [
            ...singleDateDropdownValues,
            DateRangeFilterOptionItem.CustomDates,
        ];

        const hasStartDate = updatedValues?.dateRange
            ? !isNullOrUndefined(updatedValues.dateRange[0])
            : false;
        const hasEndDate = updatedValues?.dateRange
            ? !isNullOrUndefined(updatedValues.dateRange[1])
            : false;
        return (
            !requireDateDropdownValues.includes(dateDropdownSelectedValue) || // selected dropdown value that doesn't require date. i.e. past 7 days
            (singleDateDropdownValues.includes(dateDropdownSelectedValue) && hasStartDate) ||
            (hasStartDate && hasEndDate)
        );
    };

    const handleDateChange = async (field: string, newDateValue: IDateRangeFormValues) => {
        setFieldValue(field, newDateValue);
        const shouldFilter = shouldFilterLineItems(newDateValue);
        if (shouldFilter) {
            await filterLineItemsByDate(newDateValue);
        }
    };

    const handleDateRangeChange = async (field: string, value: any) => {
        let selectedDate;
        switch (value) {
            case DateRangeFilterOptionItem.Weekly:
            case DateRangeFilterOptionItem.BiWeekly:
                selectedDate = moment();
                while (isWeekDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            case DateRangeFilterOptionItem.SemiMonthly:
                selectedDate = moment();
                while (isSemiMonthDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            case DateRangeFilterOptionItem.Monthly:
                selectedDate = moment();
                while (isMonthDateDisabled(selectedDate)) {
                    selectedDate.subtract(1, "day");
                }
                break;
            default:
                selectedDate = undefined;
                break;
        }

        const newValue: IDateRangeFormValues = {
            dateRange: selectedDate ? [selectedDate, null] : null,
            selectedItem: value,
        };

        await handleDateChange(field, newValue);
    };

    const isFullyInvoicedAncestors = (row: LineItemAncestorEntity) => {
        const selectedItems = values.lineItems.filter((x) => {
            return (
                (isGrandparentRow(row) ? x.grandparent?.id : x.parent?.id) === row.id &&
                !isFullyInvoiced(x)
            );
        });
        return selectedItems.length === 0;
    };

    const selectedRows = memoDataSource
        .filter((x) => {
            if (isLineItemRow(x)) {
                return x.isSelected || x.isFullyInvoicedButNotSaved;
            } else {
                return values.lineItems
                    .filter(
                        (li) => (isGrandparentRow(x) ? li.grandparent?.id : li.parent?.id) === x.id
                    )
                    .every((li) => li.isSelected || isFullyInvoiced(li));
            }
        })
        .map((x) => (isLineItemRow(x) ? x.lineItemId : x.id));

    const isDateFilterDisabled = selectedRows.length > 0;

    const [vt] = useVT(() => ({ scroll: { y: 800 } }), []);

    const footerButtons = (
        <BTButton
            type="primary"
            data-testid="createInvoice"
            onClick={() => {
                if (validateTotalInvoicedAmount(values.lineItems)) {
                    void handleAddLineItems();
                }
            }}
            actionBeingPerformed={actionBeingPerformed}
            loadingAction="save"
            disabled={values.lineItems.every((x) => !x.isSelected)}
        >
            {buttonText ?? <>Create Invoice</>}
        </BTButton>
    );

    return (
        <BTModalLayout title={title} footerContent={footerButtons} modalConfig={props.modalConfig}>
            <BTForm
                data-testid="lineItemsToInvoice"
                onSubmit={handleAddLineItems}
                className="LineItemsToInvoice"
            >
                <BTLayoutContent>
                    <PageSection title={entity.pageSectionTitle}>
                        <StackLineItemsConfirm
                            {...props}
                            showConfirmModal={selectedLineItemsForStack.length > 1}
                            onContinue={handleStackLineItemsConfirm}
                            onClose={() => setSelectedLineItemsForStack([])}
                        />
                        <>
                            {!entity.isCostPlusWorkflow && (
                                <BTRow className="padding-bottom-xs">
                                    Select any line items to add to the invoice and indicate the
                                    percentage or amount to invoice.
                                </BTRow>
                            )}
                            {entity.isCostPlusWorkflow && (
                                <BTRow className="padding-bottom-xs">
                                    Select line items to add to the invoice and add the markup %.
                                </BTRow>
                            )}
                        </>
                        {!entity.hideDescriptionAndNotesCheckbox && (
                            <BTRow>
                                <BTCol xs={12}>
                                    <BTCheckbox
                                        id="includeLineItemDescription"
                                        data-testid="chkIncludeLineItemDescription"
                                        checked={values.includeDescriptions}
                                        onChange={(e: CheckboxChangeEvent) => {
                                            setFieldValue("includeDescriptions", e.target.checked);
                                        }}
                                    >
                                        Include Line Item Descriptions &#38; Notes
                                    </BTCheckbox>
                                </BTCol>
                            </BTRow>
                        )}
                        {showAttachmentsCheckbox && (
                            <BTRow>
                                <BTCol xs={12}>
                                    <BTCheckbox
                                        id="includeAttachments"
                                        data-testid="includeAttachments"
                                        checked={values.includeAttachments}
                                        onChange={(e) => {
                                            setFieldValue("includeAttachments", e.target.checked);
                                        }}
                                    >
                                        Import Attachments to Invoice
                                    </BTCheckbox>
                                    <BTPopover
                                        content="Attachments carried into the invoice will keep their previously assigned client permissions."
                                        data-testid="includeAttchmentsHelpMessage"
                                    />
                                </BTCol>
                            </BTRow>
                        )}
                        <BTRow>
                            {entity.dateRangeOptions && (
                                <BTCol sm={16} md={12} lg={8}>
                                    <BTFormItem label="Date">
                                        <DateRange
                                            id="dateRange"
                                            data-testid="dateRange"
                                            title=""
                                            onBlur={setFieldTouched}
                                            onChange={handleDateChange}
                                            onDateRangeChange={handleDateRangeChange}
                                            isWeekDateDisabled={isWeekDateDisabled}
                                            isSemiMonthDateDisabled={isSemiMonthDateDisabled}
                                            entity={entity.dateRangeOptions}
                                            value={values.dateRange!}
                                            isDisabled={isDateFilterDisabled}
                                            disabledPopoverContent="Deselect items to enable filtering."
                                        />
                                    </BTFormItem>
                                </BTCol>
                            )}
                            <BTCol
                                sm={entity.dateRangeOptions ? 8 : 24} // take up entire column if date filter not present
                                md={entity.dateRangeOptions ? 12 : undefined}
                                lg={entity.dateRangeOptions ? 16 : undefined}
                            >
                                <BTFormItem
                                    label={
                                        entity.isCostPlusWorkflow
                                            ? "Adjust % Markup"
                                            : "Adjust Invoice %"
                                    }
                                    style={{ float: "right" }}
                                >
                                    <Percentage
                                        id="adjustInvoicePercent"
                                        data-testid="adjustInvoicePercent"
                                        value={values.adjustInvoicePercent}
                                        onChange={setFieldValue}
                                        onBlur={handleOverallPercentageOnBlur}
                                        style={{ textAlign: "right", maxWidth: 110 }}
                                        allowNegative={false} // currently we don't allow costPlus to apply negative markup for this mass apply
                                    />
                                    <BTButton
                                        className="Apply"
                                        actionBeingPerformed="applyPercentage"
                                        data-testid="applyPercentage"
                                        onClick={updateOverallPercent}
                                        disabled={values.lineItems.every((x) => !x.isSelected)}
                                    >
                                        Apply
                                    </BTButton>
                                </BTFormItem>
                            </BTCol>
                        </BTRow>
                        <div ref={resizeObserver}>
                            <BTTable
                                rowKey={(record: LineItemForInvoice | LineItemAncestorEntity) => {
                                    if (isNullOrUndefined(record.type)) {
                                        return "footer";
                                    }
                                    return isLineItemRow(record) ? record.lineItemId : record.id;
                                }}
                                dataSource={memoDataSource}
                                columns={tableColumns}
                                pagination={false}
                                onChange={(_pagination, _filter, sorter) =>
                                    handleTableSorted(sorter instanceof Array ? sorter[0] : sorter)
                                }
                                components={useVirtualScrolling ? vt : undefined}
                                scroll={useVirtualScrolling ? { y: scrollHeight } : undefined}
                                onRow={(record) => {
                                    return {
                                        className: classNames({
                                            ParentRow: isAncestorRow(record),
                                        }),
                                    };
                                }}
                                rowSelection={{
                                    type: "checkbox",
                                    onSelect: handleSelectionChange,
                                    selectedRowKeys: selectedRows,
                                    getCheckboxProps: (record) =>
                                        ({
                                            "data-testid": `lineItem[${memoDataSource.indexOf(
                                                record
                                            )}].isSelected`,
                                            "aria-label": isAncestorRow(record)
                                                ? record.title
                                                : `${record.title} ${record.costCodeTitle}`,
                                            disabled:
                                                (isLineItemRow(record) &&
                                                    isFullyInvoiced(record)) ||
                                                (isAncestorRow(record) &&
                                                    isFullyInvoicedAncestors(record)),
                                        } as any),
                                    onSelectAll: handleSelectAll,
                                }}
                                summary={getFooter}
                            />
                        </div>
                    </PageSection>
                </BTLayoutContent>
            </BTForm>
        </BTModalLayout>
    );
};

const LineItemsToInvoiceValidators = yup.object().shape<ILineItemsToInvoiceFormValues>({
    lineItems: yup.array().of(yup.object()),
    adjustInvoicePercent: yup.number(),
    includeDescriptions: yup.boolean(),
    showLineItemsToOwner: yup.boolean(),
    includeAttachments: yup.boolean(),
    stackLineItems: yup.boolean(),
    supportsLineItemStacking: yup.boolean(),
});

export const LineItemsToInvoicePresentational = withFormik<
    ILineItemsToInvoiceProps,
    ILineItemsToInvoiceFormValues
>({
    // use the default values we specified above
    mapPropsToValues: (props: ILineItemsToInvoiceProps) => ({
        lineItems: props.entity.lineItems.filter((li) => li.ownerPrice !== 0),
        adjustInvoicePercent: props.entity.isCostPlusWorkflow ? 0 : 100,
        includeDescriptions: true,
        showLineItemsToOwner: props.entity.showLineItemsToOwner,
        includeAttachments: false,
        dateRange: props.entity.dateRangeOptions
            ? {
                  dateRange: [null, null] as RangeValue<moment.Moment>,
                  selectedItem: DateRangeFilterOptionItem.AllDates,
              }
            : null,
        stackLineItems: false,
        supportsLineItemStacking: props.entity.supportsLineItemStacking,
    }),

    // use the yup validation object we specified above
    validationSchema: () => LineItemsToInvoiceValidators,
    validateOnChange: true,
    validateOnBlur: true,

    handleSubmit: () => {},
})(LineItemsToInvoiceInternal);
