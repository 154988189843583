// eslint-disable-next-line no-restricted-imports
import { GroupDescriptor, SortDescriptor } from "@progress/kendo-data-query";
// eslint-disable-next-line no-restricted-imports
import { GridCellProps, GridDetailRowProps } from "@progress/kendo-react-grid";
import classNames from "classnames";
import { FormikProps, withFormik } from "formik";
import update from "immutability-helper";
import { isEqual } from "lodash-es";
import { Component, ComponentType, ReactNode, Suspense } from "react";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { add, remove } from "utilities/array/array";
import {
    getFilterEntityType,
    ListEntityType,
    ListMetadata,
    PagingData,
} from "utilities/list/list.types";
import { lazyLoadWithRetry } from "utilities/react.utilities";

import { StickyHeaderType } from "commonComponents/btWrappers/BTListLayout/BTListLayoutHeader";
import { BTModal } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import {
    getSelectedEntities,
    setSelected,
} from "commonComponents/utilities/Grid/common/BTGridSelectCell/BTGridSelectCell";
import { CheckedActions } from "commonComponents/utilities/Grid/common/CheckedActions/CheckedActions";
import {
    GridViewColumn,
    GridViewItem,
    IGridSettingsFormValues,
} from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";
import { GridSize } from "commonComponents/utilities/Grid/Grid.types";
import { GridContainerHeader } from "commonComponents/utilities/Grid/GridContainerHeader";
import { SkeletonTable } from "commonComponents/utilities/SkeletonTable/SkeletonTable";

import { SavedViewsAndFiltersSettingsState } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersCard/SavedViewsAndFiltersCard.api.types";
import { SavedViewsAndFiltersSettings } from "entity/filters/SavedViewsAndFiltersList/SavedViewsAndFiltersSettings/SavedViewsAndFiltersSettings";

import "./GridContainer.less";

import { IBTGridFormValues, IExpandMap } from "./BTGrid";
import {
    getGroupedData,
    GridColumn,
    GridColumnType,
    IGridRowData,
    IGridState,
    IGroupedRowData,
    isGridGroupRow,
    isGridRowData,
    ToolbarDownloadAction,
} from "./GridContainer.types";

const BTGrid = lazyLoadWithRetry(() => import("./BTGrid"));

export const initialGridState: Readonly<IGridState> = {
    data: [],
    dataState: "loading",
    hasUnsavedChanges: false,
    sortingData: [],
    selectedData: [],
};

export const defaultGridEntity = {
    serverColumnsList: [],
    columnsList: [],
    columnsListAll: [],
    data: [],
    footerData: null,
    columnType: GridColumnType.text,
    totalPages: 0,
    records: 0,
    pageSize: 0,
    page: 0,
    infiniteScrollStatus: 0,
    isLoaded: true,
    canAdd: false,
    canUpdate: false,
    canDelete: false,
    pagingData: new PagingData(),
    listMetadata: new ListMetadata({ hasData: false }),
    transformEntity: () => {},
    transformCustomFields: () => [],
    transformCustomFieldFooterData: () => [],
};

export const defaultGridState = {
    data: [],
    dataState: "loading" as const,
    columnsList: [],
    footerData: null,
    sortingData: [],
    hasUnsavedChanges: false,
    selectedData: [],
};

export const gridStateManager = {
    onDataActionPerformed: (prevState: IGridState): IGridState => ({
        ...prevState,
        dataState: "loading",
    }),
    onError: (prevState: IGridState): IGridState => ({
        ...prevState,
        dataState: "error",
    }),
    onDataAndColumnsLoaded: (
        prevState: IGridState,
        data: (IGridRowData | IGroupedRowData)[],
        columnsList: GridColumn<unknown>[],
        footerData?: { [key: string]: any } | null,
        sortingData?: SortDescriptor[],
        updatedGridView?: GridViewItem,
        groups?: GroupDescriptor[],
        shouldExpandGroups?: boolean,
        persistRowSelection?: boolean
    ): IGridState => {
        const mappedData = getMappedInitialRows(data);
        const enabledColumns = columnsList.filter((col) => col.enabled || col.isAlwaysVisible);
        // this forces the grid to update even if all data is the same
        if (mappedData.length > 0) {
            (mappedData[0] as any)._forceUpdate = new Date();
        }

        let hasFrozenColumnsChanged = false;
        const isInitialLoad =
            prevState.sortingData.length === 0 && sortingData && sortingData.length !== 0;
        const hasSortChanged =
            sortingData &&
            !isInitialLoad &&
            !updatedGridView &&
            !isEqual(prevState.sortingData, sortingData);
        const hasColumnOrderChanged =
            !updatedGridView &&
            prevState.columnsList &&
            (enabledColumns.length !== prevState.columnsList.length ||
                !enabledColumns.every((col, index) => {
                    const gridViewColumn = prevState.columnsList![index];
                    // While iterating through the array, also compare the columns's frozen status
                    if (col.isFrozen !== gridViewColumn.isFrozen) {
                        hasFrozenColumnsChanged = true;
                    }
                    return col.id === gridViewColumn.id;
                }));
        const hasUnsavedChanges =
            !!updatedGridView?.isCustom ||
            hasFrozenColumnsChanged ||
            hasSortChanged ||
            !!hasColumnOrderChanged;

        return {
            ...prevState,
            dataState: "loaded",
            data:
                groups && groups.length > 0
                    ? persistGroupState(
                          getGroupedData(mappedData, groups, shouldExpandGroups),
                          prevState.data
                      )
                    : mappedData,
            columnsList: mapColumnsList(columnsList, groups),
            footerData: footerData,
            sortingData: sortingData || prevState.sortingData,
            hasUnsavedChanges:
                sortingData || updatedGridView ? hasUnsavedChanges : prevState.hasUnsavedChanges,
            groups,
            selectedData: persistRowSelection ? prevState.selectedData : [],
        };
    },
    /** Use this when only columnsList is fetched (may be removed if there's no use case for this) */
    onColumnsLoaded: (
        prevState: IGridState,
        columnsList: GridColumn<unknown>[],
        groups?: GroupDescriptor[]
    ): IGridState => ({
        ...prevState,
        columnsList: mapColumnsList(
            columnsList.map((col) => {
                return {
                    ...col,
                    order:
                        prevState.columnsList?.find((prevCol) => prevCol.id === col.id)?.order ??
                        col.order,
                };
            }),
            groups
        ),
    }),
    onColumnReorder: (prevState: IGridState, columnOrder: string[]): IGridState => {
        const updatedColumnList =
            prevState.columnsList &&
            columnOrder.map((field, index) => {
                // we are assuming found column here, but would be more robust in non demo implementation.
                const foundColumn = prevState.columnsList!.find((col) => col.jsonKey === field)!;
                return new GridColumn<unknown, unknown>({
                    ...foundColumn,
                    order: index,
                });
            });

        return {
            ...prevState,
            columnsList: updatedColumnList,
            hasUnsavedChanges: true,
        };
    },
    onColumnResize: (prevState: IGridState, columnKey: string, width: number): IGridState => ({
        ...prevState,
        columnsList:
            prevState.columnsList &&
            prevState.columnsList.map((col) => {
                if (col.id === columnKey) {
                    return {
                        ...col,
                        width: width,
                    };
                }
                return col;
            }),
        hasUnsavedChanges: true,
    }),
    onColumnFreeze: (prevState: IGridState, columnKey: string, isFrozen: boolean): IGridState => {
        const updatedColumnList =
            prevState.columnsList &&
            prevState.columnsList.map((col) => {
                if (col.jsonKey === columnKey) {
                    return {
                        ...col,
                        isFrozen: isFrozen,
                    };
                }
                return col;
            });

        return {
            ...prevState,
            columnsList: updatedColumnList,
            hasUnsavedChanges: true,
        };
    },
    onRowSelected: (prevState: IGridState, selectedData: IGridRowData | IGroupedRowData) => {
        let prevSelectedData = [...prevState.selectedData];
        if (!selectedData.isSelected) {
            if (prevSelectedData.findIndex((x) => x.id === selectedData["id"]) === -1) {
                prevSelectedData = add(prevSelectedData, selectedData as IBaseEntity);
            }
        } else {
            prevSelectedData = remove(prevSelectedData, selectedData as IBaseEntity, "id");
        }
        return {
            ...prevState,
            selectedData: prevSelectedData,
        };
    },
    onHeaderRowSelected: (prevState: IGridState, checked: boolean) => {
        let prevSelectedData = [...prevState.selectedData];
        prevState.data!.forEach((entity) => {
            if (checked) {
                if (prevSelectedData.findIndex((x) => x.id === entity["id"]) === -1) {
                    prevSelectedData = add(prevSelectedData, entity as IBaseEntity);
                }
            } else {
                prevSelectedData = remove(prevSelectedData, entity as IBaseEntity, "id");
            }
        });

        return {
            ...prevState,
            selectedData: prevSelectedData,
        };
    },
};

type SaveViewSettingsHandler = (
    values: IGridSettingsFormValues,
    columns: GridViewColumn[],
    saveAsNew: boolean
) => Promise<void>;

interface IToolbarActionConfigBase {
    /**
     * If undefined/true, render the toolbar action. If false, do not render the toolbar action.
     * If you never want to display a toolbar action, you do not need to specify its action configuration prop at all
     * @default true
     */
    isVisible?: boolean;
}

export const isToolbarActionVisible = (
    config?: IToolbarActionConfigBase
): config is IToolbarActionConfigBase => {
    return !!config && (config.isVisible === undefined || config.isVisible === true);
};

export interface IToolbarDownloadConfig extends IToolbarActionConfigBase {
    onClick: (columns?: (string | number)[], sortingData?: SortDescriptor[]) => void;
    actionBeingPerformed: ToolbarDownloadAction;
}

interface IToolbarViewSettingsConfig extends IToolbarActionConfigBase {
    onSave: SaveViewSettingsHandler;
    onDelete: (view: GridViewItem) => Promise<void>;
    onApply: (view: GridViewItem) => Promise<void>;
    onSetDefaultView: (view: GridViewItem) => Promise<void>;
}

interface IToolbarContentConfig extends IToolbarActionConfigBase {
    renderToolbarContent: (entities: IBaseEntity[], children?: JSX.Element) => React.ReactNode;
    selectedCount?: number;
}

export interface IGridContainerProps {
    gridState: IGridState["dataState"];
    data?: (IGridRowData | IGroupedRowData)[];
    selectedData?: IBaseEntity[];
    columnsList?: GridColumn<unknown>[];
    columnsListAll?: GridColumn<unknown>[];
    sortingData?: SortDescriptor[];
    onSortChanged?: (newSort: SortDescriptor[]) => void;
    onGroupChange?: (columnKeys: GroupDescriptor[]) => void;
    hasSelectableRows?: boolean;
    singleSelect?: boolean;
    stickyHeader?: StickyHeaderType;
    onColumnReorder: (columnOrder: string[]) => void;
    onColumnResize: (columnKey: string, width: number) => void;
    gridViews: BTSelectItem<GridViewItem>[];
    selectedGridView: GridViewItem;
    emptyStateBanner?: React.ReactNode;
    /**
     * This banner is different from empty state banner.
     * This will serve the purpose of showing information right above the grid container
     */
    gridBanner?: React.ReactNode;

    /**
     * If true, show the toolbar. If undefined/false, do not render **any** toolbar elements
     * @default false
     */
    showToolbar?: boolean;

    /**
     * Config settings for the Grid View settings modal. If undefined,
     * never render the grid view settings button.
     */
    toolbarViewSettingsConfig?: IToolbarViewSettingsConfig;

    /**
     * Config settings for any additional toolbar actions to be displayed. This is where the checked action
     * dropdown goes, or any additional custom actions you wish to add to the toolbar.
     */
    toolbarContentConfig?: IToolbarContentConfig;
    gridEmptyState?: ReactNode;

    onColumnFrozen: (columnKey: string, isFrozen: boolean) => void;
    onSelectChange?: (dataItem: IGridRowData | IGroupedRowData) => void;
    onHeaderSelectChange?: (isSelected: boolean) => void;
    onExpandChange?: (dataItem: IGridRowData) => void;
    onExpandChangeForGrouping?: (dataItem: IGroupedRowData) => void;
    shouldRowHaveDetails?: (dataItem: any) => boolean;
    canReorderColumns?: boolean;
    canFreezeColumns?: boolean;
    footerData?: { [key: string]: any } | null;
    containerName?: string;
    groups?: IGridState["groups"];
    detail?: ComponentType<GridDetailRowProps>;
    size?: GridSize;
    gridSettingsState?: SavedViewsAndFiltersSettingsState;
    setGridSettingsState?: (state: SavedViewsAndFiltersSettingsState) => void;
    entityType: ListEntityType;
    /**
     * Determines if the horizontal scrollbar will stick above the footer
     * @default true
     */
    useStickyScroll?: boolean;
    isInlineEditingEntity?: boolean;
    hasListCreation?: boolean;
    /**
     * Will not include height styles on the GridContainerHeader
     * @default false
     */
    autoHeaderHeight?: boolean;

    /**
     * Used to provide custom header cell rendering. If this is provided, the default header cell will not be rendered.
     */
    getGroupHeaderCell?: (
        cellProps: GridCellProps,
        dataItem: IGroupedRowData
    ) => JSX.Element | null;

    alignTopForMultiLineCells?: boolean;
}

/**
 * GridContainer for use in a nested form. Use `mapGridFormValues` to map props to `IBTGridFormValues`
 */
@track({ component: "Grid Container" })
export class GridContainerInternal extends Component<
    IGridContainerProps & FormikProps<IBTGridFormValues> & ITrackingProp
> {
    static defaultProps = {
        sortingData: [],
        hasSelectableRows: true,
        onSortChanged: () => {},
        onGroupChange: () => {},
        showToolbar: false,
        size: "small" as GridSize,
        isGridSettingsVisible: false,
        isAddingNewView: false,
        gridSettingsState: "closed" as SavedViewsAndFiltersSettingsState,
        useStickyScroll: true,
    };

    private handleSettingsClosed = () => {
        this.props.setGridSettingsState!("closed");
    };

    private handleSortChanged = (newSort: SortDescriptor[]) => {
        const { onSortChanged, tracking, columnsList } = this.props;
        const fieldName = columnsList?.find((c) => c.id === newSort[0].field)?.name;
        tracking?.trackEvent({
            event: "SortChange",
            extraInfo: {
                direction: newSort[0].dir === "asc" ? "Ascending" : "Descending",
                column: fieldName,
            },
        });
        if (onSortChanged) {
            onSortChanged!(newSort);
        }
    };

    private getData = () => {
        const { selectedData, values } = this.props;
        if (!selectedData) {
            return values;
        }

        return update(values, {
            rowData: {
                $apply: (rows: (IGridRowData | IGroupedRowData)[]) => {
                    return rows.map((row) => {
                        return update(row, {
                            isSelected: {
                                $set: selectedData.some((x) => x.id === row["id"]),
                            },
                        });
                    });
                },
            },
        });
    };

    render() {
        const {
            columnsList,
            sortingData,
            onGroupChange,
            onColumnReorder,
            onColumnResize,
            onColumnFrozen,
            onSelectChange,
            onExpandChange,
            onExpandChangeForGrouping,
            shouldRowHaveDetails,
            hasSelectableRows,
            stickyHeader = "Title",
            canReorderColumns,
            canFreezeColumns,
            emptyStateBanner,
            showToolbar,
            footerData,
            groups,
            detail,
            size,
            selectedGridView,
            columnsListAll,
            // FormikProps
            values,
            setFieldValue,
            setFieldTouched,
            // Additional action props
            containerName,
            toolbarContentConfig,
            toolbarViewSettingsConfig,
            gridEmptyState,
            onHeaderSelectChange,
            gridViews,
            entityType,
            selectedData,
            singleSelect,
            gridBanner,
            getGroupHeaderCell,
            autoHeaderHeight = false,
            alignTopForMultiLineCells,
        } = this.props;

        const checkedItems =
            selectedData ?? values.rowData.flatMap((dataItem) => getSelectedEntities(dataItem));

        const uncheckAll = () => {
            const checked = false;
            const allRowsSelected = values.rowData.map((row) => setSelected(row, checked));
            setFieldValue("rowData", allRowsSelected);

            // if onHeaderSelectChange is specified, do not loop over.
            if (onSelectChange && !onHeaderSelectChange) {
                values.rowData.forEach((row) => {
                    if (row.isSelected !== checked) {
                        onSelectChange(row);
                    }
                });
            }
            if (onHeaderSelectChange) {
                onHeaderSelectChange(checked);
            }
        };

        const savedViewsItems = gridViews.map((v) => v.extraData!);
        let content: React.ReactNode = undefined;
        if (isToolbarActionVisible(toolbarContentConfig)) {
            const checkedAction = (
                <CheckedActions
                    count={toolbarContentConfig.selectedCount ?? checkedItems.length}
                    onUnselectAll={uncheckAll}
                />
            );
            content = toolbarContentConfig.renderToolbarContent(checkedItems, checkedAction);
        }

        return (
            <>
                {showToolbar && (
                    <>
                        {content && (
                            <GridContainerHeader
                                content={content}
                                stickyHeader={stickyHeader}
                                autoHeight={autoHeaderHeight}
                            />
                        )}
                        {emptyStateBanner}
                        {gridBanner && values.rowData.length > 0 && (
                            <div className="margin-bottom-md">{gridBanner}</div>
                        )}
                    </>
                )}
                {gridEmptyState ? (
                    gridEmptyState
                ) : (
                    <div
                        style={{ position: "sticky" }}
                        className={classNames("GridContainer", {
                            InlineEditNewEntity: this.props.isInlineEditingEntity,
                            ListWithCreation: this.props.hasListCreation,
                        })}
                    >
                        {columnsList && values.rowData && (
                            <>
                                {/* eslint-disable-next-line react/forbid-elements */}
                                <Suspense fallback={<SkeletonTable rows={3} columns={5} />}>
                                    <BTGrid
                                        values={this.getData()}
                                        columnsList={columnsList}
                                        footerData={footerData}
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        onSortChange={this.handleSortChanged}
                                        onColumnOrderChange={onColumnReorder}
                                        onColumnResize={onColumnResize}
                                        onColumnFrozen={onColumnFrozen}
                                        onGroupChange={onGroupChange!}
                                        onSelectChange={onSelectChange}
                                        onHeaderSelectChange={onHeaderSelectChange}
                                        onExpandChange={onExpandChange}
                                        onExpandChangeForGrouping={onExpandChangeForGrouping}
                                        shouldRowHaveDetails={shouldRowHaveDetails}
                                        sort={sortingData!}
                                        hasSelectableRows={hasSelectableRows}
                                        stickyHeader={stickyHeader}
                                        useStickyScroll={this.props.useStickyScroll}
                                        canReorderColumns={canReorderColumns}
                                        canFreezeColumns={canFreezeColumns}
                                        containerName={containerName}
                                        groups={groups}
                                        detail={detail}
                                        size={size}
                                        singleSelect={singleSelect}
                                        getGroupHeaderCell={getGroupHeaderCell}
                                        alignTopForMultiLineCells={alignTopForMultiLineCells}
                                    />
                                </Suspense>
                                {this.props.gridState === "loading" && (
                                    <BTLoading displayMode="absolute" instant={true} />
                                )}
                            </>
                        )}
                    </div>
                )}
                {isToolbarActionVisible(toolbarViewSettingsConfig) && (
                    <BTModal
                        data-testid="btModalGridSettings"
                        title="Manage Saved Views"
                        setPageTitle={false}
                        width="840px"
                        visible={this.props.gridSettingsState !== "closed"}
                        beforeClose={this.handleSettingsClosed}
                        removeBodyPadding
                        useModalLayout
                    >
                        <SavedViewsAndFiltersSettings
                            dataType="view"
                            viewData={savedViewsItems}
                            columnsList={columnsListAll!}
                            columnsSelectedList={columnsList!}
                            selectedGridView={selectedGridView}
                            sortingData={sortingData}
                            onApplyView={async (view) => {
                                this.handleSettingsClosed();
                                await toolbarViewSettingsConfig.onApply(view);
                            }}
                            onSaveView={async (values, columns, saveAsNew) => {
                                await toolbarViewSettingsConfig.onSave(values, columns, saveAsNew);
                            }}
                            onDeleteView={async (view) => {
                                await toolbarViewSettingsConfig.onDelete(view);
                            }}
                            gridSettingsState={this.props.gridSettingsState!}
                            setGridSettingsState={this.props.setGridSettingsState!}
                            filterTypeEntity={getFilterEntityType(entityType!)}
                            onSetDefaultView={async (view) => {
                                await toolbarViewSettingsConfig.onSetDefaultView(view);
                            }}
                            beforeClose={this.handleSettingsClosed}
                        />
                    </BTModal>
                )}
            </>
        );
    }
}

function getMappedInitialRows(
    items: (IGroupedRowData | IGridRowData)[]
): (IGroupedRowData | IGridRowData)[] {
    return items.map(
        (row) =>
            ({
                ...row,
                isSelected: false,
            } as IGridRowData)
    );
}

function persistGroupState(
    data: (IGridRowData | IGroupedRowData)[],
    prevData?: (IGridRowData | IGroupedRowData)[]
) {
    if (!prevData) {
        return data;
    }

    const groupMap = {};
    insertGroupsInMap(groupMap, prevData);
    setExpandedFromMap(groupMap, data);
    return data;
}

function insertGroupsInMap(
    groupMap: { [key: string]: IGroupedRowData },
    data: (IGridRowData | IGroupedRowData)[]
) {
    data.forEach((row) => {
        if (isGridGroupRow(row)) {
            groupMap[row.value] = row;
            insertGroupsInMap(groupMap, row.items);
        }
    });
}

function setExpandedFromMap(
    groupMap: { [key: string]: IGroupedRowData | undefined },
    data: (IGridRowData | IGroupedRowData)[]
) {
    data.forEach((row) => {
        if (isGridGroupRow(row)) {
            const hasMatch =
                groupMap[row.value] !== undefined && row.field === groupMap[row.value]!.field;
            if (hasMatch) {
                row.isExpanded = groupMap[row.value]!.isExpanded;
            }
            setExpandedFromMap(groupMap, row.items);
        }
    });
}

function mapColumnsList(
    columns: GridColumn<any, any>[],
    groups?: GroupDescriptor[]
): GridColumn<any, any>[] {
    const groupFields = groups?.map((g) => g.field);
    return (
        columns
            // kendo grid doesn't natively support hiding columns
            .filter(
                (col) =>
                    !col.isHidden &&
                    (col.isAlwaysVisible || col.enabled) &&
                    (!groups || !groupFields!.includes(col.jsonKey))
            )
            // These two lines are only needed because kendo assumes the order you provide it is zero based and sequential.
            // One reorder will make it that way.  I don't know if we can assume ☝ from server side data.
            .sort((p, c) => p.order - c.order)
            .map((col, i) => ({ ...col, order: i }))
    );
}

function getInitializedExpandMap(
    data: (IGroupedRowData | IGridRowData)[],
    columnsList: GridColumn<any, any>[]
) {
    return data.reduce((accum, row) => {
        if (isGridRowData(row)) {
            accum[row.id] = columnsList.reduce((columnAccum, col) => {
                if (col.isExpandable) {
                    columnAccum[col.jsonKey] = false;
                }
                return columnAccum;
            }, {} as { [colKey: string]: boolean });
        }

        return accum;
    }, {} as IExpandMap);
}

export interface IGridFormProps {
    data?: (IGridRowData | IGroupedRowData)[];
    columnsList?: GridColumn<unknown>[];
    selectedGridView?: GridViewItem;
}

export const mapGridFormValues = (props: IGridFormProps): IBTGridFormValues => {
    return {
        rowData: props.data || [],
        expandMap:
            props.data && props.columnsList
                ? getInitializedExpandMap(props.data, props.columnsList)
                : {},
        selectedEntities: [],
    };
};

/**
 * GridContainer with Formik integration.  Use `GridContainerInternal` if full Formik control is required.
 */
export const GridContainer = withFormik<IGridContainerProps, IBTGridFormValues>({
    mapPropsToValues: mapGridFormValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,

    handleSubmit: async () => {
        // here we would handle row submit. Most likely would handle column list submit through other callback.
    },
})(GridContainerInternal);
