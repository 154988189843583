import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import queryString from "query-string";
import { Suspense, useContext } from "react";
import ReactDOM from "react-dom";
import { MemoryRouter, Route, RouteComponentProps, StaticContext } from "react-router";

import { AppProvider } from "helpers/AppProvider";
import { CurrencyLocale } from "helpers/AppProvider.types";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { RootForegroundNotificiationController } from "helpers/pushNotification.utilities";

import { BTSessionStorage } from "types/btStorage";

// Intentionally adding this here so storybook doesn't load. That way if everything works in storybook, we can just delete this when webforms is gone (in 2050)
import { getAppDefaultFlagValue, getAppDefaultFlagValueAsBoolean } from "utilities/appDefaultFlags";
import {
    getCurrentEnvironment,
    getCurrentSentryEnvironment,
} from "utilities/environment/environment";
import { ListEntityType } from "utilities/list/list.types";
import { isNullOrUndefined } from "utilities/object/object";
import { isInPortal } from "utilities/portal/portal";
import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";
import { parseParams } from "utilities/url/url";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import EntityListWrapper from "commonComponents/helpers/EntityListWrapper/EntityListWrapper";
import { ErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";
import { HotkeyDisplaySetup } from "commonComponents/helpers/HotkeyDisplaySetup/HotkeyDisplaySetup";
import { registerDragAndDropPlugins } from "commonComponents/utilities/DragAndDrop/DragAndDrop";
import { FocusProvider } from "commonComponents/utilities/Focus/FocusProvider";
import { RootFocusObserver } from "commonComponents/utilities/Focus/RootFocusObserver";
import { OwnerHeaderInfo } from "commonComponents/utilities/HeaderInfo/OwnerHeaderInfo";
import { MainNavigation } from "commonComponents/utilities/MainNavigation/MainNavigation";
import { MainNavigationWrapper } from "commonComponents/utilities/MainNavigation/MainNavigationWrapper";
import ModalLauncher from "commonComponents/utilities/ModalLauncher/ModalLauncher";
import PageTitle from "commonComponents/utilities/PageTitle/PageTitle";
import { ShowOnPortal } from "commonComponents/utilities/ShowOnPortal/ShowOnPortal";
import { useStickyHeaderWithMainNav } from "commonComponents/utilities/StickyContext";

import { IBudgetParams } from "entity/budget/Budget/Budget.api.types";
import AdminBuildersSearch from "entity/builderSearch/AdminBuildersSearch";
import BtAdminContactsTable from "entity/contact/BtAdmin/BtAdminContactsTable";
import { OAuthType } from "entity/login/Login/Login.api.types";
import { ReportTypes } from "entity/report/Report/Report.api.types";
import { SelectionListContainerTabs } from "entity/selection/SelectionListContainer/SelectionListContainer.types";

import "./styles/webformsCssHacks.less";

import { App } from "./App";

const Report = lazyLoadWithRetry(() => import("entity/report/Report/Report"));
const AddressConfirmation = lazyLoadWithRetry(
    () => import("entity/addressConfirmation/AddressConfirmation")
);
const TradeAgreement = lazyLoadWithRetry(
    () => import("entity/tradeAgreement/TradeAgreement/TradeAgreement")
);
const JobPickerWrapper = lazyLoadWithRetry(
    () => import("commonComponents/utilities/JobPicker/JobPickerWrapper")
);
const Login = lazyLoadWithRetry(() => import("./entity/login/Login/Login"));
const LoginEmailUsernameEntry = lazyLoadWithRetry(
    () => import("./entity/login/LoginEmailUsernameEntry/LoginEmailUsernameEntry")
);
const SelectionListContainer = lazyLoadWithRetry(
    () => import("entity/selection/SelectionListContainer/SelectionListContainer")
);

const Budget = lazyLoadWithRetry(() => import("entity/budget/Budget/Budget"));
const JobCostingBudget = lazyLoadWithRetry(
    () => import("entity/budget/JobCostingBudget/JobCostingBudget")
);

const HeaderAlerter = lazyLoadWithRetry(
    () => import("./commonComponents/utilities/HeaderAlerter/HeaderAlerter")
);
const LegacyReportListUpsell = lazyLoadWithRetry(
    () => import("entity/report/ReportList/LegacyReportListUpsell")
);

const BTAdminWebformsMenu = lazyLoadWithRetry(() => import("./layouts/BTAdminWebformsMenu"));

window.rootFocusObserver = new RootFocusObserver();
window.rootForegroundNotificationController = new RootForegroundNotificiationController();

const environment: string = getCurrentEnvironment();

const enableRUM = getAppDefaultFlagValueAsBoolean("dataDogRUM");
const enableTracing = getAppDefaultFlagValueAsBoolean("dataDogEnableTracing");
const ddAppId = getAppDefaultFlagValue("dataDogApplicationId");
const ddToken = getAppDefaultFlagValue("dataDogClientToken");

if (enableRUM && ddAppId && ddToken) {
    datadogRum.init({
        applicationId: ddAppId,
        clientToken: ddToken,
        site: getAppDefaultFlagValue("dataDogSite"),
        service: "buildertrend.net",
        env: environment,
        sessionSampleRate: getAppDefaultFlagValue("dataDogSessionSampleRate"),
        sessionReplaySampleRate: getAppDefaultFlagValue("dataDogPremiumSampleRate"), // if not included, the default is 100
        trackUserInteractions: getAppDefaultFlagValueAsBoolean("dataDogTrackUserInteractions"),
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: enableTracing ? [/https:\/\/.*buildertrend\.net/] : undefined,
        defaultPrivacyLevel: "mask-user-input",
        version: window.btJScriptGlobals.sentryRelease,
        enableExperimentalFeatures: ["feature_flags"],
    });
    datadogRum.setUser({
        id: window.btJScriptGlobals.userId,
        builderID: window.btJScriptGlobals.getBuilderId,
    });
    datadogRum.startSessionReplayRecording();
}

let dsnVal = window.btJScriptGlobals.appDefaultFlags.sentryReactDSN;
const enableSentry = getAppDefaultFlagValueAsBoolean("enableSentry");
const sentryEnvironment: string = getCurrentSentryEnvironment();

if (dsnVal && enableSentry) {
    const tracesSampleRateString = window.btJScriptGlobals.appDefaultFlags
        .sentryReactSamplingRate as string;
    const tracesSampleRate = parseFloat(tracesSampleRateString) || 0;

    const integrations: any[] = [
        Sentry.browserTracingIntegration({
            beforeStartSpan: (context) => {
                context.name = location.pathname
                    .replace(/\/[a-f0-9]{32}/g, "/<hash>")
                    .replace(/\/\d+/g, "/<digits>");
                return context;
            },
        }),
        Sentry.httpClientIntegration(),
    ];

    Sentry.init({
        dsn: dsnVal,
        environment: sentryEnvironment,
        ignoreErrors: ["ResizeObserver loop", "NetworkError"],
        integrations: integrations,
        tracesSampleRate: tracesSampleRate,
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb.category === "ui.click" && hint && hint.event) {
                const { target } = hint.event;
                if (target && target.dataset && target.dataset.testid) {
                    breadcrumb.message += ` data-testid['${target.dataset.testid}']`;
                }
            }

            return breadcrumb;
        },
        release: window.btJScriptGlobals.sentryRelease,
    });

    let scope = Sentry.getCurrentScope();
    scope.setUser({ id: window.btJScriptGlobals.userId });
    scope.setTag("builderID", window.btJScriptGlobals.getBuilderId);
}

// Register SortableJS Plugins
registerDragAndDropPlugins();

// path starts with /app/
const isSinglePageApp = window.location.pathname.toLowerCase().startsWith("/app");
const reactMainNavigation = document.getElementById("reactMainNavigation");
const reactHeaderInfo = document.getElementById("reactHeaderInfo");
const reactSinglePageAppRoot = document.getElementById("root");
const reactReportDetailsDiv = document.getElementById("reactReportDetailsDiv");
const reactReportUpsellDiv = document.getElementById("reactReportUpsellDiv");
const reactJobPicker = document.getElementById("reactJobPickerPlaceholder");
const addressConfirmation = document.getElementById("addressConfirmation");
const subTradeAgreement = document.getElementById("subTradeAgreement");

// Please do not add any more code to this file, do full react conversion instead.

if (reactMainNavigation) {
    ReactDOM.render(
        <MemoryRouter>
            <ErrorBoundary>
                <BTSuspense>
                    <Route
                        render={(props) => (
                            <FocusProvider shareFocusWithParent>
                                <MainNavigationWrapper
                                    render={(state) => (
                                        <>
                                            <MainNavigation
                                                fromReact={false}
                                                {...props}
                                                {...state}
                                            />
                                        </>
                                    )}
                                />
                            </FocusProvider>
                        )}
                    />
                </BTSuspense>
            </ErrorBoundary>
        </MemoryRouter>,
        reactMainNavigation
    );
}

if (reactJobPicker) {
    ReactDOM.render(
        <ErrorBoundary>
            <div className="jobPickerMemoryRouter">
                <MemoryRouter>
                    <AppProvider>
                        <BTSuspense>
                            <Route render={(props) => <JobPickerWrapper {...props} />} />
                        </BTSuspense>
                    </AppProvider>
                </MemoryRouter>
            </div>
        </ErrorBoundary>,
        reactJobPicker
    );
}

if (reactHeaderInfo) {
    ReactDOM.render(
        <MemoryRouter>
            <ErrorBoundary>
                <BTSuspense>
                    <Route
                        render={(props) => (
                            <MainNavigationWrapper
                                render={(state, onSetSelectedJobId) => (
                                    <>
                                        <ShowOnPortal
                                            owner
                                            render={() => (
                                                <OwnerHeaderInfo
                                                    key={state.headerKey}
                                                    setSelectedJobId={onSetSelectedJobId}
                                                    {...props}
                                                    {...state}
                                                />
                                            )}
                                        />
                                        <UserInfoContext.Consumer>
                                            {(userContext) => {
                                                return (
                                                    <ModalLauncher
                                                        search={window.location.search}
                                                        {...props}
                                                        userInfo={userContext}
                                                    />
                                                );
                                            }}
                                        </UserInfoContext.Consumer>
                                        {/* eslint-disable-next-line react/forbid-elements */}
                                        <Suspense fallback={<></>}>
                                            <HeaderAlerter {...props} {...state} />
                                        </Suspense>
                                    </>
                                )}
                            />
                        )}
                    />
                </BTSuspense>
            </ErrorBoundary>
        </MemoryRouter>,
        reactHeaderInfo
    );
}

if (reactReportDetailsDiv) {
    // eslint-disable-next-line deprecate/member-expression, no-restricted-syntax
    const qs = queryString.parse((window as any).location.search);
    const reportType = Number.parseInt(qs.reportType as string);
    const reportFilter = Number.parseInt(qs.reportFilter as string);
    ReactDOM.render(
        <MemoryRouter>
            <ErrorBoundary>
                <AppProvider
                    deferIntercomLoad={
                        reportType === ReportTypes.WorkInProgressCash ||
                        reportType === ReportTypes.WorkInProgressAccrual
                    }
                >
                    <BTSuspense>
                        <Report reportType={reportType} reportFilter={reportFilter} />
                        <HotkeyDisplaySetup />
                    </BTSuspense>
                </AppProvider>
            </ErrorBoundary>
        </MemoryRouter>,
        reactReportDetailsDiv
    );
}

if (reactReportUpsellDiv) {
    ReactDOM.render(
        <AppProvider>
            <MemoryRouter>
                <ErrorBoundary>
                    <BTSuspense>
                        <LegacyReportListUpsell />
                    </BTSuspense>
                </ErrorBoundary>
            </MemoryRouter>
        </AppProvider>,
        reactReportUpsellDiv
    );
}

if (addressConfirmation) {
    ReactDOM.render(
        <MemoryRouter>
            <ErrorBoundary>
                <BTSuspense>
                    <Route render={(props) => <AddressConfirmation {...props} />} />
                </BTSuspense>
            </ErrorBoundary>
        </MemoryRouter>,
        addressConfirmation
    );
}

const hasBeenViewed = BTSessionStorage.get("bt-boolean-hasViewedTradeAgreement");
const tradeAgreementValues = BTSessionStorage.get("bt-object-tradeAgreementValues");
if (subTradeAgreement && !hasBeenViewed && !isNullOrUndefined(tradeAgreementValues)) {
    // eslint-disable-next-line deprecate/member-expression, no-restricted-syntax
    const qs = queryString.parse((window as any).location.search);
    const openSubSetup = qs.openSubSetup;
    if (openSubSetup !== "true") {
        ReactDOM.render(
            <MemoryRouter>
                <ErrorBoundary>
                    <BTSuspense>
                        <Route
                            render={(props) => (
                                <MainNavigationWrapper
                                    render={() => (
                                        <BTSuspense>
                                            <TradeAgreement
                                                {...props}
                                                builderId={tradeAgreementValues.builderId}
                                                subId={tradeAgreementValues.subId}
                                                modalConfig={{
                                                    parentRoute: props.match.url,
                                                    beforeClose: () => {},
                                                }}
                                            />
                                        </BTSuspense>
                                    )}
                                />
                            )}
                        />
                    </BTSuspense>
                </ErrorBoundary>
            </MemoryRouter>,
            subTradeAgreement
        );
    }
}

// Start List Page Rendering

// TODO: Remove all instances of useStickyHeaderWithMainNav(); once we are on SPA.

/**
 * Helper function to wrap an AppProvider, MemoryRouter, and Route around your component and render it on a target.
 * Helper function to wrap an AppProvider, MemoryRouter, ErrorBoundary, BTSuspense, and Route around your component and render it on a target.
 * @param component Function to render your lazily loaded component. Will be passed into the component argument of a Route
 * @param path Route to render your component under. This should be the generic route for all portals
 * @param url Route to render your component under. This should be the specific generated route for your portal.
 * @param target HTML element to render the React code in. This should be the placeholder that was created in the webforms page
 */
const renderListPage = (
    component: React.ComponentType<RouteComponentProps<any, StaticContext, any>>,
    path: string,
    url: string,
    target: HTMLElement,
    useUrlSearch?: boolean
) => {
    const initialLocation = {
        pathname: url,
        search: useUrlSearch ? url.split("?")[1] ?? "" : window.location.search,
    };

    ReactDOM.render(
        <ErrorBoundary>
            <AppProvider sendPageView>
                <MemoryRouter initialEntries={[initialLocation]} initialIndex={0}>
                    <FocusProvider shareFocusWithParent hotkeysToRemove={["helpCenter"]}>
                        <BTSuspense>
                            <Route path={path} component={component} />
                        </BTSuspense>
                    </FocusProvider>
                </MemoryRouter>
            </AppProvider>
        </ErrorBoundary>,
        target
    );
};

const reactBudgetDiv = document.getElementById("reactBudgetDiv");
if (reactBudgetDiv) {
    const BudgetPage: React.FunctionComponent<RouteComponentProps> = (reactRouterProps) => {
        const builderInfo = useContext(BuilderInfoContext);
        const params = parseParams<IBudgetParams>(window.location.search, {
            parseBooleans: true,
        });

        useStickyHeaderWithMainNav();

        return (
            <BTSuspense>
                <EntityListWrapper
                    render={(props) => {
                        if (params.useJobCosting) {
                            return (
                                <>
                                    <PageTitle title="Job Costing Budget" />
                                    <JobCostingBudget
                                        {...props}
                                        {...reactRouterProps}
                                        entityType={ListEntityType.JobCostingBudget}
                                    />
                                </>
                            );
                        } else {
                            return (
                                <Budget
                                    {...props}
                                    {...reactRouterProps}
                                    builderCurrency={
                                        builderInfo?.locale.currencyLocale ?? CurrencyLocale.default
                                    }
                                    entityType={ListEntityType.Budget}
                                />
                            );
                        }
                    }}
                    isJobPickerRequired
                />
            </BTSuspense>
        );
    };

    renderListPage(BudgetPage, routes.budget.list, routes.budget.getListLink(), reactBudgetDiv);
}

const reactSelectionListDiv = document.getElementById("reactSelectionListDiv");
if (reactSelectionListDiv) {
    const SelectionListPage = (reactRouterProps: RouteComponentProps) => {
        useStickyHeaderWithMainNav();

        const tabParam = (reactRouterProps.match.params as any).tab as string | undefined;
        let initialTab: SelectionListContainerTabs | null = null;

        if (tabParam) {
            initialTab =
                SelectionListContainerTabs[tabParam as keyof typeof SelectionListContainerTabs] ??
                null;

            // Category and Location tabs go to the List now
            if (
                initialTab === SelectionListContainerTabs.Category ||
                initialTab === SelectionListContainerTabs.Location
            ) {
                initialTab = SelectionListContainerTabs.List;
            }
        }

        return (
            <BTSuspense>
                <EntityListWrapper
                    render={(props) => (
                        <SelectionListContainer {...props} {...reactRouterProps} tab={initialTab} />
                    )}
                />
            </BTSuspense>
        );
    };

    // eslint-disable-next-line deprecate/member-expression
    const queryStringParams: any = queryString.parse(window.location.search, {
        parseNumbers: true,
    });
    const search = window.location.search;

    // The Owner portal also has an Agenda tab, so need to offset the tab value to align with the tab enum
    const queryStringTab = (queryStringParams.tabID as SelectionListContainerTabs) || null;
    const tab = queryStringTab ? queryStringTab - (isInPortal({ owner: true }) ? 1 : 0) : null;

    let initialPath = routes.selection.getListLink(tab as SelectionListContainerTabs);

    if (search.includes("selectionID") && search.includes("choiceID")) {
        initialPath += routes.selectionChoice.getDetailsLink(
            queryStringParams.choiceID,
            queryStringParams.selectionID,
            queryStringParams.jobID
        );
    }
    // If the url contains selectionID but not a choiceID, we still want to open that selection
    else if (search.includes("selectionID")) {
        initialPath += routes.selection.getDetailsLink(
            queryStringParams.selectionID,
            queryStringParams.jobID
        );
    }

    renderListPage(SelectionListPage, routes.selection.list, initialPath, reactSelectionListDiv);
}

const reactLoginListDiv = document.getElementById("reactLoginListDiv");
if (reactLoginListDiv) {
    const LoginListPage = (reactRouterProps: RouteComponentProps) => {
        // eslint-disable-next-line deprecate/member-expression, no-restricted-syntax
        const qs = queryString.parse((window as any).location.search);
        // eslint-disable-next-line deprecate/member-expression
        const memoryQs = queryString.parse(reactRouterProps.location.search);
        let oauthType =
            typeof qs.oauthRedirectType === "string"
                ? OAuthType[qs.oauthRedirectType.toLowerCase() as keyof typeof OAuthType]
                : undefined;
        // map legacy query string parameters
        if (qs.redirectskilljar === "true") {
            oauthType = OAuthType.skilljar;
        }
        if (qs.redirectdataproduct === "true") {
            oauthType = OAuthType.dataproduct;
        }
        const enableRedirect =
            typeof qs.performRedirect === "string" ? qs.performRedirect === "true" : true;

        let showOrgSelector =
            typeof qs.showOrgSelector === "string" ? qs.showOrgSelector === "true" : false;
        const userName = typeof memoryQs.userName === "string" ? memoryQs.userName : "";

        // THD needs this for now - integration url should be updated to pass in showOrgSelector instead
        if (oauthType === OAuthType.thd) {
            showOrgSelector = true;
        }

        const promptParams: string[] | undefined = qs.prompt?.toString().split(" ");
        const promptLogin: boolean = promptParams?.includes("login") ?? false;
        const promptSelectAccount: boolean = promptParams?.includes("select_account") ?? false;

        return (
            <EntityListWrapper
                render={(props) => (
                    <BTSuspense>
                        <EnvironmentInfoContext.Consumer>
                            {(environmentInfo) => (
                                <>
                                    <Route
                                        path={routes.login.usernameEntry}
                                        render={() => (
                                            <LoginEmailUsernameEntry
                                                loginPostRedirect={qs.lpr?.toString()}
                                                reason={qs.reason?.toString()}
                                                oauthRedirectType={oauthType}
                                                redirectUri={qs.redirect_uri?.toString()}
                                                stateParam={qs.state?.toString()}
                                                loginHint={qs.login_hint?.toString()}
                                                userName={qs.bt_username?.toString()}
                                                promptLogin={promptLogin}
                                                {...props}
                                                {...reactRouterProps}
                                            />
                                        )}
                                    />
                                    <Route
                                        path={routes.login.passwordEntry}
                                        render={() => (
                                            <AppDefaultInfoContext.Consumer>
                                                {(appDefaultInfo) => (
                                                    <Login
                                                        oauthRedirectType={oauthType}
                                                        redirectUri={qs.redirect_uri?.toString()}
                                                        enableRedirect={enableRedirect}
                                                        loginPostRedirect={qs.lpr?.toString()}
                                                        reason={qs.reason?.toString()}
                                                        stateParam={qs.state?.toString()}
                                                        promptLogin={promptLogin}
                                                        showOrgSelector={
                                                            showOrgSelector || promptSelectAccount
                                                        }
                                                        userName={userName}
                                                        ephEnv={qs.eph_env?.toString()}
                                                        supportMobileEph={
                                                            environmentInfo?.supportMobileEphemeralLogin
                                                        }
                                                        appDefaultInfo={appDefaultInfo}
                                                        {...props}
                                                        {...reactRouterProps}
                                                    />
                                                )}
                                            </AppDefaultInfoContext.Consumer>
                                        )}
                                    />
                                </>
                            )}
                        </EnvironmentInfoContext.Consumer>
                    </BTSuspense>
                )}
                isJobPickerRequired={false}
            />
        );
    };
    renderListPage(
        LoginListPage,
        routes.login.list,
        routes.login.getUsernameLink(),
        reactLoginListDiv
    );
}

const btAdminContactsTableDiv = document.getElementById("btAdminContactsTableDiv");
if (btAdminContactsTableDiv) {
    const ContactsListPage = (reactRouterProps: RouteComponentProps) => {
        return (
            <EntityListWrapper
                render={(props) => <BtAdminContactsTable {...props} {...reactRouterProps} />}
                isJobPickerRequired={false}
            />
        );
    };

    const initialPath = routes.notFound.getDetailsLink();
    renderListPage(ContactsListPage, routes.notFound.details, initialPath, btAdminContactsTableDiv);
}

const btAdminBuilderSearchDropdownDiv = document.getElementById("btAdminBuilderSearchDropdownDiv");
if (btAdminBuilderSearchDropdownDiv) {
    const BuilderSearchDropdownPage = () => {
        return <AdminBuildersSearch data-testid="BTAdmin-admin-builders-builder-search" />;
    };

    const initialPath = routes.notFound.getDetailsLink();
    renderListPage(
        BuilderSearchDropdownPage,
        routes.notFound.details,
        initialPath,
        btAdminBuilderSearchDropdownDiv
    );
}
const divReactHeaderMenu = document.getElementById("divReactHeaderMenu");
if (divReactHeaderMenu) {
    ReactDOM.render(
        <BTSuspense>
            <BTAdminWebformsMenu />
        </BTSuspense>,
        divReactHeaderMenu
    );
}

// Please do not add any more code to this file, do full react conversion instead.

// End List Page Rendering

if (isSinglePageApp && reactSinglePageAppRoot) {
    ReactDOM.render(<App />, reactSinglePageAppRoot);
}
