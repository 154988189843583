import { message } from "antd";
import { History } from "history";
import memoizeOne from "memoize-one";
import moment, { Moment } from "moment";
import queryString from "query-string";

import { isNullOrWhitespace } from "@buildertrend/components";

import {
    DirectoryType,
    DiscussionsLinkedTypes,
    DocumentInstanceType,
    FileEncryption,
    MediaType,
    TempFileTypes,
} from "types/enum";
import { maxDaysInTrash, QueryStringParamNames, rootLevelFolderId } from "types/MediaConstants";

import { writeHtml } from "utilities/clipboard/clipboard";
import { getBase64ImageFromUrl, getFileExtension, validateBase64Image } from "utilities/file/file";
import { GridSort, ISortableColumn } from "utilities/list/list.types";
import { isInPortal } from "utilities/portal/portal";
import { getRelatedItemLink } from "utilities/relatedItem/relatedItem";
import { routes } from "utilities/routes";
import { dateSorter, lexicographicSorter, numberSorter } from "utilities/sort/sort";

import { BTServiceFileUpload } from "commonComponents/entity/media/AttachmentEntityPreview/AttachmentEntity.api.types";
import { RelatedItemType } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";

import {
    AssociatedEntityInfo,
    BreadCrumbInformation,
    BTFile,
    BTFolder,
    IFolderMenuItemsVisibility,
    IMediaListSort,
    MediaEntry,
    MediaListEntity,
    MediaTypeNames,
} from "entity/media/common/mediaTypes";

export function getMediaString(mediaType: MediaType) {
    switch (mediaType) {
        case MediaType.Document:
            return "Files";
        case MediaType.Photo:
            return "Photos";
        case MediaType.Video:
            return "Videos";
        case MediaType.Unavailable:
            return "Unavailable";
        case MediaType.All:
            return "All";
    }
}

export function getDirectoryTypeName(directoryType: DirectoryType) {
    switch (directoryType) {
        case DirectoryType.Standard:
            return "Standard";
        case DirectoryType.GlobalDocs:
            return "Global Documents";
        case DirectoryType.AttachedFiles:
            return "Attached Files";
        case DirectoryType.SubOwnerUploadedFiles:
            return "Sub/Owner Uploaded Files";
        case DirectoryType.Trash:
            return "Trash";
        default:
            return "Unknown";
    }
}

export function getListLinkForMediaType(
    destinationFolderId: number,
    mediaType: MediaType,
    dirType: DirectoryType
) {
    let listLink = "";
    switch (mediaType) {
        case MediaType.Document:
            listLink = routes.document.getListLink(dirType, destinationFolderId);
            break;
        case MediaType.Photo:
            listLink = routes.photo.getListLink(dirType, destinationFolderId);
            break;
        case MediaType.Video:
            listLink = routes.video.getListLink(dirType, destinationFolderId);
            break;
        default:
            throw new Error("Unsupported MediaType for Go To Folder");
    }

    return listLink;
}

export function handleGoToFolder(
    destinationFolderId: number,
    mediaType: MediaType,
    history: History,
    dirType: DirectoryType = DirectoryType.Standard
) {
    const newUrl = getListLinkForMediaType(destinationFolderId, mediaType, dirType);
    history.push(newUrl);
}

export function getLinkToFolder(destinationFolderId: number, mediaType: MediaType, jobId: number) {
    const dirType = jobId > 0 ? DirectoryType.Standard : DirectoryType.GlobalDocs;

    return getListLinkForMediaType(destinationFolderId, mediaType, dirType);
}

export const getCurrentDirectoryType = (
    breadCrumbs?: BreadCrumbInformation[] | null
): DirectoryType => {
    let directoryType = DirectoryType.Standard;
    if (breadCrumbs && breadCrumbs.length > 0) {
        directoryType = breadCrumbs[breadCrumbs.length - 1].directoryType;
    }

    return directoryType;
};

export const getCurrentAssociatedType = (
    breadCrumbs?: BreadCrumbInformation[] | null
): DocumentInstanceType | null => {
    if (breadCrumbs && breadCrumbs.length > 0) {
        return breadCrumbs[breadCrumbs.length - 1].associatedType;
    }

    return null;
};

export function getFileUrlFormatted(docInstanceId: number, extension: string, url: string) {
    // Only want to set the owner viewed url params on non-photo types,
    // because the media modal will handle setting owner viewed
    if (isInPortal({ owner: true }) && !isPhotoFileType(extension)) {
        const newUrl = new URL(url);
        newUrl.searchParams.append(QueryStringParamNames.IsOwner, "true");
        newUrl.searchParams.append(
            QueryStringParamNames.DocumentInstanceId,
            docInstanceId.toString()
        );
        newUrl.searchParams.append(
            QueryStringParamNames.Encryption,
            FileEncryption.FileIdIsUnencrypted.toString()
        );
        return newUrl.href;
    }
    return url;
}

export function isPDFFileType(extension: string) {
    if (!extension) {
        // Even though the extension param is a string, the extension can be undefined when it is not set in the constructor of a BTFileSystem
        return false;
    }

    return extension.replace(/\./g, "").toLowerCase() === "pdf";
}

const photoFileTypes = [
    "jpg",
    "jpeg",
    "bmp",
    "gif",
    "png",
    "heic",
    "heif",
    "ai",
    "svg",
    "eps",
    "tif",
    "tiff",
    "webp",
];
export function isPhotoFileType(extension: string) {
    return photoFileTypes.includes(extension.replace(/\./g, "").toLowerCase());
}

const videoFileTypes = ["wmv", "mp4", "mpg", "mpeg", "mov", "m4v", "avi"];
export function isVideoFileType(extension: string) {
    return videoFileTypes.includes(extension.replace(/\./g, "").toLowerCase());
}
export function isFileAVideo(file: File) {
    const fileExtension = getFileExtension(file.name);
    return file.type.includes("video") || isVideoFileType(fileExtension);
}

const documentFileTypes = [
    "pdf",
    "m4a",
    "wps",
    "xps",
    "odt",
    "doc",
    "docx",
    "docm",
    "dotx",
    "one",
    "rtf",
    "xls",
    "xlsx",
    "xltx",
    "xlm",
    "xlsm",
    "pps",
    "ppt",
    "pptx",
    "vis",
    "mdb",
    "zip",
    "rar",
    "skp",
    "csv",
    "txt",
    "html",
    "htm",
    "pub",
    "spv",
    "wav",
    "dwg",
    "dwf",
    "dwfx",
    "dxf",
    "bak",
    "est",
    "3gp",
    "3gpp",
    "blg",
    "esx",
    "dat",
    "numbers",
    "pages",
    "3d",
    "2d",
    "tcp",
    "layout",
    "plan",
    "rvt",
    "bp",
    "jp2",
    "eps",
    "dsg",
    "ppsx",
    "deck",
    "mpp",
    "gsheet",
    "msg",
    "kit",
    "swiftjob",
    "ttx",
    "eml",
    "vsd",
    "au",
    "spd",
    "3dm",
    "pcs",
    "kmz",
    "kml",
    "bimx",
    "ec6",
    "bpn",
    "ods",
    "bld",
    "amr",
    "gsm",
    "pln",
    "cdr",
    "svg",
    "ai",
    "wpd",
    "wp",
    "wp7",
    "wp6",
    "wp5",
    "wp4",
    "ctb",
    "wrl",
    "fcp",
    "vsdx",
    "heic",
    "tlx",
    "json",
    "log",
    "kmz",
    "kml",
    "psd",
    "skb",
    "skp",
    "mmdl",
    "cad",
    "alt",
    "vwx",
    "atl",
    "lnf",
];

export function isDocumentFileType(extension: string) {
    return documentFileTypes.includes(extension.replace(/\./g, "").toLowerCase());
}

export function isReadOnly(canAdd: boolean, canEdit: boolean, isNew: boolean) {
    return (!canEdit && !canAdd) || (!canEdit && canAdd && !isNew);
}

export function getDiscussionLinkType(mediaType: MediaType) {
    switch (mediaType) {
        case MediaType.Document:
        case MediaType.Unavailable:
        case MediaType.All:
            return DiscussionsLinkedTypes.Documents;
        case MediaType.Photo:
            return DiscussionsLinkedTypes.PhotoDocs;
        case MediaType.Video:
            return DiscussionsLinkedTypes.Videos;
    }
}

const templateFileTypes = ["doc", "docx", "xls", "xlsx"];
export function isTemplateFileType(extension: string) {
    return templateFileTypes.includes(extension.replace(/\./g, "").toLowerCase());
}

export function getBTFileRelatedItemLink(file: BTFile, associatedEntityInfo: AssociatedEntityInfo) {
    const { associatedType, relatedItemType } = associatedEntityInfo;

    // Takeoff related items will always have external URLs
    if (relatedItemType === RelatedItemType.Takeoff) {
        return associatedEntityInfo.externalUrl!;
    }

    let entityId = associatedEntityInfo.associatedEntityId;
    if (
        (associatedType === DocumentInstanceType.POPaymentsFileCustomField &&
            relatedItemType !== RelatedItemType.Bill) ||
        associatedType === DocumentInstanceType.RFIResponse ||
        associatedType === DocumentInstanceType.TodoChecklistItem ||
        associatedType === DocumentInstanceType.WarrantyService ||
        associatedType === DocumentInstanceType.SignedPurchaseOrderWithPayment ||
        associatedType === DocumentInstanceType.SignedPurchaseOrderWithoutPayment ||
        relatedItemType === RelatedItemType.PurchaseOrderPaymentLienWaiver
    ) {
        entityId = associatedEntityInfo.associatedParentId;
    } else if (relatedItemType === RelatedItemType.BillLienWaiver) {
        entityId = associatedEntityInfo.associatedGrandParentId;
    }

    return getRelatedItemLink(entityId, relatedItemType, file.jobId);
}

/** Converts the provided docType to a TempFileType */
export function GetTempFileTypeFromDocumentInstanceType(docType: DocumentInstanceType) {
    switch (docType) {
        case DocumentInstanceType.Bid:
            return TempFileTypes.Bids;
        case DocumentInstanceType.BidPackage:
            return TempFileTypes.BidPackages;
        case DocumentInstanceType.DailyLog:
            return TempFileTypes.DailyLogs;
        case DocumentInstanceType.Lead:
            return TempFileTypes.Leads;
        case DocumentInstanceType.LeadProposal:
            return TempFileTypes.LeadProposals;
        case DocumentInstanceType.MessagesEmailAttachment:
            return TempFileTypes.MessageAttachments;
        case DocumentInstanceType.PurchaseOrder:
            return TempFileTypes.PurchaseOrders;
        case DocumentInstanceType.RFI:
            return TempFileTypes.RFIs;
        case DocumentInstanceType.ToDo:
            return TempFileTypes.ToDos;
        case DocumentInstanceType.RFIResponse:
            return TempFileTypes.RFIResponse;
        case DocumentInstanceType.Selection:
            return TempFileTypes.Selections;
        case DocumentInstanceType.SelectionChoice:
            return TempFileTypes.SelectionChoices;
        case DocumentInstanceType.ChangeOrder:
            return TempFileTypes.ChangeOrderDocs;
        case DocumentInstanceType.Warranty:
        case DocumentInstanceType.WarrantyService:
            return TempFileTypes.Warranty;
        case DocumentInstanceType.ScheduleItems:
            return TempFileTypes.ScheduleItem;
        case DocumentInstanceType.ProposalFormatItem:
            return TempFileTypes.ProposalFormatItems;
        case DocumentInstanceType.LienWaiver:
            return TempFileTypes.LienWaivers;
        case DocumentInstanceType.CustomerInvoices:
            return TempFileTypes.OwnerPayments;
        case DocumentInstanceType.Estimate:
            return TempFileTypes.Estimate;
        case DocumentInstanceType.StandaloneDocument:
            return TempFileTypes.StandaloneDocument;
        case DocumentInstanceType.LeadEmailAttachment:
            return TempFileTypes.LeadEmailAttachment;
        case DocumentInstanceType.TodoChecklistItem:
            return TempFileTypes.TodoChecklistItem;
        case DocumentInstanceType.CostCodeItems:
            return TempFileTypes.CostCodeItems;
        case DocumentInstanceType.CreditMemo:
            return TempFileTypes.CreditMemo;
        case DocumentInstanceType.Deposit:
            return TempFileTypes.Deposit;
        case DocumentInstanceType.Receipt:
            return TempFileTypes.Receipt;
        case DocumentInstanceType.Takeoff:
            return TempFileTypes.Takeoff;
        case DocumentInstanceType.Bill:
            return TempFileTypes.Bill;
        default:
            throw "media.utils: DocumentInstanceType not supported! Please add your DocumentInstanceType to the function GetTempFileTypeFromDocumentInstanceType()";
    }
}

export function downloadFromUrl(url: string, name: string) {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}

export function openNewTabFromUrl(url: string) {
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.rel = "noreferrer";
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
}

export function downloadBTFile(file: BTFile) {
    downloadFromUrl(file.downloadUrl, file.friendlyFileName);
}

export function getFolderLink(folder: BTFolder) {
    return getFolderLinkByDetails(folder.directoryType, folder.id, folder.mediaType);
}

export function getFolderLinkByDetails(
    directoryType: DirectoryType,
    folderId: number,
    mediaType: MediaType
) {
    switch (mediaType) {
        case MediaType.Document:
            return routes.document.getListLink(directoryType, folderId);
        case MediaType.Photo:
            return routes.photo.getListLink(directoryType, folderId);
        case MediaType.Video:
            return routes.video.getListLink(directoryType, folderId);
        default:
            return "";
    }
}

export function isRootLevelFolder(folderId?: number) {
    return folderId === rootLevelFolderId;
}

export function isGlobalDocsJob(jobId: number) {
    return jobId === JobIdTypes.GlobalDocs;
}

export function canSelectFolder(folder: BTFolder) {
    const { isSpecialFolder, folderAssociatedType } = folder.specialFolderExtraData;
    return !isSpecialFolder || folderAssociatedType !== DocumentInstanceType.StandaloneDocument;
}

export function canDragFolderForAllMediaTypes(folder: BTFolder) {
    return folder.canUpdate && folder.parentFolderId !== rootLevelFolderId;
}

export function canDragFileForAllMediaType(file: BTFile, directoryType: DirectoryType) {
    return file.permissions.canMove && directoryType !== DirectoryType.SubOwnerUploadedFiles;
}

export function getMediaListEmptyStateTitle(
    mediaType: MediaType,
    isRootFolder: boolean,
    directoryType?: DirectoryType
): string {
    if (directoryType === DirectoryType.Trash) {
        return "Nothing in Trash";
    }
    return isRootFolder ? "No Folders" : `No ${MediaTypeNames.get(mediaType)?.plural}`;
}

export function areJobAndDirectoryTypeMatching(jobId: number, directoryType: DirectoryType) {
    return (
        !(jobId === JobIdTypes.GlobalDocs && directoryType === DirectoryType.Standard) &&
        !(jobId !== JobIdTypes.GlobalDocs && directoryType === DirectoryType.GlobalDocs)
    );
}

export function isDirectoryAlwaysReadonly(directoryType: DirectoryType) {
    return (
        directoryType === DirectoryType.AttachedFiles ||
        directoryType === DirectoryType.Trash ||
        (directoryType === DirectoryType.SubOwnerUploadedFiles && isInPortal({ builder: true }))
    );
}

/**
 * Removes only the specified extension from the provided filename. Case insensitive.
 * @param fileName
 * @param extension
 * @returns trimmed filename
 */
export function trimOffSpecificExtension(fileName: string, extension: string | null) {
    if (isNullOrWhitespace(extension)) {
        return fileName;
    }

    return fileName.replace(new RegExp(`\\.${extension}$`, "i"), "");
}

export function getMediaListEmptyStateDescription(
    mediaType: MediaType,
    isRootFolder: boolean,
    directoryType?: DirectoryType,
    canAddFiles?: boolean
): string {
    if (directoryType && directoryType === DirectoryType.Trash) {
        return `Move items you don't need to trash. Items in trash will be deleted forever after ${maxDaysInTrash} days.`;
    } else if (directoryType && isDirectoryAlwaysReadonly(directoryType)) {
        return "This folder is empty.";
    } else if (isRootFolder) {
        return `Create a folder to start adding files.`;
    } else if (
        isInPortal({ subs: true, owner: true }) &&
        directoryType !== DirectoryType.SubOwnerUploadedFiles &&
        !canAddFiles
    ) {
        return `This folder is either empty or you don't have permission to view the content. Reach out to the account admin for more info.`;
    } else {
        return `This folder is either empty or you don't have permission to view the content. Add ${getMediaString(
            mediaType
        ).toLowerCase()} by clicking the "Upload" button, or drop them onto the page to begin uploading.`;
    }
}

export enum MediaSortColumn {
    Name = "Name",
    TotalSize = "Total Size",
    Modified = "Modified",
    Created = "Created",
    Uploaded = "Uploaded",
    Duration = "Duration",
    DateTaken = "Date Taken",
    DateDeleted = "Date Deleted",
}

const folderSortableColumns = [
    { id: MediaSortColumn.Name, isSortable: true, name: MediaSortColumn.Name },
    { id: MediaSortColumn.TotalSize, isSortable: true, name: MediaSortColumn.TotalSize },
    { id: MediaSortColumn.Created, isSortable: true, name: MediaSortColumn.Created },
    { id: MediaSortColumn.Modified, isSortable: true, name: MediaSortColumn.Modified },
];

const fileSortableColumns = [
    { id: MediaSortColumn.Name, isSortable: true, name: MediaSortColumn.Name },
    { id: MediaSortColumn.TotalSize, isSortable: true, name: MediaSortColumn.TotalSize },
    { id: MediaSortColumn.Duration, isSortable: true, name: MediaSortColumn.Duration },
    { id: MediaSortColumn.Uploaded, isSortable: true, name: MediaSortColumn.Uploaded },
    { id: MediaSortColumn.Modified, isSortable: true, name: MediaSortColumn.Modified },
    { id: MediaSortColumn.DateTaken, isSortable: true, name: MediaSortColumn.DateTaken },
];

export const getFolderSortableColumns = (directoryType: DirectoryType) => {
    if (directoryType === DirectoryType.AttachedFiles) {
        return folderSortableColumns.filter((x) => x.name !== MediaSortColumn.Created);
    }
    return folderSortableColumns.filter((x) => x.name !== MediaSortColumn.TotalSize);
};

export const getFileSortableColumns = (directoryType: DirectoryType, mediaType: MediaType) => {
    if (directoryType === DirectoryType.Trash) {
        return [
            {
                id: MediaSortColumn.DateDeleted,
                isSortable: true,
                name: MediaSortColumn.DateDeleted,
            },
        ];
    }

    let retCols: ISortableColumn[];
    if (mediaType === MediaType.Video) {
        retCols = fileSortableColumns.filter(
            (x) =>
                x.id !== MediaSortColumn.DateTaken &&
                (isInPortal({ builder: true }) || x.name !== MediaSortColumn.Uploaded)
        );
    } else {
        const hideUploadColumn = (name: string) => name !== MediaSortColumn.Uploaded;
        retCols = fileSortableColumns
            .filter((x) => x.id !== MediaSortColumn.DateTaken || mediaType === MediaType.Photo)
            .filter((x) => x.name !== MediaSortColumn.Duration)
            .filter((x) =>
                isInPortal({
                    builder: true,
                    subs: hideUploadColumn(x.name),
                    owner: hideUploadColumn(x.name),
                })
            );
    }

    if (directoryType === DirectoryType.AttachedFiles) {
        return retCols;
    }
    return retCols.filter((x) => x.name !== MediaSortColumn.TotalSize);
};

export const applyFolderSort = (entry: MediaEntry, sort: GridSort) => {
    const folders = entry.folders?.sort((a, b) => {
        let aDate: Moment;
        let bDate: Moment;
        let result: number;
        switch (sort.sortColumn) {
            case MediaSortColumn.Name:
                result = lexicographicSorter(a.title, b.title);
                break;
            case MediaSortColumn.Modified:
                aDate = moment(a.dateModified);
                bDate = moment(b.dateModified);
                result = dateSorter(aDate, bDate);
                break;
            case MediaSortColumn.Created:
                aDate = moment(a.dateAdded);
                bDate = moment(b.dateAdded);
                result = dateSorter(aDate, bDate);
                break;
            case MediaSortColumn.TotalSize:
                result = numberSorter(a.totalSize, b.totalSize);
                break;
            case MediaSortColumn.Duration:
                return 0;
            default:
                return -1;
        }
        return sort.sortDirection === "asc" ? result : result * -1;
    });
    return folders ? [...folders] : null;
};

export const applyFileSort = (entry: MediaEntry, sort: GridSort) => {
    const files = entry.files?.sort((a, b) => {
        let aDate: Moment;
        let bDate: Moment;
        let result: number;
        switch (sort.sortColumn) {
            case MediaSortColumn.Name:
                result = lexicographicSorter(a.title, b.title);
                break;
            case MediaSortColumn.Modified:
                aDate = moment(a.dateModified);
                bDate = moment(b.dateModified);
                result = dateSorter(aDate, bDate);
                break;
            case MediaSortColumn.Uploaded:
            case MediaSortColumn.Created:
                aDate = moment(a.dateAttached);
                bDate = moment(b.dateAttached);
                result = dateSorter(aDate, bDate);
                break;
            case MediaSortColumn.DateDeleted:
                aDate = moment(a.dateDeleted);
                bDate = moment(b.dateDeleted);
                result = dateSorter(aDate, bDate);
                break;
            case MediaSortColumn.Duration:
                const aDuration = a.videoProperties?.length;
                const bDuration = b.videoProperties?.length;
                result = numberSorter(aDuration, bDuration);
                break;
            case MediaSortColumn.DateTaken:
                aDate = moment(a.dateTaken);
                bDate = moment(b.dateTaken);
                result = dateSorter(aDate, bDate);
                break;
            default:
                return -1;
        }
        return sort.sortDirection === "asc" ? result : result * -1;
    });
    return files ? [...files] : null;
};

export const getFolderMenuItemsVisibility = memoizeOne(
    (folder: BTFolder, isTemplateMode: boolean): IFolderMenuItemsVisibility => {
        const showFolderDetails = !folder.specialFolderExtraData.isSpecialFolder;
        const showEditFolderProperties =
            !folder.specialFolderExtraData.isSpecialFolder && folder.canUpdate;
        const showDownload = canSelectFolder(folder) && folder.mediaType !== MediaType.Video;
        const showCopy =
            !folder.specialFolderExtraData.isSpecialFolder &&
            folder.parentFolderId !== rootLevelFolderId &&
            folder.canUpdate;
        const showMove = showCopy && folder.mediaType !== MediaType.Video;
        const showDelete = !folder.specialFolderExtraData.isSpecialFolder && folder.canDelete;
        const areSomeMenuItemsShown =
            showFolderDetails ||
            showEditFolderProperties ||
            showDownload ||
            showCopy ||
            showMove ||
            showDelete;
        const showGenerateQRCode =
            isInPortal({ builder: true }) &&
            !folder.specialFolderExtraData.isSpecialFolder &&
            !isTemplateMode &&
            folder.directoryType !== DirectoryType.SubOwnerUploadedFiles;

        return {
            showFolderDetails,
            showEditFolderProperties,
            showDownload,
            showCopy,
            showMove,
            showDelete,
            areSomeMenuItemsShown,
            showGenerateQRCode,
        } as IFolderMenuItemsVisibility;
    }
);

export const isPhotoAttachedFiles = (mediaType: MediaType, directoryType?: DirectoryType) =>
    mediaType === MediaType.Photo && directoryType === DirectoryType.AttachedFiles;
export const isPhotosOwnerAndSubPortal = (mediaType: MediaType) =>
    mediaType === MediaType.Photo && isInPortal({ owner: true, subs: true });

export const sortMediaListEntity = (entity: MediaListEntity, sortData?: IMediaListSort) => {
    let e = { ...entity };
    if (sortData) {
        const mediaEntry = e.mediaEntry;
        if (sortData.fileSort) {
            e.mediaEntry.files = applyFileSort(mediaEntry, sortData.fileSort);
        }
        if (sortData.folderSort) {
            e.mediaEntry.folders = applyFolderSort(mediaEntry, sortData.folderSort);
        }
    }
    return e;
};

export const copyImageToClipboard = async (file: BTFile) => {
    // We want limit the size of data being copied.  We are only going to specify a width
    // which will allow the image to scale appropriately
    // note: does not scale up if width is greater than actual width of photo
    const imageWidth = 800;
    const qsParams = queryString.parseUrl(file.thumbnail);
    qsParams.query.width = String(imageWidth);
    qsParams.query.autorotate = String("true");

    const base64String = await getBase64ImageFromUrl(queryString.stringifyUrl(qsParams));
    await validateBase64Image(base64String);

    const imageHtml = `<img src="${base64String}" width="${imageWidth}" alt="${file.title}" />`;

    await writeHtml(imageHtml);

    void message.success("Image copied to clipboard!");
};

export const getAllSupportedFileTypes = () => {
    return [
        ...documentFileTypes,
        ...photoFileTypes,
        ...videoFileTypes,
        ...SoftPlanSupportedFileTypes,
    ];
};

export const convertToBTServiceFileUpload = (file: BTFile): BTServiceFileUpload => {
    const btFile: BTServiceFileUpload = {
        id: file.id.toString(),
        tempId: 0,
        uniqueId: file.id,
        annotationTempFileId: 0,
        annotationGroupId: file.annotations?.annotationGroupId,
        ext: file.extension,
        fileName: file.title,
        fileSize: file.sizeInBytes,
        hasChanged: false,
        isOriginalResolution: false,
        attachAnnotationLayers: [],
        deleteAnnotationLayers: [],
        breakLinks: false,
        is360Media: file.photoProperties?.is360Media ?? false,
        notifications: {
            showOwner: true,
            showSubs: true,
            notifyOwner: false,
            notifySubs: false,
            notifyBuilder: false,
            mainFile: false,
        },
        viewingPermissions: {
            showOwner: true,
            showSubs: true,
            mainFile: false,
        },
        dateTaken: file.dateTaken ? moment(file.dateTaken).toDate() : undefined,
    };
    return btFile;
};

export const SoftPlanSupportedFileTypes = [".cdf", ".csv", ".txt"];

export const TableDrawerXSOn = 18;
export const TableDrawerXSOff = 24;
export const DrawerXSOn = 6;
export const DrawerXSOff = 0;
