// todo instead of duplicating this enums between client/server maybe we should implmenet something like this
// http://continuousdeveloper.com/2017/10/16/keeping-your-c-and-typescript-models-in-sync/
import { CommonConstants } from "types/constants";

export enum BTLoginTypes {
    ALL = 0,
    BUILDER = 1,
    OWNER = 2,
    SUBS = 3,
    LEAD = 4,
    BTADMIN = 5,
    CONTACT = 7,
}

export enum ClosedAccountTypes {
    OpenOnly = 1,
    ClosedOnly = 2,
    OpenOrClosed = 3,
    OpenOrDataRetrieval = 4,
    Canceled = 5,
}

// Copied from .NET - Many commented out values on .NET, see that for commented skipped values
export enum BuilderFilterTypes {
    WebsiteOnly = 1,
    BuilderStatus = 6,
    IncludeUnbilled = 7,
    IncludeWebOnly = 8,
    Partner = 9,
    TypeOfContractor = 10,
    CombinedDate = 21,
    PastDueAndToday = 22,
    ExcludeSuspended = 23,
    BillingTypes = 28,
    EstAnnualRevenue = 31,
    PriceOutMethod = 32,
    ProjectTypes = 33,
    StartAnnualCycleStarted = 38,
    PhoneNumber = 40,
    BuilderNameOrPrimaryAdminName = 41,
    CSM = 42,
    SalesRep = 43,
    EstStartsLastYear = 44,
    BillingCycle = 45,
    IncludeInternalDemoAccounts = 46,
    ExcludeCredited = 51,
    OrderFormDate = 52,
}

export enum EmailLinkType {
    OwnerSelection = 1,
    BuilderSelection = 2,
    OwnerSelectionChoice = 3,
    BuilderTask = 4,
    OwnerChangeOrder = 5,
    BuilderChangeOrder = 6,
    SubChangeOrder = 7,
    ownerEmail = 8,
    subEmail = 9,
    builderEmail = 10,
    // OwnerOptionDetail = 11,
    // BuilderOptionDetail = 12,
    // SubOptionDetail = 13,
    BuilderSelectionChoice = 14,
    SubSelectionChoice = 15,
    SubSelection = 16,
    OwnerWarranty = 17,
    BuilderWarranty = 18,
    SubWarranty = 19,
    OwnerSelectionsList = 20,
    BuilderSchedule = 21,
    SubSchedule = 22,
    OwnerTask = 23,
    BuilderJobsite = 24,
    SubTradeAgreementView = 25,
    BuilderViewSubPage = 26,
    SubPurchaseOrder = 27,
    BuilderPurchaseOrder = 28,
    // BuilderPurchaseOrder_EstimatedCompletionPassed = 29
    LeadActivity = 30,
    Lead = 31,
    LeadEmailUpgradeWizard = 32,
    BuilderBid = 33,
    SubBid = 34,
    BidPackage = 35,
    DocumentEdit = 36,
    builderMessage = 37,
    builderMessageReply = 38,
    BuilderToDo = 39,
    OwnerToDo = 40,
    SubToDo = 41,
    BuilderDailyLog = 42,
    OwnerDailyLog = 43,
    SubDailyLog = 44,
    SubMessage = 45,
    OwnerMessage = 46,
    OwnerSchedule = 47,
    PurchaseOrderPayment = 48,
    BuilderSurvey = 49,
    OwnerSurvey = 50,
    TimeCardItem = 51,
    OwnerCustomerInvoice = 52,
    LeadProposal = 53,
    BuilderCustomerInvoice = 54,
    TimeCardClockOut = 55,
    JobProposal = 56,
    JobProposalOwner = 57,
    SubWePaySetup = 58,
    SubLienWaiver = 59,
    LienWaiver = 60,
    SubPurchaseOrderOnlinepayment = 61,
    WePaySetup = 62,
    OwnerSetup = 63,
    JobProposalExternal = 64,
    SubDocumentDetail = 65,
    OwnerDocumentDetail = 66,
    // PhotoAlbum = 67
    // PhotoAlbumSub = 68
    // PhotoAlbumOwner = 69
    DocumentConversation = 70,
    DocumentConversationSub = 71,
    DocumentConversationOwner = 72,
    DocumentDetail = 73,
    ApprovedSelectionList = 74,
    VideosList = 75,
    SubVideosList = 76,
    OwnerVideosList = 77,
    VideoConversation = 78,
    VideoConversationSub = 79,
    VideoConversationOwner = 80,
    BuilderBill = 81,
    AdminBuilderPaymentRefund = 82,
    PhotoDocsList = 83,
    SubPhotoDocsList = 84,
    OwnerPhotoDocsList = 85,
    PhotoConversation = 86,
    PhotoConversationSub = 87,
    PhotoConversationOwner = 88,
    DocumentAnnotation = 89,
    DailyNotification = 90,
    LeadProposalDetails = 91,
    RFIDetails = 92,
    SubRFIDetails = 93,
    RiskInsuranceSetup = 94,
    RiskInsuranceJobDetails = 95,
    PdfSignatureSigning = 96,
    PdfSignatureSigningOwner = 97,
    PdfSignatureSigningSub = 98,
    EmailLinkClickTracking = 99,
    PdfSignatureRequestDetails = 100,
    SubSummary = 101,
    NewPassword = 102,
    NPSSurvey = 103,
    LeadActivityMap = 104,
    SubSetupOnlinePayments = 105,
    BuilderSummary = 106,
    SubExternalBid = 107,
    SubExternalPO = 108,
    UpdatePaymentMethod = 109,
    BuilderViewUserPage = 110,
    // UbpConversionPrompt = 111,
    EmailLinkClickTrackingActivity = 112,
    BuilderCreditMemo = 113,
    OwnerCreditMemo = 114,
    SubReviewPayments = 115,
    SubOnlinePaymentsList = 116,
    PurchaseOrderPaymentsList = 117,
    Receipts = 118,
    // InternalUserActivation = 120,

    // Used for the mapping attribute. If there is no valid map
    None = -999,
}

/** Maps to MenuItemName in BuidlerEnums.cs
 * Used for identifying which menu item a user is on
 * So that we show the correct name in the menu under the parent
 */
export enum MenuItemName {
    None = 0,
    Summary = 1,
    Jobsites = 2,
    Calendar = 3,
    ChangeOrders = 4,
    Documents = 5,
    Photos = 6,
    Messages = 7,
    Subs = 8,
    Users = 9,
    Warranty = 10,
    Selections = 12,
    BillsPurchaseOrders = 13,
    Leads = 14,
    GeneralItems = 15,
    Bids = 16,
    ToDos = 17,
    DailyLogs = 18,
    TimeClock = 19,
    Surveys = 20,
    Estimate = 21,
    OwnerPayments = 22,
    DailyReminders = 23,
    Contacts = 24,
    Videos = 25,
    Templates = 26,
    Comments = 27,
    NotificationHistory = 28,
    HelpCenter = 29,
    BTUniversity = 30,
    Setup = 34,
    UserPreferences = 35,
    SyncSchedule = 36,
    Logout = 37,
    LeadOpportunities = 38,
    LeadActivities = 39,
    JobInfo = 40,
    JobPriceSummary = 41,
    JobsList = 42,
    JobsMap = 43,
    NewJobFromScratch = 44,
    NewJobFromTemplate = 45,
    PoPayments = 46,
    Budget = 47,
    EmailSupport = 48,
    HomeParent = 49,
    ProjectManagementParent = 50,
    CostsParent = 51,
    SalesParent = 52,
    CommunicationParent = 53,
    FilesParent = 54,
    ContactsParent = 55,
    HelpParent = 56,
    SetupParent = 57,
    NewJobParent = 58,
    More = 59,
    AcceptPaymentsOnline = 60,
    LeadProposals = 61,
    ContactUs = 62,
    RFIs = 63,
    LeadMap = 64,
    LeadActivityCalendar = 65,
    OnlinePaymentReport = 67,
    AccountingIntegration = 68,
    Reporting = 70,
    LiveChat = 71,
    ManageSubscription = 73,
    ReportingBeta = 74,
    GiveFeedback = 75,
    Receipts = 76,
    PurchaseOrders = 77,
    Bills = 78,
    Rebates = 79,
    TrainingPlan = 80,
    Specifications = 81,
    BuildertrendLearningAcademy = 83,
    Deposits = 84,
    Chat = 85,
    Allowances = 86,
    CostCodes = 87,
    JobCostingBudget = 88,
}

export enum DirectionTypes {
    ASC = 0,
    DESC = 1,
    DEFAULT = 2,
}

export const DirectionTypeStrings = new Map<DirectionTypes, "asc" | "desc">([
    [DirectionTypes.ASC, "asc"],
    [DirectionTypes.DESC, "desc"],
]);

export const DirectionTypeEnums = new Map<"asc" | "desc" | undefined, DirectionTypes>([
    ["asc", DirectionTypes.ASC],
    ["desc", DirectionTypes.DESC],
    [undefined, DirectionTypes.DEFAULT],
]);

export enum JobOrigin {
    Unknown = 0,
    Web = 1,
    Mobile = 2,
    Sample = 3,
    BTAdminImport = 4,
}

export enum LoginStatus {
    Success = 0,
    Failed = 1,
    SuggestPasswordReset = 2,
    FrozenBuilder = 3,
    NotConnected = 4,
    ClosedBuilder = 5,
    RequiresMFA = 6,
    NonBtOfferingBuilder = 7,
    RequireEmail = 8,
}

// tracking EntityActionType
export enum NotificationActionType {
    Discussion = 1,
    ChangeOrder_List = 2,
    ChangeOrder_Details = 3,
    DailyLog_List = 4,
    DailyLog_Details = 5,
    Bid_List = 6,
    Bid_Details = 7,
    PurchaseOrder_List = 8,
    PurchaseOrder_Details = 9,
    Schedule_List = 10,
    Schedule_Details = 11,
    Selection_List = 12,
    Selection_Details = 13,
    ToDo_List = 14,
    ToDo_Details = 15,
    Warranty_List = 16,
    Warranty_Details = 17,
    Message_List_Folders = 18,
    Message_List = 19,
    Message_Details = 20,
    Lead_List = 21,
    Lead_Details = 22,
    Comments_List = 23,
    SelectionChoice_Details = 24,
    Jobsite_List = 25,
    Jobsite_Details = 26,
    LeadActivity_Details = 27,
    BidPackage_Details = 28,
    Document_List = 29,
    Payment_List = 30,
    TimeClock_List = 31,
    TimeClock_Details = 32,
    DailyReminders = 33,
    Sub_Details = 34,
    LeadProposal_Details = 35,
    Schedule_Details_Confirmation = 36,
    Payment_Details = 37,
    Bill_Details = 38,
    OwnerPayment_Details = 39,
    Videos_Details = 40,
    Video_Lists = 41,
    LienWaiver_Details = 42,
    // OptionDetails = 43,
    RFI_Details = 44,
    RFI_List = 45,
    PdfSignatureRequest_Details = 46,
    None = 47,
    PdfSignature_Details_Signing = 48,
    LeadInEmail = 49,
    BuilderMainMenu = 50,
    LeadActivity_List = 51,
    FeedDocuments_List = 52,
    ContactUs = 53,
    CreditMemo_Details = 54,
    FeedSchedules_List = 55,
    Receipt_Details = 56,
    Photo_Details = 57,
    Document_Details = 58,
    InternalUser_Details_NotificationSettings = 59,
    InternalUser_Details = 60,
    Jobsite_Details_BuildersRiskInsurance = 61,
    SubSetup_Details = 62,
    Survey_Details = 63,
    SubSetup_Details_NotificationSettings = 64,
    SubSetup_Details_TradeAgreement = 65,
    SubSetup_Details_Certificates = 66,
    Sub_Details_NotificationSettings = 67,
    Jobsite_Details_Owner = 68,
    Owner_Setup = 69,
    Sub_Details_TradeAgreement = 70,
    SurveyOwner_Details = 71,
    TimeClock_ClockOut = 72,
    WarrantyService_Details = 73,
    JobProposal_Details = 74,
    Deposit_Details = 76,
    Channel_Details = 78,
    Integration_Settings = 79,
    HubSpot_Settings = 80,
    Salesforce_Settings = 81,
    Pipedrive_Settings = 82,
    Reconsent_Details = 83,
    Rebates_List = 84,
}

export enum SelectionsDynamicGridColumnTypes {
    Title = 0,
    Choices = 1,
    Category = 2,
    Location = 3,
    Allowance = 4,
    Price = 5,
    Difference = 6,
    Deadline = 7,
    Status = 8,
    Files = 9,
    Vendors = 10,
    Installers = 11,
    Jobsite = 12,
    Requests = 13,
    SelectedChoice = 14,
    SelectedDate = 15,
    Remaining = 16,
    Comments = 17,
    AllowanceId = 18,
    IsRequired = 19,
    RemainingAllowance = 20,
    AllowanceTotal = 21,
    AllowanceTitle = 22,
    JobId = 23,
    SelectionId = 24,
}

export enum OnlinePaymentDefaultAllowValues {
    NO = 1,
    CUSTOM = 2,
    YES = 3,
}

export enum DiscussionsLinkedTypes {
    All = 0,
    Selections = 1,
    ChangeOrders = 2,
    PurchaseOrders = 3,
    Warranty = 4,
    Bids = 5,
    DailyLogs = 6,
    ScheduleItems = 7,
    ToDos = 8,
    OwnerPayments = 9,
    Documents = 11,
    Videos = 12,
    LegacyBills = 13,
    PhotoDocs = 14,
    BidPackages = 15,
    CreditMemos = 16,
    Receipts = 17,
    Bills = 18,
    Jobsite = 19,
    Deposit = 20,
}

export enum TempFileTypes {
    LeadsImport = 0,
    EstimatesImport = 1,
    ScheduleItem = 2,
    JobSites = 3,
    DailyLogs = 4,
    OnlineSignup = 5,
    ToDos = 6,
    LeadsCustomField = 7,
    Leads = 8,
    Warranty = 9,
    DocumentAnnotations = 10,
    Photos = 11,
    MultiUpload = 12,
    MessageAttachments = 13,
    DailyLogDocs = 14,
    ChangeOrderDocs = 15,
    PurchaseOrderPaymentLineItems = 16,
    WarrantyPhotos = 17,
    Selections = 18,
    SelectionChoices = 19,
    DocumentNotifications = 20,
    CostGroupPhotos = 21,
    SubCustomFields = 22,
    MassPaymentLineItems = 23,
    PurchaseOrders = 24,
    OwnerPayments = 25,
    SelectionChoiceAssignedUserMapping = 26,
    LeadProposals = 27,
    SubCertificate = 28,
    POPaymentCustomFieldFiles = 29,
    LienWaivers = 30,
    SelectionChoiceOrder = 31,
    BidPackages = 32,
    ProfilePicture = 33,
    CookieReauth = 34,
    AttachedVideos = 35,
    Contacts = 36,
    PoMapping = 37,
    CostItemImport = 38,
    RFIs = 39,
    RFIResponse = 40,
    WarrantyService = 41,
    RiskInsurance = 42,
    Bids = 43,
    ProposalFormatItems = 44,
    StandaloneDocument = 45,
    Estimate = 46,
    LeadEmailAttachment = 47,
    AnnotationLayerData = 48,
    TodoChecklistItem = 49,
    CustomFieldFiles = 50,
    BuilderPrintLogo = 51,
    BuilderMobileLogo = 52,
    BuilderSubLogo = 53,
    CostCodeItems = 54,
    CreditMemo = 55,
    Receipt = 56,
    DataEntry = 57,
    TradeAgreement = 58,
    Takeoff = 59,
    OutboundPaymentLogo = 60,
    Bill = 61,
    CostCodeImport = 62,
    OutboundPaymentSignature = 63,
    Deposit = 65,
}

/** Obsolete enum to support CFV. Consider removing with the CFV. */
export enum FolderTypes {
    Trash = -999,
    NotAFolder = 0,
    Folder = 1,
    GlobalFolder = 2,
}

export enum DocumentInstanceType {
    StandaloneDocument = 0,
    ChangeOrder = 1,
    // Options have been removed as a feature, but we still allow the export of old data (including docs).
    // This reference needs to remain server side. Should not use in React.
    Option = 2,
    SelectionChoice = 3,
    Selection = 4,
    Lead = 5,
    PurchaseOrder = 6,
    LeadEmailAttachment = 7,
    MailAttachment = 10,
    SubInsuranceCert = 11,
    SubTradeAgreement = 12,
    SubsFileCustomField = 13,
    Warranty = 14,
    WarrantyFileCustomField = 15,
    JobsiteFileCustomField = 16,
    LeadsFileCustomField = 17,
    ContactFileCustomField = 18,
    BidPackage = 19,
    Bid = 20,
    MessagesEmailAttachment = 21,
    GlobalDocument = 22,
    ScheduleItems = 23,
    CustomerInvoices = 25,
    LeadProposal = 26,
    DailyLog = 27,
    Estimate = 28,
    ToDo = 29,
    ToDoFileCustomField = 30,
    LienWaiver = 31,
    SignedLienWaiver = 32,
    POPaymentsFileCustomField = 33,
    ChangeOrdersFileCustomField = 34,
    ProposalFormatItem = 35,
    WarrantyService = 36,
    OwnerPaymentsFileCustomField = 37,
    CostCodeItems = 38,
    SelectionChoiceLineItems = 39,
    LoginFileCustomField = 40,
    DailyLogsFileCustomField = 41,
    RFI = 42,
    RFIResponse = 43,
    BulkExport = 44,
    SignedPurchaseOrderWithPayment = 45,
    SignedPurchaseOrderWithoutPayment = 46,
    RiskInsuranceBinder = 47,
    RiskInsuranceProposal = 48,
    RiskInsurancePolicy = 49,
    RiskInsuranceEndorsement = 50,
    TodoChecklistItem = 51,
    SelectionFileCustomField = 52,
    PurchaseOrderFileCustomField = 53,
    OwnerProfile = 54,
    CreditMemo = 55,
    Receipt = 56,
    Takeoff = 57,
    Bill = 58,
    Specification = 59,
    Deposit = 60,
    ElectronicConsent = 61,
    ClientUpdate = 62,
    ProofOfPurchase = 63,
}

export enum MediaType {
    Unavailable = 0,
    Document = 1,
    Photo = 2,
    Video = 4,
    All = 7,
}

export enum FileLocation {
    NotSet = 99,
    SharedFolder = 0,
    GlobalFolder = 1,
    JobsiteFolder = 2,
    OtherFolder = 5,
    ContractsFolder = 6,
    MessagesEmailAttachmentFolder = 7,
    LeadsFolderShared = 8,
    AllBuilderShared = 9,
    ReceiptsFolder = 10,
    ProfilePictures = 11,
}

export enum MediaPropertiesTab {
    Details = 0,
    VersionHistory = 1,
}

export enum CustomFieldAssociatedType {
    Lead = 0,
    LeadActivity = 1,
    JobsJobInfo = 2,
    Contacts = 3,
    Warranty = 4,
    Subs = 5,
    ToDos = 6,
    PurchaseOrderPayments = 7,
    ChangeOrders = 8,
    OwnerPayments = 9,
    InternalUsers = 10,
    DailyLogs = 11,
    RFIs = 12,
    Selections = 13,
    PurchaseOrders = 14,
}

export enum CustomFieldDataType {
    None = -1,
    Text = 0,
    MultiLineText = 1,
    CheckBox = 2,
    WholeNumber = 3,
    ListOfUsers = 4,
    ListOfSubs = 5,
    Date = 6,
    Currency = 7,
    File = 8,
    DropDownList = 9,
    DropDownCheckList = 10,
    Link = 11,
    MultiSelectUsers = 12,
    MultiSelectSubs = 13,
}

export enum HoSubAck {
    Pending = 1,
    Reschedule = 2,
    Approved = 3,
    BuilderApproved = 4,
    InternallyAccepted = 5,
}

export enum ApprovalStatusTypes {
    NotSubmitted = 1,
    ServiceCompleted = 2,
    SubReschedule = 3,
    HomeownerReschedule = 4,
    NeedsReWork = 5,
    MeetsWarrantyStandards = 6,
    ReadyForNextPhase = 7,
}

export enum MobileColors {
    None = 0,
    Red = 1,
    Green = 2,
    Black = 3,
    Yellow = 4,
    Gray = 5,
    Orange = 6,
}

export enum SurveyQuestionTypes {
    Stars = 1,
    OneToTen = 2,
    YesNo = 3,
    OpenEnded = 4,
}

export enum LeadStatus {
    Open = 0,
    Lost = 1,
    Sold = 2,
    NoOpportunity = 3,
    InProgress = 4,
    OnHold = 5,
    Pending = 6,
    NotApplicable = 7,
}

export enum OpenActivityType {
    Edit = 0,
    ScheduleNew = 1,
    LogCompleted = 2,
    ScheduleNewFollowUp = 3,
    ShowEmailAddress = 4,
    ImportActivity = 5,
    Proposal = 6,
    CopyProposal = 7,
    ViewActivity = 8,
}

export enum PriorityLevel {
    NONE = 0,
    LOW = 1,
    HIGH = 3,
    HIGHEST = 4,
}

export enum ChangeOrderStatus {
    Unreleased = 0,
    Pending = 1,
    Declined = 2,
    Approved = 3,
    Manual = 4,
    ManualDecline = 5,
    All = 6,
    Expired = 7,
    AllForOwner = 8,
    OwnerRequested = 9,
}

export enum PaymentStatus {
    Unpaid = 1,
    Expired = 2,
    Authorized = 3,
    Failed = 4,
    Cancelled = 5,
    Reserved = 6,
    ChargedBack = 7,
    Captured = 8,
    Refunded = 9,
    ManuallyCaptured = 10,
    RefundedAndManuallyCaptured = 11,
    PaymentCapturedButNotProperlyRecorded = 12,
    Void = 13,
}

export enum SettlementStatus {
    InTransit = 1,
    Settled = 2,
}

export enum PaymentType {
    Check = 1,
    CreditCard = 2,
    All = Check | CreditCard,
    Loan = 4,
    PushPayments = 8,
}

export enum MarketplaceOfferingStatus {
    None = 0,
    New = 1,
    Recommended = 2,
    Connected = 3,
    ConnectedAccessible = 4,
}

export enum MarketplaceOfferingType {
    OnsiteConsulting = 2,
    QuickBooks = 3,
    Xero = 4,
    WePay = 5,
    Btu = 6,
    Bri = 7,
    // ProWebsites = 8,
    // Surveys = 9,
    LoginBox = 10,
    LeadContactForm = 11,
    HomeImprovementLoan = 12,
    // Monthend = 13,
    // BriMediumTile = 14,
    GreenSky = 15,
    TwoTen = 16,
    HomeDepot = 17,
    Gusto = 18,
    Hubspot = 21,
}

export enum MarketplaceAction {
    None = 0,
    ShowAdminPrompt = 1,
    GenerateLeadOnClick = 2,
}

export enum CostType {
    Both = -999,
    Cost = 0,
    Variance = 1,
}

export enum LeadSource {
    OnboardingWidget = 0,
    Marketplace15Seconds = 1,
    MarketplaceContactForm = 2,
}

export enum ProServices {
    Unknown = 0,
    NewWebsite = 1,
    BuildertrendUniversity = 2,
    PaymentProcessing = 3,
    BuildersRiskInsurance = 4,
    HomeImprovementLoans = 5,
    OnsiteConsulting = 6,
    TwoTen = 7,
    HomeDepot = 8,
    // Monthend = 9,
    GreenSky = 10,
}

export enum TagTypes {
    "Lead" = 1,
    "Schedule Item" = 2,
    "To-Do" = 3,
    "Daily Log" = 4,
    "Time Clock" = 5,
    "Survey Question" = 6,
    "Error Reporting" = 7,
    "Receipt" = 8,
}

export enum LinkType {
    External = 0,
    InternalWebForms = 1,
    InternalReact = 2,
}

export enum Classification {
    _11Month = 10,
    _12Month = 20,
    _30Day = 30,
    _60Day = 40,
    HoldingUntil = 50,
    NeedsInspection = 70,
    PreClose = 110,
    PunchList = 120,
    Other = 100,
    NewHomeOwner = 80,
    MeetsWarrantyStandards = 60,
    NonWarranty = 90,
    ServiceCompleted = 130,
}

export enum PerformingUserType {
    Unassigned = 0,
    InternalUser = 1,
    Subcontractor = 2,
    Misc = 3,
    HomeDepot = 4,
}

export enum POApprovalStatus {
    Unreleased = -999,
    PendingInternallyAssigned = 0,
    PendingSubReleased = 1,
    ApprovedBySub = 2,
    ApprovedInternally = 3,
    DeclinedBySub = 4,
    PastCompletionDate = 5,
    Voided = 6,
}

/**
 * This is a simplified work complete status so that the server maps from the complex work complete status to this simple one.
 */
export enum POWorkStatusForList {
    NotComplete = 1,
    Complete = 2,
    Voided = 3,
}

export enum POPaidStatusForList {
    NotPaid = 1,
    PartiallyPaid = 2,
    UnderSubReview = 3,
    OnlinePaymentProcessing = 4,
    Paid = 5,
    Voided = 6,
}

export enum POWorkStatus {
    NotComplete = -999,
    SubRequested = 6,
    SubRequestReset = 7,
    SubRequestCompletedThenReset = 11, // Not Complete...
    InspectionCompleteReadyForPayment = 31, // Work Complete...
    InspectionCompleteReadyForPaymentFromCalendar = 32, // Work Complete from cal...
    WorkCompleteInternalUser = 41, // Work Complete...
    WorkCompleteInternalUserFromCalendar = 42, // Work Complete from cal...
}

export enum POVerifyWorkStatuses {
    Pending = 1,
    Voided = 2,
    InProgress = 3,
    SubRequestedInspectionAndPayment = 4,
    WorkCompleted = 5,
}

export enum EmailFolder {
    Global = -9,
    Inbox = -10,
    Drafts = -11,
    Sent = -12,
    Trash = -13,
    None = -999,
}

export enum ReplyType {
    Send = 0,
    Reply = 1,
    ReplyAll = 2,
    Forward = 3,
}

export enum BilledStatus {
    Unknown = 0,
    NotBilled = 1,
    PendingSync = 2,
    Billed = 3,
    Paid = 4,
}

export enum TabPermissions {
    None = 0,
    Summary = 1,
    Jobsites = 2,
    Calendar = 3,
    ChangeOrders = 4,
    Documents = 5,
    Photos = 6,
    Email = 7,
    Subs = 8,
    Admin = 9,
    Warranty = 10,
    Options = 11,
    Selections = 12,
    PurchaseOrders = 13,
    Leads = 14,
    EstimateGeneralItems = 15,
    Bidding = 16,
    ToDos = 17,
    DailyLogs = 18,
    TimeClock = 19,
    Surveys = 20,
    Estimates = 21,
    OwnerInvoices = 22,
    DailyReminders = 23,
    Contacts = 24,
    Videos = 25,
    Templates = 26,
    RFIs = 27,
    Specifications = 28,
    InternalUsers = 29,
    Payroll = 30,
    // ActiveSub = 32,
    CostCodes = 33,
    LeadProposals = 34,
    Receipts = 35,
    Rebates = 36,
    Chat = 37,
    JobCostingBudget = 38,
}

export enum AccessoryPermissions {
    LeadEmail = 1,
    Accounting = 2,
    WebHosting = 3,
    Reporting = 4,
    TimeClockLabor = 5,
    JobProposals = 6,
    ActiveSubs = 7,
    OutboundPayments = 8,
    Takeoffs = 9,
    OutboundPaymentSetup = 10,
}

export enum DiscountTypes {
    Percentage = 1,
    FlatRate = 2,
}

export enum PaymentEntityTypes {
    MassPayment = -1,
    ChangeOrder = 1,
    OwnerInvoice = 2,
    POPayment = 3,
    LeadProposal = 4,
    RiskInsurancePremium = 5,
    RiskInsuranceFee = 6,
    NoEntity = 7,
    Deposit = 8,
}

export enum PaymentTypes {
    OwnerInvoice = 1,
    ChangeOrder = 2,
}

export enum LinkedScheduleType {
    None = -1,
    Schedules = 0,
    PurchaseOrders = 1,
    BidPackages = 2,
    Selections = 3,
    Surveys = 4,
    CustomerInvoices = 5,
    // Options = 6,
    PurchaseOrderPayments = 7,
    ToDos = 8,
    SignatureRequests = 9,
    RFIs = 10,
    // OptionSets = 11,
    Tasks = 12,
    Bills = 13,
}

export enum PredecessorTypes {
    // / "After"
    FS = 1,
    // / "Before"
    SS = 2,
}

export enum NumberRangeOptionItem {
    EqualTo = 2147483644,
    LessThan = 2147483645,
    GreaterThan = 2147483646,
    All = 2147483647,
}

export enum BillingCycles {
    Monthly = 1,
    Quarterly = 3,
    SixMonths = 6,
    Annually = 12,
    Biennially = 24,
    Triennially = 36,
}

export enum DateRangeFilterOptionItem {
    Past365Days = -365,
    Past180Days = -180,
    Past90Days = -90,
    Past60Days = -60,
    Past45Days = -45,
    Past30Days = -30,
    Past14Days = -14,
    Past7Days = -7,
    TodayAndYesterday = -1,
    Today = 0,
    TodayAndTomorrow = 1,
    Next7Days = 7,
    Next14Days = 14,
    Next30Days = 30,
    LessThan90Days = 1090,
    GreaterThan90Days = 1091,
    TodayOnward = 99999,
    Yearly = 2147483628,
    CurrentYear = 2147483627,
    PastYear = 2147483632,
    Past6Months = 2147483633,
    Past3Months = 2147483634,
    LastMonth = 2147483635,
    CurrentMonth = 2147483636,
    SemiMonthly = 2147483637,
    Monthly = 2147483638,
    BiWeekly = 2147483639,
    Weekly = 2147483640,
    RecentAndFuture = 2147483641,
    CustomDates = 2147483646,
    AllDates = 2147483647,
}

export enum DateFrequencyTypes {
    Custom = 0,
    Weekly = 1,
    SemiMonthly = 2,
    Monthly = 3,
    Yearly = 4,
}

export enum SignaturePrefs {
    All = 0,
    ApprovalsOnly = 1,
    None = 2,
}

export enum RequireSelectionSignaturePrefs {
    AllApprovals = 1,
    OwnerApprovalsOnly = 2,
    None = 3,
}

export enum FilterType {
    Checkbox = 0,
    SingleSelect = 1,
    Date = 2,
    MultiSelect = 3,
    GroupedMultiSelect = 4,
    SearchField = 5,
    TextField = 6,
    NumberField = 7,
    DateGroups = 8,
    NumberFilter = 9,
    KeywordSearch = 10,
    Currency = 11,
    Time = 12,
    BuilderSelect = 13,
}

export enum POMappingEntityTypes {
    Bids = 1,
    Selections = 2,
    ChangeOrders = 3,
    Estimates = 4,
    SelectionChoices = 5,
    BidLineItems = 6,
    ChangeOrderLineItems = 7,
    SelectionChoiceLineItems = 8,
}

export enum PaymentMethodType {
    Check = 1,
    CreditCard = 2,
    All = 3,
}

export enum PaymentMobileWalletType {
    ApplePay = 1,
    GooglePay = 2,
}

export enum PaymentBrands {
    Visa = 1,
    Mastercard = 2,
    AmericanExpress = 3,
    Discover = 4,
    Jcb = 5,
    Diners = 6,
    Checking = 7,
    Savings = 8,
    Business = 9,
    GreenSky = 10,
    Nelnet = 11,
    Maestro = 12,
}

export enum OpenedFrom {
    Nothing = 0,
    ChangeOrder = 1,
    PurchaseOrder = 2,
    Rfi = 3,
    Bill = 4,
    Bid = 5,
    Schedule = 6,
    ProofOfPurchase = 7,
    Receipt = 8,
}

export enum OwnerCalendarDefaultViews {
    CalendarView = 0,
    ListView = 1,
    GantChartView = 2,
}

export enum BuilderSelectionViews {
    Category = 0,
    Location = 1,
    List = 2,
    Card = 3,
    Hierarchy = 4,
}

export enum ActivityCalendarView {
    Month = 0,
    Week = 1,
    Day = 2,
}

export enum OwnerSelectionViews {
    Category = 0,
    Location = 1,
    List = 2,
    Card = 3,
    Agenda = 4,
}

export enum ForbiddenReason {
    Denied = 0,
    UpgradeRequired = 1,
    NotConnected = 2,
}

export enum BadRequestReason {
    NotSpecified = 0,
    RequestError = 1,
    Required = 2,
    InvalidValue = 3,
    AccountSyncError = 4,
    UserSyncError = 5,
    LimitExceeded = 6,
}

export enum ViewingPermissionAction {
    Grant = 1,
    Remove = 2,
    Set = 3,
}

export enum ShiftType {
    Regular = 0,
    PTO = 1,
}

export enum MobileStatusIcons {
    NoIcon = "",
    Unreleased = "UNRELEASED",
    Pending = "PENDING",
    PendingReleased = "PENDINGRELEASED",
    PartiallyComplete = "PARTIALLYCOMPLETE",
    Expired = "EXPIRED",
    BuilderApproved = "BUILDERAPPROVED",
    SubApproved = "SUBAPPROVED",
    OwnerApproved = "OWNERAPPROVED",
    ApprovedNextPhase = "APPROVEDNEXTPHASE",
    Declined = "DECLINED",
    Cancelled = "CANCELLED",
}

export enum SelectionApprovalStatusType {
    Unreleased = -1,
    Pending = 0,
    DeclinedByOwner = 1,
    Selected = 2,
    BuilderOverride = 3,
    Unavailable = 4,
    DeclinedByBuilder = 5,
    PartiallyComplete = 6,
    Locked = 7,
    PendingPriceRequestedByOwner = 8,
}

export enum AcceptPaymentOptions {
    No = 1,
    Custom = 2,
    Yes = 3,
}

export enum UserActivationStatus {
    ReadyToInvite = "Ready for Invite",
    NoEmail = "No Email",
    Active = "Active",
    Disabled = "Disabled",
    Pending = "Pending",
    Frozen = "Frozen",
    Inactive = "Inactive",
}

export enum SubVendorTradeAgreementStatus {
    Accepted = "Accepted",
    Pending = "Pending",
    Declined = "Declined",
}

export enum TradeAgreementApprovalStatus {
    NoRequestMade = CommonConstants.EmptyInteger,
    Pending = 0,
    Accepted = 1,
    Declined = 2,
    ManuallyAcceptedByBuilder = 3,
    Unreleased = 4,
}

export enum CostTypes {
    None = -1,
    Labor = 1,
    Material = 2,
    Equipment = 3,
    Subcontractor = 4,
    Bid = 5,
    Allowance = 6,
    Other = 7,
}

export enum MarkedAs {
    None = -1,
    Bid = 1,
    Allowance = 2,
    LegacyBidAllowance = 3,
}

export enum BidPackageColumnTypes {
    Title = 1,
    Job = 2,
    Files = 3,
    Responses = 4,
    Accepted = 5,
    Released = 6,
    Deadline = 7,
    Status = 8,
    Description = 9,
    RFIs = 10,
    MultipleAcceptedBids = 11,
    InternalNotes = 12,
    BidPricingFormat = 13,
    BidPackageId = 14,
    JobId = 15,
    UnlinkedBidLineItems = 16,
    IsUsingDivisions = 17,
    BidDivisionCount = 18,
    BidRequestCount = 19,
    ApprovedBidTotal = 20,
}

// Template is only used for the Subs and Internal Users Job Access Lists
export enum JobStatusTypes {
    Closed = 0,
    Open = 1,
    Deleted = 2,
    Presale = 3,
    Warranty = 4,
    Template = 5,
}

export enum JobsiteImportStatus {
    Started = 0,
    Processing = 1,
    Success = 2,
    Failed = 3,
    ActionRequired = 4,
}

export enum JobImportActionRequiredType {
    None = 0,
    Job = 1,
    TemplateCopy = 2,
    ScheduleConflictsWarning = 3,
    ScheduleAddPerformingUsersToJob = 4,
    ScheduleNotifyAllPerformingUsers = 5,
}

export enum ProposalStatus {
    ApprovedUnreleased = -2,
    DeclinedUnreleased = -1,
    Unreleased = 0,
    Pending = 1,
    Declined = 2,
    Approved = 3,
    ManualApprove = 4,
    ManualDecline = 5,
    Expired = 6,
}

export enum ProposalSignatureRequiredStatus {
    NotRequired = 0,
    Required = 1,
}

export enum AccountingValidationStatus {
    Valid = 0,
    Invalid = 1,
    NotLinked = 2,
    Pending = 3,
    Billed = 4,
}

export enum OperatorType {
    Add,
    Subtract,
}

export enum CalendarItemType {
    ScheduleItem = 0,
    Task = 1,
    Photos = 2,
    ChangeOrders = 3,
    // OptionDeadlines = 4,
    SelectionDeadlines = 5,
    WarrantyService = 6,
    DailyLog = 7,
    ToDo = 8,
    CustomerInvoices = 9,
    Phases = 10,
    BidPackages = 11,
    RFIs = 12,
    PurchaseOrders = 13,
    LeadActivities = 14,
}

export enum CheckAllState {
    None,
    Indeterminate,
    AllChecked,
}

export enum AccountingIntegrationType {
    None = 0,
    QuickBooksDesktop = 1,
    QuickBooksOnline = 2,
    Xero = 3,
    // QBWebConnected = 4 : This is a data-only option that should only be used on mobile
}

export enum DirectoryType {
    Trash = -999,
    Standard = 0,
    GlobalDocs = 1,
    AttachedFiles = 2,
    SubOwnerUploadedFiles = 3,
}

export enum FileStatus {
    Unavailable = 0,
    Available = 1,
    Pending = 2,
    Uploading = 3,
    Error = 4,
}

export enum EntityWithLinkedCostCodeItemsType {
    JobEstimate = 0,
    LeadProposal = 1,
    Selection = 2,
    SelectionChoice = 3,
    ChangeOrder = 4,
    PurchaseOrder = 5,
    CostGroups = 6,
}

export enum HelpCenterTypes {
    HelpCenter = 1,
    SubHelpCenter = 2,
    OwnerHelpCenter = 3,
    QuickBooksHelp = 4,
    XeroHelp = 5,
    CalendarFeedGuide = 6,
    SupportedBrowsers = 7,
    NotificationConfigOptions = 8,
    OwnerNotificationConfigOptions = 9,
    SubNotificationConfigOptions = 10,
    PaymentsFromPrintouts = 11,
    ChaseBankAccounts = 12,
    ThreeDs = 13,
    Leads = 14,
}

export enum ImageResizeMode {
    Pad = "pad",
    Crop = "crop",
    Max = "max",
}

export enum ObjectFit {
    Fill = "fill",
    Contain = "contain",
    Cover = "cover",
    None = "none",
    ScaleDown = "scale-down",
}

export enum FileEncryption {
    FileIdIsEncrypted = 0,
    FileIdIsUnencrypted = 1,
    // Likely adding additional enum vals based on GetFile Intuit scan solution
}

export enum OnlineSignupStatus {
    Started = 0,
    Processing = 1,
    Success = 2,
    Failed = 3,
}

export enum SignupStep {
    SignupContact = 0,
    SignupBilling = 1,
}

export enum DataScienceModalStep {
    FirstPage = 0,
    SecondPage = 1,
}

export enum PartnerCategoryType {
    Takeoffs = 1,
}

export enum SignupCodeAccountTypes {
    Normal = 0,
    Demo = 1,
    Unbilled = 2,
    ACH = 3,
}

export enum AcceptDocuments {
    TermsAndConditions = 1,
    TermsOfUseAndPrivacyPolicy = 2,
}

export enum MicrodepositVerificationStatus {
    Unverified = 0,
    Verified = 1,
    VerificationFailed = 2,
    Deleted = 3,
}

export enum ItemType {
    Unknown = 0,
    Plan = 1,
    Addon = 2,
    Charge = 3,
}
export enum AttachedItemType {
    UnKnown = 0,
    Recommended = 1,
    Mandatory = 2,
    Optional = 3,
}

export enum PricingModel {
    UnKnown = 0,
    FlatFee = 1,
    PerUnit = 2,
    Tiered = 3,
    Volume = 4,
    Stairstep = 5,
}

export enum BillingPeriodUnit {
    UnKnown = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4,
}

export enum DiscountType {
    UnKnown = 0,
    FixedAmount = 1,
    Percentage = 2,
}

export enum DurationType {
    UnKnown = 0,
    OneTime = 1,
    Forever = 2,
    LimitedPeriod = 3,
}

export enum ChargebeePriceType {
    TaxExclusive = 1,
    TaxInclusive = 2,
}

export enum ChargebeeEntityType {
    Adhoc = 1,
    PlanItemPrice = 2,
    AddonItemPrice = 3,
    ChargeItemPrice = 4,
}

export enum ChargebeeInvoiceEstimateLineItemEntityType {
    UnKnown = 0,
    PlanSetup = 1,
    Plan = 2,
    Addon = 3,
    Adhoc = 4,
    PlanItemPrice = 5,
    AddonItemPrice = 6,
    ChargeItemPrice = 7,
}

export enum TaxExemptReason {
    TaxNotConfigured = 1,
    RegionNonTaxable = 2,
    Export = 3,
    CustomerExempt = 4,
    ProductExempt = 5,
    ZeroRated = 6,
    ReverseCharge = 7,
    HighValuePhysicalGoods = 8,
}

export enum ChargebeeSubscriptionStatus {
    UnKnown = 0,
    Future = 1,
    In_Trial = 2,
    Active = 3,
    Non_Renewing = 4,
    Paused = 5,
    Cancelled = 6,
}

export enum GetPaidYourWayTestGroup {
    None = 0,
    PrePay = 1,
    TransactionBased = 2,
    BuildersChoice = 3,
}

export enum TrialEndAction {
    SiteDefault = 1,
    PlanDefault = 2,
    ActivateSubscription = 3,
    CancelSubscription = 4,
}

export enum ShareType {
    Entity = 0,
    Social = 1,
    System = 2,
    LocalEdit = 3,
}

export enum SharedEntityType {
    StandaloneDocument = 0,
    BidForSub = 1,
    EmailMessage = 2,
    JobProposal = 3,
    LeadProposal = 4,
    PurchaseOrderForSub = 5,
    UserActivation = 6,
    OwnerInvoiceAnonymous = 8,
    LienWaivers = 9,
    LeadProposalPDFGeneration = 10,
    JobProposalPDFGeneration = 11,
    OwnerInvoice = 12,
    UserScheduleConfirmation = 13,
    // OnlinePayment = 14
    PurchaseOrderPDFGeneration = 15,
    LienWaiverPDFGeneration = 16,
    OutboundPayment = 17,
    Deposit = 18,
    MicrodepositVerification = 19,
    DepositAnonymous = 20,
}

export enum ActivationStatus {
    NotSent = 0,
    Pending = 1,
    Active = 2,
    Disabled = 3,
}

export enum InternalUserActivationStatus {
    Inactive = 0,
    ActiveNoInvite = 1,
    ActiveInvitePending = 2,
    ActiveInviteAccepted = 3,
    Archived = 4,
}

/**
 * This enum currently serves two purposes. All values are used for different estimate "views"
 * and only CostCategory and Assembly are used for "groupings" when on the ProposalWorksheet "view"
 */
export enum ProposalGroupTypes {
    /**
     * List View
     */
    None = 0,
    /**
     * Cost Code View and Cost Code Grouping
     */
    CostCategory = 1,
    /**
     * Groups View and Custom Grouping
     */
    Assembly = 2,
    /**
     * Proposal Worksheet View
     */
    ProposalWorksheet = 3,
    /**
     * Takeoff Assemblies View
     */
    Takeoff = 4,
}

export enum MeteredUsageType {
    GetPaidYourWayOutboundPayment = 1,
}

export enum EntityTypes {
    Todo = 1,
    Contact = 2,
    ChangeOrder = 3,
    PurchaseOrder = 4,
    Sub = 5,
    InternalUser = 6,
    IntegrationPartner = 7,
    Bill = 8,
    Discussion = 9,
    // Events for all media files: Documents, Photos, and Videos
    File = 10,
    DailyLog = 11,
    Schedule = 12,
    TimeClock = 13,
    Selection = 14,
    Survey = 15,
    Warranty = 16,
    OwnerInvoice = 17,
    Rfi = 18,
    Bid = 19,
    Estimate = 20,
    Lead = 21,
    Job = 22,
    GlobalUser = 24,
    Message = 27,
    SignatureRequest = 28,
    BidPackage = 29,
    LeadProposal = 30,
    SelectionChoice = 31,
    Allowance = 32,
    Builder = 33,
    // Events for all media folders: Documents, Photos, and Videos
    Folder = 34,
    Payment = 35,
    CostCode = 36,
    CostItem = 37,
    CreditMemo = 38,
    Receipt = 39,
    TodoChecklistItem = 40,
}

export enum NotificationTypes {
    DONTSAVE = 0,
    NEWMESSAGE = 1,
    SCHEDULESHIFT = 2,
    SCHEDULEREMINDER = 3,
    CHANGEORDERAPPROVED = 4,
    CHANGEORDERADDED = 5,
    CHANGEORDERNEWDEADLINE = 6,
    CHANGEORDERREMINDER = 7,
    WARRANTYADDED = 11,
    WARRANTYNEEDSFOLLOWUP = 12,
    WARRANTYSTATUSCHANGED = 13,
    WARRANTYFEEDBACKREMINDER = 14,
    WARRANTYDAILYREMINDER = 15,
    INSURANCEREMINDER = 17,
    MANUALLYGENERATED = 18,
    SELECTION_APPROVED = 19,
    SELECTION_DEADLINE_REMINDER = 20,
    SELECTION_DISCUSSION_ADDED = 21,
    SELECTION_CHOICE_ADDED = 22,
    SELECTION_OWNER_PRICE_REQUESTED = 23,
    SELECTION_OWNER_PRICE_ENTERED = 24,
    SELECTION_FILES_ADDED = 25,
    SELECTION_CHOICE_FILES_ADDED = 26,
    SELECTION_ADDED = 27,
    USER_NEEDS_SCHEDULE_CONFIRMATION = 28,
    CHANGEORDER_FILES_ADDED = 29,
    OPTION_FILES_ADDED = 30,
    CHANGEORDER_DISCUSSION_ADDED = 31,
    SUB_ACTIVATION = 32,
    USER_SCHEDULE_CONFIRMED_TO_BUILDER = 33,
    HOMEOWNER_ACTIVATION = 34,
    WARRANTY_OWNER_APPOINTMENT_UPDATED = 35,
    WARRANTY_OWNER_LEFT_FEEDBACK = 36,
    WARRANTY_SUB_APPOINTMENT_UPDATED = 37,
    DAILY_LOG_NOTIFICATION = 38,
    WARRANTY_COORDINATOR_CHANGED = 39,
    WARRANTY_SCHEDULE_CHANGED = 40,
    SELECTION_APPROVED_VENDOR = 41,
    SELECTION_APPROVED_INSTALLER = 42,
    SELECTION_CHOICE_VENDOR_PRICE_REQUESTED = 43,
    TradeAgreementAcceptanceRequestedToSub = 44,
    TradeAgreementActionTaken = 45,
    SELECTION_MANUALLY_APPROVED = 46,
    CHANGEORDER_MANUALLY_APPROVED = 47,

    // POs
    PO_Released_To_Sub = 48,
    PO_Accepted_By_Sub = 49,
    PO_Assigned_To_Internal_User = 50,
    PO_Manually_Approved_For_Sub = 51,
    PO_Sub_Requested_Payment = 52,
    PO_WorkComplete_ReadyForPayment = 53,
    PO_Payment_Made = 54,
    PO_DISCUSSION_ADDED = 55,
    PoFilesAdded = 56,
    WARRANTYADDED_MANUALLY = 57,
    PO_InspectWorkReminder = 58,
    LeadOutEmail = 59,
    LeadInEmail = 60,
    JOBSITE_CHANGE_PROJECT_MANAGER = 61,
    LeadNotifyWhenOtherEmployeeContact = 62,
    LeadNotifySalespersonChanged = 64,
    SELECTION_SUB_PRICE_ENTERED = 65,
    LeadNotifyActivityReminder = 66,
    LeadNotifyEmailQuotaAlert = 67,
    WARRANTY_DISCUSSION_ADDED = 68,
    USER_SCHEDULE_DECLINED_TO_BUILDER = 69,
    WarrantyFilesAdded = 70,
    PO_WorkComplete_InternallyAssigned = 71,

    // BIDS
    Bid_SubSubmitted = 72,
    Bid_BuilderSubmitted = 73,
    Bid_Accepted = 74,
    Bid_Released = 75,
    Bid_SubConfirmStatusChanged = 76,
    Bid_ResetToInProgress = 77,
    Bid_Changed = 78,
    Bid_Declined = 79,
    Bid_SubResubmitted = 80,
    Bid_BidPackageReopened = 81,
    Bid_BiddingClosed = 82,
    Bid_BidPackageUnreleased = 83,
    Bid_DiscussionAdded = 88,
    Bid_BidPackageDeadlineReminder = 89,
    Bid_AcceptedBuilder = 91,
    WARRANTY_SUB_SCHEDULE_ADDED = 92,
    Todos_Added = 93,
    Todos_Reminder = 94,
    Todos_CompletedByOthers = 95,
    Bid_BidPackageFilesAdded = 96,
    PO_Declined = 97,
    PaymentReminder = 98,

    Daily_Log_Discussion_Added = 99,
    Schedule_Item_Discussion_Added = 100,
    TO_DO_DISCUSSION_ADDED = 101,
    OwnerRequestedChangeOrder = 102,
    BuilderSentSurvey = 103,
    BuilderScheduledSurvey = 104,
    OwnerSubmittedSurvey = 105,
    LeadActivityAssigned = 106,
    TimeClock_Adjustment = 107,
    CustomerInvoice = 108,
    LeadProposalReleased = 109,
    CustomerInvoiceUpdated = 110,
    TimeClock_OvertimeReached = 111,
    CustomerInvoiceReminder = 112,
    CustomerInvoicePastDue = 113,
    TimeClock_LongShift = 114,
    LeadProposal_ProposalSent = 115,
    LeadProposal_AcceptDecline = 116,
    LeadProposal_Viewed = 117,
    DailyLog_FilesAdded = 118,
    JobProposalReleased = 119,
    JobProposal_AcceptDecline = 120,
    JobProposal_Viewed = 121,
    SubOnlinePaymentWaiting = 122,
    WarrantyRescheduleRequested = 123,
    POPaymentReadyForPayment = 124,
    ToDoFilesAdded = 125,
    LienWaiverSent = 126,
    LienWaiverSigned = 127,
    LienWaiverDeclined = 128,
    OnlinePaymentInvitation = 129,
    CustomerInvoiceFilesAdded = 130,
    OnlinePaymentSent = 131,
    OwnerPaymentDiscussionAdded = 132,
    PDF_Annotated = 134,
    Document_Uploaded = 135,
    OnlinePaymentReceipt = 137,
    SubSetupForOnlinePayments = 138,
    PhotoCommentAdded = 139,
    DocumentCommentAdded = 140,
    ScheduleItemFilesAdded = 141,
    OwnerSubUploadedDocs = 142,
    OwnerPaymentAdded = 143,
    Video_Uploaded = 145,
    VideoCommentAdded = 147,
    LeadUnsubscribed = 148,
    LienWaiverFilesAdded = 149,
    PODocumentsAddedBySub = 150,
    Todos_PastDue = 152,
    WarrantyServiceInternallyAssigned = 153,
    LeadProposalPaymentDueReminder = 154,
    PhotoAdded = 155,
    ChangeOrderApprovalRetracted = 156,
    LeadUnsubscribeConfirmation = 157,

    // RFIs
    RFIAddedByBuilder = 158,
    RFIReminder = 159,
    RFIResponseAdded = 160,
    RFIAddedBySub = 161,
    RFICompletedReopened = 162,
    RFIAssignedToYou = 163,
    PO_Voided_By_Builder = 164,
    RFIPastDue = 165,
    RFIFilesAdded = 166,

    // Risk Insurance
    RiskInsuranceApprovedDenied = 167,
    RiskInsurancePolicyReceived = 168,
    RiskInsuranceIndividualBindersReceived = 169,
    LeadActivityUpdate = 170,

    // PDF Signature
    PdfSignatureRequestAssigned = 171,
    PdfSignatureRequestSigned = 172,
    PdfSignatureRequestReminder = 173,
    PdfSignatureRequestPastDue = 174,
    PdfSignatureRequestResetToPending = 175,

    // Email Link Tracking
    EmailLinkClicked = 177,

    // Partner Integrations
    PO_Released_To_Home_Depot = 178,
    LeadProposalPaymentMade = 179,
    SUB_ACTIVATION_REQUESTED = 180,
    MassReassign = 181,
    JobsiteViewingAccess = 182,
    LeadProposalPastDue = 183,
    EstimatePastDue = 184,
    TimeClock_OutOfBoundsShift = 185,
    BidSubmittedReceipt = 186,
    POReceipt = 187,
    CreditMemoAdded = 188,
    JobsLinked = 189,
    UserActivationRequest = 190,
    InternalUserActivated = 191,
    RECEIPTS_DISCUSSION_ADDED = 192,
    SUB_UPLOADED_CERTIFICATE = 193,
    BillReadyForPayment = 194,
    PaymentMethodMicrodepositSent = 195,
    OnlineProcessPaymentInvitation = 196,
    CREDITMEMO_DISCUSSION_ADDED = 197,
    PO_Approval_Reminder = 198,
    BILL_DISCUSSION_ADDED = 199,

    // Obsoleted Values should be negative to prevent interferring with new additions
    PO_Accepted_Declined_By_Sub = -1,
    PO_Declined_Internal = -2,
    PO_Finalized_For_Payment = -3,
    Bid_RFICreated = -4,
    Bid_RFIAssigned = -5,
    Bid_RFIAnswered = -6,
    Bid_RFIAnswerByReminder = -7,
    // OPTIONADDED = -8,
    // OPTIONAPPROVED = -9,
    // OPTIONREMINDER = -10,
    BillConvertedToPO = 144,
}

export enum BillsAndPOsTab {
    PurchaseOrders = 1,
    Bills = 2,
}

export enum AccountingMethod {
    Cash = 0,
    Accrual = 1,
}

export enum ChargebeeInvoiceStatus {
    Paid = 1,
    Posted = 2,
    PaymentDue = 3,
    NotPaid = 4,
    Voided = 5,
    Pending = 6,
}

export enum OutboundWalletStatus {
    WalletAllowed = 0,
    WalletProPending = 1,
    WalletProRejected = 2,
    WalletRejected = 3,
}

export enum EstimatedAnnualRevenue {
    ZeroToFourHundredNinetyNineThousand = 1,
    FiveHundredThousandTo999K = 2,
    OneMillionToUnderTwoMillion = 3,
    TwoMillionToUnderFiveMillion = 4,
    FiveMillionToUnderTenMillion = 5,
    TenMillionToUnderFifteenMillion = 6,
    FifteenMillionToUnderTwentyFiveMillion = 7,
    TwentyFiveMillionToUnderFiftyMillion = 8,
    FiftyMillionPlus = 9,
    PreferNotToAnswer = 10,
}

export enum PrimaryJobType {
    FullyCustomNew = 0,
    SemiCustomNew = 1,
    ProductionHomes = 2,
    ModularHomes = 3,
    MultiFamilyHomes = 4,
    WholeHomeRemodels = 5,
    HomeAdditions = 6,
    KitchenBathBasement = 7,
    InsuranceRestoration = 8,
    SpecialtyTrade = 9,
    Commercial = 10,
    PoolsOutdoor = 11,
    RenovateResell = 12,
    Other = 13,
}

export enum JobContractType {
    FixedPrice = 0,
    OpenBook = 1,
    Other = 99,
}

export enum JobDescriptionType {
    Other = -1,
    None = 0,
    Accountant = 1,
    Superintendent = 2,
    Owner = 3,
    FieldCrew = 4,
    OfficeManager = 5,
    ProjectManager = 6,
    ProductSelectionsCoordinator = 7,
    Designer = 8,
    Sales = 9,
}

export enum ClientObservableBackgroundJobStatus {
    NotStarted = 0,
    Processing = 1,
    Success = 2,
    Failed = 3,
    Deleted = 4,
    ActionRequired = 5,
}

/**
 * There are server side versions of these that match - do not change names
 */
export enum ImpersonationTypes {
    None = 0,
    BTAdmin = 1,
    BuilderImpersonatingOwner = 2,
}

export enum DemoType {
    AccountExecutive = 0,
    PaymentProcessingSpecialist = 1,
}

export enum DeactivationReason {
    PersonalEmailUsed = 0,
    EmailTypo = 1,
    SecondaryEmailActivation = 2,
    IncorrectEmail = 3,
    AccountRecoveryIssue = 4,
    CompanyEmailReplaced = 5,
}

export enum ActivationErrorTypes {
    None = 0,
    DefaultInvalidInvite = 1,
    CrossLoginTypeActivation = 2,
    InviteAlreadyAccepted = 3,
    UserIsDisabledOrDeleted = 4,
    AccountOwnerDoesNotExistOrIsClosed = 5,
}
