import { routes } from "utilities/routes";

export const JobHelpers = {
    /**
     * @description This function is used to determine if the job details page is in full-page mode, which means it handles routing and links differently.
     * @param bdsJobDetailsToFullPageFlag feature flag
     * @param path our current path
     * @returns boolean
     */
    shouldHandleAsFullPage: (bdsJobDetailsToFullPageFlag: boolean | undefined, path: string) => {
        return bdsJobDetailsToFullPageFlag && path.includes(routes.job.flat);
    },
};
