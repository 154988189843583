import { BdsIcon } from "@buildertrend/components";

import { BTAlert } from "commonComponents/btWrappers/BTAlert/BTAlert";

interface IPublicEphBannerProps {
    ephRemainingDays: number;
}

function PublicEphBanner({ ephRemainingDays }: IPublicEphBannerProps) {
    return (
        <BTAlert
            type="info"
            showIcon
            icon={<BdsIcon iconName="ExclamationCircle" size="lg" />}
            data-testid="publicEphAlert"
            className="PublicEphBanner"
            message={`You are viewing a test version of our website. This site is temporary and will be unavailable in ${ephRemainingDays} days.`}
        />
    );
}

export default PublicEphBanner;
