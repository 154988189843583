import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IClientPortalSettingsDrawerProps } from "./ClientPortalSettingsDrawer";

const ClientPortalSettingsDrawer = lazyLoadWithRetry(() => import("./ClientPortalSettingsDrawer"));

export const RouteClientPortalSettingsDrawer: React.FunctionComponent<
    Omit<IClientPortalSettingsDrawerProps, "history" | "location" | "match">
> = (props) => {
    return (
        <RouteRelative
            path={routes.job.clientPortalSettings}
            render={(routeProps) => (
                <BTSuspense>
                    <ClientPortalSettingsDrawer {...props} {...routeProps} />
                </BTSuspense>
            )}
        />
    );
};
