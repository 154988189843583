import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { ActivationResponse } from "commonComponents/entity/credentials/Credentials/Credentials.api.types";

import { LoginResponse } from "entity/login/Login/Login.api.types";

import {
    Auth0ActivationResponse,
    IFinalizeActivationRequest,
    ILinkAccountEntity,
    ILinkAccountRequest,
    ILoginRequest,
    LinkAccountEntity,
} from "./LinkAccount.api.types";
import { default as LinkAccountEntityExampleJson } from "./LinkAccountEntity.example.json";

export interface ILinkAccountHandler {
    linkExisting(request: ILinkAccountRequest): Promise<ActivationResponse>;
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    get(shareToken: string): Promise<LinkAccountEntity>;
    login(request: ILoginRequest): Promise<LoginResponse>;
    createNewUser: (request: ILinkAccountRequest) => Promise<ActivationResponse>;
    finalizeActivation: (request: IFinalizeActivationRequest) => Promise<Auth0ActivationResponse>;
    forgotPassword: (username: string) => Promise<EmptyResponseEntity>;
}

export class LinkAccountHandler implements ILinkAccountHandler {
    /*
     * @deprecated Use the useUsersActivationByShareTokenGetV2 handler instead.
     */
    async get(shareToken: string): Promise<LinkAccountEntity> {
        return await APIHandler(`/apix/v2/Users/activation/${shareToken}`, {
            method: "GET",
            responseType: LinkAccountEntity,
        });
    }
    async linkExisting(request: ILinkAccountRequest): Promise<ActivationResponse> {
        return await APIHandler("/api/Users/LinkExisting", {
            method: "PUT",
            data: request,
            responseType: ActivationResponse,
        });
    }
    async login(request: ILoginRequest): Promise<LoginResponse> {
        return await APIHandler("/api/Login/AjaxLogin", {
            method: "POST",
            data: request,
            responseType: LoginResponse,
        });
    }

    async createNewUser(request: ILinkAccountRequest): Promise<ActivationResponse> {
        return await APIHandler("/api/Users/CreateNewUser", {
            method: "POST",
            data: request,
            responseType: ActivationResponse,
        });
    }

    async finalizeActivation(
        request: IFinalizeActivationRequest
    ): Promise<Auth0ActivationResponse> {
        return await APIHandler("/api/Users/FinalizeActivation", {
            method: "POST",
            data: request,
            responseType: Auth0ActivationResponse,
        });
    }

    async forgotPassword(username: string): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Login/ForgotPassword`, {
            method: "POST",
            data: { username: username },
            responseType: EmptyResponseEntity,
        });
    }
}

export class FakeLinkAccountHandler implements ILinkAccountHandler {
    async get(_shareToken: string): Promise<LinkAccountEntity> {
        return Promise.resolve(
            new LinkAccountEntity(LinkAccountEntityExampleJson as ILinkAccountEntity)
        );
    }
    async linkExisting(_request: ILinkAccountRequest): Promise<ActivationResponse> {
        throw new Error("Method not implemented.");
    }
    async login(_request: ILoginRequest): Promise<LoginResponse> {
        throw new Error("Method not implemented.");
    }
    async createNewUser(_request: ILinkAccountRequest): Promise<ActivationResponse> {
        throw new Error("Method not implemented.");
    }
    async finalizeActivation(
        _request: IFinalizeActivationRequest
    ): Promise<Auth0ActivationResponse> {
        throw new Error("Method not implemented.");
    }
    async forgotPassword(_username: string): Promise<EmptyResponseEntity> {
        throw new Error("Method not implemented.");
    }
}

export const defaultLinkAccountHandler = new LinkAccountHandler();
