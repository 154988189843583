import { BdsText } from "@buildertrend/components";

import { externalPages } from "utilities/externalPages";

import { BTExternalLink } from "commonComponents/btWrappers/BTExternalLink/BTExternalLink";

interface ICredentialsDisclaimerProps {
    actionLabel: string;
}

export function CredentialsDisclaimer({ actionLabel }: ICredentialsDisclaimerProps) {
    return (
        <>
            <BdsText
                size="normal-md"
                text={`By clicking "${actionLabel}", I agree to the Buildertrend `}
            />
            <BTExternalLink
                href={externalPages.BuildertrendTermsOfUse}
                target="_blank"
                isUnderline={true}
            >
                <BdsText size="normal-md" text="Terms of Use" />
            </BTExternalLink>
            <BdsText size="normal-md" text=" and " />
            <BTExternalLink
                href={externalPages.BuildertrendPrivacyPolicy}
                target="_blank"
                isUnderline={true}
            >
                <BdsText size="normal-md" text="Privacy Policy" />
            </BTExternalLink>
            <BdsText size="normal-md" text="." />
        </>
    );
}
