import classNames from "classnames";
import { Component } from "react";

import { ImageResizeMode, ObjectFit } from "types/enum";

import {
    BTIconFileExcelFilled,
    BTIconFileFilled,
    BTIconFileImageFilled,
    BTIconFilePdfFilled,
    BTIconFilePptFilled,
    BTIconFileTextFilled,
    BTIconFileWordFilled,
    BTIconFileZipFilled,
    BTIconFolderOpenFilled,
    BTIconFolderOpenGlobalFilled,
    BTIconPictureFilled,
    BTIconVideoCameraFilled,
} from "commonComponents/btWrappers/BTIcon";
import { Thumbnail } from "commonComponents/utilities/Thumbnail/Thumbnail";

import "./IconFile.less";

interface IIconFileProps {
    /**
     * The file extension that correlates with the desired icon to display.
     * If a thumbnail is also provided, the source will be set to the thumbnail.
     * @examples xlsx, pdf, pptx, docx
     */
    fileType?: string;

    /**
     * Indicates the desired icon is the generic (yellow) folder icon.
     * If the isGlobalFolder prop is also true, the source will be set to the global (blue) folder icon.
     */
    isFolder?: boolean;

    /**
     * Indicates the desired icon is the global (blue) folder icon.
     * Does not need to be combined with the isFolder prop if only the global folder icon is desired.
     */
    isGlobalFolder?: boolean;

    /**
     * Sets the size of the icon.
     */
    size: "xsmall" | "small" | "medium" | "large" | "xlarge" | number;

    /**
     * Sets the source for the image displayed.
     * If not provided, the source will default to a standard file icon (e.g. PDF icon) based on the file type.
     */
    thumbnail?: string;
    /**
     * Sets the size of the thumbnail.
     */
    thumbnailSize?: "xsmall" | "small" | "medium" | "large" | "xlarge" | number;

    thumbnailResizeMode?: ImageResizeMode;

    className?: string;

    /**
     * Applies to the icon for images
     * Toggles between the standard ant icon for images and BTs horizontal icon for images
     */
    horizontal?: boolean;

    /**
     * if we need a custom click handler for the icon
     */
    onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
}

export class IconFile extends Component<IIconFileProps> {
    private getIcon = (sizeInPixels: number) => {
        const { fileType, isFolder, isGlobalFolder, className, horizontal, onClick } = this.props;

        const commonProps = {
            size: sizeInPixels,
            className,
            onClick,
        };

        if (isFolder && !isGlobalFolder) {
            return <BTIconFolderOpenFilled {...commonProps} />;
        }

        if (isGlobalFolder) {
            return <BTIconFolderOpenGlobalFilled {...commonProps} />;
        }

        switch (fileType?.toLowerCase()) {
            case "xls":
            case "xlm":
            case "xlsx":
            case "xltx":
            case "xlsm":
            case "csv":
            case "gsheet":
                return <BTIconFileExcelFilled {...commonProps} />;

            case "pdf":
                return <BTIconFilePdfFilled {...commonProps} />;

            case "pps":
            case "ppt":
            case "pptx":
                return <BTIconFilePptFilled {...commonProps} />;

            case "doc":
            case "docx":
            case "docm":
            case "dotx":
            case "one":
            case "wps":
                return <BTIconFileWordFilled {...commonProps} />;

            case "txt":
            case "text":
            case "rtf":
            case "log":
                return <BTIconFileTextFilled {...commonProps} />;

            case "wmv":
            case "mp4":
            case "mpg":
            case "mpeg":
            case "mov":
            case "m4v":
            case "m4a":
            case "avi":
                return <BTIconVideoCameraFilled {...commonProps} />;

            case "jpg":
            case "jpeg":
            case "bmp":
            case "gif":
            case "png":
            case "heic":
            case "ai":
            case "svg":
            case "eps":
            case "tif":
            case "tiff":
            case "webp":
                return horizontal ? (
                    <BTIconPictureFilled {...commonProps} />
                ) : (
                    <BTIconFileImageFilled {...commonProps} />
                );
            case "zip":
            case "rar":
                return <BTIconFileZipFilled {...commonProps} />;

            default:
                return <BTIconFileFilled {...commonProps} />;
        }
    };

    private getSizeInPixels = (size: string | number): number => {
        let sizeInPixels: number = 24;

        if (typeof size === "number") {
            return size;
        }

        switch (size) {
            case "xsmall":
                sizeInPixels = 14;
                break;

            case "small":
                sizeInPixels = 24;
                break;

            case "medium":
                sizeInPixels = 32;
                break;

            case "large":
                sizeInPixels = 40;
                break;

            case "xlarge":
                sizeInPixels = 50;
                break;
        }

        return sizeInPixels;
    };

    render() {
        const { thumbnail, className, onClick, size, thumbnailSize, thumbnailResizeMode } =
            this.props;
        const sizeInPixels = this.getSizeInPixels(size);

        const icon = this.getIcon(sizeInPixels);

        if (thumbnail) {
            const thumbnailSizeInPixels = this.getSizeInPixels(thumbnailSize ?? size);
            return (
                <Thumbnail
                    className={classNames("IconFile-Thumbnail", className, {
                        Clickable: onClick !== undefined,
                    })}
                    src={thumbnail}
                    key={thumbnail}
                    onClickDangerous={onClick}
                    width={thumbnailSizeInPixels}
                    height={thumbnailSizeInPixels}
                    alt=""
                    fit={ObjectFit.Cover}
                    mode={thumbnailResizeMode}
                    fallback={icon}
                />
            );
        }

        return icon;
    }
}
