import moment from "moment";
import { ReactNode } from "react";

import { BTSelectItem, mapBTServiceDropdownToSelectItem } from "types/apiResponse/apiResponse";
import { BTLocalStorage } from "types/btStorage";
import { ActivationStatus } from "types/enum";

import { Email } from "commonComponents/entity/email/InputEmail/InputEmail.types";
import { RemoveFromRecentlyViewedLocalStorage } from "commonComponents/utilities/MainNavigation/globalSearch/GlobalSearchUtils";
import { LinkType } from "commonComponents/utilities/MainNavigation/searchLegacy/SearchBar.utilities";

export enum SearchCategory {
    Todo = 1,
    Contact = 2,
    ChangeOrders = 3,
    PurchaseOrders = 4,
    Subs = 5,
    InternalUsers = 6,
    DailyLogs = 7,
    OwnerInvoices = 8,
    Messages = 9,
    Schedules = 10,
    Warranties = 11,
    BidPackages = 12,
    Leads = 13,
    Selections = 14,
    LeadProposalTemplates = 15,
    LeadProposals = 16,
    JobProposals = 17,
    RFIs = 18,
    SelectionChoices = 19,
    Allowances = 20,
    Documents = 21,
    Photos = 22,
    Videos = 23,
    Jobs = 24,
    Templates = 25,
    DocumentFolders = 26,
    PhotoFolders = 27,
    VideoFolders = 28,
    CostCodes = 29,
    CostItems = 30,
    Bids = 31,
    TodoChecklistItems = 32,
    Comments = 33,
    Bills = 34,
}

export const GcpSearchCategories: readonly SearchCategory[] = [
    SearchCategory.Messages,
    SearchCategory.Documents,
];

export const AllSearchCategories = -1;
export const AllJobs = -1;
export const SearchExpirationMinutes = 3;

export interface IHighlightedField {
    content: React.ReactNode;
    hasHighlight: boolean;
}

export interface ISearchBarResult {
    primaryField?: IHighlightedField;
    secondaryField?: IHighlightedField;
    tertiaryField?: IHighlightedField;
    categoryField?: IHighlightedField;
    icon?: ReactNode;
    link?: string;
    data: ISearchBarResultResponse;
    linkType?: LinkType;
}

export class SearchBarEntity {
    constructor(data: any) {
        this.results = data.results;
        this.resultsLimited = data.resultsLimited;
        this.canLoadPage = data.canLoadPage;
        this.totalCount = data.totalCount;
        this.debug = data.debug;
    }
    results: ISearchBarResultResponse[];
    processedResults: ISearchBarResult[];
    resultsLimited: boolean;
    canLoadPage: boolean;
    totalCount: number;

    /**
     * This object goes straight into mixpanel, don't reference this
     */
    debug: any;
    searchTerm: string;
}

export class SearchBarFilterResponse {
    constructor(data: any) {
        this.categorySelectItems = mapBTServiceDropdownToSelectItem(data.categories);
        this.jobSelectItems = mapBTServiceDropdownToSelectItem(data.availableJobs);
    }
    jobSelectItems: BTSelectItem[];
    categorySelectItems: BTSelectItem[];
}

export class SearchBarValidateResponse {
    constructor(data: any) {
        this.searchItems = data.searchItems;
    }
    searchItems: ISearchBarResultResponse[];
}

export enum SearchResultScreen {
    Closed = 0,
    Results = 1,
    Error = 2,
    RecentResults = 3,
    Empty = 4,
    EmptyFilters = 5,
}

export enum SearchInteractiveItems {
    SearchInput = "searchInput",
    JobFilter = "jobFilter",
    ItemFilter = "itemFilter",
    ResetFilters = "resetFilters",
    RecentResults = "recentResults",
    SearchResults = "searchResults",
}

export function getSearchResultKey(i: number) {
    return `${SearchInteractiveItems.SearchResults}-${i}`;
}

export function getRecentResultsKey(i: number) {
    return `${SearchInteractiveItems.RecentResults}-${i}`;
}

export function getResultKeyIndex(key: string) {
    return key.split("-")[1];
}

export interface ISearchBarResultResponse {
    id: number;
    jobId: number | null;
    jobName: string | null;
    category: SearchCategory;
    highlights: Record<string, IHighlightData>;
    builderId: number;
    expires: moment.Moment;
    userId: number;
    emails?: Email[];
    displayName?: string;
    firstName?: string;
    lastName?: string;
    activationStatus?: ActivationStatus;
}

interface IHighlightData {
    count: number;
    highlights: string[];
}

export function getLabelForSearchCategory(category: SearchCategory) {
    switch (category) {
        case SearchCategory.Todo:
            return "To-Do's";
        case SearchCategory.Contact:
            return "Customer Contacts";
        case SearchCategory.ChangeOrders:
            return "Change Orders";
        case SearchCategory.PurchaseOrders:
            return "Purchase Orders";
        case SearchCategory.Bills:
            return "Bills";
        case SearchCategory.Subs:
            return "Subs / Vendors";
        case SearchCategory.InternalUsers:
            return "Internal Users";
        case SearchCategory.DailyLogs:
            return "Daily Logs";
        case SearchCategory.OwnerInvoices:
            return "Invoices";
        case SearchCategory.Messages:
            return "Messages";
        case SearchCategory.Schedules:
            return "Schedules";
        case SearchCategory.Warranties:
            return "Warranties";
        case SearchCategory.BidPackages:
            return "Bid Packages";
        case SearchCategory.Leads:
            return "Leads";
        case SearchCategory.LeadProposals:
            return "Lead Proposals";
        case SearchCategory.LeadProposalTemplates:
            return "Lead Proposal Templates";
        case SearchCategory.JobProposals:
            return "Job Proposals";
        case SearchCategory.Selections:
            return "Selections";
        case SearchCategory.RFIs:
            return "RFIs";
        case SearchCategory.SelectionChoices:
            return "Selection Choices";
        case SearchCategory.Allowances:
            return "Allowances";
        case SearchCategory.Documents:
            return "Documents";
        case SearchCategory.Photos:
            return "Photos";
        case SearchCategory.Videos:
            return "Videos";
        case SearchCategory.Jobs:
            return "Jobs";
        case SearchCategory.Templates:
            return "Templates";
        case SearchCategory.PhotoFolders:
            return "Photo Folders";
        case SearchCategory.DocumentFolders:
            return "Document Folders";
        case SearchCategory.VideoFolders:
            return "Video Folders";
        case SearchCategory.CostCodes:
            return "Cost Codes";
        case SearchCategory.CostItems:
            return "Cost Items";
        default:
            return "";
    }
}

export function loadRecentSearchCache(userIds?: number[]): ISearchBarResultResponse[] {
    return BTLocalStorage.get("bt-objectArray-searchBarRecentResults")
        .filter((r) => userIds === undefined || userIds.includes(r.userId))
        .map((r) => ({
            ...r,
            expires: moment(r.expires),
        }));
}

export function deleteSearchEntity(category: number, ...ids: number[]) {
    const recentlyViewedResults = loadRecentSearchCache();
    BTLocalStorage.set(
        "bt-objectArray-searchBarRecentResults",
        recentlyViewedResults.map((r) => {
            if (r.category === category && ids.includes(r.id)) {
                r.expires = moment();
            }
            return r;
        })
    );

    // New search logic - change all calls to this as part of flag cleanup
    RemoveFromRecentlyViewedLocalStorage(category, ...ids);
}
