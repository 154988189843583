import { Radio } from "antd";
import classNames from "classnames";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, useState } from "react";

import { FolderTypes, ImageResizeMode } from "types/enum";

import { BTCheckbox } from "commonComponents/btWrappers/BTCheckbox/BTCheckbox";
import { canViewBTFileSystemInLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import {
    HiddenMediaContextMenu,
    MediaContextMenu,
} from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu";
import { areContextItemsPresent } from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu.utility";
import { IconFile } from "commonComponents/utilities/IconFile/IconFile";
import TextTruncate from "commonComponents/utilities/TextTruncate/TextTruncate";

import "./MediaTile.less";

interface IMediaTileSelectableProps {
    isSelected: boolean;
    canSelectMultiple: boolean;
}

interface IMediaTileContextMenuProps {
    renderOverlay: (onMenuClick: () => void) => JSX.Element;
    items: Record<string, boolean>;
}

interface IMediaTileProps {
    file: BTFileSystem;
    onClickTile?(): void;
    onClickThumbnail?(e: React.MouseEvent<HTMLImageElement, MouseEvent>): void;
    selectableProps?: IMediaTileSelectableProps;
    contextMenuProps?: IMediaTileContextMenuProps;
}

export const MediaTile: FunctionComponent<IMediaTileProps> = ({
    file,
    onClickTile,
    onClickThumbnail,
    selectableProps,
    contextMenuProps,
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    const hasContextMenu = contextMenuProps !== undefined;

    const canSelect = selectableProps !== undefined;
    const showCheckbox = canSelect && (isHovering || selectableProps.isSelected);

    const isFolder = file.folderType !== FolderTypes.NotAFolder;
    const isGlobalFolder = file.folderType === FolderTypes.GlobalFolder;
    const canViewInLightbox = canViewBTFileSystemInLightbox(file);

    const thumbnail = file.thumbnail ?? file.docPath;
    const tileThumbnail = canViewInLightbox ? thumbnail : undefined;

    const component = (
        <div
            datatest-id={`MediaTile-${file.id}`}
            className={classNames("MediaTile", {
                selected: selectableProps?.isSelected,
            })}
            onClick={onClickTile}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div>
                <div
                    className={classNames({
                        invisible: !showCheckbox,
                    })}
                >
                    {canSelect && selectableProps.canSelectMultiple && (
                        <BTCheckbox
                            className="SelectFile"
                            data-testid="selectFile"
                            id="selectFile"
                            checked={selectableProps.isSelected}
                        />
                    )}
                    {canSelect && !selectableProps.canSelectMultiple && (
                        <Radio
                            className="SelectFile"
                            data-testid="selectFile"
                            id="selectFile"
                            checked={selectableProps.isSelected}
                        />
                    )}
                </div>
                <div className="ThumbnailContainer">
                    <IconFile
                        isFolder={isFolder}
                        isGlobalFolder={isGlobalFolder}
                        fileType={file.extension}
                        size="large"
                        thumbnail={tileThumbnail}
                        thumbnailSize={100}
                        thumbnailResizeMode={ImageResizeMode.Crop}
                        onClick={onClickThumbnail}
                    />
                </div>
            </div>
            <div className="FileTitleRow">
                <IconFile
                    className={classNames({
                        invisible: isFolder,
                    })}
                    isFolder={isFolder}
                    isGlobalFolder={isGlobalFolder}
                    fileType={file.extension}
                    size="xsmall"
                />
                <TextTruncate className="FileTitle">{file.fileName}</TextTruncate>
                <span>
                    {hasContextMenu && areContextItemsPresent(contextMenuProps.items) ? (
                        <MediaContextMenu
                            renderOverlay={contextMenuProps.renderOverlay}
                            file={file}
                            iconSize={16}
                        />
                    ) : (
                        <HiddenMediaContextMenu iconSize={16} />
                    )}
                </span>
            </div>
        </div>
    );

    if (hasContextMenu) {
        return (
            <MediaContextMenu renderOverlay={contextMenuProps.renderOverlay} file={file}>
                {component}
            </MediaContextMenu>
        );
    }
    return component;
};
