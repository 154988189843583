import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { FunctionComponent, PropsWithChildren, useCallback, useRef, useState } from "react";

import { BdsButton } from "@buildertrend/components";

import { addCloseOnScroll, removeCloseOnScroll } from "utilities/helpers";

import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import { BTIconEllipsisOutlined } from "commonComponents/btWrappers/BTIcon";

interface IMediaContextMenuProps {
    file: BTFileSystem;
    renderOverlay: (menuItemClick: () => void) => JSX.Element;
    iconSize?: number;
}

const MediaContextMenuIcon: FunctionComponent<{ className?: string; size?: number }> = ({
    className,
    size,
}) => <BTIconEllipsisOutlined className={className} rotate={90} size={size} />;

export const HiddenMediaContextMenu: FunctionComponent<{ iconSize?: number }> = ({ iconSize }) => (
    <MediaContextMenuIcon className="invisible" size={iconSize} />
);

export const MediaContextMenu: FunctionComponent<PropsWithChildren<IMediaContextMenuProps>> = ({
    children,
    file,
    renderOverlay,
}) => {
    const dropdownMenuSpan = useRef<HTMLSpanElement | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleScroll = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleVisibleChange = useCallback(
        (visible: boolean) => {
            setIsOpen(visible);

            if (dropdownMenuSpan.current) {
                if (visible) {
                    addCloseOnScroll(dropdownMenuSpan.current, handleScroll);
                } else {
                    removeCloseOnScroll(dropdownMenuSpan.current, handleScroll);
                }
            }
        },
        [handleScroll]
    );
    const handleClose = useCallback(() => {
        handleVisibleChange(false);
    }, [handleVisibleChange]);

    const dropdownOverlay = renderOverlay(handleClose);

    return (
        <>
            <span ref={dropdownMenuSpan} />
            <BTDropdown
                overlay={dropdownOverlay}
                trigger={children ? ["contextMenu"] : ["click"]}
                visible={isOpen}
                onVisibleChange={handleVisibleChange}
                destroyPopupOnHide
            >
                {children || (
                    <div
                        className="flex justify-content-center"
                        onContextMenu={handleClose}
                        data-testid={`file-context-menu-${file.id}`}
                    >
                        <BdsButton
                            iconName="DotsThreeVertical"
                            testid={`file-context-menu-btn-${file.id}`}
                            displayType="tertiary"
                            onClick={handleVisibleChange}
                        />
                    </div>
                )}
            </BTDropdown>
        </>
    );
};
