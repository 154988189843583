import useRefreshPrompt from "commonComponents/utilities/Versioning/useRefreshPrompt";

import "./RefreshPrompt.less";

function RefreshPrompt() {
    useRefreshPrompt();
    return null;
}

export default RefreshPrompt;
