import { Divider } from "antd";
import { FormikErrors, InjectedFormikProps, withFormik } from "formik";
import { FunctionComponent, useRef } from "react";

import { BdsText } from "@buildertrend/components";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

import { BTLoginTypes } from "types/enum";

import yup from "utilities/form/yup";
import { routes } from "utilities/routes";

import { AuthStoreDiscovery } from "commonComponents/auth/AuthStoreDiscovery";
import {
    AuthStoreDiscoveryActionType,
    IAuthStoreDiscoveryFormValues,
} from "commonComponents/auth/AuthStoreDiscovery.types";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTForm } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { CredentialsDisclaimer } from "commonComponents/entity/credentials/CredentialsDisclaimer/CredentialsDisclaimer";
import { UserActivationLogoDisplay } from "commonComponents/entity/userActivation/UserActivationLogoDisplay/UserActivationLogoDisplay";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";
import {
    IInvisibleCaptchaHandle,
    InvisibleCaptcha,
} from "commonComponents/utilities/Recaptcha/InvisibleRecaptcha";

import { UserActivationPageValuePropositions } from "entity/UserActivationPage/ValuePropositions/ValuePropositions";
import { UserActivationWelcomeMessage } from "entity/UserActivationPage/WelcomeMessage";

import "./CreateAccount.less";

export interface ICreateAccountPresentationalProps {
    userDisplayName: string;
    username: string;
    loginType: BTLoginTypes;
    logoSrc: string;
    builderName: string;
    actionBeingPerformed: AuthStoreDiscoveryActionType;
    onSubmit: (
        values: IAuthStoreDiscoveryFormValues,
        setErrors: (errors: FormikErrors<IAuthStoreDiscoveryFormValues>) => void
    ) => Promise<void>;
    shareToken: string;
    requireEmailAsUsername: boolean;
}

const CreateAccountInternal: FunctionComponent<
    InjectedFormikProps<ICreateAccountPresentationalProps, IAuthStoreDiscoveryFormValues>
> = (props) => {
    const {
        handleSubmit,
        setFieldValue,
        loginType,
        logoSrc,
        userDisplayName,
        builderName,
        values,
        actionBeingPerformed,
        shareToken,
    } = props;

    const captchaRef = useRef<IInvisibleCaptchaHandle>(null);
    const handleSubmitWithRecaptcha = async (e?: React.FormEvent<HTMLFormElement> | undefined) => {
        await captchaRef.current!.executeCaptchaChallenge();
        handleSubmit(e);
    };

    return (
        <AppDefaultInfoContext.Consumer>
            {(appDefaultInfo) => (
                <BTForm
                    data-testid="CreateAccountPresentational"
                    onSubmit={handleSubmitWithRecaptcha}
                    style={{ height: "100vh" }}
                >
                    <BTRow style={{ display: "none" }}>
                        <BTCol>
                            <InvisibleCaptcha
                                ref={captchaRef}
                                fieldName="recaptchaToken"
                                showRecaptcha
                                setFieldValue={setFieldValue}
                            />
                        </BTCol>
                    </BTRow>
                    <BTRow className="CreateAccountPage">
                        <BTCol md={24} lg={8}>
                            <BTRow justify="center" className="LeftPanelWrapper">
                                <BTCol span={16}>
                                    <BTRow
                                        className="flex-column"
                                        justify="center"
                                        style={{ height: "100%" }}
                                        gutter={[16, 16]}
                                    >
                                        <BTCol>
                                            <UserActivationLogoDisplay logoSrc={logoSrc} />
                                        </BTCol>
                                        <BTCol>
                                            <UserActivationWelcomeMessage
                                                userDisplayName={userDisplayName}
                                                builderName={builderName}
                                            />
                                        </BTCol>
                                        <BTCol>
                                            <AuthStoreDiscovery
                                                actionBeingPerformed={actionBeingPerformed}
                                                handleSubmit={handleSubmitWithRecaptcha}
                                                setFieldValue={setFieldValue}
                                                username={values.username}
                                                buttonLabel="Continue"
                                                captchaRef={captchaRef}
                                            />
                                            <Divider />
                                        </BTCol>
                                        <BTCol>
                                            <BTRow className="flex-column">
                                                <BTCol>
                                                    <BTRow
                                                        justify="center"
                                                        align="middle"
                                                        className="flex-column"
                                                    >
                                                        <BTCol className="text-center">
                                                            <CredentialsDisclaimer actionLabel="Continue" />
                                                        </BTCol>
                                                        {!appDefaultInfo?.removeCreateLinkDecision && (
                                                            <BTCol className="text-center margin-top-sm">
                                                                <BdsText
                                                                    size="normal-md"
                                                                    text="Already have an account? "
                                                                />

                                                                <BTLink
                                                                    to={routes.user.getLinkAccountLink(
                                                                        shareToken
                                                                    )}
                                                                >
                                                                    <BdsText
                                                                        size="normal-md"
                                                                        text="Log in"
                                                                    />
                                                                </BTLink>
                                                                <BdsText
                                                                    size="normal-md"
                                                                    text="."
                                                                />
                                                            </BTCol>
                                                        )}
                                                        <BTCol className="margin-top-md margin-bottom-md">
                                                            <BTLogo
                                                                layout="horizontal"
                                                                color="dark-blue-blue"
                                                                size="sm"
                                                            />
                                                        </BTCol>
                                                    </BTRow>
                                                </BTCol>
                                            </BTRow>
                                        </BTCol>
                                    </BTRow>
                                </BTCol>
                            </BTRow>
                        </BTCol>
                        <BTCol md={24} lg={16}>
                            <UserActivationPageValuePropositions loginType={loginType} />
                        </BTCol>
                    </BTRow>
                </BTForm>
            )}
        </AppDefaultInfoContext.Consumer>
    );
};

const usernameValidator = yup.string().required().label("Email");

const CreateAccountValidators = (props: ICreateAccountPresentationalProps) => {
    return yup.object().shape<IAuthStoreDiscoveryFormValues>({
        username: props.requireEmailAsUsername
            ? usernameValidator
                  .email()
                  .label("Email")
                  // this matches the email length requirements in auth0
                  .matches(
                      /^.{1,64}@.{1,256}$/,
                      "Email address must be less than 64 characters before the '@' and less than 256 characters after the '@'"
                  )
            : usernameValidator,
        recaptchaToken: yup.string().required().label("Recaptcha"),
    });
};

export const CreateAccountPresentational = withFormik<
    ICreateAccountPresentationalProps,
    IAuthStoreDiscoveryFormValues
>({
    // use the default values we specified above
    mapPropsToValues: (props: ICreateAccountPresentationalProps) => ({
        username: props.username,
        recaptchaToken: null,
    }),

    // use the yup validation object we specified above
    validationSchema: (props: ICreateAccountPresentationalProps) => CreateAccountValidators(props),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,

    handleSubmit: (values: IAuthStoreDiscoveryFormValues, { props, setErrors, setSubmitting }) => {
        setSubmitting(true);
        props
            .onSubmit(values, setErrors)
            .then(() => {
                setSubmitting(false);
            })
            .catch((error) => {
                setErrors({ username: error });
            });
    },
})(CreateAccountInternal);
