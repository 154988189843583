import { uuid4 } from "@sentry/utils";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import {
    BillingPeriodUnit,
    EstimatedAnnualRevenue,
    OnlineSignupStatus,
    PrimaryJobType,
} from "types/enum";

import {
    AddressEntity,
    AddressFormValues,
    IAddressCountryChangedRequest,
    IHasAddress,
} from "commonComponents/entity/address/Address/Address.api.types";
import { AddressResolutionStatus } from "commonComponents/entity/addressResolve/AddressResolve/AddressResolve.api.types";

import { SignupContact } from "entity/SignupForm/SignupContact/SignupContact.api.types";
import { InvoiceEstimate } from "entity/Subscription/Subscription.api.types";

import { default as SignupFormAPIResponseExample } from "../SignupForm/SignupForm.api.json";

export type SignupFormActions = undefined | "save" | "delete";
export const SignupFormBusinessNameLength = 50;
export const SignupFormWebsiteLength = 50;

export interface ISignupFormValues {
    primaryContact: SignupContact;
    championContact: SignupContact;
    isPrimaryContactBtChampion: boolean;
    businessName: string;
    website: string;
    address: AddressFormValues;
    timezoneID: number;
    selectedPaymentMethodNonce: string | null;
    paymentMethodNonceSetTime?: moment.Moment;
    billingAddress: AddressFormValues;
    addressResolvedType: AddressResolutionStatus;
    interestedInCBUSAOpportunities: boolean;
    agreedToTermsAndConditions: boolean;
    agreedToPrivacyPolicy: boolean;
    useSameBillingAddress: boolean;
    captchaResponse: string | undefined;
    repInitials: string | undefined;
    signupPrimaryJobType: PrimaryJobType;
    estimatedAnnualRevenue: EstimatedAnnualRevenue;
    desiredOutcome: DesiredOutcome;
    recurringFee: number;
}

export class SignupFormEntity implements IHasAddress {
    address: AddressEntity;
    primaryContact: SignupContact;
    hasTrainingContact: boolean;
    trainingContact: SignupContact;
    businessName: string;
    website: string;
    timezoneList: BTSelectItem[];
    signupPrimaryJobTypeList: BTSelectItem[];
    estimatedAnnualRevenueList: BTSelectItem[];
    desiredOutcomeList: BTSelectItem[];
    billingFrequency: string;
    recurringFee: number;
    braintreeClientToken: string;
    billingAddress: SignupBillingAddress;
    rewinBuilderID?: number;
    useSameBillingAddress: boolean;
    accountType: number;
    isTradeShowSignup: boolean;
    actualDemoDate: string;
    displayError: boolean;
    internalName: string;
    displayName: string | undefined;
    originalAmount: number;
    signupCode: string;
    signupCodeDescription: string;
    signupOrderSummary: SignupOrderSummary[];

    constructor(data: typeof SignupFormAPIResponseExample) {
        this.address = data.address && new AddressEntity(data.address);
        this.primaryContact = new SignupContact(data.primaryContact);
        this.trainingContact = new SignupContact(data.trainingContact);
        this.hasTrainingContact = data.hasTrainingContact;
        this.businessName = data.businessName;
        this.website = data.website;
        this.billingFrequency = data.billingFrequency;
        this.recurringFee = data.recurringFee;
        this.braintreeClientToken = data.braintreeClientToken;
        this.timezoneList =
            data.timezoneList === null
                ? []
                : data.timezoneList.map((x: any) => new BTSelectItem(x));
        this.signupPrimaryJobTypeList =
            data.signupPrimaryJobTypeList === null
                ? []
                : data.signupPrimaryJobTypeList.map((x: any) => new BTSelectItem(x));
        this.estimatedAnnualRevenueList =
            data.estimatedAnnualRevenueList === null
                ? []
                : data.estimatedAnnualRevenueList.map((x: any) => new BTSelectItem(x));
        this.desiredOutcomeList =
            data.desiredOutcomeList === null
                ? []
                : data.desiredOutcomeList.map((x: any) => new BTSelectItem(x));
        this.billingAddress =
            data.billingAddress && new SignupBillingAddress(new AddressEntity(data.billingAddress));
        this.rewinBuilderID = data.rewinBuilderID;
        this.useSameBillingAddress = true;
        this.accountType = data.accountType;
        this.isTradeShowSignup = data.isTradeShowSignup;
        this.actualDemoDate = data.actualDemoDate;
        this.displayError = data.displayError;
        this.internalName = data.internalName;
        this.displayName = data.displayName;
        this.originalAmount = data.originalAmount;
        this.signupCode = data.code;
        this.signupCodeDescription = data.codeDescription;
        this.signupOrderSummary = data.signupOrderSummary.map((x) => new SignupOrderSummary(x));
    }
}

export class ChargebeeSubscriptionResponse {
    chargebeeCustomerId: string;
    isBusinessAddressInvalid: boolean;
    paymentFailureMessage: string;
    displayError: boolean;

    constructor(data: any) {
        this.chargebeeCustomerId = data.chargebeeCustomerId;
        this.isBusinessAddressInvalid = data.isBusinessAddressInvalid;
        this.paymentFailureMessage = data.paymentFailureMessage;
        this.displayError = data.displayError;
    }
}

export class SignupOrderSummary {
    constructor(data: (typeof SignupFormAPIResponseExample.signupOrderSummary)[0]) {
        this.subscriptionInvoiceEstimate = new InvoiceEstimate(data.subscriptionInvoiceEstimate);
        this.paidTrainingAmount = data.paidTrainingAmount;
        this.billingFrequency = data.billingFrequency;
        this.signupCoupons = data.signupCoupons.map((x) => new SignupCoupon(x));
        this.instanceKey = uuid4();
    }
    instanceKey: string;
    subscriptionInvoiceEstimate: InvoiceEstimate;
    paidTrainingAmount: number;
    billingFrequency: string;
    signupCoupons: SignupCoupon[];
}

export class SignupCoupon {
    constructor(data: {
        durationLength: number;
        durationType: BillingPeriodUnit;
        discountAmount: number;
        lineItemId: string;
    }) {
        this.durationLength = data.durationLength;
        this.durationType = data.durationType;
        this.discountAmount = data.discountAmount;
        this.lineItemId = data.lineItemId;
    }
    durationLength: number;
    durationType: BillingPeriodUnit;
    discountAmount: number;
    lineItemId: string;
}

export class SignupBillingAddress {
    constructor(data: AddressEntity) {
        this.address = data;
    }
    address: AddressEntity;
}

export class BTTimezoneFromLatLongResponse {
    timezoneID: number;

    constructor(data: any) {
        this.timezoneID = data.timezoneID;
    }
}

export class LeadSourceValues {
    source: string;
    medium: string;
    campaign: string;
    term: string;
    content: string;
    gclid: string;
    msclkid: string;
    fbclid: string;
    campaignId: string;
    reference: string;
    landing: string;
    sourceTags: string;
    wbraid: string;
    gbraid: string;
    formURL: string;

    constructor(data: any) {
        this.source = data.source;
        this.medium = data.medium;
        this.campaign = data.campaign;
        this.term = data.term;
        this.content = data.content;
        this.gclid = data.gclid;
        this.msclkid = data.msclkid;
        this.fbclid = data.fbclid;
        this.campaignId = data.campaignId;
        this.reference = data.reference;
        this.landing = data.landing;
        this.sourceTags = data.sourceTags;
        this.wbraid = data.wbraid;
        this.gbraid = data.gbraid;
        this.formURL = data.formURL;
    }
}

export interface ILeadDataRequest {
    primaryContact: SignupContact;
    leadSourceValues: LeadSourceValues;
    captchaResponse: string;
    businessName: string;
    signupPrimaryJobType: number;
    estimatedAnnualRevenue: number;
    desiredOutcome: number;
}

export interface IChargebeeSubscriptionRequest {
    encryptedBuilderID: string;
    signupCode: string;
    paymentMethodNonce: string;
    customerContact: CustomerContact;
    isRetry: boolean;
}

export class HttpResponseMessage {}

export interface ISignupFormGetRequest {
    signupCode: string;
    salesforceAccountID: string;
    isAutoCode: boolean;
    salesforceOpportunityID: string;
    rewinBuilderID: string;
    sfRewinContactLoginID: string;
    testExperience: string;
    websiteTestExperience: string;
}

export class CurrentTimezoneResponse {
    constructor(data: any) {
        this.timeZoneDate = data.timeZoneDate;
        this.observesDaylightSavings = data.observesDaylightSavings;
        this.timeZoneName = data.timeZoneName;
    }

    timeZoneDate?: moment.Moment;
    observesDaylightSavings: boolean;
    timeZoneName: string;
}

export interface ISignupFormCreateAccountRequest {
    code: string;
    primaryContact: SignupContact;
    trainingContact: SignupContact;
    hasTrainingContact: boolean;
    businessName: string;
    website: string;
    address: IAddressCountryChangedRequest;
    timezoneID: number;
    timezoneName: string | undefined;
    billingAddress: IAddressCountryChangedRequest;
    rewinBuilderID: string;
    paymentMethodNonce: string;
    addressValidated: boolean;
    salesforceAccountID: string;
    salesforceOpportunityID: string;
    sfRewinContactLoginID: string;
    testExperience: string;
    captchaResponse: string;
    repInitials: string;
    interestedInCBUSAOpportunities: boolean;
    signupPrimaryJobType: PrimaryJobType;
    estimatedAnnualRevenue: EstimatedAnnualRevenue;
    actualDemoDate: string;
    leadSourceValues?: LeadSourceValues;
    desiredOutcome?: DesiredOutcome;
    recurringFee: number;
}

export class SignupFormDeleteResponse {}

export class SignupFormCreateResponse {
    status: OnlineSignupStatus;
    signupID: number;
    encryptedBuilderID: string;

    constructor(data: any) {
        this.status = data.status;
        this.signupID = data.signupID;
        this.encryptedBuilderID = data.encryptedBuilderID;
    }
}

export class CustomerContact {
    builderId?: string | null;
    chargebeeCustomerId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    company?: string | null;
    website?: string | null;
    businessAddressCountry?: string | null;
    businessAddressStreet?: string | null;
    businessAddressCity?: string | null;
    businessAddressState?: string | null;
    businessAddressZip?: string | null;
    businessAddressTimezone?: string | null;
    billingAddressCountry?: string | null;
    billingAddressStreet?: string | null;
    billingAddressCity?: string | null;
    billingAddressState?: string | null;
    billingAddressZip?: string | null;
    billingAddressTimezone?: string | null;
    autoCollection: boolean;
}

export enum DesiredOutcome {
    ImprovedLeadManagement = 1,
    StreamlinedProjectPlanning = 2,
    MoreEfficientJobsites = 3,
    EnhancedEstimate = 4,
    AccurateFinancialManagement = 5,
}
