export class HawkRouteConfig {
    constructor(pathname: string, toNavigationPath?: (id: number) => string) {
        this.pathname = pathname.replace("/app", "");
        this.toNavigationPath = toNavigationPath ?? ((_: number) => this.pathname);
    }

    pathname: string;
    toNavigationPath: (id: number) => string;
}

export const hawkRouteConfig = {
    base: new HawkRouteConfig("/Admin/Hawk"),
    queue: new HawkRouteConfig(
        "/Admin/Hawk/Queue/:queueId",
        (queueId?: number) => `/Admin/Hawk/Queue/${queueId}`
    ),
    reviewHistory: new HawkRouteConfig("/Admin/Hawk/ReviewHistory"),
    audit: new HawkRouteConfig("/Admin/Hawk/Audit"),
};
