// todo rename to NotificationPanel after flag removal
import { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { queryClient } from "helpers/queryClient";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { routes } from "utilities/routes";

import { NotificationsGetV2Params, useNotificationsGetV2 } from "handlers";

import {
    INotificationPanelHandler,
    NotificationPanelHandler,
} from "entity/notification/NotificationPanel/NotificationPanel.api.handler";
import {
    InfiniteScrollResponse,
    NotificationItem,
} from "entity/notification/NotificationPanel/NotificationPanel.api.types";
import NotificationPanelPresentational from "entity/notification/NotifticationPanelV2/NotificationPanelPresentational";

interface INotificationPanelV2Props extends RouteComponentProps {
    hasNotifications: boolean;
    onClose: () => void;
    notificationHandler?: INotificationPanelHandler;
}

export interface INotificationResultData {
    infiniteScrollData: InfiniteScrollResponse;
    pagesLoaded: number;
    items: NotificationItem[];
}

const defaultHandler = new NotificationPanelHandler();
export const MAX_NOTIFICATION_PAGE_SIZE = 25;

function NotificationPanelV2({
    hasNotifications,
    onClose,
    notificationHandler = defaultHandler,
    history,
    match,
}: INotificationPanelV2Props) {
    const userContext = useRequiredContext(UserInfoContext);
    const builderInfo = useRequiredContext(BuilderInfoContext);
    const [onlyShowUnread, setOnlyShowUnread] = useState(false);

    const request: NotificationsGetV2Params = {
        FirstRow: 1,
        LastRow: MAX_NOTIFICATION_PAGE_SIZE,
        PreviousId: 0,
        OnlyShowUnread: onlyShowUnread,
    };

    const { data, isLoading, error, queryKey } = useNotificationsGetV2(request);

    const items = data?.data.notificationSummaryItems ?? [];
    const hasMoreItems = data?.data.hasMoreItems ?? false;

    let prevHasNotifications = useRef(hasNotifications);

    const handleMarkRead = async (notificationId: number, markRead: boolean) => {
        const cardIndex = items.findIndex((item) => item.id === notificationId);

        if (cardIndex === -1 || items[cardIndex].isRead === markRead) {
            return;
        }

        try {
            await notificationHandler.updateStatus(
                notificationId,
                items[cardIndex].messageData.builderId,
                markRead
            );
            await queryClient.invalidateQueries(queryKey);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    const handleMarkAllRead = async () => {
        try {
            await notificationHandler.markAllRead(userContext.globalUserId!, builderInfo.builderId);
            await queryClient.invalidateQueries(queryKey);
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    useEffect(() => {
        const refresh = async () => {
            if (!prevHasNotifications.current && hasNotifications) {
                await queryClient.invalidateQueries(queryKey);
            }
            prevHasNotifications.current = false;
        };
        void refresh();
    }, [hasNotifications, queryKey]);

    const handleToggleUnread = (checked: boolean) => {
        setOnlyShowUnread(checked);
    };

    const handleNotificationPrefsClick = () => {
        history.push(
            match.url +
                "/QuickSettings" +
                routes.notifications.getUserPreferencesLink(
                    userContext.globalUserId!,
                    userContext.loginType
                )
        );
        onClose();
    };

    if (error) {
        showAPIErrorMessage(error);
        return null;
    }

    return (
        <NotificationPanelPresentational
            items={items}
            hasMoreItems={hasMoreItems}
            hasNotifications={hasNotifications}
            onMarkRead={handleMarkRead}
            onMarkAllRead={handleMarkAllRead}
            isLoading={isLoading}
            onlyShowUnread={onlyShowUnread}
            onToggleUnread={handleToggleUnread}
            onOpenPrefs={handleNotificationPrefsClick}
            onOpenNotificationList={onClose}
        />
    );
}

export default NotificationPanelV2;
