export enum AuthStoreTypes {
    Buildertrend = 0,
    Auth0 = 1,
}

export interface IAuthStoreForUsername {
    authStore: AuthStoreTypes;
    userExists: boolean;
    emailValid: boolean;
}

export class AuthStoreForUsername implements IAuthStoreForUsername {
    constructor(data: IAuthStoreForUsername) {
        this.authStore = data.authStore;
        this.userExists = data.userExists ?? false;
        this.emailValid = data.emailValid ?? false;
    }

    authStore: AuthStoreTypes;
    userExists: boolean;
    emailValid: boolean;
}

export interface IAuthStoreDiscoveryFormValues {
    username: string;
    recaptchaToken: string | null;
}

export type AuthStoreDiscoveryActionType = "submit" | undefined;

export interface IOAuthStateParamState {
    stateIdentifier: string;
    shareToken?: string;
    lastPageRequested?: string;
}
