import { FunctionComponent, useContext } from "react";
import { Redirect } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";

import { routes } from "utilities/routes";

const RedirectToFrozenPage: FunctionComponent = () => {
    const builderInfoContext = useContext(BuilderInfoContext);
    if (builderInfoContext?.loginsDisabled) {
        return <Redirect to={routes.account.frozen} />;
    }
    return null;
};

export default RedirectToFrozenPage;
