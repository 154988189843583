import { LineItemType } from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { stringifyUrl } from "query-string";
import queryString from "query-string";
import { generatePath, matchPath } from "react-router";

import { BuilderInfo } from "helpers/AppProvider.types";

import {
    BTLoginTypes,
    CostType,
    DirectionTypes,
    DirectoryType,
    DiscussionsLinkedTypes,
    EmailFolder,
    EmailLinkType,
    EntityTypes,
    ForbiddenReason,
    HelpCenterTypes,
    MediaPropertiesTab,
    MediaType,
    OpenActivityType,
    OpenedFrom,
    ReplyType,
    TabPermissions,
    TagTypes,
    ViewingPermissionAction,
} from "types/enum";

import { SortDirection } from "utilities/list/list.types";
import { getCurrentPortalType, isInPortal, PortalType } from "utilities/portal/portal";
import { routesWebforms } from "utilities/routesWebforms";
import { isNullOrWhitespace } from "utilities/string/string";
import { removeQueryStringParams } from "utilities/url/url";

import { IDTypes } from "commonComponents/entity/accounting/common/accounting.api.types";
import { OpenedFromEntity } from "commonComponents/entity/permissionWizard/JobViewingPermissionWizard/JobViewingPermissionWizard.api.types";
import { ScheduleConflictViews } from "commonComponents/entity/scheduleConflicts/ScheduleConflict.api.types";
import { JobIdTypes } from "commonComponents/utilities/JobPicker/JobPicker.types";

import { TabOptions } from "entity/bidPackage/BidPackage/BidPackage";
import { CategoryType } from "entity/category/common/categoryType";
import { CommentListTabKeys } from "entity/comment/CommentList/CommentList.api.types";
import { CostCatalogViewType } from "entity/costCatalog/CostCatalog.types";
import { TemplateType } from "entity/document/Document/Document.api.types";
import { SystemFilterType } from "entity/filters/Filter/Filter.api.types";
import { ImportTypes } from "entity/importWizard/ImportWizard/ImportWizard.types";
import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { JobDetailTab } from "entity/job/Job.api.types";
import { JobListTabs } from "entity/job/JobList/JobList.api.types";
import { LeadTabTypes } from "entity/lead/Common/LeadTab/LeadTab.types";
import { LeadToJobModes } from "entity/lead/LeadToJob/LeadToJob.types";
import { ExternalIntegrationTypes } from "entity/marketplace/MarketplaceDirectory/MarketplaceDirectory.api.types";
import { OnlinePaymentTabs } from "entity/onlinePayment/common/OnlinePaymentReports.types";
import {
    PurchasingDashboardActivityPages,
    PurchasingDashboardPages,
} from "entity/rebate/PurchasingDashboard/PurchasingDashboard.types";
import { RebateListTabKeys } from "entity/rebate/RebateList/common/RebateList.types";
import {
    ScheduleEntityDefaultParams,
    ScheduleTabNames,
} from "entity/schedule/Schedule/Schedule.api.types";
import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";
import { SelectionListContainerTabs } from "entity/selection/SelectionListContainer/SelectionListContainer.types";
import { SubSetupTabKeys } from "entity/sub/SubSetup/SubSetup.api.types";
import { SubTabKeys } from "entity/sub/SubVendor/SubVendor.api.types";
import { SurveyListContainerTabs } from "entity/survey/common/SurveyListContainer/SurveyListContainer.types";
import { TemplateListTabs } from "entity/template/TemplateList/TemplateListTab/TemplateListTab.api.types";
import { ShiftDetailsTab } from "entity/timeClock/Shift/Shift.api.types";
import { ShiftListContainerTabs } from "entity/timeClock/ShiftList/ShiftListContainer/ShiftListContainer.api.types";
import {
    ExternalEntitiesWithUserActivation,
    isValidUserActivationMethod,
    UserActivationMethods,
} from "entity/UserActivationPage/UserActivationPage.api.types";
import { VendorTypes } from "entity/vendor/common/vendor.types";

/** makes the page available to subs and owners, if your page is only for builder you do not need to use this
 * @example <DetailPageLayout path={this.getPathForAllPortals("/Entity/:id")} component={MyEntity} />
 */
function getPathForAllPortals(path: string) {
    return `(/Admin)?(/Owner)?(/Subs)?${path}`;
}

function getPathForSignedInShare(path: string) {
    return `(/Builder)?(/Owner)?(/Subs)?(/Contact)?${path}`;
}

function getPathForAdminPortal(path: string) {
    return `/Admin${path}`;
}

function getPathForBuilderOrSubPortals(path: string) {
    return `(/Subs)?${path}`;
}

function getPathForBuilderOrOwnerPortals(path: string) {
    return `(/Owner)?${path}`;
}

function getPathForExternalSubsPage(path: string) {
    return `/subs/share/:builderId${path}`;
}

function getPathForExternalOwnerPage(path: string) {
    return `/owner/share/:builderId?${path}`;
}

function getPathForInvoiceExternalOwnerPage(path: string) {
    return `/owner/share/${path}`;
}

function getPathForExternalPrint(path: string) {
    return `/printExternal/:builderId${path}`;
}

function getPathForExternalLeadsPage(path: string) {
    return `/share/:builderId${path}`;
}

function getPathForExternalMicrodepositPageWithShareToken(path: string) {
    return `/share/:shareToken(.{36})${path}`;
}

function getPathForExternalMicrodepositPage(path: string) {
    return `/share/:builderId?${path}`;
}

function getPathForExternalScheduleConfirmationPage(path: string) {
    return `/share/:builderId${path}`;
}

function getPathForExternalOutboundPaymentPage(path: string) {
    return `/subs/share/:shareToken(.{36})${path}`;
}

export interface IWebviewRouteParams {
    platform: "ios" | "android";
    mobileVersion: string;
}

/**
 * CAUTION: All webview routes are served through cache to support offline mode. That means your webviews should NOT have
 * any references to BTJScriptGlobals; those values are injected by our reverse proxy (which will eventually be phased out post SPA),
 * so they may be stale when offline.
 */
function getPathForWebviewPage(path: string) {
    return `(/Admin)?(/Owner)?(/Subs)?/webview/:platform(ios|android)/:mobileVersion${path}`;
}

/**
 * Adds Portal Type string to the start of the url
 * @param path
 * @param portalType
 */
export function addPathForCurrentPortal(path: string) {
    const currentPortal = getCurrentPortalType();
    return addPathForPortal(path, currentPortal);
}

export function addPathForPortal(path: string, loginType: PortalType) {
    let userPortalName = "";

    if (loginType !== PortalType.BUILDER) {
        if (loginType === PortalType.BTADMIN) {
            userPortalName = "/admin";
        } else {
            userPortalName = "/" + PortalType[loginType].toLowerCase();
        }
    }

    return userPortalName + path;
}

function addQueryParams(url: string, params: Record<string, string>) {
    const urlParams = new URLSearchParams();
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            urlParams.append(key, params[key]);
        }
    }
    if (url.includes("?")) {
        url += "&" + urlParams.toString();
    } else {
        url += "?" + urlParams.toString();
    }
    return url;
}

const marketplaceInternal = {
    redirect: "/Marketplace/MarketplaceRedirect",
};

export function getBaseRoute() {
    if (window === window.top) {
        // We aren't in an iframe
        return routes.appBase;
    }
    return "";
}

/**
 * Checks the following URLs to determine if the portal should be added to the route
 * @param url The URL to check
 * @returns
 */
export function shouldAddPortalToRoute(url: string) {
    return !url.startsWith("/app/link/");
}

/**
 *
 * @deprecated Use proper routing instead
 */
export const updateWebformsQueryStringParams = (excludedKeys: string[] = []) => {
    const qsParams = removeQueryStringParams(window.location.search, excludedKeys);
    // eslint-disable-next-line deprecate/member-expression
    routesWebforms.replaceState({}, document.title, `?${qsParams}`);
};

/**
 * Get the path (everything after .net) from a URL.
 * @param url
 * @returns the path from the URL, or null if url isn't a valid url or if it isn't a BT url
 */
export const getPathFromBTUrl = (url: string) => {
    let urlObj: URL;
    try {
        urlObj = new URL(url);
    } catch (ignored) {
        return null;
    }
    // is this URL inside BT
    if (urlObj.origin !== window.location.origin) return null;
    return urlObj.pathname;
};

// todo see if making a component would make this cleaner, see https://www.blogreader.com.au/blog/named-routes-with-react-router.html
/**
 * Note: If you want to open a legacy webforms page use webformsDialogHandler
 * @see webformsDialogHandler
 */
export const routes = {
    admin: {
        users: {
            usernameLookup: "/Admin/UsernameLookup",
        },
        subscriptionPricingLimits: {
            list: getPathForAdminPortal("/SubscriptionPricingLimits"),
        },
        adminMain: "/Admin/AdminMain",
        adminSubscriptionManager: {
            subscriptionManager: getPathForAdminPortal(
                "/SubscriptionManager/:builderID/:subscriptionID"
            ),
            getSubscriptionManagerLink(builderID: number, subscriptionID: string) {
                return generatePath(this.subscriptionManager, {
                    builderID,
                    subscriptionID,
                });
            },
        },
        subscriptionPricingGuardrails: {
            list: "/Admin/SubscriptionPricingLimits",
        },
    },
    user: {
        activation: "/User/Activation",
        activationEmailUsernameEntry: "/User/ActivationEmailUsernameEntry",
        linkAccount: "/User/LinkAccount",
        linkAccountActivation: "/User/LinkAccountBuildertrend",
        linkInviteExpired: "/User/LinkInviteExpired",
        linkInvalidInvite: "/User/LinkInvalidInvite",
        linkCrossLoginTypeActivationError: "/User/CrossLoginTypeActivationError",
        linkSignedInAccountActivation: getPathForSignedInShare("/User/LinkSignedInAccount"),
        createAccount: getPathForSignedInShare("/User/CreateAccount"),
        finalizeCreateAccount: getPathForAllPortals("/User/FinalizeCreateAccount/:shareToken"),
        newUser: "/User/NewUser",
        signedInLinked: getPathForSignedInShare("/User/SignedInLinked"),
        getActivationLink(shareToken: string, activationMethod?: UserActivationMethods) {
            let path = generatePath(`${routes.share.path}${this.activation}`, { shareToken });
            if (isValidUserActivationMethod(activationMethod)) {
                path = `${path}?activationMethod=${activationMethod}`;
            }
            return path;
        },
        getExternalActivationLink(shareToken: string, entity?: ExternalEntitiesWithUserActivation) {
            let path = generatePath(`${routes.share.path}${this.activation}`, { shareToken });
            if (entity) {
                path = `${path}?entityType=${entity}`;
            }
            return path;
        },
        getActivationEmailUsernameEntryLink(shareToken: string, loginHint: string) {
            let path = generatePath(`${routes.share.path}${this.activationEmailUsernameEntry}`, {
                shareToken,
            });
            if (!isNullOrWhitespace(loginHint)) {
                path = `${path}?loginHint=${encodeURIComponent(loginHint)}`;
            }
            return path;
        },
        getCurrentUserPreferences(userId: number) {
            if (isInPortal({ builder: true })) {
                return routes.internalUsers.getInternalUserDetailsLink(userId);
            } else if (isInPortal({ subs: true })) {
                return routes.sub.getSetupLink(userId);
            } else if (isInPortal({ owner: true })) {
                return routes.owner.getSetupLink(userId);
            }
            return "";
        },
        getLinkAccountLink(shareToken: string) {
            return generatePath(`${routes.share.path}${this.linkAccount}`, { shareToken });
        },
        getLinkAccountActivation(shareToken: string, username: string) {
            let path = generatePath(`${routes.share.path}${this.linkAccountActivation}`, {
                shareToken,
            });
            path = `${path}?username=${username}`;
            return path;
        },
        getLinkSignedInAccountActivation(
            shareToken: string,
            username: string,
            loginType?: BTLoginTypes
        ) {
            let portalType = loginType ? `/${BTLoginTypes[loginType].toLowerCase()}` : "";
            let path = generatePath(
                `${routes.share.path}${portalType}${this.linkSignedInAccountActivation}`,
                {
                    shareToken,
                }
            );
            path = `${path}?username=${username}`;
            return path;
        },
        getCreateAccountLink(shareToken: string, loginType?: BTLoginTypes) {
            let portalType = loginType ? `/${BTLoginTypes[loginType].toLowerCase()}` : "";
            let path = generatePath(`${routes.share.path}${portalType}${this.createAccount}`, {
                shareToken,
            });
            return path;
        },
        getNewUserActivation(shareToken: string, username: string) {
            let path = generatePath(`${routes.share.path}${this.newUser}`, { shareToken });
            path = `${path}?username=${username}`;
            return path;
        },
        getLinkInvalidInvite(shareToken: string, loginType: BTLoginTypes) {
            let path = generatePath(`${routes.share.path}${this.linkInvalidInvite}`, {
                shareToken,
                loginType,
            });
            path = `${path}?loginType=${loginType}`;
            return path;
        },
        getSignedInLinkedActivation(
            shareToken: string,
            username: string,
            loginType?: BTLoginTypes
        ) {
            let portalType = loginType ? `/${BTLoginTypes[loginType].toLowerCase()}` : "";
            let path = generatePath(`${routes.share.path}${portalType}${this.signedInLinked}`, {
                shareToken,
            });
            path = `${path}?username=${encodeURIComponent(username)}`;
            return path;
        },
    },
    auth0: {
        redirect: "/auth/redirect",
        getLoginLink(
            domain: string,
            clientId: string,
            host: string,
            audience: string,
            redirectLink: string,
            scopes: string[],
            promptParams: string[],
            externalOrgLinkID?: string
        ) {
            const scopesUrlString = encodeURIComponent(scopes.join(" "));
            const promptParamsEncoded = encodeURIComponent(promptParams.join(" "));
            const orgLinkParam = externalOrgLinkID ? `&externalOrgLinkID=${externalOrgLinkID}` : "";

            let url = `https://${domain}/authorize?response_type=code&client_id=${clientId}&redirect_uri=https://${host}/app/auth/redirect&audience=${audience}&scope=${scopesUrlString}&state=${redirectLink}${orgLinkParam}`;
            if (promptParamsEncoded) {
                url = url + `&prompt=${promptParamsEncoded}`;
            }

            return url;
        },
    },
    account: {
        frozen: "/Frozen",
        getFrozenLink(
            isClosed: boolean,
            isAdmin?: boolean,
            builderName?: string,
            encryptedBuilderId?: string,
            contactSupport?: boolean
        ) {
            const adminQS = `&isAdmin=${isAdmin}`;
            const builderNameQS = `&builderName=${builderName}`;
            const encryptedQS = `&encryptedBuilderId=${encryptedBuilderId}`;
            const mustContactSupport = `&mustContactSupport=${contactSupport}`;
            return `${this.frozen}?isClosed=${isClosed}${isAdmin ? adminQS : ""}${
                contactSupport ? mustContactSupport : ""
            }${builderName ? builderNameQS : ""}${encryptedBuilderId ? encryptedQS : ""}`;
        },
    },
    accountSetup: {
        wizard: "/AccountSetup/Wizard",
    },
    addressConfirmation: {
        details: "/AddressConfirmation",
        getAddressConfirmationLink() {
            return this.details;
        },
    },
    apiDocs: "/apidocs",
    appBase: "/app",
    home: "/",
    bid: {
        list: getPathForAllPortals("/Bids"),
        print: "/Bid/Print",
        getPrintLink(id: number) {
            const path = `${this.print}?id=${id}`;
            return addPathForCurrentPortal(path);
        },
        getListLink() {
            return addPathForCurrentPortal("/Bids");
        },
        settings: "/BidSettings",
        getSettingsLink() {
            return this.settings;
        },

        details: getPathForBuilderOrSubPortals("/Bid/:id/:jobId/:openedFrom/:builderId"),
        getDetailsLink(
            id: number,
            jobId: number,
            openedFrom = OpenedFrom.Nothing,
            builderId: number = 0
        ) {
            const path = generatePath(this.details, { id, jobId, openedFrom, builderId });
            return addPathForCurrentPortal(path);
        },

        external: getPathForExternalSubsPage("/Bid/:bidId/:subId/:shareToken(.{36})?"),
        getExternalLink(builderId: string, bidId: string, subId: string, shareToken?: string) {
            return `${generatePath(this.external, { builderId, bidId, subId, shareToken })}`;
        },
    },
    bidPackage: {
        list: "/BidPackages",
        print: "/BidPackage/Print",
        getPrintLink(ids: number[]) {
            const path = `/BidPackage/Print?ids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        getListLink() {
            return addPathForCurrentPortal(this.list);
        },
        details: getPathForBuilderOrSubPortals("/BidPackage/:id/:jobId"),
        getDetailsLink(
            id: number,
            jobId: number,
            isTemplateMode?: boolean,
            initialTab: TabOptions = "general"
        ) {
            const templateModeQSParam =
                typeof isTemplateMode !== "undefined" ? `&isTemplateMode=${isTemplateMode}` : "";
            const path = `${generatePath(this.details, {
                id,
                jobId,
                isTemplateMode,
            })}?initialTab=${initialTab}${templateModeQSParam}`;
            return addPathForCurrentPortal(path);
        },
    },
    branding: {
        settings: "/BrandingSettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    budget: {
        list: getPathForAllPortals("/Budget"),
        getListLink() {
            return addPathForCurrentPortal("/Budget");
        },
        settings: "/BudgetSettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    builderRiskInsurance: {
        settings: "/RiskInsuranceSettings/:settingsLayout/:autoOpenForm",
        requestForm: "/RiskInsurance/JobsiteRequestForm/:jobId",
        jobRequestForm: "/RiskInsurance/JobRequestForm/:jobId",
        builderProposalForm: "/RiskInsurance/BuilderProposalForm/:builderId",
        getRequestFormLink(jobId: number) {
            return generatePath(this.requestForm, { jobId });
        },
        getJobRequestFormLink(jobId: number) {
            return generatePath(this.jobRequestForm, { jobId });
        },
        getSettingsLink(settingsLayout: boolean, autoOpenForm: boolean) {
            return generatePath(this.settings, { settingsLayout, autoOpenForm });
        },
        getBuilderProposalForm(builderId: number) {
            return generatePath(this.builderProposalForm, { builderId });
        },
        briUpdateUtility: "/Admin/BRIUpdateUtility",
    },
    builderSetup: {
        adminSearch: "/Admin/BuilderSetup",
        adminSetup: "/Admin/BuilderSetup/:builderId",
        getAdminSetupLink(builderId: string) {
            return generatePath(this.adminSetup, { builderId });
        },
        setup: "/setup",
        uploadFiles: "/UploadFiles",
    },
    takeoff: {
        takeoffSettings: "/TakeoffSettings",
        getTakeoffSettingsLink() {
            return this.takeoffSettings;
        },
        launchTakeoff: "/LaunchTakeoff",
        getLaunchTakeoffLink() {
            return this.launchTakeoff;
        },
    },
    bulkExport: {
        settings: "/BulkExportSettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    changeOrder: {
        print: getPathForAllPortals("/ChangeOrder/Print"),
        getPrintLink(ids: number[]) {
            const path = `/ChangeOrder/Print?ids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        list: getPathForAllPortals("/ChangeOrders"),
        getListLink() {
            return addPathForCurrentPortal("/ChangeOrders");
        },
        settings: "/ChangeOrderSettings",
        getSettingsLink() {
            return this.settings;
        },
        changeOrderPaymentDetails: getPathForAllPortals("/Payments/:id/ChangeOrderPayment"),
        getChangeOrderPaymentDetailsLink(id: number) {
            const path = generatePath(this.changeOrderPaymentDetails, { id });
            return addPathForCurrentPortal(path);
        },
        ownerRequest: "/Owner/ChangeOrders/:id/:jobId/Request",
        getOwnerRequestLink(id: number, jobId: number) {
            const path = generatePath(this.ownerRequest, { id, jobId });
            return path;
        },
        changeOrderApprovalSuccess: getPathForAllPortals("/ChangeOrder/ApprovalSuccess"),
        getChangeOrderApprovalSuccessLink() {
            const path = generatePath(this.changeOrderApprovalSuccess);
            return addPathForCurrentPortal(path);
        },
        changeOrderDetails: getPathForAllPortals("/ChangeOrder/:id/:jobId"),
        getChangeOrderDetailsPath(id: number, jobId: number) {
            return generatePath(this.changeOrderDetails, { id, jobId });
        },
        getChangeOrderDetailsLink(id: number, jobId: number, fromRfi?: boolean) {
            let path = generatePath(this.changeOrderDetails, { id, jobId });
            if (fromRfi) {
                path += "?fromRFI=true";
            }
            return addPathForCurrentPortal(path);
        },
        bidMapping() {
            return "/BidMapping";
        },
        bidPackageDetails() {
            return "/BidPackage/:bidPackageId/:jobId";
        },
        getBidPackageDetailsLink(bidPackageId: number, jobId: number) {
            return generatePath(this.bidPackageDetails(), { bidPackageId, jobId });
        },
        bidDetails() {
            return `/Bid/:bidId/:jobId/${OpenedFrom.ChangeOrder}`;
        },
        getBidDetailsLink(jobId: number, bidId: number) {
            return generatePath(this.bidDetails(), { jobId, bidId });
        },
    },
    clientUpdates: {
        list: "/ClientUpdates",
        getListLink() {
            return this.list;
        },
        details: "/ClientUpdate/:id",
        getDetailsLink(id: number) {
            return generatePath(this.details, { id });
        },
    },
    deposits: {
        list: getPathForAllPortals("/DepositsList"),
        details: getPathForBuilderOrOwnerPortals("/Deposit/:id/:jobId/:payOnline"),
        getListLink() {
            return addPathForCurrentPortal("/DepositsList");
        },
        getDetailsPath(id: number, jobId: number, payOnline: boolean = false) {
            return generatePath(this.details, {
                id,
                jobId,
                payOnline,
            });
        },
        getDetailsLink(depositId: number, jobId: number, payOnline: boolean = false) {
            return addPathForCurrentPortal(this.getDetailsPath(depositId, jobId, payOnline));
        },
        external: getPathForExternalOwnerPage("/Deposit/:depositId/:shareToken(.{36})?"),
        getExternalLink(
            depositId: string,
            builderId: string,
            shareToken: string,
            checkoutId?: string
        ) {
            const checkoutIdParameter = checkoutId ? `?checkout_id=${checkoutId}` : "";
            return `${generatePath(this.external, {
                depositId,
                builderId,
                shareToken,
            })}${checkoutIdParameter}`;
        },
        getListLinkWithDepositSelected(
            depositId: number,
            jobId: number,
            payOnline: boolean = false,
            systemFilterType?: SystemFilterType
        ) {
            let url = addPathForCurrentPortal(
                "/DepositsList" + this.getDetailsPath(depositId, jobId, payOnline)
            );
            if (systemFilterType) {
                url = addQueryParams(url, { filter: systemFilterType.toString() });
            }
            return url;
        },
        postDepositCheckout: "/PostDepositCheckout/:depositId",
        getPostDepositCheckoutPath(depositId: number) {
            return generatePath(this.postDepositCheckout, { depositId });
        },
        sendToPos: "/SendToPos",
        getSendToPosPath() {
            return this.sendToPos;
        },
        payOnline: "/payDepositOnline",
        getPayOnlinePath() {
            return this.payOnline;
        },
        payOffline: "/payOffline",
        getPayOfflinePath() {
            return this.payOffline;
        },
        applyToInvoice: "/ApplyToInvoice/:jobId/:id",
        getApplyToInvoicePath(jobId: number, id: number) {
            return generatePath(this.applyToInvoice, { jobId, id });
        },
        print: getPathForAllPortals("/Deposit/Print"),
        getPrintLink(jobId: number, depositId: number) {
            const path = `/Deposit/Print?jobId=${jobId}&depositId=${depositId}`;
            return addPathForCurrentPortal(path);
        },
        externalPrintShareToken: `/Deposit/printExternal/:shareToken(.{36})`,
        getExternalPrintLinkShareToken(shareToken: string) {
            return generatePath(this.externalPrintShareToken, {
                shareToken,
            });
        },
    },
    editor: {
        /**
         * Clean this up once mobile updates the firbase config to use the new editor route.
         * TODO also remove /editor from precacheWorker.
         * @deprecated this should be removed after mobile moves to the webview/ route */
        legacyMobile:
            "/editor/:viewMode(fullscreen|embed)/:platform(ios|android)/:interfaceVersion/:mobileVersion",

        mobile: getPathForWebviewPage("/editor/:viewmode(fullscreen|embed)/:interfaceVersion"),
        getMobileEditorLink(
            viewMode: "fullscreen" | "embed",
            platform: "ios" | "android",
            interfaceVersion: string,
            mobileVersion: string
        ) {
            return generatePath(this.legacyMobile, {
                viewMode,
                platform,
                interfaceVersion,
                mobileVersion,
            });
        },
    },
    specification: {
        list: getPathForAllPortals("/Specifications"),
        getListLink() {
            return addPathForCurrentPortal("/Specifications");
        },
        details: "/Specification/:id/:jobId",
        getSpecificationDetails(id: number, jobId: number) {
            return generatePath(this.details, { id, jobId });
        },
        print: getPathForAllPortals("/Specifications/Print"),
        getPrintLink(ids: number[]) {
            const path = `/Specifications/Print?specificationIds=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
    },
    company: {
        settings: "/CompanySettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    document: {
        create: "/Documents/Create/:folderId/:builderId/:jobId/",
        getCreateLink(folderId: number, builderId: number, jobId: number) {
            return generatePath(this.create, { folderId, builderId, jobId });
        },

        list: getPathForAllPortals("/Documents/:directoryType/:folderId"),
        getAdminSetupListLink() {
            const path = `/Documents/${DirectoryType.GlobalDocs}/0/`;
            return routes.jobPicker.selectJobLink(JobIdTypes.GlobalDocs, path);
        },
        getListLink(
            directoryType: DirectoryType = DirectoryType.Standard,
            folderId: number = 0,
            selectedJobPickerId?: number
        ) {
            let path = addPathForCurrentPortal(
                generatePath(`/Documents/${DirectoryType[directoryType]}/:folderId`, {
                    directoryType,
                    folderId,
                })
            );
            if (selectedJobPickerId) {
                path = routes.jobPicker.selectJobLink(selectedJobPickerId, path);
            }
            return path;
        },

        unread: "/Owner/Document/Unread",
        getUnreadLink() {
            return this.unread;
        },
        editOnline: "/EditOnline/:documentInstanceId",
        getEditOnlineLink(documentInstanceId: number) {
            return generatePath(this.editOnline, { documentInstanceId });
        },
    },
    financing: {
        settings: "/Settings/Financing/:settingsLayout",
        getSettingsLink(settingsLayout: boolean) {
            return generatePath(this.settings, { settingsLayout });
        },
    },
    gettingStarted: {
        dataEntry: "/GettingStarted/DataEntry",
        getDataEntryLink() {
            return this.dataEntry;
        },
    },
    job: {
        list: getPathForAllPortals("/Jobs/List"),
        map: getPathForAllPortals("/Jobs/Map"),
        getListLink(tab: JobListTabs = JobListTabs.List): string {
            return addPathForCurrentPortal(generatePath(`/Jobs/${JobListTabs[tab]}`, { tab }));
        },
        comments: "/Jobs/Comments",
        webview: getPathForWebviewPage("/Jobs/Comments"),
        getCommentsLink() {
            return this.comments;
        },
        print: getPathForAllPortals("/JobPriceSummary/Print/:jobId/:printOnLoad?"),
        getPrintLink(jobId: number, printOnLoad?: boolean) {
            const path = `/JobPriceSummary/Print/${jobId}/${printOnLoad}`;
            return addPathForCurrentPortal(path);
        },
        jobPrint: getPathForAllPortals("/JobPrint/Print/:jobId/:printOnLoad?"),
        getJobPrintLink(jobId: number, printOnLoad: boolean) {
            const path = `/JobPrint/Print/${jobId}/${printOnLoad}`;
            return addPathForCurrentPortal(path);
        },
        details: getPathForAllPortals(
            "/Job/:jobId/:defaultTab/:accessedFromContact/:accessedFromLead/:ownerInvoiceId/:openCondensed?"
        ),
        getDetailsPath(
            jobId: number,
            defaultTab: JobDetailTab = JobDetailTab.Job,
            accessedFromContact: boolean = false,
            accessedFromLead: boolean = false,
            ownerInvoiceId?: number | null,
            openCondensed: boolean = false
        ) {
            const ownerInvoiceParam =
                typeof ownerInvoiceId === "undefined" ||
                ownerInvoiceId === null ||
                isNaN(ownerInvoiceId)
                    ? "undefined"
                    : ownerInvoiceId;
            return generatePath(this.details, {
                jobId,
                defaultTab,
                accessedFromContact,
                accessedFromLead,
                ownerInvoiceId: ownerInvoiceParam,
                openCondensed,
            });
        },
        flat: "/JobPage",
        flatDetails: getPathForAllPortals("/JobPage/:jobId/:defaultTab"),
        getFlatDetailsPath(
            jobId: number,
            defaultTab: JobDetailTab = JobDetailTab.Job,
            accessedFromContact: boolean = false,
            accessedFromLead: boolean = false,
            ownerInvoiceId?: number | null,
            openCondensed: boolean = false
        ) {
            const ownerInvoiceParam =
                typeof ownerInvoiceId === "undefined" ||
                ownerInvoiceId === null ||
                isNaN(ownerInvoiceId)
                    ? "undefined"
                    : ownerInvoiceId;
            let path = generatePath(this.flatDetails, {
                jobId,
                defaultTab,
            });
            const qp = new URLSearchParams();
            if (accessedFromContact) qp.set("accessedFromContact", accessedFromContact.toString());
            if (accessedFromLead) qp.set("accessedFromLead", accessedFromLead.toString());
            if (ownerInvoiceId) qp.set("ownerInvoiceId", ownerInvoiceParam.toString());
            if (openCondensed) qp.set("openCondensed", openCondensed.toString());

            const queryString = qp.toString();
            if (queryString.length > 0) path = `${path}?${queryString}`;

            return path;
        },
        getFlatDetailsLink(
            jobId: number,
            defaultTab: JobDetailTab = JobDetailTab.Job,
            accessedFromContact: boolean = false,
            accessedFromLead: boolean = false,
            ownerInvoiceId?: number | null,
            openCondensed: boolean = false
        ) {
            const path = this.getFlatDetailsPath(
                jobId,
                defaultTab,
                accessedFromContact,
                accessedFromLead,
                ownerInvoiceId,
                openCondensed
            );
            return addPathForCurrentPortal(path);
        },

        getDetailsLink(
            jobId: number,
            defaultTab: JobDetailTab = JobDetailTab.Job,
            accessedFromContact: boolean = false,
            accessedFromLead: boolean = false,
            ownerInvoiceId?: number | null,
            openCondensed: boolean = false
        ) {
            const path = this.getDetailsPath(
                jobId,
                defaultTab,
                accessedFromContact,
                accessedFromLead,
                ownerInvoiceId,
                openCondensed
            );
            return addPathForCurrentPortal(path);
        },
        jobFromTemplate: "/JobFromTemplate/:leadId/:jobType/:leadSoldDate/:sourceTemplateId",
        getJobFromTemplateLink(
            leadId: number,
            jobType: number,
            leadSoldDate: string,
            sourceTemplateId: number = JobIdTypes.NoJobs
        ) {
            return generatePath(this.jobFromTemplate, {
                leadId,
                jobType,
                leadSoldDate,
                sourceTemplateId,
            });
        },
        settings: "/JobSettings",
        getSettingsLink() {
            return this.settings;
        },
        jobGroupEditor: "/Job/JobGroupEditor/:groupId",
        getJobGroupEditorLink(groupId: number) {
            return generatePath(this.jobGroupEditor, { groupId: groupId });
        },
        jobPriceSummary: getPathForAllPortals("/JobPriceSummary/:jobId"),
        getJobPriceSummaryLink(jobId: number) {
            const path = generatePath(this.jobPriceSummary, { jobId: jobId });
            return addPathForCurrentPortal(path);
        },
        userManagement: {
            base: "/Job/UserManagement/",
            pathForHomeOwnerManagement: "/Job/:jobId/UserManagement/Homeowners",
            getPathForHomeOwnerManagement(jobId: number) {
                return generatePath(this.pathForHomeOwnerManagement, { jobId });
            },
        },
        clientPortalSettings: getPathForAllPortals("/ClientPortalSettings"),
        getClientPortalSettings() {
            const path = generatePath(this.clientPortalSettings);
            return path;
        },
    },
    jobPicker: {
        selectJob: getPathForAllPortals("/JobPicker/SelectJob"),
        selectJobLink(jobId: number, nextUrl: string) {
            return generatePath(
                addPathForCurrentPortal(
                    `${this.selectJob}?selectJobId=${jobId}&nextUrl=${encodeURIComponent(nextUrl)}`
                )
            );
        },
    },
    mobile: {
        mobileLogin: "/MobileLogin",
        baseSchema: "buildertrend://",
        getMobileLink(emailLinkType: EmailLinkType, key1: number, key2?: number, key3?: number) {
            return `${this.baseSchema}buildertrend.com/?elt=${emailLinkType}&key1=${key1}${
                key2 ? `&key2=${key2}` : ""
            }${key3 ? `&key3=${key3}` : ""}`;
        },
        mobileDownload: "/mobile/download",
        getMobileDownload() {
            return this.mobileDownload;
        },
    },
    notFound: {
        details: "/NotFound",
        getDetailsLink() {
            return generatePath(this.details);
        },
    },
    purchaseOrder: {
        list: getPathForAllPortals("/PurchaseOrders"),
        getListLink() {
            return addPathForCurrentPortal("/PurchaseOrders");
        },
        print: getPathForAllPortals("/PurchaseOrder/Print"),
        // This "external" print is really just used by pubsub to generate pdfs of approved POs
        printExternal: getPathForAllPortals(
            getPathForExternalPrint("/PurchaseOrder/Print/:shareToken(.{36})?")
        ),
        getPrintLink(ids: number[]) {
            const path = `/PurchaseOrder/Print?poid=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        getPrintExternalLink(builderId: string, shareToken: string, isPDFMode: boolean) {
            let path = generatePath(this.printExternal, { builderId, shareToken });
            path = `${path}?isPDFMode=${isPDFMode}`;
            return path;
        },
        details: getPathForAllPortals("/PurchaseOrder/:id/:jobId/:varianceChangeOrderId(\\d+)?"),
        getDetailsPath(id: number, jobId?: number, varianceChangeOrderId?: number) {
            return generatePath("/PurchaseOrder/:id/:jobId/:varianceChangeOrderId(\\d+)?", {
                id,
                jobId,
                varianceChangeOrderId,
            });
        },
        getDetailsLink(id: number, jobId?: number, varianceChangeOrderId?: number) {
            return addPathForCurrentPortal(this.getDetailsPath(id, jobId, varianceChangeOrderId));
        },
        modalPath: "/PurchaseOrder/:id/:jobId/:varianceChangeOrderId(\\d+)?",
        getModalLink(id: number, jobId?: number, varianceChangeOrderId?: number) {
            return generatePath(this.details, { id, jobId, varianceChangeOrderId });
        },
        changeOrder: getPathForAllPortals(
            "/PurchaseOrder/:id/:jobId/ChangeOrder/:changeOrderId/:jobId"
        ),
        getChangeOrderLink(id: number, jobId: number, changeOrderId: number) {
            const path = generatePath(
                "/PurchaseOrder/:id/:jobId/ChangeOrder/:changeOrderId/:jobId",
                { id, jobId, changeOrderId }
            );
            return addPathForCurrentPortal(path);
        },
        external: getPathForExternalSubsPage("/PurchaseOrder/:poId/:subId/:shareToken(.{36})?"),
        getExternalLink(builderId: string, poId: string, subId: string, shareToken?: string) {
            return generatePath(this.external, { builderId, poId, subId, shareToken });
        },
    },
    purchaseOrderPayment: {
        list: getPathForAllPortals("/PurchaseOrderPayments"),
        getListLink() {
            return addPathForCurrentPortal("/PurchaseOrderPayments");
        },
        details:
            "/PurchaseOrderPayment/:id/:jobId/:isFromMakeAPayment(true|false)?/:lienWaiver(lienWaiver)?",
        getDetailsLink(
            id: number,
            jobId: number,
            isFromMakeAPayment?: boolean,
            lienWaiver?: string
        ) {
            return generatePath(
                "/PurchaseOrderPayment/:id/:jobId/:isFromMakeAPayment(true|false)?/:lienWaiver(lienWaiver)?",
                { id, jobId, isFromMakeAPayment, lienWaiver }
            );
        },
    },
    bill: {
        list: getPathForAllPortals("/Bills"),
        getListLink() {
            return addPathForCurrentPortal("/Bills");
        },
        print: getPathForAllPortals("/bill/print"),
        getPrintLink(ids: number[], jobId: number) {
            const path = `/bill/print?billIds=${ids.join(",")}&jobId=${jobId}`;
            return addPathForCurrentPortal(path);
        },
        details: getPathForAllPortals("/Bill/:id/:jobId/:lienWaiver(lienWaiver)?"),
        getDetailsPath(billId: number, jobId: number, lienWaiver?: string) {
            return generatePath("/Bill/:id/:jobId/:lienWaiver(lienWaiver)?", {
                id: billId,
                jobId,
                lienWaiver,
            });
        },
        getDetailsLink(billId: number, jobId: number, lienWaiver?: string) {
            return addPathForCurrentPortal(this.getDetailsPath(billId, jobId, lienWaiver));
        },
        mapping: getPathForAllPortals("/BillMapping/:purchaseOrderId/:jobId/:isLinkBillToPO"),
        getMappingPath(purchaseOrderId: number, jobId: number, isLinkBillToPO: boolean = false) {
            return generatePath(this.mapping, {
                purchaseOrderId,
                jobId,
                isLinkBillToPO,
            });
        },
        massPay: "/BillMassPay/:isOnlinePayment/:selectedJobId?",
        getMassPayLink(isOnlinePayment: boolean, selectedJobId?: number) {
            return `${generatePath(this.massPay, { isOnlinePayment, selectedJobId })}`;
        },
        lienWaiverReview: getPathForAllPortals("/LienWaiverReview"),
        lienWaiverReviewExternal: getPathForExternalSubsPage(
            "/LienWaiverReview/:subId/:shareToken(.{36})?"
        ),
        getLienWaiverReviewExternalLink(builderId: string, subId: string, shareToken?: string) {
            return generatePath(this.lienWaiverReviewExternal, {
                builderId,
                subId,
                shareToken,
            });
        },
    },
    billPayment: {
        details: getPathForAllPortals("/BillPayment/:id"),
        getDetailsPath(merchantPaymentId: number) {
            return generatePath("/BillPayment/:id", {
                id: merchantPaymentId,
            });
        },
    },
    outboundPayments: {
        walletInfo: "/Wallet",
        mailCheck: "/MailCheck",
        printCheck: "/PrintCheck",
        external: getPathForExternalOutboundPaymentPage("/OutboundPaymentExternal"),
        getOutboundPaymentExternalLink(shareToken: string, fromEmail?: boolean) {
            let path = generatePath(this.external, { shareToken });
            if (fromEmail) {
                path += `?fromEmail=${fromEmail}`;
            }
            return path;
        },
    },
    warranty: {
        print: getPathForAllPortals("/Warranty/Print"),
        getPrintLink(ids: number[]) {
            const path = `/Warranty/Print?ids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },

        list: getPathForAllPortals("/Warranties"),
        getListLink() {
            return addPathForCurrentPortal("/Warranties");
        },

        details: getPathForAllPortals("/Warranty/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            const path = generatePath("/Warranty/:id/:jobId", { id, jobId });
            return addPathForCurrentPortal(path);
        },

        service: getPathForAllPortals("/Warranty/:id/:jobId/Service/:serviceId/:completed"),
        getServiceLink(id: number, jobId: number, serviceId: number, completed: boolean) {
            const path = generatePath("/Warranty/:id/:jobId/Service/:serviceId/:completed", {
                id,
                jobId,
                serviceId,
                completed,
            });
            return addPathForCurrentPortal(path);
        },

        serviceRelative: getPathForAllPortals("/Service/:serviceId/:completed"),
        getServiceLinkRelative(serviceId: number, completed: boolean) {
            const path = generatePath(this.serviceRelative, { serviceId, completed });
            return addPathForCurrentPortal(path);
        },

        settings: "/WarrantySettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    selection: {
        print: getPathForAllPortals("/Selection/Print"),
        getPrintLink(
            ids: number[],
            jobIds: number[],
            shouldPrintAll: boolean,
            sortColumn?: string,
            sortDirection?: SortDirection,
            selectedView?: SelectionListContainerTabs
        ) {
            let direction: DirectionTypes = DirectionTypes.DEFAULT;
            if (sortDirection) {
                direction = sortDirection === "asc" ? DirectionTypes.ASC : DirectionTypes.DESC;
            }
            const idString = ids.join(",");
            let path = `/Selection/Print?ids=${idString}&jobIds=${jobIds.join(
                ","
            )}&shouldPrintAll=${shouldPrintAll}`;
            path += `${sortColumn ? `&sortColumn=${sortColumn}` : ""}`;
            path += `${sortDirection ? `&sortDirection=${direction}` : ""}`;
            path += `${selectedView ? `&selectedView=${selectedView}` : ""}`;
            return addPathForCurrentPortal(path);
        },

        settings: "/SelectionSettings",
        getSettingsLink() {
            return this.settings;
        },

        cardList: getPathForAllPortals("/SelectionCards"),
        getCardListLink() {
            return addPathForCurrentPortal("/SelectionCards");
        },

        details: getPathForAllPortals("/Selection/:id/:jobId"),
        getDetailsLink(id: number, jobId: number, keyChoiceId?: number) {
            const path = `${generatePath(this.details, { id, jobId })}${
                keyChoiceId ? `?lockingChoiceId=${keyChoiceId}` : ""
            }`;
            return addPathForCurrentPortal(path);
        },

        list: getPathForAllPortals("/Selections/:tab"),
        getListLink(tab: SelectionListContainerTabs = SelectionListContainerTabs.Default) {
            return addPathForCurrentPortal(
                generatePath(`/Selections/${SelectionListContainerTabs[tab]}`, { tab })
            );
        },
    },
    selectionChoice: {
        details: getPathForAllPortals("/SelectionChoice/:choiceId/:jobId/:selectionId(\\d+)?"),
        getDetailsLink(choiceId: number, jobId: number, selectionId?: number) {
            const path = generatePath(this.details, { choiceId, jobId, selectionId });
            return addPathForCurrentPortal(path);
        },
        dependentSelections: "/DependentSelections/:choiceId/:jobId",
        getDependentSelectionsLink(choiceId: number, jobId: number) {
            return generatePath(this.dependentSelections, { choiceId, jobId });
        },
        copyChoicesFromSelection: "/CopyChoicesFromSelection/:targetSelectionId/:jobId",
        getCopyChoicesFromSelectionLink(targetSelectionId: number, jobId: number) {
            return generatePath(this.copyChoicesFromSelection, { targetSelectionId, jobId });
        },
    },
    allowance: {
        print: getPathForAllPortals("/Allowance/Print"),
        getPrintLink(id: number, jobId: number) {
            const path = `/Allowance/Print?id=${id}&jobId=${jobId}`;
            return addPathForCurrentPortal(path);
        },
        details: getPathForBuilderOrOwnerPortals("/Allowance/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            const path = generatePath(this.details, { id, jobId });
            return addPathForCurrentPortal(path);
        },
        getModalLink(id: number, jobId: number) {
            return generatePath(this.details, { id, jobId });
        },
    },
    survey: {
        print: getPathForAllPortals("/Survey/Print"),
        getPrintLink(ids: number[]) {
            const path = `/Survey/Print?ids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },

        questionReportPrint: getPathForAllPortals("/Survey/QuestionsPrint"),
        getQuestionReportPrintLink(jobId: number) {
            const path = `/Survey/QuestionsPrint?jobId=${jobId}`;
            return addPathForCurrentPortal(path);
        },

        questionDetails: getPathForAllPortals("/SurveyQuestion/:id"),
        getQuestionDetailsLink(id: number) {
            return generatePath(this.questionDetails, { id });
        },

        surveyDefinition: "/SurveyDefinition/:id",
        getSurveyDefinitionLink(id: number) {
            return generatePath(this.surveyDefinition, { id });
        },

        settings: "/SurveySettings",
        getSettingsLink() {
            return this.settings;
        },

        questionReport: getPathForAllPortals("/QuestionReport/:id/:jobIds"),
        getQuestionReport(id: number, jobIds: number[]) {
            const jobIdsString = jobIds.join(",");
            return generatePath(this.questionReport, { id, jobIds: jobIdsString });
        },

        details: "/Survey/:id/:jobId/:surveyDefinitionId?",
        getDetailsLink(id: number, jobId: number, surveyDefinitionId?: number) {
            return generatePath(this.details, { id, jobId, surveyDefinitionId });
        },

        ownerSurvey: getPathForBuilderOrOwnerPortals("/Survey/:id"),
        getOwnerSurvey(id: number) {
            return generatePath(this.ownerSurvey, { id });
        },

        ownerList: getPathForBuilderOrOwnerPortals("/OwnerSurveys"),
        getOwnerSurveyList() {
            return addPathForCurrentPortal("/OwnerSurveys");
        },

        list: getPathForAllPortals("/Surveys/:tab"),
        getBuilderListLink(tab: SurveyListContainerTabs = SurveyListContainerTabs.Individual) {
            const tabNameFromEnum = SurveyListContainerTabs[tab];
            const path = generatePath("/Surveys/:tab", { tab: tabNameFromEnum });
            return addPathForCurrentPortal(path);
        },
        getListLink(
            loginType: BTLoginTypes,
            tab: SurveyListContainerTabs = SurveyListContainerTabs.Individual
        ) {
            if (loginType === BTLoginTypes.OWNER || loginType === BTLoginTypes.CONTACT) {
                return this.getOwnerSurveyList();
            }
            return this.getBuilderListLink(tab);
        },
    },
    tag: {
        details: "/Tag/:tagType/:tagId",
        getDetailsLink(tagType: TagTypes, tagId: number) {
            return generatePath(this.details, { tagId, tagType });
        },
    },
    chat: {
        list: getPathForAllPortals("/Chat"),
        getListLink() {
            return addPathForCurrentPortal(generatePath(this.list));
        },
        getDraftChannelPathWithParticipantLink(
            participantId: number,
            participantName: string
        ): string {
            return (
                this.getListLink() +
                this.getDraftChannelPathWithParticipantPath(participantId, participantName)
            );
        },
        search: "/Search",
        channel: "/Channel/:channelId",
        draftChannelWithParticipant: `/Channel/:channelId/:participantId/:participantName`,
        createChannelUrlParam: "Create" as const,
        getChannelPath(channelId: string, jumpToMessageId?: string): string {
            const path = generatePath(this.channel, { channelId });
            return jumpToMessageId ? `${path}#${jumpToMessageId}` : path;
        },
        getDraftChannelPath(): string {
            return this.getChannelPath(this.createChannelUrlParam);
        },
        getChannelPathWithMesssageAsFocus(channelId: string, messageId: string) {
            return `${this.getChannelPath(channelId)}#${messageId}`;
        },
        getDraftChannelPathWithParticipantPath(
            participantId: number,
            participantName: string
        ): string {
            return generatePath(this.draftChannelWithParticipant, {
                channelId: this.createChannelUrlParam,
                participantId,
                participantName,
            });
        },

        webview: getPathForWebviewPage("/Chat"),
        getWebviewLink(webviewConfig: IWebviewRouteParams) {
            return addPathForCurrentPortal(
                generatePath(this.webview, {
                    platform: webviewConfig.platform.toLowerCase(),
                    mobileVersion: webviewConfig.mobileVersion.toLowerCase(),
                })
            );
        },
    },
    toDo: {
        list: getPathForAllPortals("/ToDos"),
        getListLink(portalTypeOverride?: PortalType) {
            if (portalTypeOverride) {
                return addPathForPortal("/ToDos", portalTypeOverride);
            }
            return addPathForCurrentPortal("/ToDos");
        },
        details: getPathForAllPortals("/ToDo/:id/:jobId/:isFromRfi"),
        getDetailsPath(id: number, jobId: number, isFromRfi: boolean) {
            return generatePath(this.details, { id, jobId, isFromRfi });
        },
        getDetailsLink(
            id: number,
            jobId: number,
            refreshParentAfterSave?: boolean,
            isFromRfi: boolean = false
        ) {
            let path = this.getDetailsPath(id, jobId, isFromRfi);
            if (refreshParentAfterSave) {
                path += "?refreshParentAfterSave=true";
            }
            return addPathForCurrentPortal(path);
        },

        getFullDetailsLink(
            portalType: PortalType,
            id: number,
            jobId: number,
            refreshParentAfterSave?: boolean,
            isFromRfi: boolean = false
        ) {
            return (
                this.getListLink(portalType) +
                this.getDetailsLink(id, jobId, refreshParentAfterSave, isFromRfi)
            );
        },
        // Parses a path even if it's appended to the end of another path, for example the
        // schedules page.
        parseDetailsPath(pathname: string) {
            const result = matchPath<{ id: string; jobId: string; isFromRfi: string }>(pathname, {
                path: ["**/ToDo/:id/:jobId/:isFromRfi(true|false)"],
                exact: false,
                strict: false,
            });
            if (!result) return null;
            return {
                id: parseInt(result.params.id),
                jobId: parseInt(result.params.jobId),
                isFromRfi: result.params.isFromRfi === "true",
                entityType: EntityTypes.Todo,
            };
        },

        print: getPathForAllPortals("/ToDo/Print"),
        getPrintLink(completedDetails: boolean, ids: number[]) {
            const path = `/ToDo/Print?completedDetails=${completedDetails}&toDoIds=${ids.join(
                ","
            )}`;
            return addPathForCurrentPortal(path);
        },

        settings: "/ToDoSettings",
        getSettingsLink() {
            return this.settings;
        },

        assign: "/ToDo/Assign/:toDoIds",
        getToDoAssignLink(toDoIds: number[]) {
            const path = generatePath(this.assign, { toDoIds: toDoIds.join(",") });
            return addPathForCurrentPortal(path);
        },

        detailsCopyModal: "/ToDoCopy",
        getDetailsCopyModalLink() {
            return this.detailsCopyModal;
        },

        bulkDelete: "/ToDoBulkDelete/:builderId/:todoIds/:jobId?",
        getToDoBulkDeleteLink(todoIds: number[], builderId: number, jobId?: number) {
            const path = generatePath(this.bulkDelete, {
                builderId: builderId,
                todoIds: todoIds.join(","),
                jobId: jobId,
            });
            return addPathForCurrentPortal(path);
        },
    },
    rfi: {
        details: getPathForAllPortals("/RFI/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            const path = generatePath("/RFI/:id/:jobId", { id, jobId });
            return addPathForCurrentPortal(path);
        },

        related: getPathForBuilderOrSubPortals(
            "/RelatedRfi/:linkedId/:linkedType/:builderId/:jobId"
        ),
        getRelatedLink(linkedId: number, linkedType: number, builderId: number, jobId: number) {
            const path = generatePath(this.related, { linkedId, linkedType, builderId, jobId });
            return addPathForCurrentPortal(path);
        },

        print: getPathForBuilderOrSubPortals("/RFI/Print"),
        getPrintLink(id: number) {
            return addPathForCurrentPortal(`/RFI/Print?id=${id}`);
        },

        settings: "/RFISettings",
        getSettingsLink() {
            return this.settings;
        },

        bidPackageDetails: "/BidPackage/:bidPackageId",
        getBidPackageDetailsLink(bidPackageId: number) {
            return generatePath(this.bidPackageDetails, { bidPackageId });
        },

        list: getPathForAllPortals("/RFIs"),
        getListLink() {
            return addPathForCurrentPortal("/RFIs");
        },
    },
    onlinePayments: {
        settings: "/OnlinePaymentSettings",
        getSettingsLink() {
            return this.settings;
        },
        list: getPathForAllPortals("/OnlinePayments"),
        getListLink() {
            return addPathForCurrentPortal("/OnlinePayments");
        },
        paymentReport: getPathForAllPortals("/OnlinePaymentReport/:tab"),
        groupedPaymentReport: getPathForAllPortals("/OnlinePaymentReport/:tab(NewReport)"),
        getPaymentReportLink(
            loginType: BTLoginTypes,
            tab: OnlinePaymentTabs = OnlinePaymentTabs.AllPayments
        ) {
            if (loginType === BTLoginTypes.BUILDER) {
                return `/OnlinePaymentReport/${OnlinePaymentTabs[tab]}`;
            } else if (loginType === BTLoginTypes.SUBS) {
                return this.paymentReportSubs;
            }
            throw new Error("Invalid login type");
        },
        paymentReportSubs: "/subs/OnlinePaymentReport",
        getAdminReportLink() {
            return addPathForCurrentPortal("/OnlinePayments");
        },
        externalPostOnlinePayment: "/OwnerMassOnlinePaymentExternal",
        getExternalPostPaymentLink() {
            return generatePath(this.externalPostOnlinePayment);
        },
        inboundHostedOnboarding: "/InboundHostedOnboarding",
        PaymentTos: "/PaymentTos/:service",
        getPaymentTosLink(service: string) {
            const path = generatePath(this.PaymentTos, { service });
            return addPathForCurrentPortal(path);
        },
        ownerMassOnlinePayment: getPathForBuilderOrOwnerPortals("/OwnerMassOnlinePayment"),
        getDetailsPath() {
            return "/OwnerMassOnlinePayment";
        },
        getDetailsLink() {
            return addPathForCurrentPortal(this.getDetailsPath());
        },
        onlinePaymentDetails: getPathForAllPortals("/OnlinePayment/:id"),
        getOnlinePaymentDetailsLink(id: number) {
            const path = generatePath("/OnlinePayment/:id", { id });
            return addPathForCurrentPortal(path);
        },
        getPaymentFromPrintoutUrl: (location: Location) => {
            return `${location.hostname}/pay`;
        },
        OnlinePaymentCodeEntry: getPathForExternalOwnerPage("pay"),
        OnlineInvoiceCodeEntry: getPathForInvoiceExternalOwnerPage("payinvoice/:code"),
        onlinePaymentAccountDetails: getPathForAdminPortal("/OnlinePaymentAccount/:builderId/:id"),
        getOnlinePaymentAccountDetailsLink(builderId: number, id: string) {
            const path = generatePath("/OnlinePaymentAccount/:builderId/:id", { builderId, id });
            return addPathForCurrentPortal(path);
        },
        enableLending: getPathForAdminPortal("/Lending"),
        getEnableLendingLink() {
            const path = generatePath("/Lending", {});
            return addPathForCurrentPortal(path);
        },
        addLoan: getPathForAdminPortal("/Loan"),
        getAddLoanLink() {
            const path = generatePath("/Loan", {});
            return addPathForCurrentPortal(path);
        },
        outboundPaymentSetupWizard: "/OutboundPaymentsSetup",
        outboundPayment: "/OutboundPayment",
        paymentOverview: "/PaymentsOverview",
        getPaymentsOverviewLink() {
            return this.paymentOverview;
        },
        paymentServicePaymentMethod: {
            newPaymentMethodPath: "/PaymentServiceNewPaymentMethod",
            verifyPaymentMethodPath:
                "/PaymentServiceVerifyPaymentMethod/:merchantPaymentMethodId/:paymentServicePaymentMethodId/:isOutboundPaymentMethod",
            getVerifyPaymentMethodLink(
                merchantPaymentMethodId: number,
                paymentServicePaymentMethodId: string,
                isOutboundPaymentMethod: boolean
            ) {
                return generatePath(this.verifyPaymentMethodPath, {
                    merchantPaymentMethodId,
                    paymentServicePaymentMethodId,
                    isOutboundPaymentMethod,
                });
            },
            externalWithShareToken: getPathForExternalMicrodepositPageWithShareToken(
                "/PaymentServiceVerifyPaymentMethod/"
            ),
            external: getPathForExternalMicrodepositPage(
                "/PaymentServiceVerifyPaymentMethod/:merchantPaymentMethodId"
            ),
            getExternalLinkWithShareToken(shareToken: string) {
                return generatePath(this.externalWithShareToken, { shareToken });
            },
        },
        adminPrescreening: "/Admin/PaymentsPrescreening",
        bankValidationRedirect: "/onlinepayment/oauthredirect",
        stuckPaymentUtility: "/Admin/StuckPaymentUtility",
        sellOBP: "/SellOBP",
        financialDocumentCollection: "/DocumentCollection",
        adminMerchantPaymentList: "/Admin/MerchantPaymentList",
    },
    settlement: {
        settlementDetails: "/Settlement/:id",
        getSettlementDetailsLink(id: number) {
            const path = generatePath("/Settlement/:id", { id });
            return addPathForCurrentPortal(path);
        },
    },
    costCatalog: {
        settings: "/CostCatalogSettings",
        getSettingsLink(viewType: CostCatalogViewType) {
            return generatePath(`${this.settings}?viewType=${viewType}`);
        },
        addFromCatalog: "/Add/CostCatalog",
        getAddFromCostCatalogLink() {
            return this.addFromCatalog;
        },
        costGroupDetailsPath: "/CostCatalog/CostGroup/:id",
        getCostGroupDetailsLink(id: number) {
            return generatePath(this.costGroupDetailsPath, { id });
        },
        costItemDetailsPath: "/CostCatalog/CostItem/:id",
        /**
         * @param id A cost item id, or 0 for new cost items.
         * @param costCodeId Used only when id = 0 to set the default
         *                   selected cost code when creating a new cost item.
         */
        getCostItemDetailsLink(id: number, costCodeId?: number | null) {
            const path = generatePath(this.costItemDetailsPath, { id });
            if (id === 0 && costCodeId) {
                return `${path}?costCodeId=${costCodeId}`;
            }
            return path;
        },
        costCodePath: "/CostCatalog/CostCode/:id",
        getCostCodeLink(id: number) {
            return generatePath(this.costCodePath, { id });
        },
    },
    costCodeManagement: {
        costCodes: "/Settings/CostCodes",
    },
    costCode: {
        list: "/CostCodes",
        settings: "/CostCodeSettings",
        getSettingsLink() {
            return this.settings;
        },
        accountingImport: "/CostCodeSettings/AccountingImport",
        getAccountingImportLink() {
            return addPathForCurrentPortal(this.accountingImport);
        },
        legacyAccountingImport: "/AccountingImport",
        getLegacyAccountingImportLink() {
            return addPathForCurrentPortal(this.legacyAccountingImport);
        },
        quickFixManageAccounting:
            "/ManageAccounting/:checkExpenseAccount/:serviceItemsOnly/:doRefresh",
        getQuickFixManageAccountingLink(
            costCodeIdsList: number[],
            checkExpenseAccount: boolean,
            serviceItemsOnly: boolean,
            doRefresh: boolean
        ) {
            const path = `${generatePath(this.quickFixManageAccounting, {
                checkExpenseAccount,
                serviceItemsOnly,
                doRefresh,
            })}?costCodeIds=${costCodeIdsList.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        manageAccounting:
            "/CostCodeSettings/ManageAccounting/:checkExpenseAccount/:serviceItemsOnly/:doRefresh",
        getManageAccountingLink(
            costCodeIdsList: number[],
            checkExpenseAccount: boolean,
            serviceItemsOnly: boolean,
            doRefresh: boolean
        ) {
            const path = `${generatePath(this.manageAccounting, {
                checkExpenseAccount,
                serviceItemsOnly,
                doRefresh,
            })}?costCodeIds=${costCodeIdsList.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        costCodeConflict: "/Conflict",
        getCostCodeConflictLink() {
            const path = generatePath(this.costCodeConflict);
            return addPathForCurrentPortal(path);
        },
        costCodeDetails: "/CostCode/Code/:costType/:costCodeId",
        getCostCodeDetailsLink(costCodeId: number, costType: CostType) {
            const path = generatePath(this.costCodeDetails, {
                costType: CostType[costType],
                costCodeId,
            });
            return addPathForCurrentPortal(path);
        },

        costCodeSetupWizard: "/CostCode/SetupWizard",
        getCostCodeSetupWizardLink() {
            return addPathForCurrentPortal(this.costCodeSetupWizard);
        },
        importRecommendedCostCodes: "/CostCode/ImportRecommended",
        getImportRecommendedCostCodesLink() {
            return addPathForCurrentPortal(this.importRecommendedCostCodes);
        },

        costCodeDetailsIndex: `/CostCode/Code/:costType?/:costCodeId?/:index?`,
        getCostCodeDetailsIndexLink(costCodeId: number, costType: CostType, index?: number) {
            const path = generatePath(this.costCodeDetailsIndex, {
                costCodeId,
                costType: CostType[costType],
                index,
            });
            return addPathForCurrentPortal(path);
        },

        getCostCodeDetailsIndexRoute(costType: CostType, index: number) {
            return `/CostCode/Code/${CostType[costType]}/:costCodeId/${index}`;
        },

        costCodeLegacyLink: "/CostCode/Code/:costType?/:costCodeId?",
        getCostCodeLegacyLink(costCodeId: number, costType: number) {
            return `/CostCode/Code/${costType}/${costCodeId}`;
        },

        costCategoryDetails: "/Category/:categoryType/:costCategoryId",
        getCostCategory(costCategoryId: number, categoryType: CostType) {
            const path = generatePath(this.costCategoryDetails, {
                categoryType: CostType[categoryType],
                costCategoryId,
            });
            return addPathForCurrentPortal(path);
        },

        costCodeMassReassign: "/CostCode/CostCodeMassReassign/:costType",
        getCostCodeMassReassign(costType: CostType) {
            const path = generatePath(this.costCodeMassReassign, {
                costType: CostType[costType],
            });
            return addPathForCurrentPortal(path);
        },
        importFromExcel: "/CostCode/ImportFromExcel",
        getImportFromExcelLink() {
            return addPathForCurrentPortal(this.importFromExcel);
        },
        addCostCodes: "/CostCode/Add",
        getAddCostCodesLink() {
            return addPathForCurrentPortal(this.addCostCodes);
        },
    },
    costGroup: {
        detailsPath: "/CostGroup/:id",
        getDetailsLink(id: number) {
            return generatePath(this.detailsPath, { id });
        },
    },
    costItem: {
        detailsPath: "/CostItem/:id",
        getDetailsLink(id: number) {
            return generatePath(this.detailsPath, { id });
        },
    },
    lead: {
        details: "/Lead/:id",
        getDetailsLink(id: number) {
            return generatePath(this.details, { id });
        },

        print: getPathForAllPortals("/Lead/Print"),
        getPrintLink(ids: number[]) {
            const path = `/Lead/Print?ids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },

        settings: "/SalesSettings",
        getSettingsLink() {
            return this.settings;
        },

        settingsCustomField: "/SalesSettings/CustomField/:id",
        getSettingsCustomField(id: number) {
            return `${this.settings}/CustomField/${id}`;
        },

        list: "/Leads",
        getListLink() {
            return "/Leads";
        },

        leadList: "/leads/:tab",
        getLeadListLink(tab: LeadTabTypes = LeadTabTypes.ListView) {
            let tabName: LeadTabTypes | string = tab;
            if (tab === LeadTabTypes.ListView) {
                tabName = "opportunities";
            } else if (tab === LeadTabTypes.ActivityView) {
                tabName = "activities";
            } else if (tab === LeadTabTypes.ActivityCalendar) {
                tabName = "calendar";
            } else if (tab === LeadTabTypes.ActivityTemplates) {
                tabName = "activityTemplates";
            } else if (tab === LeadTabTypes.Map) {
                tabName = "map";
            } else if (tab === LeadTabTypes.Proposals) {
                tabName = "proposals";
            } else if (tab === LeadTabTypes.ProposalTemplates) {
                tabName = "proposalTemplates";
            }
            return generatePath(this.leadList, { tab: tabName });
        },

        emailWizard: "/Leads/EmailWizard/:ids",
        getEmailWizardLink(ids: number[]) {
            return generatePath(this.emailWizard, { ids: ids.join(",") });
        },

        websiteContactForm: "/Leads/WebsiteContactForm",
        getWebsiteContactForm() {
            return this.websiteContactForm;
        },

        leadToJob: "/LeadToJob/:mode",
        getConvertLeadToJob(mode: LeadToJobModes) {
            return generatePath(this.leadToJob, { mode: LeadToJobModes[mode] });
        },

        newJobFromTemplates: "/NewJobFromTemplates",
        getNewJobFromTemplates() {
            return this.newJobFromTemplates;
        },
    },
    leadProposals: {
        details: "/LeadProposal/:proposalId/:leadId/:generatePDF",
        getDetailsLink(proposalId: number, leadId: number, generatePDF: boolean) {
            return generatePath(this.details, { proposalId, leadId, generatePDF });
        },
        list: getPathForAllPortals("/LeadProposals"),
        getListLink() {
            return addPathForCurrentPortal("/LeadProposals");
        },
        templateDetails: "/LeadProposalTemplate/:proposalTemplateId",
        getTemplateDetailsLink(proposalTemplateId: number) {
            return generatePath(this.templateDetails, { proposalTemplateId });
        },
        templateList: getPathForAllPortals("/LeadProposalTemplates"),
        getTemplateListLink() {
            return addPathForCurrentPortal("/LeadProposalTemplates");
        },
        leadProposalPrint: "/LeadProposal/Print/:proposalId",
        getLeadProposalPrintLink(proposalId: number) {
            return generatePath(this.leadProposalPrint, { proposalId });
        },
        leadProposalPrintExternal: getPathForExternalLeadsPage("/LeadProposal/Print/:proposalId"),
        getLeadProposalExternalPrintLink(builderId: string, proposalId: string) {
            return generatePath(this.leadProposalPrintExternal, { builderId, proposalId });
        },
        externalDetails: getPathForExternalLeadsPage(
            // The .{36} regex for the shareToken optional path parameter verifies that the Guid passed in has exactly 36 characters.
            // We need verification to allow the pay lead proposal modal to work when the shareToken Guid is not present
            "/LeadProposal/:leadId/:proposalId/:isBuilder/:shareToken(.{36})?"
        ),

        getExternalDetailsLink(
            builderId: string,
            leadId: string,
            proposalId: string,
            isBuilder: boolean,
            shareToken?: string,
            checkoutId?: string
        ) {
            const checkoutIdParameter = checkoutId ? `?checkout_id=${checkoutId}` : "";
            return `${generatePath(this.externalDetails, {
                builderId,
                leadId,
                proposalId,
                isBuilder,
                shareToken,
            })}${checkoutIdParameter}`;
        },
        mobilePreview: "/LeadProposalMobilePreview/:proposalId",
        mobilePreviewLegacy: "/LeadProposalMobilePreview/:leadId/:proposalId",
        proposalPdfGenerationPage: getPathForExternalLeadsPage(
            "/Proposal/ProposalPdfGeneration/:proposalId/:shareToken(.{36})?"
        ),
        payOnlineMobile: "/LeadProposal/:id/payOnlineMobile",
        payOnlineWebforms: "/LeadProposal/:id/PayOnlineWebforms",
        externalPayOnlineWebforms: getPathForExternalLeadsPage(
            "/LeadProposal/:encryptedProposalId/PayOnlineWebforms"
        ),
        getProposalPDFGenerationURL(
            builderId: string,
            proposalId: string,
            shareToken: string,
            isLeadProposal: boolean
        ) {
            let path = generatePath(this.proposalPdfGenerationPage, {
                builderId,
                proposalId,
                shareToken,
            });

            if (isLeadProposal) {
                path += "?isLeadProposal=true";
            }
            return path;
        },

        importFromCostCodes: "/ImportFromCostCodes",
        getImportFromCostCodes() {
            return this.importFromCostCodes;
        },
    },
    leadActivity: {
        colorChange: "/LeadActivityColorChange/:activityIds",
        getColorChangeLink(activityIds: number[]) {
            return generatePath(this.colorChange, { activityIds: activityIds.join(",") });
        },

        delete: "/LeadActivityDelete/:activityIds",
        getDeleteLink(activityIds: number[]) {
            return generatePath(this.delete, { activityIds: activityIds.join(",") });
        },

        details: "/LeadActivity/:id/:leadId/:openActivity",
        getDetailsLink(
            id: number,
            leadId: number,
            openActivity: OpenActivityType,
            activityDate: moment.Moment | undefined
        ) {
            let path = generatePath(this.details, { id, leadId, openActivity });
            if (activityDate !== undefined) {
                path = `${path}?activityDate=${activityDate.format("YYYY-MM-DD")}`;
            }
            return path;
        },

        list: "/LeadActivities",
        calendar: "/LeadActivityCalendar",
        reassign: "/LeadActivityReassign/:activityIds",
        getReassignLink(activityIds: number[]) {
            return generatePath(this.reassign, { activityIds: activityIds.join(",") });
        },

        reassignWrapper: "/LeadActivityReassign/:activityIds/:builderId",
        getReassignWrapperLink(activityIds: number[], builderId: number) {
            return generatePath(this.reassignWrapper, {
                activityIds: activityIds.join(","),
                builderId,
            });
        },

        templateDetails: getPathForAllPortals("/LeadActivityTemplate/:id"),
        getTemplateDetailsLink(id: number) {
            const path = generatePath(this.templateDetails, { id });
            return addPathForCurrentPortal(path);
        },

        templateList: getPathForAllPortals("/LeadActivityTemplates"),
        getTemplateListLink() {
            return addPathForCurrentPortal("/LeadActivityTemplates");
        },
    },
    ownerInvoice: {
        print: getPathForAllPortals("/OwnerInvoice/Print"),
        printWebview: getPathForWebviewPage(
            "/OwnerInvoice/Print/:invoiceId/:isOwnerPreview/:showCustomFields/:showPaymentCode/:showInvoiceDescription?"
        ),
        getPrintLink(
            invoiceId: number,
            showCustomFields?: boolean,
            isOwnerPreview?: boolean,
            showPaymentCode?: boolean,
            showInvoiceDescription?: boolean
        ) {
            const path = `/OwnerInvoice/Print?invoiceId=${invoiceId}&showCustomFields=${showCustomFields}&showPaymentCode=${showPaymentCode}&showInvoiceDescription=${showInvoiceDescription}&isOwnerPreview=${isOwnerPreview}`;
            return addPathForCurrentPortal(path);
        },
        externalPrintShareToken: `/printExternal/:shareToken(.{36})/:showCustomFields/:showPaymentCode/:showInvoiceDescription`,
        getExternalPrintLinkShareToken(
            showCustomFields: boolean,
            shareToken: string,
            showPaymentCode?: boolean,
            showInvoiceDescription?: boolean
        ) {
            return generatePath(this.externalPrintShareToken, {
                shareToken,
                showCustomFields,
                showPaymentCode,
                showInvoiceDescription,
            });
        },
        externalPrint:
            "/printExternalId/:builderId/:invoiceId/:showCustomFields/:showPaymentCode/:showInvoiceDescription",
        getExternalPrintLink(
            builderId: number,
            invoiceId: string,
            showCustomFields?: boolean,
            showPaymentCode?: boolean,
            showInvoiceDescription?: boolean
        ) {
            return generatePath(this.externalPrint, {
                builderId,
                invoiceId,
                showCustomFields,
                showPaymentCode,
                showInvoiceDescription,
            });
        },
        details: getPathForBuilderOrOwnerPortals("/OwnerInvoice/:id/:jobId/:payOnline"),
        externalDetails: getPathForExternalOwnerPage(
            "/OwnerInvoice/:shareToken(.{36})?/:id?/:jobId?"
        ),
        sharedDetails: "/OwnerInvoiceShared/:invoiceId?/:payOnline(true|false)?",
        getDetailsPath(invoiceId: number, jobId: number, payOnline: boolean = false) {
            return generatePath(this.details, {
                id: invoiceId,
                jobId,
                payOnline,
            });
        },
        getDetailsLink(invoiceId: number, jobId: number, payOnline: boolean = false) {
            return addPathForCurrentPortal(this.getDetailsPath(invoiceId, jobId, payOnline));
        },
        getExternalDetailsLink(
            encodedInvoiceId?: string,
            builderId?: string,
            encryptedJobId?: string,
            shareToken?: string,
            checkoutId?: string
        ) {
            const checkoutIdParameter = checkoutId ? `?checkout_id=${checkoutId}` : "";
            return `${generatePath(this.externalDetails, {
                builderId,
                id: encodedInvoiceId,
                jobId: encryptedJobId,
                shareToken,
            })}${checkoutIdParameter}`;
        },

        getSharedDetailsLink(invoiceId?: number, payOnline: boolean = false) {
            return generatePath(this.sharedDetails, {
                invoiceId,
                payOnline,
            });
        },

        settings: "/OwnerInvoiceSettings",
        getSettingsLink() {
            return this.settings;
        },
        list: getPathForAllPortals("/OwnerInvoices"),
        getListLink(portalType?: PortalType, systemFilterType?: SystemFilterType) {
            let url = portalType
                ? addPathForPortal("/OwnerInvoices/Shared", portalType)
                : addPathForCurrentPortal("/OwnerInvoices");
            if (systemFilterType) {
                url = addQueryParams(url, { filter: systemFilterType.toString() });
            }
            return url;
        },
        getListLinkWithInvoiceSelected(
            invoiceId: number,
            jobId: number,
            payOnline: boolean = false,
            systemFilterType?: SystemFilterType
        ) {
            let url = addPathForCurrentPortal(
                "/OwnerInvoices" + this.getDetailsPath(invoiceId, jobId, payOnline)
            );
            if (systemFilterType) {
                url = addQueryParams(url, { filter: systemFilterType.toString() });
            }
            return url;
        },
        payOnlineMobile: getPathForBuilderOrOwnerPortals(
            "/OwnerInvoice/:id/:jobId/payOnlineMobile"
        ),
    },
    paymentSelector: {
        path: "/payOnline",
    },
    importWizard: {
        adminPath: "/Admin/ImportWizard",
        path: "/ImportWizard/:importType",
        getLink(importType: ImportTypes) {
            return generatePath(this.path, { importType });
        },
        info: "/ImportWizard/Info/:importType",
        getInfoLink(importType: ImportTypes) {
            return generatePath(this.info, { importType });
        },
    },
    templateImport: {
        details: "/TemplateImport/:jobId/:tabId/:externalJobId?",
        getDetailsLink(jobId: number, tabId: TabPermissions, externalJobId?: string) {
            return generatePath(this.details, { jobId, tabId, externalJobId });
        },
    },
    template: {
        templateDetails: "/Template/:jobId",
        getTemplateDetailsLink(jobId: number) {
            return generatePath(this.templateDetails, { jobId });
        },
        templateFromTemplate: "/TemplateFromTemplate/:externalJobId?",
        getTemplateFromTemplateLink(externalJobId?: string) {
            return generatePath(this.templateFromTemplate, { externalJobId });
        },
        list: getPathForAllPortals("/Templates/:tab"),
        getListLink(tab: TemplateListTabs = TemplateListTabs.MyTemplates) {
            const tabNameFromEnum = Object.values(TemplateListTabs)[tab];
            return addPathForCurrentPortal(generatePath(this.list, { tab: tabNameFromEnum }));
        },
        copyTemplateToOrg: "/Template/CopyTemplateToOrg/:templateId",
        getCopyTemplateToOrg(templateId: number) {
            return generatePath(this.copyTemplateToOrg, { templateId });
        },
    },
    internalUsers: {
        quickAdd: "/InternalUserQuickAdd",
        list: getPathForAllPortals("/InternalUsers"),
        linkToSub: "/LinkToSub",
        getListLink() {
            return "/InternalUsers";
        },
        internalUserSettings:
            "/InternalUserSettings/:showCustomFields/:fromInternalUserId/:currentTab",
        getInternalUserSettingsLink(
            showCustomFields: boolean,
            fromInternalUserId: number = -1,
            currentTab: InternalUserTabKeys = InternalUserTabKeys.Overview
        ) {
            return generatePath(this.internalUserSettings, {
                showCustomFields,
                fromInternalUserId,
                currentTab,
            });
        },
        internalUserRoleDetails:
            "/InternalUserSettings/Role/:roleId/:copyFromId/:showCustomFields/:fromInternalUserId/:currentTab",
        getInternalUserRoleDetailsLink(
            roleId: number,
            copyFromId: number = -1,
            showCustomFields: boolean,
            fromInternalUserId: number,
            currentTab: InternalUserTabKeys = InternalUserTabKeys.Overview
        ) {
            return generatePath(this.internalUserRoleDetails, {
                roleId,
                copyFromId,
                showCustomFields,
                fromInternalUserId,
                currentTab,
            });
        },
        internalUserDetails: "/InternalUser/:id",
        getInternalUserDetailsLink(
            id: number,
            initialTab?: InternalUserTabKeys,
            userPrefMode?: boolean
        ) {
            let path = generatePath(this.internalUserDetails, { id });
            const qp = new URLSearchParams();
            if (initialTab) {
                qp.set("initialTab", initialTab.toString());
            }
            if (userPrefMode) {
                qp.set("upm", userPrefMode.toString());
            }
            if (qp.toString().length > 0) {
                path = `${path}?${qp.toString()}`;
            }
            return path;
        },
        quickActionsUserPreferences: "/QuickActionsUserPreferences",
        getQuickActionsUserPreferencesLink() {
            return generatePath(this.quickActionsUserPreferences);
        },
    },
    subscription: {
        adminList: "/Admin/Subscription/:builderId/:isAdminUser",
        getAdminListLink(builderId: number, isAdminUser: boolean) {
            return generatePath(this.adminList, { builderId, isAdminUser });
        },
    },
    subscriptionManager: {
        settings: "/SubscriptionSettings/:fromSettings/:fromFrozenBuilder",
        getSettingsLink(fromSettings: boolean, fromFrozenBuilder: boolean) {
            return generatePath(this.settings, { fromSettings, fromFrozenBuilder });
        },
    },
    dailyLog: {
        list: getPathForAllPortals("/DailyLogs"),
        getListLink(portalTypeOverride?: PortalType) {
            if (portalTypeOverride) {
                return addPathForPortal("/DailyLogs", portalTypeOverride);
            }
            return addPathForCurrentPortal("/DailyLogs");
        },
        print: getPathForAllPortals("/DailyLog/Print"),
        getPrintLink(filters: string, pageNumber: number, pageSize: number) {
            const path = `/DailyLog/Print?filters=${filters}&pn=${pageNumber}&ps=${pageSize}`;
            return addPathForCurrentPortal(path);
        },

        details: getPathForAllPortals("/DailyLog/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            return addPathForCurrentPortal(this.getDetailsPath(id, jobId));
        },
        // Parses a path even if it's appended to the end of another path, for example the
        // schedules page.
        parseDetailsPath(pathname: string) {
            const result = matchPath<{ id: string; jobId: string }>(pathname, {
                path: ["**/DailyLog/:id/:jobId"],
                exact: true,
                strict: false,
            });
            if (!result) return null;
            return {
                id: parseInt(result.params.id),
                jobId: parseInt(result.params.jobId),
                entityType: EntityTypes.DailyLog,
            };
        },
        getDetailsPath(id: number, jobId: number) {
            return generatePath("/DailyLog/:id/:jobId", { id, jobId });
        },

        getDetailsRecursiveLink(id: number, jobId: number) {
            return generatePath(this.details, { id, jobId });
        },

        getFullDetailsLink(portalType: PortalType, id: number, jobId: number) {
            return this.getListLink(portalType) + this.getDetailsLink(id, jobId);
        },
        settings: "/DailyLogSettings",
        getSettingsLink() {
            return this.settings;
        },
    },
    contact: {
        // remove 'isFromSelector' parameter once we don't open the page from webforms
        details: "/Contact/:id/:isFromSelector",
        getDetailsLink(id: number, isFromSelector: boolean) {
            return generatePath(this.details, { id, isFromSelector });
        },
        print: "/Contact/Print",
        getPrintLink(id: number) {
            return `${this.print}?id=${id}`;
        },
        settings: "/CustomerContactSettings",
        getSettingsLink() {
            return this.settings;
        },
        contactUs: "/ContactUs",
        getContactUsLink() {
            return this.contactUs;
        },
        contactInfoDisplay: "/ContactUsDisplay",
        getContactInfoDisplayLink() {
            return this.contactInfoDisplay;
        },
        list: getPathForAllPortals("/Contacts"),
        getListLink() {
            return addPathForCurrentPortal("/Contacts");
        },
        emailAndInvite: "/Contact/emailAndInvite/",
        getEmailAndInviteLink() {
            return this.emailAndInvite;
        },
        releaseAndShareModal: "/Contact/releaseAndShareModal/",
        getReleaseAndShareModalLink() {
            return this.releaseAndShareModal;
        },
        search: "/ContactSearch",
        clientJobPermissionSettings: "/ClientJobPermissionSettings/Defaults",
        getClientJobPermissionSettingsLink() {
            return this.clientJobPermissionSettings;
        },
    },
    comment: {
        settings: "/CommentSettings",
        getSettingsLink() {
            return this.settings;
        },
        list: getPathForAllPortals("/Comments/:tab"),
        getListLink(tab: CommentListTabKeys = CommentListTabKeys.Conversation) {
            return addPathForCurrentPortal(`/Comments/${CommentListTabKeys[tab]}`);
        },
        webview: getPathForAllPortals(
            getPathForWebviewPage("/Comments/:builderId/:entityType/:entityId/:jobId?")
        ),
    },
    conversation: {
        details: getPathForAllPortals("/Conversation/:entityId/:entityType/:builderId/:jobId?"),
        getDetailsPath(
            entityId: number,
            entityType: DiscussionsLinkedTypes,
            builderId: number,
            jobId?: number
        ) {
            return generatePath("/Conversation/:entityId/:entityType/:builderId/:jobId?", {
                entityId,
                entityType,
                builderId,
                jobId,
            });
        },
        getDetailsLink(
            entityId: number,
            entityType: DiscussionsLinkedTypes,
            builderId: number,
            jobId?: number
        ) {
            return addPathForCurrentPortal(
                this.getDetailsPath(entityId, entityType, builderId, jobId)
            );
        },
    },
    media: {
        annotation:
            "/Annotation/:annotationId/:documentInstanceId/:loginType/:builderId/:isOnNewEntity/:isOnTempFile/:isReadOnly",
        getAnnotationLink(
            annotationId: number,
            documentInstanceId: number,
            loginType: BTLoginTypes,
            builderId: number,
            isOnNewEntity: boolean,
            isOnTempFile: boolean,
            isReadOnly?: boolean
        ) {
            return generatePath(this.annotation, {
                annotationId,
                documentInstanceId,
                loginType,
                builderId,
                isOnNewEntity,
                isOnTempFile,
                isReadOnly: isReadOnly || false,
            });
        },

        settings: "/MediaSettings",
        getSettingsLink() {
            return this.settings;
        },

        copy: "/Media/Copy/:jobId",
        getCopyLink(jobId: number, mediaType: MediaType, fileIds: number[], folderIds: number[]) {
            return stringifyUrl({
                url: generatePath(this.copy, { jobId }),
                query: {
                    mediaType: mediaType.toString(),
                    mediaIds: fileIds.join(","),
                    folderIds: folderIds.join(","),
                },
            });
        },

        move: "/Media/Move/:folderId/:jobId/:mediaType",
        getMoveLink(
            folderId: number,
            jobId: number,
            mediaType: MediaType,
            fileIds: number[],
            folderIds: number[],
            initialFolderId?: number
        ) {
            return stringifyUrl({
                url: generatePath(this.move, { folderId, jobId, mediaType }),
                query: {
                    documentInstanceIds: fileIds.join(","),
                    folderIds: folderIds.join(","),
                    initialFolderId: initialFolderId?.toString(),
                },
            });
        },

        upload: "/Media/Upload/:folderId",
        getUploadLink(folderId: number) {
            return generatePath(this.upload, { folderId });
        },

        browseBTFiles: "/Media/Browse",
        getBrowseLink({ jobId, leadId }: { jobId?: number; leadId?: number }) {
            return stringifyUrl({
                url: generatePath(this.browseBTFiles),
                query: {
                    jobId: jobId?.toString(),
                    leadId: leadId?.toString(),
                },
            });
        },

        viewingPermissions: "/Media/ViewingPermissions/:folderId/:jobId/:showSubs/:showOwner",
        getViewingPermissionsLink(
            documentInstanceIds: number[],
            folderId: number,
            jobId: number,
            mediaType: MediaType,
            viewingPermissionType?: ViewingPermissionAction,
            showOwner?: boolean,
            showSubs?: boolean
        ) {
            return stringifyUrl({
                url: generatePath(this.viewingPermissions, {
                    folderId,
                    jobId,
                    showSubs,
                    showOwner,
                }),
                query: {
                    mediaType: mediaType.toString(),
                    docIds: documentInstanceIds.join(","),
                    viewingPermissionAction: viewingPermissionType?.toString(),
                },
            });
        },
        folderDetails: "/Media/FolderDetails/:folderId/:jobId",
        getFolderDetailsLink(
            folderId: number,
            parentFolderId: number,
            jobId: number,
            mediaType: MediaType
        ) {
            return `${generatePath(this.folderDetails, {
                folderId,
                jobId,
            })}?mediaType=${mediaType}&parentFolderId=${parentFolderId}`;
        },
        folderProperties: "/Media/FolderProperties/:folderId/:jobId",
        getFolderPropertiesLink(
            folderId: number,
            parentFolderId: number,
            jobId: number,
            mediaType: MediaType
        ) {
            return `${generatePath(this.folderProperties, {
                folderId,
                jobId,
            })}?mediaType=${mediaType}&parentFolderId=${parentFolderId}`;
        },

        mediaDetails: "/Media/Details/:docInstanceId/:jobId/:mediaType/:folderId/:isOwnerProfile",
        getMediaDetailsLink(
            docInstanceId: number,
            jobId: number,
            mediaType: MediaType,
            folderId: number = 0,
            isOwnerProfile: boolean = false
        ) {
            return `${generatePath(this.mediaDetails, {
                docInstanceId,
                jobId,
                mediaType,
                folderId,
                isOwnerProfile,
            })}`;
        },

        mediaProperties:
            "/Media/Properties/:docInstanceId/:jobId/:mediaType/:folderId/:templateType/:templateDocumentInstanceId/:activeTab",
        getMediaPropertiesLink(
            docInstanceId: number,
            jobId: number,
            mediaType: MediaType,
            folderId: number = 0,
            templateType: TemplateType = TemplateType.None,
            templateDocumentInstanceId: number = 0,
            activeTab: MediaPropertiesTab = MediaPropertiesTab.Details
        ) {
            return `${generatePath(this.mediaProperties, {
                docInstanceId,
                jobId,
                mediaType,
                folderId,
                templateType,
                templateDocumentInstanceId,
                activeTab,
            })}`;
        },

        folderViewingPermissions: `/Media/FolderViewingPermissions/:jobId/:mediaType`,
        getFolderViewingPermissionsLink(folderIds: number[], jobId: number, mediaType: MediaType) {
            return stringifyUrl({
                url: generatePath(this.folderViewingPermissions, { jobId, mediaType }),
                query: {
                    folderIds: folderIds.join(","),
                },
            });
        },

        folderEmailInfo: `/Media/FolderEmailInfo/:folderId/:jobId/:mediaType`,
        getFolderEmailInfoLink(folderId: number, jobId: number, mediaType: MediaType) {
            return generatePath(this.folderEmailInfo, { folderId, jobId, mediaType });
        },

        replace:
            "/Media/Replace/:documentInstanceId/:isFromMediaPage/:mediaType/:includeThisDoc?/:linkedByAnnotationGroup?",
        getReplaceLink(
            documentInstanceId: number,
            isFromMediaPage: boolean,
            mediaType: MediaType,
            includeThisDoc?: boolean,
            linkedByAnnotationGroup?: boolean
        ) {
            return generatePath(this.replace, {
                documentInstanceId,
                isFromMediaPage,
                mediaType,
                includeThisDoc,
                linkedByAnnotationGroup,
            });
        },
        shared: "/Media/Shared/:shareToken(.{36})",
        getSharedLink(builderId: string, shareToken?: string) {
            return generatePath(this.shared, { builderId, shareToken });
        },
    },
    photo: {
        preview: getPathForAllPortals("/Photo/Preview/:id/:jobId"),
        getPhotoPreviewLink(documentInstanceId: number, jobId: number) {
            return generatePath(this.preview, { id: documentInstanceId, jobId });
        },
        list: getPathForAllPortals("/Photos/:directoryType/:folderId"),
        getAdminSetupListLink() {
            return `/Photos/${DirectoryType.GlobalDocs}/0/`;
        },
        getListLink(
            directoryType: DirectoryType = DirectoryType.Standard,
            folderId: number = 0,
            selectedJobPickerId?: number
        ) {
            let path = addPathForCurrentPortal(
                generatePath(`/Photos/${DirectoryType[directoryType]}/:folderId`, {
                    directoryType,
                    folderId,
                })
            );
            if (selectedJobPickerId) {
                path = routes.jobPicker.selectJobLink(selectedJobPickerId, path);
            }
            return path;
        },
        draw: getPathForAllPortals(
            "/Photos/:documentInstanceId/:builderId/:loginType/:isOnNewEntity/:isOnTempFile"
        ),
        getPhotoDrawLink(
            documentInstanceId: number,
            builderId: number,
            loginType: BTLoginTypes,
            isOnNewEntity: boolean,
            isOnTempFile: boolean
        ) {
            return addPathForCurrentPortal(
                generatePath(this.draw, {
                    documentInstanceId,
                    builderId,
                    loginType,
                    isOnNewEntity,
                    isOnTempFile,
                })
            );
        },
    },
    video: {
        list: getPathForAllPortals("/Videos/:directoryType/:folderId"),
        getListLink(
            directoryType: DirectoryType = DirectoryType.Standard,
            folderId: number = 0,
            selectedJobPickerId?: number
        ) {
            let path = addPathForCurrentPortal(
                generatePath(`/Videos/${DirectoryType[directoryType]}/:folderId`, {
                    directoryType,
                    folderId,
                })
            );
            if (selectedJobPickerId) {
                path = routes.jobPicker.selectJobLink(selectedJobPickerId, path);
            }
            return path;
        },
        getAdminSetupListLink() {
            const path = `/Videos/${DirectoryType.GlobalDocs}/0/`;
            return routes.jobPicker.selectJobLink(JobIdTypes.GlobalDocs, path);
        },
    },
    estimate: {
        list: "/Estimate",
        getListLink(jobId?: number) {
            const url = addPathForCurrentPortal("/Estimate");
            if (jobId) {
                return routes.jobPicker.selectJobLink(jobId, url);
            }
            return url;
        },

        settings: "/EstimateSettings",
        getSettingsLink() {
            return this.settings;
        },
        estimateBidMapping: getPathForAllPortals("/Estimate/BidMapping/:jobId"),
        getBidsMappingLink(ids: number[], jobId: number) {
            const path = `${generatePath(this.estimateBidMapping, { jobId })}?ids=${ids.join(",")}`;
            return path;
        },
        lineItemDetail: getPathForAllPortals("/Estimate/:jobId/:lineItemId/:lineItemType"),
        getLineItemDetail(
            lineItemId: number,
            jobId: number,
            lineItemType: LineItemType,
            parentId?: number
        ) {
            if (parentId === undefined) {
                return generatePath(this.lineItemDetail, {
                    lineItemId,
                    jobId,
                    lineItemType,
                });
            } else {
                return `${generatePath(this.lineItemDetail, {
                    lineItemId,
                    jobId,
                    lineItemType,
                })}?parentId=${parentId}`;
            }
        },
        proposal: "/Proposal/:proposalId/:jobId",
        getProposalLink(jobId: number, proposalId: number) {
            return generatePath(this.proposal, { proposalId, jobId });
        },

        externalProposalPage: "/share/:shareToken(.{36})/Proposal",

        proposalPrint: getPathForBuilderOrOwnerPortals(
            "/Estimate/:worksheetId/Proposal/:proposalId/Print"
        ),
        getProposalPrintLink(worksheetId: number, proposalId?: number) {
            let path: string;
            if (proposalId) {
                path = generatePath(this.proposalPrint, { worksheetId, proposalId });
            } else {
                path = generatePath(this.legacyProposalPrint, { worksheetId });
            }

            return addPathForCurrentPortal(path);
        },
        LegacyGetOwnerProposalLink() {
            return addPathForCurrentPortal("/Proposal");
        },
        ownerEstimate: "/Owner/Estimate",
        ownerProposal: "/Owner/Estimate/:worksheetId/Proposal/:proposalId",
        legacyOwnerProposal: "/Owner/Proposal",
        getOwnerProposalMainNavLink(builderInfo?: BuilderInfo | null) {
            if (builderInfo?.flags.multipleIterativeProposals) {
                return this.ownerEstimate;
            }
            return this.legacyOwnerProposal;
        },
        getOwnerProposalLink(worksheetId: number, proposalId?: number) {
            let path: string;
            if (proposalId) {
                path = generatePath(this.ownerProposal, { worksheetId, proposalId });
            } else {
                path = this.legacyOwnerProposal;
            }

            return path;
        },

        legacyProposalPrint: getPathForBuilderOrOwnerPortals("/Proposal/Print/:worksheetId"),

        getExternalProposalPageLink(shareToken: string) {
            return generatePath(this.externalProposalPage, {
                shareToken,
            });
        },
        proposalPrintExternal: "/share/:shareToken(.{36})/Proposal/Print",
        getExternalProposalPrintLink(shareToken: string) {
            return generatePath(this.proposalPrintExternal, { shareToken: shareToken });
        },
        getProposalPDFGenerationURL(shareToken: string) {
            return generatePath(this.proposalPdfGenerationPage, { shareToken });
        },
        proposalPdfGenerationPage: "/share/:shareToken(.{36})/Proposal/ProposalPdfGeneration",
        estimateWebView: getPathForWebviewPage("/Estimate/:jobId"),
        addGroup: "/Group/:groupId",
        getAddGroup(groupId?: number) {
            return generatePath(this.addGroup, { groupId });
        },
    },
    rebates: {
        settings: "/RebateSettings",
        getSettingsLink() {
            return this.settings;
        },
        list: "/Rebates/:tab",
        getListLink(tab: string = RebateListTabKeys.Overview) {
            return addPathForCurrentPortal(generatePath("/Rebates/:tab", { tab }));
        },

        details: getPathForAllPortals("/Rebate/:id"),
        getDetailsPath(rebateId: number) {
            return generatePath("/Rebate/:id", {
                id: rebateId,
            });
        },
        getDetailsLink(rebateId: number) {
            return addPathForCurrentPortal(this.getDetailsPath(rebateId));
        },

        supplier: "/Supplier/:supplierId",
        getSupplierPath(supplierId: number) {
            return generatePath("/Supplier/:supplierId", { supplierId });
        },
        getSupplierLink(supplierId: number) {
            return addPathForCurrentPortal(this.getSupplierPath(supplierId));
        },

        term: "/Term/:termId",
        getTermPath(termId: number) {
            return generatePath("/Term/:termId", { termId });
        },
        getTermLink(termId: number) {
            return addPathForCurrentPortal(this.getTermPath(termId));
        },

        proofOfPurchaseDetails: getPathForAllPortals("/ProofOfPurchase/:id"),
        getProofOfPurchaseDetailsPath(proofOfPurchaseId: number) {
            return generatePath("/ProofOfPurchase/:id", { id: proofOfPurchaseId });
        },
        getProofOfPurchaseDetailsLink(proofOfPurchaseId: number) {
            return addPathForCurrentPortal(this.getProofOfPurchaseDetailsPath(proofOfPurchaseId));
        },
    },
    purchasing: {
        dashboard: "/Purchasing/:tabId",
        getDashboardLink(tab: string = PurchasingDashboardPages.Summary) {
            return addPathForCurrentPortal(generatePath("/Purchasing/:tab", { tab }));
        },
        getAllReceiptsLink() {
            return addPathForCurrentPortal(
                generatePath(
                    `/Purchasing/${PurchasingDashboardPages.Activity}/${PurchasingDashboardActivityPages.Receipts}`
                )
            );
        },
        getAllRebatesLink() {
            return `${this.getDashboardLink(PurchasingDashboardPages.Activity)}/${
                PurchasingDashboardActivityPages.Rebates
            }`;
        },
    },
    receipts: {
        details: getPathForAllPortals("/Receipt/:id"),
        getDetailsLink(id: number) {
            let path = this.getDetailsPath(id);
            return addPathForCurrentPortal(path);
        },
        getDetailsPath(id: number) {
            return generatePath(this.details, { id });
        },
        list: getPathForAllPortals("/Receipts"),
        getListLink() {
            return addPathForCurrentPortal("/Receipts");
        },
        upload: getPathForAllPortals("/Receipts/Upload"),
        getUploadLink() {
            return addPathForCurrentPortal("/Receipts/Upload");
        },
    },
    lienWaiver: {
        details: getPathForAllPortals("/LienWaiver/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            const path = `/LienWaiver/${id}/${jobId}`;
            return addPathForCurrentPortal(path);
        },
        detailsExternal: getPathForExternalSubsPage(
            "/LienWaiverExternal/:encodedLienWaiverId/:encodedSubId"
        ),
        getDetailsLinkExternal(
            builderId: string,
            encodedLienWaiverId: string,
            encodedSubId: string
        ) {
            return generatePath(this.detailsExternal, {
                builderId,
                encodedLienWaiverId,
                encodedSubId,
            });
        },
        print: getPathForAllPortals("/LienWaiver/Print"),
        getPrintLink(ids: number[]) {
            const path = `/LienWaiver/Print?popids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        getPrintExternalLink(builderId: string, ids: string) {
            let path = generatePath(this.printExternal, { builderId });
            path += `?popids=${ids}`;
            return path;
        },
        printExternal: getPathForAllPortals(getPathForExternalPrint("/LienWaiver/Print")),
    },
    customFields: {
        details: "/CustomFields/:associatedType/:customFieldId",
        getDetailsLink(associatedType: number, customFieldId: number) {
            return generatePath(this.details, { associatedType, customFieldId });
        },
    },
    creditMemos: {
        print: getPathForAllPortals("/CreditMemos/Print/:id/:jobId"),
        details: getPathForAllPortals("/CreditMemos/:id/:jobId"),
        getDetailsLink(id: number, jobId: number) {
            const path = generatePath("/CreditMemos/:id/:jobId", { id, jobId });
            return addPathForCurrentPortal(path);
        },
        getPrintLink(id: number, jobId: number) {
            const path = generatePath("/CreditMemos/Print/:id/:jobId", { id, jobId });
            return addPathForCurrentPortal(path);
        },
        list: getPathForAllPortals("/CreditMemos"),
        getListLink() {
            return addPathForCurrentPortal("/CreditMemos");
        },
    },
    invoicePayments: {
        list: getPathForAllPortals("/InvoicePayments"),
        getListLink() {
            return addPathForCurrentPortal("/InvoicePayments");
        },
    },
    trainingPlan: {
        list: "/TrainingPlan",
        details: "/TrainingPlanItem/:id",
        getListLink() {
            return this.list;
        },
        getItemDetailsLink(id: number) {
            const path = generatePath(this.details, { id });
            return addPathForCurrentPortal(path);
        },
    },
    marketplace: {
        integrationSettings: "/IntegrationsSettings/:type",
        getintegrationSettingsLink(type: number) {
            return generatePath(this.integrationSettings, { type });
        },

        getMarketplaceLink: () => {
            return "https://buildertrend.com/marketplace/";
        },
        getIntegrationLink(integration: ExternalIntegrationTypes) {
            return `https://buildertrend.com/integration/${integration}`;
        },
        getPaymentsLink() {
            return `https://buildertrend.com/payments-for-customers/`;
        },
        getRedirectPath: () => addPathForCurrentPortal(marketplaceInternal.redirect),
        reconsent: "/Reconsent",
        getReconsentLink: () => {
            addPathForCurrentPortal("/Reconsent");
        },
    },
    additionalServices: {
        getAdditionalServicesLink() {
            return "https://buildertrend.com/additional-customer-services/";
        },
    },
    category: {
        details: "/category/:catId/:categoryType/:allowDeleteExistingRefs?",
        /**
         * This is not generating a link - this is generating a route with an explicit category type
         * to allow multiple category type modals to exist on the same page without interfering with
         * each other.
         */
        getDetailsRoute: (categoryType: CategoryType) => {
            return `/category/:catId/${categoryType}/:allowDeleteExistingRefs?`;
        },
        getCategoryLink(catId: number, categoryType: CategoryType) {
            return generatePath(this.details, { catId, categoryType });
        },
    },
    multiFactorAuth: {
        multiFactorAuth: "/MultiFactorAuth/:credentialId",
        getMultiFactorAuthLink(credentialId: number) {
            return generatePath(this.multiFactorAuth, { credentialId });
        },
    },
    settings: {
        settings: "/Settings",
        getSettingsLink() {
            return this.settings;
        },
    },
    sub: {
        paymentRequest: getPathForAllPortals("/PaymentRequest/:poId/:popId"),
        getPaymentRequestLink(poId: number, popId: number) {
            return generatePath(this.paymentRequest, { poId, popId });
        },
        settings: "/SubSettings",
        getSettingsLink() {
            return this.settings;
        },
        division: "/SubContractor/Division",
        getDivisionLink(ids: number[]) {
            const path = `/SubContractor/Division?subids=${ids.join(",")}`;
            return addPathForCurrentPortal(path);
        },
        details: "/SubVendor/:id/:defaultTab",
        getDetailsLink(id: number, defaultTab: SubTabKeys = SubTabKeys.AdditionalInfo) {
            return generatePath(this.details, { id, defaultTab });
        },
        setup: "/subs/Setup/:id/:initialTab/:openTradeAgreement",
        getSetupLink(id: number, initialTab?: SubSetupTabKeys, openTradeAgreement?: boolean) {
            const openTrade = openTradeAgreement ?? false;
            const initTab = initialTab ?? SubSetupTabKeys.CompanyInfo;
            const path = generatePath(this.setup, {
                id,
                initialTab: initTab,
                openTradeAgreement: openTrade,
            });
            return path;
        },
        list: getPathForAllPortals("/Sub"),
        getListLink() {
            return addPathForCurrentPortal("/Sub");
        },
    },
    owner: {
        setup: "/Owner/Setup/:jobId",
        getSetupLink(jobId: number) {
            const path = generatePath(this.setup, { jobId });
            return path;
        },
        externalIdPaymentMethodApprove: "/Owner/Setup/:jobId/:externalId",
    },
    schedule: {
        phaseDetails: getPathForAllPortals("/PhaseDetails/:id"),
        getPhaseDetailsLink(id: number) {
            return generatePath(this.phaseDetails, { id });
        },
        settings: "/ScheduleSettings",
        getSettingsLink() {
            return this.settings;
        },
        details: getPathForAllPortals("/Schedule/:id/:jobId"),
        getDetailsLink(
            id: number,
            jobId: number,
            initialTab?: ScheduleTabNames,
            linkedPredId?: number,
            startDate?: moment.Moment,
            phaseId?: number,
            endDate?: moment.Moment
        ) {
            let path = generatePath(this.details, { id, jobId });

            const qp = new URLSearchParams();
            if (initialTab) {
                qp.set("initialTab", initialTab.toString());
            }
            if (linkedPredId) {
                qp.set("linkedPredId", linkedPredId.toString());
            }
            if (startDate) {
                qp.set("startDate", startDate.toString());
            }
            if (endDate) {
                qp.set("endDate", endDate.toString());
            }
            if (phaseId) {
                qp.set("phaseId", phaseId.toString());
            }
            if (qp.toString().length > 0) {
                path = `${path}?${qp.toString()}`;
            }

            return addPathForCurrentPortal(path);
        },
        getFullDetailsLink(params: {
            tab: ScheduleListContainerTabs;
            id: number;
            jobId: number;
            initialTab?: ScheduleTabNames;
            linkedPredId?: number;
            startDate?: moment.Moment;
            phaseId?: number;
            endDate?: moment.Moment;
        }) {
            return (
                this.getListLink(params.tab) +
                this.getDetailsLink(
                    params.id,
                    params.jobId,
                    params.initialTab,
                    params.linkedPredId,
                    params.startDate,
                    params.phaseId,
                    params.endDate
                )
            );
        },
        getDefaultParamDetailsLink(
            id: number,
            jobId?: number,
            defaultParams?: ScheduleEntityDefaultParams
        ) {
            let path = generatePath(this.details, { id, jobId });
            if (defaultParams) {
                const { title, assignedUserIds, startDate, endDate, isAllDay } = defaultParams;
                let displayColor = defaultParams.displayColor;

                if (startDate?.isValid && endDate?.isValid) {
                    // Can't pass the '#' character to a URL. Need to covert it using URL encoding.
                    if (displayColor !== null) {
                        displayColor = encodeURIComponent(`${displayColor}`);
                    } else {
                        displayColor = "";
                    }
                    path += `?title=${title}&displayColor=${displayColor}&assignedUserIds=${assignedUserIds}&startDate=${encodeURIComponent(
                        startDate.toISOString()
                    )}&endDate=${encodeURIComponent(endDate.toISOString())}&isAllDay=${isAllDay}`;
                }
            }
            return addPathForCurrentPortal(path);
        },
        // Parses a path even if it's appended to the end of another path.
        parseDetailsPath(pathname: string) {
            const result = matchPath<{ tab: string; id: string; jobId: string }>(pathname, {
                path: ["**/Schedule/:id/:jobId"],
                exact: false,
                strict: false,
            });
            if (!result) return null;
            return {
                id: parseInt(result.params.id),
                jobId: parseInt(result.params.jobId),
                entityType: EntityTypes.Schedule,
            };
        },
        rfiDetails: getPathForAllPortals("/ScheduleRFI/:id/:jobId"),
        getRfiDetailsLink(id: number, jobId: number) {
            const path = generatePath("/ScheduleRFI/:id/:jobId", { id, jobId });
            return addPathForCurrentPortal(path);
        },
        copyDetails: "/ScheduleCopy/:id/:jobId",
        getCopyDetailsLink(id: number, jobId: number) {
            return generatePath(this.copyDetails, { id, jobId });
        },
        conflictReport: "/ScheduleConflictReport/:view/:id?",
        getConflictReportLink(view: ScheduleConflictViews, id?: number) {
            return generatePath(this.conflictReport, { view, id });
        },
        notificationPrompt: "/ScheduleNotificationPromot/:scheduleIds",
        getNotificationPromptLink(scheduleIds: number[]) {
            return generatePath(this.notificationPrompt, { scheduleIds: scheduleIds.join(",") });
        },
        baselineList: getPathForAllPortals("/Schedules/8"),
        list: getPathForAllPortals("/Schedules/:tab"),
        getListLink(tab: ScheduleListContainerTabs) {
            return addPathForCurrentPortal(generatePath("/Schedules/:tab", { tab: tab }));
        },
        workdayExceptionDetails: "/ScheduleWorkdayExceptions/:id/:jobId",
        getWorkdayDetailsLink(id: number, jobId: number) {
            return generatePath(this.workdayExceptionDetails, { id, jobId });
        },
        confirmationExternal: getPathForExternalScheduleConfirmationPage(
            "/Schedules/External/:userId/:shareToken"
        ),
        getConfirmationExternalLink(builderId: string, userId: string, shareToken: string) {
            return generatePath(this.confirmationExternal, { builderId, userId, shareToken });
        },
    },
    timeClock: {
        settings: "/TimeClockSettings",
        getSettingsLink() {
            return this.settings;
        },
        shiftDetails: "/Shift/:id/:jobId?/:tab",
        getShiftDetailsLink(id: number, jobId?: number, tab?: ShiftDetailsTab) {
            const selectedTab = tab ?? ShiftDetailsTab.General;
            return generatePath(this.shiftDetails, {
                id,
                jobId,
                tab: ShiftDetailsTab[selectedTab],
            });
        },
        shiftDetailsCostCodeConflict:
            "/Shift/:id/:jobId/:tab/CostCode/Code/:costType/:costCodeId/Conflict",
        getShiftDetailsCostCodeConflictLink(
            id: number,
            jobId: number,
            costCodeId: number,
            costType: CostType,
            initialTab?: number
        ) {
            const tab = initialTab ?? 1;
            const path = generatePath(this.shiftDetailsCostCodeConflict, {
                id,
                jobId,
                tab,
                costType,
                costCodeId,
            });
            return addPathForCurrentPortal(path);
        },
        clockInClockOut: "/ClockInClockOut/:id/:jobId/:isClockIn/:isMultiple",
        getClockInClockOutLink(id: number, jobId: number, isClockIn: boolean, isMultiple: boolean) {
            const path = generatePath(this.clockInClockOut, {
                id,
                jobId,
                isClockIn,
                isMultiple,
            });
            return addPathForCurrentPortal(path);
        },
        list: getPathForAllPortals("/TimeClock/:tab"),

        getListLink(tab: ShiftListContainerTabs = ShiftListContainerTabs.Reports): string {
            return addPathForCurrentPortal(
                generatePath(`/TimeClock/${ShiftListContainerTabs[tab]}`, { tab })
            );
        },
    },
    notifications: {
        details: "/NotificationDetails/:notificationId/:jobsiteId/:dateSent/:isArchive",
        getDetailsLink(
            notificationId: number,
            jobsiteId: number,
            dateSent: moment.Moment,
            isArchive: boolean
        ) {
            return generatePath(this.details, {
                notificationId,
                jobsiteId,
                dateSent: dateSent.utc().format(),
                isArchive,
            });
        },
        list: getPathForAllPortals("/Notifications"),
        getListLink() {
            return addPathForCurrentPortal("/Notifications");
        },
        legacyList: getPathForAllPortals("/NotificationHistory"),
        getLegacyListLink() {
            return addPathForCurrentPortal("/NotificationHistory");
        },
        preferences: "/NotificationPreferences/:notificationId/:builderId",
        getPreferencesLink(notificationId: number, builderId: number) {
            return generatePath(this.preferences, {
                notificationId,
                builderId,
            });
        },
        getUserPreferencesLink: (userId: number, loginType: BTLoginTypes) => {
            if (loginType === BTLoginTypes.BUILDER) {
                return routes.internalUsers.getInternalUserDetailsLink(
                    userId,
                    InternalUserTabKeys.Notifications
                );
            } else if (loginType === BTLoginTypes.SUBS) {
                return routes.sub.getSetupLink(userId, SubSetupTabKeys.Notifications);
            } else if (loginType === BTLoginTypes.OWNER || loginType === BTLoginTypes.CONTACT) {
                return routes.owner.getSetupLink(userId);
            }
            throw new Error("not implemented");
        },
    },
    messages: {
        oldList: getPathForAllPortals("/Messages"),
        list: getPathForAllPortals("/Messages/:folder"),
        getListLink(
            folderId: EmailFolder | number = EmailFolder.Inbox,
            systemFilterType?: SystemFilterType
        ) {
            let folder: string | number = folderId;
            if (folderId < 0) {
                folder = EmailFolder[folder];
            }

            const path = generatePath(this.list, { folder });
            let url = addPathForCurrentPortal(path);
            if (systemFilterType) {
                url = addQueryParams(url, { filter: systemFilterType.toString() });
            }
            return url;
        },
        print: getPathForAllPortals("/Message/Print/:messageId/:folderId"),
        getPrintLink(messageId: number, folderId: number) {
            return addPathForCurrentPortal(generatePath(this.print, { messageId, folderId }));
        },
        details: getPathForAllPortals("/Message/:folder/:messageId"),
        getMessageDetailsLink(messageId: number, folderId?: number) {
            let folder: string | number | undefined = folderId;
            if (folderId && folderId < 0 && folderId in EmailFolder) {
                folder = EmailFolder[folderId];
            } else if (folder === undefined) {
                folder = EmailFolder[EmailFolder.Inbox];
            }

            const path = generatePath(this.details, { folder, messageId });
            return addPathForCurrentPortal(path);
        },
        detailsExternal: getPathForAllPortals("/External/Message/:shareToken"),
        getMessageDetailsExternalLink(shareToken: string) {
            return generatePath(this.detailsExternal, { shareToken });
        },
        moveMessage: "/Messages/Move",
        getMoveMessageLink() {
            return this.moveMessage;
        },

        compose: getPathForAllPortals("/Compose/:messageId/:jobId"),
        /**
         * Gets the link to a new message editor
         *
         * @param messageId - The message id to be edited (0 if editing new message instead of a draft)
         * @param jobId - The current jobId (0 if no Job has been selected yet)
         * @param optionalQsParams - Additional optional link params
         */
        getComposeLink(
            messageId: number,
            jobId: number,
            optionalQsParams?: {
                /** The reply type of the new message */
                replyType?: ReplyType;
                /** The ID of the message being replied to (0 if no reply) */
                replyId?: number;
                /** The ID of the recipient to whom you are sending the message */
                defaultRecipientId?: number;
                /** The subject of the message */
                defaultSubject?: string;
                /** The document instance ids that are attached to the message */
                attachedDocumentIds?: number[];
                /** Special case for mailing ownerProfile photo, which is currently not a doc inst */
                attachOwnerProfile?: boolean;
            }
        ) {
            let path = generatePath(this.compose, { messageId, jobId });
            return stringifyUrl({
                url: addPathForCurrentPortal(path),
                query: {
                    replyType: optionalQsParams?.replyType?.toString(),
                    replyId: optionalQsParams?.replyId?.toString(),
                    defaultRecipientId: optionalQsParams?.defaultRecipientId?.toString(),
                    defaultSubject: optionalQsParams?.defaultSubject,
                    docInstanceIds: optionalQsParams?.attachedDocumentIds?.join(","),
                    attachOwnerProfile: optionalQsParams?.attachOwnerProfile?.toString(),
                },
            });
        },
    },
    mediaFolderList: {
        getFolderList(folderId: number, jobId: number, mediaType: MediaType) {
            let location = "";
            const directory = jobId === 0 ? DirectoryType.GlobalDocs : DirectoryType.Standard;
            switch (mediaType) {
                case MediaType.Document:
                    location = routes.document.getListLink(directory, folderId, jobId);
                    break;
                case MediaType.Photo:
                    location = routes.photo.getListLink(directory, folderId, jobId);
                    break;
                case MediaType.Video:
                    location = routes.video.getListLink(directory, folderId, jobId);
                    break;
            }
            return location;
        },
    },
    featureFlags: {
        list: "/Admin/FeatureFlags",
        details: "/Admin/FeatureFlag/Details/:id",
        getDetailsLink(id: number) {
            return generatePath(this.details, { id });
        },
    },
    search: {
        btAdminUtility: "/Admin/SearchUtils",
    },
    partners: {
        list: "/Admin/Partners",
        details: "/Admin/Partner/Details/:id",
        getDetailsLink(id: number) {
            return generatePath(this.details, { id });
        },
    },
    subscriptionItemSetup: {
        details: "/Admin/SubscriptionItemSetupUtility",
        getDetailsLink() {
            return this.details;
        },
    },
    subscriptionSignUpCodes: {
        list: "/Admin/SubscriptionSignUpCodes",
        details: "/Admin/SubscriptionSignUpCode/Details/:id",
        getDetailsLink(id: number) {
            return generatePath(this.details, { id });
        },
    },
    cancellation: {
        cancelBuilder: "/Admin/Cancel/:builderId/:subscriptionId",
        getCancellationLink(builderId: number, subscriptionId: string) {
            return generatePath(this.cancelBuilder, { builderId, subscriptionId });
        },
    },
    suspension: {
        suspendBuilder: "/Admin/Suspend/:builderId/:adminId/:editSuspension",
        getSuspensionLink(builderId: number, adminId: number, editSuspension: boolean) {
            return generatePath(this.suspendBuilder, { builderId, adminId, editSuspension });
        },
    },
    accountChange: {
        adminAccountChangeRequest: "/Admin/AccountChangeRequest/:builderId/:adminId",
        getAdminAccountChangeRequestLink(builderId: number, adminId: number) {
            return generatePath(this.adminAccountChangeRequest, { builderId, adminId });
        },
        builderAccountChangeRequest: "/BuilderAccountChangeRequest/:builderId/:linkId",
        getBuilderAccountChangeRequestLink(builderId: number, linkId: string) {
            return generatePath(this.builderAccountChangeRequest, { builderId, linkId });
        },
    },
    rebateReceiptReview: {
        queues: "/Admin/RebateHawk/Queues",
        queue: "/Admin/RebateHawk/Queue/:queueId",
        getQueueLink(queueId: number) {
            return generatePath(this.queue, { queueId });
        },
        queueReceiptDetail: "/Admin/RebateHawk/Queue/:queueId/Receipt/:receiptId",
        getQueueReceiptDetailLink(queueId: number, receiptId: number) {
            return generatePath(this.queueReceiptDetail, { queueId, receiptId });
        },
        list: "/Admin/RebateHawk/Receipts",
        receiptDetail: "/Admin/RebateHawk/Receipts/:receiptId",
        getReceiptDetailLink(receiptId: number) {
            return generatePath(this.receiptDetail, { receiptId });
        },
    },
    login: {
        list: "/Login",
        usernameEntry: "/Login/Username",
        passwordEntry: "/Login/Password",
        getUsernameLink() {
            return this.usernameEntry;
        },
        getListLink() {
            return this.list;
        },
        getPasswordEntryLink() {
            return this.passwordEntry;
        },
    },
    accounting: {
        settings: "/Settings/Accounting",
        getSettingsLink() {
            return this.settings;
        },
        linkJobToAccounting: {
            wizard: "/LinkJobToAccounting/:jobID",
            getWizardLink(
                jobID: number,
                supportsJobsiteIntegrationTypes: boolean,
                ownerInvoiceID?: number
            ) {
                let path =
                    generatePath(this.wizard, { jobID }) +
                    `?supportsJobsiteIntegrationTypes=${supportsJobsiteIntegrationTypes}`;

                if (ownerInvoiceID) {
                    path += `&ownerInvoiceID=${ownerInvoiceID}`;
                }

                return path;
            },
        },
        linkEntityToAccounting: {
            details: "/LinkEntityToAccounting/:idType/:entityID?",
            getDetailsLink(idType: IDTypes, entityID?: number) {
                return generatePath(this.details, { idType, entityID });
            },
        },
        otherAccountingCost: {
            details: "/OtherAccountingCost/:id/:transactionId?",
            getDetailsLink(otherAccountingCostId: number, transactionId?: string) {
                return generatePath(this.details, {
                    id: otherAccountingCostId,
                    transactionId: transactionId,
                });
            },
        },
        oAuthRedirect: "/Settings/Accounting/OAuthRedirect",
        quickBooksSyncSchedule: "/QuickBooksSyncSchedule",
        massImportFromAccounting: {
            path: "/MassImportFromAccounting",
            getMassImportFromAccountingLink() {
                return addPathForCurrentPortal(this.path);
            },
        },
    },
    payroll: {
        settings: "/PayrollSettings/:success?/:error?",
        getSettingsLink(success?: string, error?: string) {
            return generatePath(this.settings, { success, error });
        },
    },
    oauth: {
        authRedirect: "/OAuth/AuthRedirect/:code?/:state?",
        getAuthRedirectLink(code: string, state: string) {
            return generatePath(this.authRedirect, { code, state });
        },
    },
    signatureRequest: {
        details: "/SignatureRequest/:id/:jobId",
        getDetailsLink(id: number, jobId: number) {
            return generatePath(this.details, { id, jobId });
        },
        create: "/CreateSignatureRequest/:documentInstanceId/:jobId",
        getCreateLink(documentInstanceId: number, jobId: number) {
            return generatePath(this.create, { documentInstanceId, jobId });
        },
        remove: "/RemoveSignatureRequest/:removeAll",
        getRemoveLink(removeAll: boolean) {
            return generatePath(this.remove, { removeAll });
        },
        sign: "/SignatureSigning/:documentInstanceId/:loginType/:builderId",
        getSignLink(documentInstanceId: number, loginType: BTLoginTypes, builderId: number) {
            return generatePath(this.sign, { documentInstanceId, loginType, builderId });
        },
        standaloneDetails: "/SignatureRequest/:id/:jobId/:docInstanceId",
        getStandaloneDetailsLink(id: number, jobId: number, docInstanceId: number) {
            return generatePath(this.standaloneDetails, { id, jobId, docInstanceId });
        },
    },
    addPerformingUsersToJob: {
        path: "/AddPerformingUsersToJob",
        getPath(
            jobId: number,
            openedFromEntity: OpenedFromEntity,
            scheduleIds: number[],
            canSelectUsers: boolean
        ) {
            return generatePath(this.path, {
                jobId,
                openedFromEntity,
                scheduleIds: scheduleIds.join(","),
                canSelectUsers,
            });
        },
    },
    lineItemsToInvoice: {
        path: "/LineItemsToInvoice",
    },
    existingOwnerInvoiceSelect: {
        path: "/ExistingOwnerInvoiceSelect",
    },
    addFromLineItemsToInvoice: {
        path: "/addFromLineItemsToInvoice/:lineItemType/:entityIds?",
        getLink(lineItemType: LineItemType, entityIds?: number[]) {
            return generatePath(this.path, { lineItemType, entityIds: entityIds?.join(",") });
        },
    },
    poMapping: {
        path: "/POMapping",
        getUrlWithQueryString(
            url: string,
            ids: number[],
            jobId: number,
            toExistingPo: boolean,
            isProposed?: boolean
        ) {
            // Using query strings is not preferred and can mess with routing. In this case,
            // it's necessary because selecting a large number of selections will quickly
            // exceed the 2000 character url limit set in place by our .net routing
            return queryString.stringifyUrl({
                url,
                query: {
                    ids: ids.join(),
                    jobId: jobId.toString(),
                    toExistingPo: toExistingPo.toString(),
                    isProposed: isProposed?.toString(),
                },
            });
        },

        getChangeOrderPath() {
            return `${this.path}/ChangeOrder`;
        },
        getChangeOrderLink(changeOrderIds: number[], jobId: number, toExistingPo: boolean) {
            return this.getUrlWithQueryString(
                this.getChangeOrderPath(),
                changeOrderIds,
                jobId,
                toExistingPo
            );
        },

        getBidPath() {
            return `${this.path}/Bid`;
        },
        getBidLink(bidPackageIds: number[], jobId: number, toExistingPo: boolean) {
            return this.getUrlWithQueryString(
                this.getBidPath(),
                bidPackageIds,
                jobId,
                toExistingPo
            );
        },

        getEstimatePath() {
            return `${this.path}/Estimate`;
        },
        getEstimateLink(estimateIds: number[], jobId: number, toExistingPo: boolean) {
            return this.getUrlWithQueryString(
                this.getEstimatePath(),
                estimateIds,
                jobId,
                toExistingPo
            );
        },

        getSelectionPath() {
            return `${this.path}/Selection`;
        },
        getSelectionLink(selectionIds: number[], jobId: number, toExistingPo: boolean) {
            return this.getUrlWithQueryString(
                this.getSelectionPath(),
                selectionIds,
                jobId,
                toExistingPo
            );
        },

        getSelectionChoicePath() {
            return `${this.path}/SelectionChoice`;
        },
        getSelectionChoiceLink(selectionChoiceIds: number[], jobId: number, toExistingPo: boolean) {
            return this.getUrlWithQueryString(
                this.getSelectionChoicePath(),
                selectionChoiceIds,
                jobId,
                toExistingPo
            );
        },
    },
    signupForm: {
        signup: "/Signups/:code/:autoCode?/:salesforceAccountID?/:salesforceOpportunityID?/:rewinBuilderID?/:sfRewinContactLoginID?/:testExperience?",
        getExternalLink(
            code: string,
            autoCode?: string,
            salesforceAccountID?: string,
            salesforceOpportunityID?: string,
            rewinBuilderID?: string,
            sfRewinContactLoginID?: string,
            testExperience?: string
        ) {
            return generatePath(this.signup, {
                code,
                autoCode,
                salesforceAccountID,
                salesforceOpportunityID,
                rewinBuilderID,
                sfRewinContactLoginID,
                testExperience,
            });
        },
    },
    signupPriceCodePage: {
        signup: "/Signups",
        getSignupLink() {
            return generatePath(this.signup);
        },
    },
    signupWhatsNext: {
        whatsNext: "/Signups/WhatsNext/:builderId/:fromEmail/:isRewin/:loginId?",
        getWhatsNextLink(
            builderId: string,
            fromEmail: boolean,
            isRewin: boolean,
            loginId?: string
        ) {
            return generatePath(this.whatsNext, { builderId, fromEmail, isRewin, loginId });
        },
    },

    excludeFromChurn: {
        excludeFromChurn: "/Admin/ExcludeFromChurn/:builderId",
        getExcludeFromChurnLink(builderId: number) {
            return generatePath(this.excludeFromChurn, { builderId });
        },
    },
    errorPage: {
        noAccess: "/Error/NoAccess/:reason",
        getNoAccessLink(reason: ForbiddenReason) {
            return generatePath(this.noAccess, { reason });
        },
        legacyWebformsServerError: "/Error/Server",
    },
    ownerPortalRedirect: "/ownerPortalRedirect/:jobId/:editPermissions",
    getOwnerPortalRedirectLink(jobId: number, editPermissions: boolean = false) {
        return generatePath(this.ownerPortalRedirect, { jobId, editPermissions });
    },
    list: {
        selectedEntityCopy: "/Copy/:ids",
        getCopyLink(ids: number[]) {
            return generatePath(this.selectedEntityCopy, { ids: ids.join(",") });
        },
    },
    ownerSummary: {
        path: "/Owner/Summary", // todo combine with builder landing
        getLink() {
            return this.path;
        },
    },
    vendor: {
        homeDepotSettings: "/TheHomeDepotSettings",
        getHomeDepotSettingsLink() {
            return this.homeDepotSettings;
        },
        vendorStoreFinder: "/VendorStoreFinder/:vendorType",
        getVendorStoreFinderLink(vendorType: VendorTypes) {
            return generatePath("/VendorStoreFinder/:vendorType", { vendorType });
        },
        vendorReceipt: getPathForExternalPrint("/VendorReceipt/:vendorType/:vendorPurchaseId"),
    },
    tradeAgreement: {
        subTradeAgreement: getPathForBuilderOrSubPortals("/TradeAgreement/:builderId/:subId"),
        getSubTradeAgreementLink(builderId: number, subId: number) {
            return generatePath("/TradeAgreement/:builderId/:subId", { builderId, subId });
        },
        print: getPathForAllPortals("/TradeAgreement/Print/:tradeAgreementId"),
        getSubTradeAgreementPrintLink(tradeAgreementId: number) {
            return addPathForCurrentPortal(
                generatePath("/TradeAgreement/Print/:tradeAgreementId", { tradeAgreementId })
            );
        },
    },
    landingPage: {
        path: "/Landing",
        getLink() {
            return this.path;
        },
        getLinkForLoginType(loginType: BTLoginTypes, includeBaseRoute: boolean) {
            let url = "";
            if (loginType === BTLoginTypes.BUILDER) {
                url = this.path;
            } else if (loginType === BTLoginTypes.SUBS) {
                url = routesWebforms.Summary.GetSubSummaryUrl();
            } else if (loginType === BTLoginTypes.OWNER || loginType === BTLoginTypes.CONTACT) {
                url = routes.ownerSummary.getLink();
            }

            if (includeBaseRoute && loginType !== BTLoginTypes.SUBS) {
                url = getBaseRoute() + url;
            }
            return url;
        },
    },
    tax: {
        settings: "/TaxesSettings",
        getSettingsLink() {
            return this.settings;
        },
        taxRateDetails: "/Tax/TaxRate/:taxRateId",
        getTaxRateDetailsLink(taxRateId: number) {
            return generatePath(this.taxRateDetails, { taxRateId });
        },
        taxGroupDetails: "/Tax/TaxGroup/:taxGroupId",
        getTaxGroupDetailsLink(taxGroupId: number) {
            return generatePath(this.taxGroupDetails, { taxGroupId });
        },
    },
    share: {
        path: "/share/:shareToken",
        getLink(shareToken: string) {
            return generatePath(this.path, { shareToken });
        },
    },
    assembly: {
        details: "/assembly/:assemblyId",
        getAssemblyDetails(assemblyId: number) {
            return generatePath(this.details, { assemblyId });
        },
    },
    workdayException: {
        details: "/WorkdayException/:workdayExceptionID/:jobID",
        bdsDetails: "/:orgId/WorkdayException/:workdayExceptionID/:jobID/:isOffline",
        getWorkdayExceptionDetailsLink(workdayExceptionID: number, jobID: number) {
            return generatePath(this.details, { workdayExceptionID, jobID });
        },
        getBdsWorkdayExceptionDetailsLink(
            orgId: number,
            workdayExceptionID: number,
            jobID: number,
            isOffline: boolean
        ) {
            return generatePath(this.bdsDetails, { orgId, workdayExceptionID, jobID, isOffline });
        },
    },
    sentry: {
        adminLogExceptionInSentry: "/Admin/LogExceptionInSentry",
    },
    help: {
        giveFeedback: "/Help/Givefeedback",
        getHelpCenterUrl: (type: HelpCenterTypes) => {
            if (type === HelpCenterTypes.Leads) {
                return "https://helpcenter.buildertrend.net/en/articles/9213302-lead-opportunities-overview";
            }
            return "https://helpcenter.buildertrend.net";
        },
    },
    activityFeed: {
        path: "/ActivityFeed",
    },
    notConnected: {
        path: "/Owner/AccountNotConnected/:fromLoginPage",
        getLink(fromLoginPage: boolean) {
            return generatePath(this.path, { fromLoginPage });
        },
    },
    entityLink: {
        path: "/link/:externalId",
        getLink(externalId: string) {
            return generatePath(this.path, { externalId });
        },
        getFullLink(externalId: string) {
            return `${window.location.origin}/app${this.getLink(externalId)}`;
        },
        // Used to test if pasted text is a /share link. We want to be as strict as possible with
        // this filtering to prevent a non-BT share link from showing as a rich embed in the editor.
        parsePath: (pathname: string) => {
            const result = matchPath<{ externalId: string }>(pathname, {
                path: ["**/link/:externalId"],
                exact: false,
                strict: false,
            });
            if (!result?.params.externalId) return null;
            const externalIdRegex = /[a-fA-F0-9]{8}(-[a-fA-F0-9]{4}){3}-[a-fA-F0-9]{12}/g;
            if (!externalIdRegex.test(result.params.externalId)) return null;
            return {
                externalId: result.params.externalId,
            };
        },
    },
    passwordReset: {
        path: "/PasswordReset/",
    },
    jobCostingBudget: {
        path: getPathForAllPortals("/JobCostingBudget"),
        getLink: () => addPathForCurrentPortal("/JobCostingBudget"),
    },
};
