import { EnvironmentInfo } from "helpers/AppProvider.types";

export function GetEphDaysRemaining(environmentInfo?: EnvironmentInfo): number | undefined {
    if (environmentInfo?.name.startsWith("Ephemeral") && environmentInfo.infraCreationDate) {
        return daysRemaining(environmentInfo.infraCreationDate);
    }

    return undefined;
}

function daysRemaining(startDate: Date): number {
    const currentDate = new Date();
    const difference = currentDate.getTime() - startDate.getTime();
    const remainingDays = Math.max(0, 10 - Math.floor(difference / (1000 * 60 * 60 * 24)));

    return remainingDays;
}
