import { DatePicker } from "antd";
import moment from "moment";

import { ITrackingProp, track } from "utilities/analytics/analytics";
import { getClosestModal } from "utilities/helpers";

import { ValueDisplay } from "commonComponents/utilities/ValueDisplay/ValueDisplay";

interface IBTYearPickerProps {
    id: string;
    "data-testid": string;
    readOnly?: boolean;
    value?: moment.Moment | null;
    readonly?: boolean;
    disabled?: boolean;
    className?: string;
    /** pass formik setFieldValue */
    onChange: (field: string, value?: moment.Moment) => void;
}
export const BTYearPicker = track((props) => ({
    element: "BTYearPicker",
    uniqueId: props["data-testid"],
}))(function ({
    value,
    onChange,
    id,
    readOnly,
    "data-testid": testid,
    ...otherprops
}: IBTYearPickerProps & ITrackingProp) {
    const onValueChange = (date: moment.Moment | null) => {
        const dateNullOrUndefined = date ?? undefined;
        onChange(id, dateNullOrUndefined);
    };

    if (readOnly) {
        return <ValueDisplay id={id} data-testid={testid} value={value?.format("YYYY")} />;
    }
    return (
        // eslint-disable-next-line react/forbid-elements
        <DatePicker.YearPicker
            value={value}
            onChange={onValueChange}
            getPopupContainer={getClosestModal}
            allowClear={false}
            format="YYYY"
            placeholder=""
            {...otherprops}
        />
    );
});
