import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import { BdsButton } from "@buildertrend/components";

import { routes } from "utilities/routes";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTDropdown } from "commonComponents/btWrappers/BTDropdown/BTDropdown";
import {
    BTIconCheckOutlined,
    BTIconEllipsisOutlined,
    BTIconExternalLink,
    BTIconNotifications,
    BTIconSlidersHorizontal,
} from "commonComponents/btWrappers/BTIcon";
import { BTLink } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTMenu } from "commonComponents/btWrappers/BTMenu/BTMenu";
import { BTMenuItem } from "commonComponents/btWrappers/BTMenu/BTMenuItem";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSwitch } from "commonComponents/btWrappers/BTSwitch/BTSwitch";
import { BTTitle } from "commonComponents/btWrappers/BTTitle/BTTitle";

import { NotificationSummaryItem } from "handlers";

import { NotificationCard } from "entity/notification/NotificationCard/NotificationCard";
import { NotificationItem } from "entity/notification/NotificationPanel/NotificationPanel.api.types";
import { NotificationPanelEmptyState } from "entity/notification/NotificationPanel/NotificationPanelEmptyState/NotificationPanelEmptyState";
import NotificationCardSkeleton from "entity/notification/NotifticationPanelV2/NotificationCardSkeleton";

import "./NotificationPanelV2.less";

interface INotificationPanelPresentationalProps {
    hasNotifications: boolean;
    items: NotificationSummaryItem[];
    hasMoreItems: boolean;
    isLoading: boolean;
    onlyShowUnread: boolean;
    onToggleUnread: (checked: boolean) => void;
    onMarkRead: (notificationId: number, markRead: boolean) => Promise<void>;
    onMarkAllRead: () => Promise<void>;
    onOpenPrefs: () => void;
    onOpenNotificationList: () => void;
}

function NotificationPanelPresentational(props: INotificationPanelPresentationalProps) {
    const NotificationPanelRef = useRef(null);
    const [openMenuId, setOpenMenuId] = useState(0);
    const [skeletonCount, setSkeletonCount] = useState<number>(3);

    const handleMenuOpen = (id: number) => {
        setOpenMenuId(id);
    };

    const handleMarkRead = async (notificationId: number, markRead: boolean) => {
        setOpenMenuId(0);
        await props.onMarkRead(notificationId, markRead);
    };

    useEffect(() => {
        if (props.items.length > 0) {
            setSkeletonCount(props.items.length);
        }
    }, [props.items]);

    return (
        <div id="NotificationPanelV2" ref={NotificationPanelRef}>
            <BTRow
                id="NotificationPanelContent"
                data-testid="NotificationPanelContent"
                className="NotificationPanelContent"
            >
                <BTCol xs={24}>
                    <>
                        <div className="Header">
                            <BTTitle level={2}>Notifications</BTTitle>
                            <div className="Options">
                                <BTSwitch
                                    id="chkOnlyShowUnread"
                                    data-testid="chkOnlyShowUnread"
                                    checked={props.onlyShowUnread}
                                    onCheckedChange={(_, value) => props.onToggleUnread(value)}
                                    label="Only show unread"
                                    size="small"
                                    className="margin-right-sm"
                                />
                                <BTPopover
                                    zIndex={100}
                                    content="View all notifications"
                                    placement="bottom"
                                    className="margin-right-sm"
                                >
                                    <BTLink
                                        to={routes.notifications.getListLink()}
                                        onClick={props.onOpenNotificationList}
                                        linkType="list"
                                    >
                                        <BTIconExternalLink />
                                    </BTLink>
                                </BTPopover>
                                <BTDropdown
                                    data-testid="moreOptions"
                                    overlayClassName="NotificationHeaderMoreOptions"
                                    overlay={
                                        <BTMenu>
                                            <BTMenuItem
                                                key="markAllUnread"
                                                data-testid="markAllUnread"
                                                onClick={props.onMarkAllRead}
                                            >
                                                <BTIconCheckOutlined className="margin-right-xs" />
                                                Mark all as read
                                            </BTMenuItem>
                                            <BTMenuItem
                                                data-testid="viewPrefs"
                                                key="viewPrefs"
                                                onClick={props.onOpenPrefs}
                                            >
                                                <BTIconSlidersHorizontal className="margin-right-xs" />
                                                Preferences
                                            </BTMenuItem>
                                        </BTMenu>
                                    }
                                >
                                    <BTIconEllipsisOutlined rotate={90} size={14} />
                                </BTDropdown>
                            </div>
                        </div>
                        {props.isLoading && <NotificationCardSkeleton quantity={skeletonCount} />}
                        {(!props.isLoading || props.items.length > 0) && (
                            <div>
                                {props.items.map((item) => (
                                    <NotificationCard
                                        key={item.id}
                                        entity={new NotificationItem(item)}
                                        notificationPanelRef={NotificationPanelRef}
                                        openMenuId={openMenuId}
                                        onMenuOpen={handleMenuOpen}
                                        onMarkRead={handleMarkRead}
                                    />
                                ))}
                                {props.hasMoreItems && (
                                    <div className="MoreNotifications">
                                        <BTLink
                                            to={routes.notifications.getListLink()}
                                            onClick={props.onOpenNotificationList}
                                            linkType="list"
                                        >
                                            <BdsButton
                                                testid="btnSeeMore"
                                                iconSize="sm"
                                                displayType="tertiary"
                                                text="See older notificaitons"
                                            />
                                        </BTLink>
                                    </div>
                                )}
                            </div>
                        )}
                        {!props.isLoading &&
                            !props.hasMoreItems &&
                            (props.items.length > 0 || props.onlyShowUnread) && (
                                <div
                                    className={classNames("MoreNotifications", {
                                        MoreNotificationsEmptyState:
                                            props.items.length === 0 && props.onlyShowUnread,
                                    })}
                                >
                                    <div>
                                        <BTIconNotifications isOnWhite size={64} />
                                    </div>
                                    That's all your notifications.
                                </div>
                            )}
                        {!props.isLoading && props.items.length === 0 && !props.onlyShowUnread && (
                            <NotificationPanelEmptyState
                                hasListData={false}
                                hasFilteredData={false}
                                isReadonly={true}
                                onCallToActionClick={() => {}}
                            />
                        )}
                    </>
                </BTCol>
            </BTRow>
        </div>
    );
}

export default NotificationPanelPresentational;
