import NotFoundError from "layouts/errors/NotFoundError";
import { IListPageChildProps } from "layouts/ListPageLayout";
import { RouteComponentProps } from "react-router";

import { WhenBuilderFlag } from "helpers/globalContext/BuilderInfoContext";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";

import "../../landingPage/LandingPage/LandingPage.less";
import "./JobFullPage.less";

const Job = lazyLoadWithRetry(() => import("entity/job/Job/Job"));

interface IJobProps {
    jobId: string;
    defaultTab: string;
}

export function JobPage(_first: IListPageChildProps, second: RouteComponentProps<IJobProps>) {
    return (
        <WhenBuilderFlag
            isEnabled="bdsJobDetailsToFullPage"
            then={<WrappedPage routeProps={second} />}
            otherwise={<NotFoundError />}
        />
    );
}

interface IWrappedPageProps {
    routeProps: RouteComponentProps<IJobProps>;
}

function WrappedPage({ routeProps }: IWrappedPageProps) {
    const { location, history } = routeProps;

    const { jobId, defaultTab } = routeProps.match.params;
    const query = new URLSearchParams(location.search);
    const accessedFromContact = query.get("accessedFromContact") === "true";
    const accessedFromLead = query.get("accessedFromLead") === "true";
    const ownerInvoiceId = query.get("ownerInvoiceId");
    const openCondensed = query.get("openCondensed") === "true";
    const redirectToJobLandingPage = () => {
        history.push(routes.landingPage.path);
    };

    const modalConfig: IModalConfiguration = {
        parentRoute: routes.landingPage.path,
        beforeClose: redirectToJobLandingPage,
    };

    const returnToListPage = () => {
        history.push(routes.job.getListLink());
    };

    return (
        <BTSuspense>
            <div className="JobFullPage">
                <Job
                    id={parseInt(jobId)}
                    defaultTab={defaultTab}
                    accessedFromContact={accessedFromContact}
                    accessedFromLead={accessedFromLead}
                    ownerInvoiceId={parseInt(ownerInvoiceId ?? "")}
                    openCondensed={openCondensed}
                    modalConfig={modalConfig}
                    useFullPage
                    onBack={returnToListPage}
                    {...routeProps}
                />
            </div>
        </BTSuspense>
    );
}
