import { BdsButton } from "@buildertrend/components";

import { AuthStoreDiscoveryActionType } from "commonComponents/auth/AuthStoreDiscovery.types";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTFormItemAutomatic } from "commonComponents/btWrappers/BTForm/BTForm";
import { BTInput } from "commonComponents/btWrappers/BTInput/BTInput";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";
import {
    IInvisibleCaptchaHandle,
    InvisibleCaptcha,
} from "commonComponents/utilities/Recaptcha/InvisibleRecaptcha";

interface IAuthStoreDiscoveryProps {
    actionBeingPerformed: AuthStoreDiscoveryActionType;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => Promise<void>;
    setFieldValue: (field: string, value: string) => void;
    username: string;
    buttonLabel: string;
    sectionClass?: string;
    captchaRef: React.RefObject<IInvisibleCaptchaHandle>;
    readonlyUsername?: boolean;
}

export const AuthStoreDiscovery: React.FunctionComponent<IAuthStoreDiscoveryProps> = ({
    actionBeingPerformed,
    handleSubmit,
    setFieldValue,
    username,
    captchaRef,
    buttonLabel,
    sectionClass = "",
    readonlyUsername = false,
}) => {
    return (
        <PageSection className={sectionClass} removeBodyPadding>
            <BTRow>
                <BTCol>
                    <InvisibleCaptcha
                        ref={captchaRef}
                        fieldName="recaptchaToken"
                        showRecaptcha
                        setFieldValue={setFieldValue}
                    />
                </BTCol>
            </BTRow>
            <BTRow className="flex-column" gutter={[16, 16]}>
                <BTCol>
                    <BTFormItemAutomatic id="username">
                        <BTInput
                            id="username"
                            onChange={setFieldValue}
                            data-testid="username"
                            autoFocus
                            value={username}
                            readOnly={readonlyUsername}
                        />
                    </BTFormItemAutomatic>
                </BTCol>
                <BTCol>
                    <BdsButton
                        style={{ minWidth: "100%" }}
                        displayType="primary"
                        id="submitButton"
                        testid="submitButton"
                        loading={actionBeingPerformed === "submit"}
                        disabled={actionBeingPerformed !== undefined}
                        onClick={handleSubmit}
                        text={buttonLabel}
                    />
                </BTCol>
            </BTRow>
        </PageSection>
    );
};
