import { message } from "antd";
import { forwardRef, useImperativeHandle, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

interface IInvisibleCaptchaProps {
    fieldName: string;
    showRecaptcha: boolean;
    setFieldValue: (key: string, value: any) => void;
}

export interface IRecaptchaResponse {
    isCaptchaChallengeSuccessful: boolean;
    token: string | undefined;
}

export interface IInvisibleCaptchaHandle {
    executeCaptchaChallenge: () => Promise<IRecaptchaResponse>;
}

export const InvalidCaptchaErrorMessage = "Failed to validate captcha challenge. Please try again.";

export const InvisibleCaptcha = forwardRef<IInvisibleCaptchaHandle, IInvisibleCaptchaProps>(
    (props: IInvisibleCaptchaProps, ref: React.Ref<IInvisibleCaptchaHandle>) => {
        const { showRecaptcha, fieldName, setFieldValue } = props;
        const refRecaptcha = useRef<ReCAPTCHA>(null);

        useImperativeHandle(ref, () => ({
            executeCaptchaChallenge: async (): Promise<IRecaptchaResponse> => {
                if (!showRecaptcha) {
                    return { isCaptchaChallengeSuccessful: true, token: undefined };
                }
                refRecaptcha?.current?.reset();
                const token = await refRecaptcha?.current?.executeAsync();
                if (!token) {
                    void message.error(InvalidCaptchaErrorMessage);
                    return { isCaptchaChallengeSuccessful: false, token: undefined };
                }
                setFieldValue(fieldName, token);
                return { isCaptchaChallengeSuccessful: true, token: token };
            },
        }));

        return (
            <AppDefaultInfoContext.Consumer>
                {(appDefaultInfo) => {
                    if (!showRecaptcha) {
                        return null;
                    }
                    return (
                        <ReCAPTCHA
                            sitekey={appDefaultInfo?.invisibleRecaptchaApiKey || ""}
                            size="invisible"
                            ref={refRecaptcha}
                        />
                    );
                }}
            </AppDefaultInfoContext.Consumer>
        );
    }
);
