/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useQuery } from "@tanstack/react-query";
import type {
    QueryFunction,
    QueryKey,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type { EmailsStatusGetV2Params } from "./models";
import type { EmailStatusContract } from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Stability Level**: `stable`
 * @summary Gets the status of an email address
 */
export const emailsStatusGetV2 = (
    params: EmailsStatusGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<EmailStatusContract>(
        { url: `/apix/v2/Emails/status`, method: "GET", params, signal },
        options
    );
};

export const getEmailsStatusGetV2QueryKey = (params: EmailsStatusGetV2Params) => {
    return [`/apix/v2/Emails/status`, ...(params ? [params] : [])] as const;
};

export const getEmailsStatusGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof emailsStatusGetV2>>,
    TError = ErrorType<unknown>
>(
    params: EmailsStatusGetV2Params,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof emailsStatusGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getEmailsStatusGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof emailsStatusGetV2>>> = ({ signal }) =>
        emailsStatusGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof emailsStatusGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type EmailsStatusGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof emailsStatusGetV2>>
>;
export type EmailsStatusGetV2QueryError = ErrorType<unknown>;

/**
 * @summary Gets the status of an email address
 */

export function useEmailsStatusGetV2<
    TData = Awaited<ReturnType<typeof emailsStatusGetV2>>,
    TError = ErrorType<unknown>
>(
    params: EmailsStatusGetV2Params,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof emailsStatusGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getEmailsStatusGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

export const getEmailsStatusGetV2ResponseMock = (
    overrideResponse: Partial<EmailStatusContract> = {}
): EmailStatusContract => ({
    available: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getEmailsStatusGetV2MockHandler = (
    overrideResponse?:
        | EmailStatusContract
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<EmailStatusContract> | EmailStatusContract)
) => {
    return http.get("*/v2/Emails/status", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getEmailsStatusGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};
export const getEmailsMock = () => [getEmailsStatusGetV2MockHandler()];
