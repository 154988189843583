import { EmptyResponseEntity } from "types/emptyResponseEntity";

import { APIHandler } from "utilities/apiHandler";

import { IReleaseToOwnerFormValues } from "entity/estimate/Proposal/ReleaseToOwner/ReleaseToOwner.types";
import { IResetStatusPromptFormValues } from "entity/estimate/Proposal/ResetStatusPrompt/ResetStatusPrompt.types";

import {
    IApproveDeclineProposalRequest,
    IRecategorizeProposalFormatRequest,
    ISetPreviewLayoutOptionsRequest,
    ISetShowAdditionalPrintInfoRequest,
    IUnreleasedProposalSaveRequest,
    ProposalEntity,
} from "./Proposal.types";

export interface IProposalHandler {
    get(id: number): Promise<ProposalEntity>;
    getOwnerProposalExternal(shareToken?: string): Promise<ProposalEntity>;
    getOwnerProposal(worksheetId?: number, proposalId?: number): Promise<ProposalEntity>;
    release(
        id: number,
        values: IReleaseToOwnerFormValues,
        saveRequest?: IUnreleasedProposalSaveRequest
    ): Promise<ProposalEntity>;
    updateUnreleasedProposal(
        id: number,
        request: IUnreleasedProposalSaveRequest
    ): Promise<ProposalEntity>;
    unrelease(
        id: number,
        values: IResetStatusPromptFormValues,
        ownerPrice: number,
        applyCostItemUpdates?: boolean
    ): Promise<EmptyResponseEntity>;
    approveDeclineProposal(
        id: number | string,
        values: IApproveDeclineProposalRequest
    ): Promise<ProposalEntity>;
    reset(
        id: number,
        values: IResetStatusPromptFormValues,
        ownerPrice: number,
        applyCostItemUpdates?: boolean
    ): Promise<EmptyResponseEntity>;
    setPreviewLayoutOptions(
        id: number,
        data: ISetPreviewLayoutOptionsRequest
    ): Promise<EmptyResponseEntity>;
    setShowAdditionalPrintInfo(
        id: number,
        data: ISetShowAdditionalPrintInfoRequest
    ): Promise<EmptyResponseEntity>;
    recategorizeFormat(
        id: number,
        request: IRecategorizeProposalFormatRequest
    ): Promise<EmptyResponseEntity>;
    notify(id: number): Promise<EmptyResponseEntity>;
}

export class ProposalHandler implements IProposalHandler {
    async get(id: number): Promise<ProposalEntity> {
        return await APIHandler(`/api/Proposals/${id}`, {
            method: "GET",
            responseType: ProposalEntity,
        });
    }

    async getOwnerProposalExternal(shareToken?: string): Promise<ProposalEntity> {
        return await APIHandler(`/api/Proposals/External?shareToken=${shareToken}`, {
            method: "GET",
            responseType: ProposalEntity,
        });
    }

    async getOwnerProposal(worksheetId?: number, proposalId?: number) {
        return await APIHandler(`/api/Proposals/ReadOnly`, {
            method: "GET",
            data: { id: worksheetId, proposalId },
            responseType: ProposalEntity,
        });
    }

    async release(
        id: number,
        values: IReleaseToOwnerFormValues,
        saveRequest?: IUnreleasedProposalSaveRequest
    ): Promise<ProposalEntity> {
        return await APIHandler(`/api/Proposals/${id}/Release`, {
            method: "PUT",
            data: {
                notifyOwner: values.notifyOwner,
                ownerEmail: values.ownerEmail,
                EmailDescription: values.emailDescription,
                sendMeACopy: values.sendMeACopy,
                saveRequest: saveRequest,
            },
            responseType: ProposalEntity,
        });
    }

    async unrelease(
        id: number,
        values: IResetStatusPromptFormValues,
        ownerPrice: number,
        applyCostItemUpdates?: boolean
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/Unrelease`, {
            method: "PUT",
            data: {
                savePdf: values.savePdfCopy,
                pdfTitle: values.pdfTitle,
                applyCostItemUpdates,
                ownerPrice,
            },
            responseType: EmptyResponseEntity,
        });
    }

    async reset(
        id: number,
        values: IResetStatusPromptFormValues,
        ownerPrice: number,
        applyCostItemUpdates?: boolean
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/Reset`, {
            method: "PUT",
            data: {
                savePdf: values.savePdfCopy,
                pdfTitle: values.pdfTitle,
                applyCostItemUpdates,
                ownerPrice,
            },
            responseType: EmptyResponseEntity,
        });
    }

    async updateUnreleasedProposal(
        id: number,
        request: IUnreleasedProposalSaveRequest
    ): Promise<ProposalEntity> {
        return await APIHandler(`/api/Proposals/${id}`, {
            method: "PUT",
            data: request,
            responseType: ProposalEntity,
        });
    }

    async approveDeclineProposal(
        id: number | string,
        request: IApproveDeclineProposalRequest
    ): Promise<ProposalEntity> {
        if (request.shareToken) {
            return await APIHandler(`/api/Proposals/ApproveDeclineExternal`, {
                method: "PUT",
                data: request,
                responseType: ProposalEntity,
            });
        } else {
            return await APIHandler(`/api/Proposals/${id}/ApproveDecline`, {
                method: "PUT",
                data: request,
                responseType: ProposalEntity,
            });
        }
    }

    async setPreviewLayoutOptions(
        id: number,
        data: ISetPreviewLayoutOptionsRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/SetPreviewLayoutOptions`, {
            method: "PUT",
            data: data,
            responseType: EmptyResponseEntity,
        });
    }

    async setShowAdditionalPrintInfo(
        id: number,
        data: ISetShowAdditionalPrintInfoRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/SetShowAdditionalPrintInfo`, {
            method: "PUT",
            data: data,
            responseType: EmptyResponseEntity,
        });
    }

    async recategorizeFormat(
        id: number,
        request: IRecategorizeProposalFormatRequest
    ): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/RecategorizeProposalFormat`, {
            method: "PUT",
            data: request,
            responseType: EmptyResponseEntity,
        });
    }

    async notify(id: number): Promise<EmptyResponseEntity> {
        return await APIHandler(`/api/Proposals/${id}/Notify`, {
            method: "PUT",
            responseType: EmptyResponseEntity,
        });
    }
}
