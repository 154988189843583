import "./UserActivationLogoDisplay.less";

interface IUserActivationLogoDisplayProps {
    logoSrc: string;
}
export function UserActivationLogoDisplay({ logoSrc }: IUserActivationLogoDisplayProps) {
    return (
        <div className="UserActivationLogoDisplay">
            {logoSrc && (
                <img
                    alt="Builder Logo"
                    src={logoSrc}
                    className="UserActivationLogoDisplay--BuilderLogo"
                />
            )}
        </div>
    );
}
