import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";
import { RouteRelative } from "commonComponents/utilities/RouteRelative/RouteRelative";

import { IProposalProps } from "entity/estimate/Proposal/Proposal";

const Proposal = lazyLoadWithRetry(() => import("./Proposal"));

export const RouteProposal = (
    props: Omit<IProposalProps, "jobId" | "history" | "location" | "match" | "id">
) => (
    <RouteRelative
        path={routes.estimate.proposal}
        render={(routeProps) => {
            const routeValues = routeProps.match.params;
            return (
                <BTSuspense>
                    <Proposal
                        {...props}
                        {...routeProps}
                        id={parseInt(routeValues.proposalId)}
                        jobId={parseInt(routeValues.jobId)}
                    />
                </BTSuspense>
            );
        }}
    />
);
