import { AttachedFiles, BTFileSystem } from "legacyComponents/FileUploadContainer.types";

import { BTServiceDropdown } from "types/apiResponse/apiResponse";
import { CostTypes, MarkedAs } from "types/enum";

import { newLineToHtmlBr } from "utilities/string/string";

import { TaxGroupServiceListItemExtraData } from "commonComponents/financial/TaxRateSelect/TaxRateSelect.api.types";

import { VendorTypes } from "entity/vendor/common/vendor.types";

export enum PriceTypes {
    FlatFee = 1,
    LineItems = 2,
    RequestFromVendor = 3,
}

export interface ICostCode {
    id: number;
    name: string;
    isFlatRateCostCode: boolean;
    isCustomerVariance?: boolean;
}

export enum LineItemType {
    GeneralItem = -1,
    Bid = 0,
    BidPackage = 1,
    PurchaseOrder = 2,
    ToDo = 3,
    PurchaseOrderPayment = 4,
    SelectionChoice = 5,
    ChangeOrder = 6,
    Allowance = 7,
    LegacyBill = 8,
    TimeCardItem = 9,
    EstimateLineItem = 10,
    ProposalLineItem = 11,
    ProposalTemplateLineItem = 12,
    JobTemplateLineItem = 13,
    OwnerInvoice = 14,
    OtherAccountingCost = 15,
    CreditMemo = 16,
    Bill = 17,
    Receipt = 18,
    ReceiptReview = 19,
}

export enum ProposalType {
    None = 0,
    Lead = 1,
    Job = 2,
    Template = 3,
}

type LineItemIdType = number | null | undefined;

export class LineItem {
    constructor(data: ILineItemResponse, pageType: string, pageTypeEnum: LineItemType) {
        // The Knockout LIC provides this value directly as a builderCost property, so if it
        // exists, just copy over to the newly instantiated line items
        if (data.builderCost !== undefined) {
            this.builderCost = data.builderCost;
        } else {
            // Owner Invoice doesn't have calculatedAmount, it uses ownerPrice
            this.builderCost =
                pageTypeEnum === LineItemType.OwnerInvoice
                    ? data.ownerPrice!
                    : data.calculatedAmount;
        }
        this.id = data.lineItemId !== undefined ? data.lineItemId : data.id;
        this.costCodeId = data.costCode !== undefined ? data.costCode : data.costCodeId;
        this.costCode = data.costCode !== undefined ? data.costCode : data.costCodeId;
        this.unitCost = data.unitCost;
        this.quantity = data.quantity;
        this.unitType = data.unitType;
        this.quantityIsLinked = data.quantityIsLinked;
        this.unitTypeIsLinked = data.unitTypeIsLinked;
        this.markupColumn = data.markupColumn === 0 ? "1" : data.markupColumn;
        this.markupPercentage = data.markupPercentage;
        this.markupPerUnit = data.markupPerUnit;
        this.markupPrice = data.markupPrice;
        this.ownerPrice = data.ownerPrice;
        this.calculatedAmount = data.calculatedAmount;
        this.isTBD = data.isTBD;
        this.title = data.title;
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.catalogItemId = data.catalogItemId;
        this.costCatalogUpdateType = data.costCatalogUpdateType;
        this.pageType = pageType;
        this.pageTypeEnum = pageTypeEnum;
        this.excludeFromNewExpected = data.excludeFromNewExpected;
        this.shouldUseAutoUpdates = false;
        this.varianceCode = data.varianceCode;
        this.costTypeId = data.costTypeId;
        this.parentId = null;
        this.purchaseOrderId = data.purchaseOrderId;
        this.purchaseOrderLineItemId = data.purchaseOrderLineItemId;
        this.relatedGeneralItemId = data.relatedGeneralItemId;
        this.relatedChangeOrderLineItemId = data.relatedChangeOrderLineItemId;
        this.relatedBidLineItemId = data.relatedBidLineItemId;
        this.relatedStackLinks = data.relatedStackLinks;
        this.selectionChoiceLineItemId = data.selectionChoiceLineItemId;
        this.billLineItemId = data.billLineItemId;
        this.relatedAccountingCostId = data.relatedAccountingCostId;
        this.costTypes = data.costTypes;
        this.markedAs = data.markedAs;
        this.vendorProductId = data.vendorProductId;
        this.vendorType = data.vendorType;
        this.isDiscontinued = data.isDiscontinued;
        this.taxGroupId = data.taxGroupId;
        this.totalWithTax = data.totalWithTax;
        this.receiptId = data.receiptId;
        this.vendorPurchaseLineItemId = data.vendorPurchaseLineItemId;
        this.vendorPurchaseId = data.vendorPurchaseId;
        this.assemblyId = data.assemblyId;
        this.takeoffLineItemId = data.takeoffLineItemId;
        this.allowanceLineItemId = data.allowanceLineItemId;
        this.allowanceId = data.allowanceId;
        this.externalProductId = data.externalProductId;
        this.globalProductCatalogId = data.globalProductCatalogId;
    }

    id: LineItemIdType;
    costCodeId: number | null | undefined;
    costCode: number | null | undefined;
    costTypeId: number | null | undefined;
    unitCost: number | null | undefined;
    quantity: number | null | undefined;
    unitType: string | null | undefined;
    quantityIsLinked: boolean | null | undefined;
    unitTypeIsLinked: boolean | null | undefined;
    builderCost: number;
    calculatedAmount: number | null | undefined;
    markupColumn: number | null | string | undefined;
    markupPercentage: number | null | undefined;
    markupPerUnit: number | null | undefined;
    markupPrice: number | null | undefined;
    ownerPrice: number | null | undefined;
    isTBD: boolean | null | undefined;
    title: string | null | undefined;
    description: string | null | undefined;
    internalNotes: string | null | undefined;
    catalogItemId: number | null | undefined;
    costCatalogUpdateType: number | null | undefined;
    pageType: string | undefined;
    pageTypeEnum: number | undefined;
    excludeFromNewExpected: boolean | null | undefined;
    shouldUseAutoUpdates: boolean | null | undefined;
    varianceCode: number | null | undefined;
    parentId: null;
    purchaseOrderId?: number | null | undefined;
    purchaseOrderLineItemId?: number | null | undefined;
    relatedGeneralItemId?: number | null | undefined;
    relatedChangeOrderLineItemId?: number | null | undefined;
    relatedBidLineItemId?: number | null | undefined;
    selectionChoiceLineItemId?: number | null | undefined;
    relatedStackLinks?: Record<number, RelatedStackItem[]>;
    billLineItemId?: number | null | undefined;
    relatedAccountingCostId?: number | null | undefined;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    vendorProductId: number | null | undefined;
    vendorType: VendorTypes | null | undefined;
    isDiscontinued: boolean | null | undefined;
    taxGroupId: number | null | undefined;
    totalWithTax: number | null | undefined;
    receiptId: number | null | undefined;
    vendorPurchaseLineItemId: number | null | undefined;
    vendorPurchaseId: number | null | undefined;
    assemblyId: number | null | undefined;
    takeoffLineItemId?: number | null;
    allowanceLineItemId?: number | null;
    allowanceId?: number | null;
    externalProductId?: number;
    globalProductCatalogId?: string;
}

export enum MarkupColumnTypes {
    Percentage = 1,
    Price = 2,
    OwnerPrice = 3,
    UnitPrice = 4,
}

export interface ILineItemResponse {
    DisplayOrder: number | null | undefined;
    FormatParentId: number | null | undefined;
    MarkupPercentageActive?: boolean;
    OwnerPriceActive?: boolean;
    amountInvoiced?: number;
    amountPaid: number | null | undefined;
    amountSavedOrPaid?: number;
    builderCost?: number;
    calculatedAmount: number;
    catalogItemId: number | null | undefined;
    catalogsExist: boolean | null | undefined;
    costCatalogUpdateType: number | null | undefined;
    costCode: number;
    costCodeId: number | null | undefined;
    costCodeItemId: number | null | undefined;
    costItemTitle: string | null | undefined;
    costCodeTitle: string | null | undefined;
    costGroupId: number | null | undefined;
    costTypeId: number | null | undefined;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    description: string | null | undefined;
    estimateId: number | null | undefined;
    excludeFromNewExpected: boolean | null | undefined;
    files: BTFileSystem[] | undefined;
    hidePercentInvoicedForSharedContainer?: boolean;
    hideTitle?: boolean;
    id: number;
    internalNotes: string | null | undefined;
    isCostCodeItem: boolean | number | null | undefined;
    isTBD: boolean | null | undefined;
    lineItemId: number | null | undefined;
    markupAmount: number;
    markupColumn: MarkupColumnTypes | 0 | null | undefined;
    markupPerUnit: number | null | undefined;
    markupPercent: number;
    markupPercentage: number | null | undefined;
    markupPrice: number | null | undefined;
    markupType: MarkupColumnTypes | null | undefined;
    newEstimateProposalLi?: boolean;
    ownerPrice: number | null | undefined;
    ownerPriceIsLinked: boolean | null | undefined;
    parentId: number | null | undefined;
    purchaseOrderId: number | null | undefined;
    purchaseOrderLineItemId: number | null | undefined;
    purchaseOrderPaymentLineItemId: number | null | undefined;
    quantity: number | null | undefined;
    quantityIsLinked: boolean | null | undefined;
    relatedChangeOrderLineItemId: number | null | undefined;
    relatedGeneralItemId?: number | null | undefined;
    relatedBidLineItemId?: number | null | undefined;
    relatedStackLinks?: Record<number, RelatedStackItem[]>;
    relatedOwnerInvoiceCustomId?: string;
    relatedOwnerInvoiceId?: number;
    selectionChoiceLineItemId: number | null | undefined;
    billLineItemId: number | null | undefined;
    relatedAccountingCostId: number | null | undefined;
    shouldUseAutoUpdates?: boolean;
    showPercentInvoiced?: boolean;
    subDescription: string | null | undefined;
    title: string | null | undefined;
    unitCost: number | null | undefined;
    unitType: string | null | undefined;
    unitTypeIsLinked: boolean | null | undefined;
    varianceCode: number | null | undefined;
    varianceCodeId?: number;
    varianceCodeTitle: string | null | undefined;
    vendorProductId: number | null | undefined;
    vendorType: VendorTypes | null | undefined;
    isDiscontinued: boolean | null | undefined;
    taxGroupId: number | null | undefined;
    totalWithTax: number | null | undefined;
    receiptId: number | null | undefined;
    vendorPurchaseLineItemId: number | null | undefined;
    vendorPurchaseId: number | null | undefined;
    assemblyId: number | null | undefined;
    takeoffLineItemId?: number | null;
    allowanceLineItemId?: number | null | undefined;
    allowanceId?: number | null | undefined;
    externalProductId?: number;
    globalProductCatalogId?: string;
}

export class GeneralItemMappingLineItem implements ILineItemResponse {
    constructor(data: any) {
        this.id = -1 * (data.id ?? data.relatedGeneralItemId); // give new line item a unique temporary negative id
        this.relatedGeneralItemId = data.id || data.relatedGeneralItemId;
        this.costCode = data.costCode ? data.costCode.costCodeId : data.costCodeId;
        this.costCodeId = this.costCode;
        this.title = data.itemTitle;
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.quantity = data.quantity;
        this.unitCost = data.unitCost ?? 0;
        this.unitType = data.unit;
        this.markupColumn = data.markupColumn || data.markupType;
        this.markupPercentage = data.markupPercentage || data.markupPercent;
        this.markupPerUnit = data.markupPerUnit;
        this.markupAmount = data.markupAmount;
        this.ownerPrice = data.ownerPrice;
        this.costTypes = data.costTypes;
        this.markedAs = data.markedAs;
        this.assemblyId = data.assemblyId;
        this.takeoffLineItemId = data.takeoffLineItemId;
        this.vendorPurchaseId = data.vendorPurchaseId;
    }

    DisplayOrder: number | null | undefined;
    FormatParentId: number | null | undefined;
    MarkupPercentageActive?: boolean;
    OwnerPriceActive?: boolean;
    amountInvoiced?: number;
    amountPaid: number | null | undefined;
    amountSavedOrPaid?: number;
    calculatedAmount: number;
    catalogItemId: number | null | undefined;
    catalogsExist: boolean | null | undefined;
    costCatalogUpdateType: number | null | undefined;
    costCode: number;
    costCodeId: number | null | undefined;
    costCodeItemId: number | null | undefined;
    costItemTitle: string | null | undefined;
    costCodeTitle: string | null | undefined;
    costGroupId: number | null | undefined;
    costTypeId: number | null | undefined;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    description: string | null | undefined;
    estimateId: number | null | undefined;
    excludeFromNewExpected: boolean | null | undefined;
    files: BTFileSystem[] | undefined;
    hidePercentInvoicedForSharedContainer?: boolean;
    hideTitle?: boolean;
    id: number;
    internalNotes: string | null | undefined;
    isCostCodeItem: boolean | number | null | undefined;
    isTBD: boolean | null | undefined;
    lineItemId: number | null | undefined;
    markupAmount: number;
    markupColumn: MarkupColumnTypes | null | undefined;
    markupPerUnit: number | null | undefined;
    markupPercent: number;
    markupPercentage: number | null | undefined;
    markupPrice: number | null | undefined;
    markupType: MarkupColumnTypes | null | undefined;
    newEstimateProposalLi?: boolean;
    ownerPrice: number | null | undefined;
    ownerPriceIsLinked: boolean | null | undefined;
    parentId: number | null | undefined;
    purchaseOrderId: number | null | undefined;
    purchaseOrderLineItemId: number | null | undefined;
    purchaseOrderPaymentLineItemId: number | null | undefined;
    quantity: number | null | undefined;
    quantityIsLinked: boolean | null | undefined;
    relatedChangeOrderLineItemId: number | null | undefined;
    relatedGeneralItemId?: number | null | undefined;
    relatedOwnerInvoiceCustomId?: string;
    relatedOwnerInvoiceId?: number;
    selectionChoiceLineItemId: number | null | undefined;
    billLineItemId: number | null | undefined;
    relatedAccountingCostId: number | null | undefined;
    shouldUseAutoUpdates?: boolean;
    showPercentInvoiced?: boolean;
    subDescription: string | null | undefined;
    title: string | null | undefined;
    unitCost: number | null | undefined;
    unitType: string | null | undefined;
    unitTypeIsLinked: boolean | null | undefined;
    varianceCode: number | null | undefined;
    varianceCodeId?: number;
    varianceCodeTitle: string | null | undefined;
    vendorProductId: number | null | undefined;
    vendorType: VendorTypes | null | undefined;
    isDiscontinued: boolean | null | undefined;
    taxGroupId: number | null | undefined;
    totalWithTax: number | null | undefined;
    receiptId: number | null | undefined;
    vendorPurchaseLineItemId: number | null | undefined;
    vendorPurchaseId: number | null | undefined;
    assemblyId: number | null | undefined;
    takeoffLineItemId: number | null | undefined;
}

export interface ILineItemContainerData {
    /** Sets the tab name for Google Analytic events */
    gaTabName?: string;

    // / Original Container Params
    /** When true, helps determine if a line item can be removed */
    canDelete?: boolean;
    /** List of variance code ids, used to determine if the
     * line items has customer variance */
    customerVarianceCodeIds?: number[];
    /** File data to be passed through to cost catalog and cvf within
     * the container */
    files?: any[];
    /** When true, enables the responsive mode of the container,
     * showing buttons to scroll horizontally */
    responsive?: boolean;
    /** Provides the id of the code that all line items should be
     * using for variance when showing variance codes */
    selectedVarianceCode?: number;
    /** When true, enables client side paging.
     * Used in conjunction with the pagerVM */
    shouldHavePages?: boolean;
    /** When true, applies variance codes on each line item.
     * Used in conjunction with selectedVarianceCode */
    showVarianceCodes?: boolean;
    /** Represents an externally reordered list of line items
     * Will handle setting up display orders and pausing subscriptions
     * Connects to updateEstimateOrder within the container */
    updatedLineItemsOrder?: any[];
    /** Represents the possible variances codes that can be selected */
    varianceCodes?: ICostCode[];
    /** Represents a sub / vendor's id, used to determine if the
     * container can be switched to request from vendor mode */
    vendorID?: string;
    // / End Original Container Params

    // / Original Page Data Params
    /** * Boolean that allows the title field to be editable */
    allowTitleNotesEdit?: boolean;
    /** * Represents the amount that has previously been paid or
     * saved on a PO for better validation */
    amountPaidOrSaved?: number;
    /** * Determines if the line items are in readonly mode
     * and if additional ones can be added */
    canAddLineItem?: boolean;
    /** * Enables the percent invoiced link if the current user
     * can add owner invoices */
    canAddOwnerPayments?: boolean;
    /** * Enables the Add / Edit buttons on the cost code selector */
    canEditCostCodes?: boolean;
    /** * Enables reordering line items when the user has the ability to */
    canReorderLineItems?: boolean;
    /** * Puts the cost code selector into readonly mode if true */
    costCodeSelectorReadOnly?: boolean;
    /** * Sets the BTServiceDropdown data for CostTypes on
     * Purchase Order Line Items */
    costTypes?: BTServiceDropdown;
    /** * Overrides the title of "Builder Cost" with a custom string */
    customBuilderCostHeader?: string;
    /** * Overrides the title of "Unit Cost" with a custom string */
    customUnitCostHeader?: string;
    /** * Id of the cost code to use when creating a new line item */
    defaultCostCodeId?: number;
    /** * Max length of the description field */
    descriptionMax?: number;
    /** * When true, disables the description field of the line item */
    descriptionReadOnly?: boolean;
    /** * When true, disables the builder cost field of the line item */
    disableBuilderCost?: boolean;
    /** * When true, displays the toggle for expanding / collapsing all
     * additional info on all line items */
    displayShowHideAdditionalInfo?: boolean;
    /** * When true, displays the title fields for each line item */
    displayTitle?: boolean;
    /** * Id of the entity that has line items on it
     * (ex: ChangeOrderId, BidId) */
    entityId?: number;
    /** * Helps determine if a cost item's link can be broken */
    entityPending?: boolean;
    /** * When true, locks down ability to edit cost codes from the
     * inline cost code selector */
    hasAutoSaveFunctionality?: boolean;
    /** * When true, enables the ability to edit the
     * container and line items */
    hasEditPermission?: boolean;
    /** * When true, shows the percent of the line item
     * that has been invoiced */
    hasPercentInvoicePermission?: boolean;
    /** * When true, shows the user the price values of
     * each line item and totals */
    hasPriceViewingPermission?: boolean;
    /** * When true, helps decide if the user can add a line item */
    hideAddButton?: boolean;
    /** * When true, hides the client price field when the container
     * is in flat fee mode */
    hideFlatFeeOwnerPrice?: boolean;
    /** * When true, hides the radio buttons that allow the container
     * to switch between flat fee and line item mode */
    hideRadioButtons?: boolean;
    /** * Passes to the cost catalog, filters out inactive cost codes
     * based on the inactivation date and date of entity */
    inactiveCostCodeCutoffDate?: string;
    /** * Max length of the description field */
    internalNotesMax?: number;
    /** * When true, helps determine if the markup columns
     * should be disabled */
    isOwnerApproved?: boolean;
    /** * Determines if quantity is required, defaults true */
    isQuantityRequired?: boolean;
    /** * JobId of the entity that has line items on it */
    jobId?: number | null | undefined;
    /** * When true, helps determine if the line item should be
     * displayed as readonly */
    lineItemReadOnly?: boolean;
    /** *
     * Determines the amount that has been saved or paid on each
     * line item for better display & validation
     * Maps PurchaseOrderLineItemId to Amount (saved / paid)
     */
    lineItemsAmountSavedOrPaid?: Map<number, number>;
    /** * List of line item ids that have payments made against them */
    lineItemsWithPayments?: Map<number, number>;
    /** * Type that is needed when making Cost Item and
     * Cost Group API calls */
    linkedCostItemType?: LinkedCostItemTypes;
    /** * Used for Bills / POs for when a line item can be deleted */
    lineItemsSentToAccounting?: number[];
    /** * Used for Bills / POs for when a line item can be deleted */
    lineItemsWithVoidedPOPLI?: number[];
    /** *
     * When true, a blank spot will be left when fields are
     * hidden vs collapsing horizontally.
     * The difference is just in the KO binding, visible vs visibility
     */
    maintainPOColumnSpacing?: boolean;
    /** * When true, only the markup fields on each
     * line item are disabled */
    onlyLockMarkup?: boolean;
    /** * Overrides the title of "Client Price" with a custom string */
    ownerPriceHeader?: string;
    /** * String value of the type of the implementing entity
     * (ex: "proposal", "estimate") */
    pageType: string;
    /** * Enum value of the type of the implementing entity
     * (ex: 1, 2) */
    pageTypeEnum: LineItemType;
    /** * The date when the price was requested */
    priceRequested?: string | Date; // TODO: follow up on this when fully implemented
    /** * Enum representing the various pricing types the
     * container supports */
    priceType: PriceTypes;
    /** *
     * Enum representing the various types of proposals
     * the container is used for
     * @default None = 0
     */
    proposalType?: ProposalType;
    /** * Tooltip text for quantity field,
     * only used by bids. */
    quantityToolTipText?: string;
    /** * When true, allows each line item to make API calls
     * to save itself when values change */
    shouldUseAutoUpdates?: boolean;
    /** *
     * Used for bids, allows the amounts to remain hidden until submitted.
     * When true, the saved values will be shown. When false, the values
     * on the front end will be displayed as 0. If saved, the values are
     * still overwritten. */
    showAmountsForLineItems?: boolean;
    /** * When true, displays the builder cost for each line item
     * and the total builder cost */
    showBuilderCost?: boolean;
    /** * When true, displays the builder and owner cost inputs
     * while in flat fee mode */
    showBuilderOwnerCostOnFlatFee?: boolean;
    /** * When true, displays the cost catalog icon as part of
     * the cost code selector */
    showCatalog?: boolean;
    /** * When true, displays a checkbox next to each line item
     * on hover, to allow for multiple items to be selected */
    showCheckboxOnHover?: boolean;
    /** * When true, formats the flat fee mode as line item mode
     * (ex: Purchase Orders & Bills) */
    showLineItemOnFlatFee?: boolean;
    /** * When true, displays the "Total Markup" section */
    showMarkupTotal?: boolean;
    /** * When true, allows for the ability to create a
     * related PO from one or many line items */
    showPOButton?: boolean;
    /** * When true, displays the "Request from Vendor" radio button,
     * enabling the "Request from Vendor" mode of the container */
    showRequestFromVendor?: boolean;
    /** * When true, allows the ability for client prices
     * to be marked as To Be Decided */
    showTbd?: boolean;
    /** * Used in conjunction with the showToOwnerCheckBox,
     * this represents the actual value of the checkbox */
    showToOwner?: boolean;
    /** * When true, makes the "Show to Owner" checkbox visible,
     * allowing the user to choose to share items with the owner */
    showToOwnerCheckBox?: boolean;
    /** * When true, appends a tooltip on the "Show to Owner"
     * checkbox explaining the purpose of the checkbox */
    showToOwnerTooltipVisible?: boolean;
    /** * Represents the text entered for the subs description */
    subDescription?: string;
    /** * When true, displays an "Owner Invoice" column and
     * inlines related Owner Invoices */
    supportsBillInvoicing?: boolean;
    /** * When true, add additional requirements and restrictions
     * when editing line items with saved payments against them */
    supportsSavedPOPayments?: boolean;
    /** * When true, disables the TBD checkbox.
     * Used in conjunction with the showTbd param. */
    tbdReadOnly?: boolean;
    /** * String representing the tooltip to display next to the
     * unit cost column. Only visible when text is present. */
    unitCostToolTipText?: string;
    /** * When true, uses a special inline description field,
     * rather then displaying the description below in the collapsable area */
    useInlineDescription?: boolean;
    /** * Tooltip text for the unit type,
     * only used by bids */
    unitTypeToolTipText?: string;
    /** * Boolean to stop certain behavior with markup calculation
     * if the line item type is bids */
    isBids?: boolean;
    /** When true, disable the % invoiced button in the LIC
     *  This is only relevant on COs */
    hideLineItemInvoiceButtons?: boolean;
    // / End Original Page Data Params
}

export interface IHasLineItems {
    lineItems: ILineItemData;
    lineItemContainerData: ILineItemContainerData;
}

export interface IServiceValidatedField {
    value: any;
    validators: any[];
}

export interface ILineItemDefaults {
    catalogExists?: boolean;
    costCode?: IServiceValidatedField;
    costCodeItemId?: IServiceValidatedField;
    costCodeOptions?: IServiceValidatedField;
    costCodeTitle?: IServiceValidatedField;
    description?: IServiceValidatedField;
    flatRateId?: number;
    hasCostViewingPermissions?: IServiceValidatedField;
    hideBuilderCost?: number;
    internalNotes?: IServiceValidatedField;
    keysThatBreakCostLink: string[];
    markupAmount?: IServiceValidatedField;
    markupColumn?: IServiceValidatedField;
    markupPercent?: IServiceValidatedField;
    markupPerUnit?: IServiceValidatedField;
    markupTypeOptions?: IServiceValidatedField;
    marginPercent?: IServiceValidatedField;
    ownerPrice?: IServiceValidatedField;
    priceTBD?: IServiceValidatedField;
    quantity?: IServiceValidatedField;
    title?: IServiceValidatedField;
    unitCost?: IServiceValidatedField;
    unitType?: IServiceValidatedField;
    varianceCodeOptions?: IServiceValidatedField;
    taxGroups?: BTServiceDropdown<TaxGroupServiceListItemExtraData>;
    costTypes?: BTServiceDropdown;
    costTypesMarkup?: IServiceValidateCostTypesMarkup[];
}

export interface IServiceValidateCostTypesMarkup {
    costType: number;
    markupPercent: IServiceValidatedField;
    marginPercent: IServiceValidatedField;
}

export interface ILineItemData {
    value: ILineItemResponse[];
    defaults: ILineItemDefaults;
}

export enum LinkedCostItemTypes {
    Estimate = 0,
    LeadProposal = 1,
    Selection = 2,
    SelectionChoice = 3,
    ChangeOrder = 4,
    PurchaseOrder = 5,
    CostGroups = 6,
}

export class CostCollectionImportData {
    constructor(collectionImportData: any, attachedFiles: any) {
        this.costGroups = collectionImportData.costGroups.map(
            (group: any) => new CostGroupForImport(group)
        );
        this.includeDescription = collectionImportData.includeDescription;
        this.includeFiles = collectionImportData.includeFiles;

        if (attachedFiles) {
            this.attachedFiles = new AttachedFiles(attachedFiles);
        }
    }

    costGroups: CostGroupForImport[];
    includeDescription: boolean;
    includeFiles: boolean;
    attachedFiles?: AttachedFiles;

    generateDescriptionStringAggregate = (existingDescription: string): string => {
        let newDescription = existingDescription;

        this.costGroups.forEach((group) => {
            if (group.title.length > 0) {
                newDescription += newDescription.length > 0 ? "<br><br>" : "";
                newDescription += `Cost Group Title<br>${group.title}`;
            }

            if (group.description.length > 0 && this.includeDescription) {
                newDescription += newDescription.length > 0 ? "<br><br>" : "";
                newDescription += `Cost Group Description<br>${newLineToHtmlBr(group.description)}`;
            }
        });

        return newDescription;
    };
}

export class CostGroupForImport {
    constructor(data: any) {
        this.title = data.title();
        this.description = data.description();
    }
    title: string;
    description: string;
}

export class RelatedStackItem {
    constructor(relatedLineItemId: number, title: string) {
        this.relatedLineItemId = relatedLineItemId;
        this.title = title;
    }

    relatedLineItemId: number;
    title: string;
}
