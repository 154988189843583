import { BTLoginTypes } from "types/enum";

import { PreviousActivation } from "commonComponents/entity/credentials/Credentials/Credentials.api.types";

import { ActivationErrorTypes } from "handlers";

export type LinkAccountFormActions = undefined | "linkAccount";

export interface ILinkAccountFormValues {
    username: string;
    password: string;
    recaptchaToken: string | null;
}

export interface ILinkAccountRequest {
    username: string;
    password?: string;
    shareToken: string;
    isSignedIn?: boolean;
}

export interface ILoginRequest {
    username: string;
    password: string;
}

export interface ILinkAccountEntity {
    logoSrc: string;
    name: string;
    builderName: string;
    loginType: BTLoginTypes;
    suggestedUsername: string;
    isActivated: boolean;
    hasActiveSession: boolean;
    username: string;
    hasAlreadyLinked: boolean;
    errorType: ActivationErrorTypes;
    sessionLoginType: BTLoginTypes;
    isDisabled: boolean;
}

export class LinkAccountEntity implements ILinkAccountEntity {
    constructor(data: ILinkAccountEntity) {
        this.logoSrc = data.logoSrc;
        this.name = data.name;
        this.builderName = data.builderName;
        this.loginType = data.loginType;
        this.suggestedUsername = data.suggestedUsername;
        this.isActivated = data.isActivated;
        this.hasActiveSession = data.hasActiveSession;
        this.username = data.username;
        this.hasAlreadyLinked = data.hasAlreadyLinked;
        this.errorType = data.errorType;
        this.sessionLoginType = data.sessionLoginType;
        this.isDisabled = data.isDisabled;
    }
    logoSrc: string;
    name: string;
    builderName: string;
    loginType: BTLoginTypes;
    suggestedUsername: string;
    isActivated: boolean;
    hasActiveSession: boolean;
    username: string;
    hasAlreadyLinked: boolean;
    errorType: ActivationErrorTypes;
    sessionLoginType: BTLoginTypes;
    isDisabled: boolean;
}

export interface IAuth0ActivationResponse {
    globalUserId: number;
    loginType: BTLoginTypes;
    previousActivation: PreviousActivation;
    userId: number;
    errorType: ActivationErrorTypes;
    logoSrc: string;
    sessionLoginType: BTLoginTypes;
    builderName: string;
}

export class Auth0ActivationResponse implements IAuth0ActivationResponse {
    constructor(data: IAuth0ActivationResponse) {
        this.globalUserId = data.globalUserId;
        this.loginType = data.loginType;
        this.previousActivation = data.previousActivation;
        this.userId = data.userId;
        this.errorType = data.errorType;
        this.logoSrc = data.logoSrc;
        this.sessionLoginType = data.sessionLoginType;
        this.builderName = data.builderName;
    }
    globalUserId: number;
    loginType: BTLoginTypes;
    previousActivation: PreviousActivation;
    userId: number;
    errorType: ActivationErrorTypes;
    logoSrc: string;
    sessionLoginType: BTLoginTypes;
    builderName: string;
}

export interface IShareTokenLoginType {
    loginType: BTLoginTypes;
}

export interface IFinalizeActivationRequest {
    shareToken: string;
    emailAddress: string;
}
