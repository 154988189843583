import { Empty } from "antd";
import classNames from "classnames";
import { BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import { useCallback, useContext } from "react";
import { IBaseSlide } from "yet-another-react-lightbox";

import { UserInfoContext } from "helpers/globalContext/UserInfoContext";

import { BTLoginTypes, MediaType } from "types/enum";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTLayoutContent } from "commonComponents/btWrappers/BTLayout/BTLayout";
import { BTLightbox } from "commonComponents/btWrappers/BTLightbox/BTLightbox";
import {
    btFileSystemToSlide,
    canViewBTFileSystemInLightbox,
} from "commonComponents/btWrappers/BTLightbox/BTLightbox.utility";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { BTModalLayout } from "commonComponents/btWrappers/BTModal/BTModalLayout";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { getAttachmentOverlayItems } from "commonComponents/entity/media/common/MediaContextMenu/MediaContextMenu.utility";
import { AttachmentOverlay } from "commonComponents/entity/media/common/MediaContextMenu/Overlays/AttachmentOverlay";
import { MediaTile } from "commonComponents/entity/media/common/MediaGrid/MediaTile";
import { MediaTable } from "commonComponents/entity/media/common/MediaTable/MediaTable";
import { FileViewToggle } from "commonComponents/entity/media/FileViewToggle/FileViewToggle";
import { ViewAllAttachmentsActions } from "commonComponents/entity/media/ViewAllAttachments/ViewAllAttachments.types";
import { SharingSettingsAPI } from "commonComponents/entity/sharing/SharingContainer/SharingContainer.types";
import { PageSection } from "commonComponents/utilities/PageSection/PageSection";

import { AnnotationType, SelectedMediaListViews } from "entity/media/common/mediaTypes";

import "./ViewAllAttachments.less";

interface IViewAllAttachmentsPresentationalProps {
    files: BTFileSystem[];
    jobId?: number;
    lightboxSelectedId: number | null;
    view: SelectedMediaListViews;
    actionBeingPerformed: ViewAllAttachmentsActions;
    modalConfig?: IModalConfiguration;
    sharingSettings?: SharingSettingsAPI;
    isReadonly?: boolean;
    isNewEntity?: boolean;

    onOpenLightbox: (id: number) => void;
    onSlideChange: (_: number, slide: IBaseSlide) => void;
    onCloseLightbox: () => void;
    onViewChange: (value: SelectedMediaListViews) => void;
    onBulkDownload: () => Promise<void>;
    onVideoDownload: (file: BTFileSystem) => Promise<void>;
    onAddClicked?: () => void;
    onViewPermissionsUpdate?: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onNotify: (file: BTFileSystem, loginType: BTLoginTypes) => Promise<void>;
    onFileAnnotate?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onFileDraw?: (
        docInstanceId: number,
        annotationType: AnnotationType,
        mediaType: MediaType
    ) => Promise<void>;
    onEditOnline?: (docInstanceId: number, mediaType: MediaType) => Promise<void>;
    onFileDelete?: (file: BTFileSystem) => Promise<void>;
}

export const ViewAllAttachmentsPresentational = ({
    files,
    jobId,
    lightboxSelectedId,
    view,
    actionBeingPerformed,
    modalConfig,
    sharingSettings,
    isReadonly,
    isNewEntity,
    onOpenLightbox,
    onSlideChange,
    onCloseLightbox,
    onViewChange,
    onBulkDownload,
    onVideoDownload,
    onAddClicked,
    onViewPermissionsUpdate,
    onNotify,
    onFileAnnotate,
    onFileDraw,
    onEditOnline,
    onFileDelete,
}: IViewAllAttachmentsPresentationalProps) => {
    const userInfo = useContext(UserInfoContext);

    const handleClickThumbnail = useCallback(
        (e: React.MouseEvent<HTMLImageElement, MouseEvent>, file: BTFileSystem) => {
            // stop row from being selected
            e.stopPropagation();
            // open lightbox on double click
            const canViewInLightbox = canViewBTFileSystemInLightbox(file);
            if (canViewInLightbox && e.detail === 2) {
                onOpenLightbox(file.id);
            }
        },
        [onOpenLightbox]
    );

    const slides = files.filter(canViewBTFileSystemInLightbox).map(btFileSystemToSlide);
    const index = slides.findIndex((s) => s.metadata?.mediaId === lightboxSelectedId);
    const open = index >= 0;
    const downloadAllCount = files.filter((f) => f.mediaType !== MediaType.Video).length;
    const hasVideo = downloadAllCount < files.length;
    return (
        <BTModalLayout title="Attachments" modalConfig={modalConfig}>
            <BTLayoutContent>
                <div className="ViewAllAttachments">
                    <PageSection
                        isStickyHeader
                        title={
                            <div className="flex justify-content-between">
                                <div className="buttons">
                                    {jobId !== undefined && downloadAllCount > 0 && (
                                        <BTPopover
                                            disabled={
                                                actionBeingPerformed !== undefined || !hasVideo
                                            }
                                            content="Videos cannot be downloaded at this time."
                                        >
                                            <BTButton
                                                data-testid="downloadAll"
                                                onClick={onBulkDownload}
                                                actionBeingPerformed={actionBeingPerformed}
                                                loadingAction="download"
                                            >
                                                Download All ({downloadAllCount})
                                            </BTButton>
                                        </BTPopover>
                                    )}
                                    {!isReadonly && (
                                        <BTButton
                                            data-testid="addNewFile"
                                            onClick={onAddClicked}
                                            type="primary"
                                            className="margin-left-xs"
                                        >
                                            Add
                                        </BTButton>
                                    )}
                                </div>

                                <FileViewToggle value={view} onChange={onViewChange} />
                            </div>
                        }
                    >
                        {view === "Table" && (
                            <MediaTable
                                files={files}
                                isNewEntity={isNewEntity}
                                onVideoDownload={onVideoDownload}
                                onViewPermissionsUpdate={onViewPermissionsUpdate}
                                onNotify={onNotify}
                                onFileAnnotate={onFileAnnotate}
                                onFileDraw={onFileDraw}
                                onEditOnline={onEditOnline}
                                onFileDelete={onFileDelete}
                                isReadOnly={isReadonly}
                                sharingSettings={sharingSettings}
                                onOpenLightbox={onOpenLightbox}
                            />
                        )}
                        {view === "Tile" && (
                            <div className={classNames("FilePickerGrid", "padding-bottom-md")}>
                                {files.length === 0 && (
                                    <Empty
                                        description="No attachments"
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                )}
                                {files.length > 0 && (
                                    <BTRow gutter={[8, 8]}>
                                        {files.map((file) => {
                                            const items = getAttachmentOverlayItems(
                                                file,
                                                !!isReadonly,
                                                userInfo?.loginType,
                                                sharingSettings
                                            );
                                            return (
                                                <BTCol key={file.id} xs={8} sm={6} md={4}>
                                                    <MediaTile
                                                        file={file}
                                                        onClickThumbnail={(e) =>
                                                            handleClickThumbnail(e, file)
                                                        }
                                                        contextMenuProps={{
                                                            renderOverlay: (onMenuClick) => (
                                                                <AttachmentOverlay
                                                                    items={items}
                                                                    file={file}
                                                                    isReadOnly={isReadonly}
                                                                    isNewEntity={isNewEntity}
                                                                    onClickDownloadVideo={
                                                                        onVideoDownload
                                                                    }
                                                                    onFilePermissionsUpdate={
                                                                        onViewPermissionsUpdate
                                                                    }
                                                                    onClickNotify={onNotify}
                                                                    onClickAnnotate={onFileAnnotate}
                                                                    onClickDraw={onFileDraw}
                                                                    onClickEditOnline={onEditOnline}
                                                                    onClickDelete={onFileDelete}
                                                                    onMenuClick={onMenuClick}
                                                                />
                                                            ),
                                                            items,
                                                        }}
                                                    />
                                                </BTCol>
                                            );
                                        })}
                                    </BTRow>
                                )}
                            </div>
                        )}
                    </PageSection>
                </div>
            </BTLayoutContent>
            <BTLightbox
                slides={slides}
                index={index}
                open={open}
                onClose={onCloseLightbox}
                onSlideChange={onSlideChange}
            />
        </BTModalLayout>
    );
};
