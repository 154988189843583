import { History } from "history";
import { Component } from "react";
import { Route } from "react-router";

import { lazyLoadWithRetry } from "utilities/react.utilities";
import { routes } from "utilities/routes";

import { BTSuspense } from "commonComponents/btWrappers/BTSuspense/BTSuspense";

import { IBuilderRiskInsuranceStatusHandler } from "entity/builderRiskInsurance/BuilderRiskInsuranceStatus/BuilderRiskInsuranceStatus.api.handler";
import { IJobHandler } from "entity/job/Job.api.handler";

const JobFromTemplate = lazyLoadWithRetry(
    () => import("entity/job/JobFromTemplate/JobFromTemplate")
);
const Job = lazyLoadWithRetry(() => import("entity/job/Job/Job"));

interface IRouteJobProps {
    parentRoute: string;
    history: History;
    beforeClose: () => Promise<void>;
    onSave?: () => void;
    handler?: IJobHandler;
    briHandler?: IBuilderRiskInsuranceStatusHandler;
    useFullPage?: boolean;
    onBack?: () => void;
}
export class RouteJob extends Component<IRouteJobProps> {
    render() {
        const path = this.props.useFullPage ? routes.job.flatDetails : routes.job.details;
        return (
            <>
                <Route
                    path={this.props.parentRoute + path}
                    render={(routeProps) => {
                        const urlParams = routeProps.match.params;
                        const defaultTab = urlParams.defaultTab ? urlParams.defaultTab : undefined;

                        const modalConfig = {
                            parentRoute: this.props.parentRoute,
                            beforeClose: async () => {
                                await this.props.beforeClose();
                                this.props.history.push(this.props.parentRoute);
                            },
                        };

                        const jobId = parseInt(urlParams.jobId);
                        const accessedFromContact = urlParams.accessedFromContact
                            ? urlParams.accessedFromContact === "true"
                            : false;
                        const accessedFromLead = urlParams.accessedFromLead
                            ? urlParams.accessedFromLead === "true"
                            : false;
                        const ownerInvoiceId = urlParams.ownerInvoiceId
                            ? parseInt(urlParams.ownerInvoiceId)
                            : undefined;
                        const openCondensed = urlParams.openCondensed
                            ? urlParams.openCondensed === "true"
                            : false;

                        return (
                            <BTSuspense>
                                <Job
                                    id={jobId}
                                    defaultTab={defaultTab}
                                    modalConfig={modalConfig}
                                    accessedFromContact={accessedFromContact}
                                    accessedFromLead={accessedFromLead}
                                    onSave={this.props.onSave}
                                    ownerInvoiceId={ownerInvoiceId}
                                    openCondensed={openCondensed}
                                    handler={this.props.handler}
                                    briHandler={this.props.briHandler}
                                    {...routeProps}
                                    useFullPage={this.props.useFullPage}
                                    onBack={this.props.onBack}
                                />
                            </BTSuspense>
                        );
                    }}
                />
                <Route
                    path={this.props.parentRoute + routes.job.jobFromTemplate}
                    render={(routeProps) => {
                        const modalConfig = {
                            parentRoute: this.props.parentRoute,
                            beforeClose: () => {
                                this.props.history.push(this.props.parentRoute);
                            },
                        };

                        const urlParams = routeProps.match.params;
                        const sourceTemplateId = parseInt(urlParams.sourceTemplateId);
                        return (
                            <BTSuspense>
                                <JobFromTemplate
                                    modalConfig={modalConfig}
                                    {...routeProps}
                                    sourceTemplateId={sourceTemplateId}
                                />
                            </BTSuspense>
                        );
                    }}
                />
            </>
        );
    }
}

export class RouteTemplateJobForJobPage extends Component<
    IRouteJobProps & { loadJobForFullPage?: () => Promise<void> }
> {
    render() {
        return (
            <Route
                path={this.props.parentRoute + routes.job.jobFromTemplate}
                render={(routeProps) => {
                    const modalConfig = {
                        parentRoute: this.props.parentRoute,
                        beforeClose: () => {
                            this.props.history.push(this.props.parentRoute);
                        },
                    };

                    const urlParams = routeProps.match.params;
                    const sourceTemplateId = parseInt(urlParams.sourceTemplateId);
                    return (
                        <BTSuspense>
                            <JobFromTemplate
                                loadJobForFullPage={this.props.loadJobForFullPage}
                                useFullPage
                                modalConfig={modalConfig}
                                {...routeProps}
                                sourceTemplateId={sourceTemplateId}
                            />
                        </BTSuspense>
                    );
                }}
            />
        );
    }
}
