export enum PurchasingDashboardPages {
    Summary = "summary",
    SupplierPrograms = "supplierPrograms",
    Payments = "payments",
    HowItWorks = "howItWorks",
    Activity = "activity",
}

export enum PurchasingDashboardActivityPages {
    Rebates = "rebates",
    Receipts = "allReceipts",
}
