import {
    AttachedFiles,
    AttachedFilesRequest,
    IHasAttachedFiles,
} from "legacyComponents/FileUploadContainer.types";
import {
    IHasLineItems,
    ILineItemContainerData,
    ILineItemData,
    ILineItemResponse,
    LineItem,
    LineItemType,
    LinkedCostItemTypes,
    MarkupColumnTypes,
    PriceTypes,
} from "legacyComponents/LineItemContainer.types";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import {
    BTSelectItem,
    IBaseEntity,
    JobInfo,
    mapBTServiceDropdownToSelectItem,
    RelatedRFIs,
} from "types/apiResponse/apiResponse";
import {
    CostTypes,
    CustomFieldAssociatedType,
    DocumentInstanceType,
    MarkedAs,
    SignaturePrefs,
} from "types/enum";

import {
    calculateBuilderCost,
    calculateMarginPercentageFromMarkup,
} from "utilities/lineItem/lineItemHelper";

import { ICustomFieldFormValues } from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer";
import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
    IHasCustomFieldContainer,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import {
    DiscussionsEntity,
    IHasDiscussionContainer,
} from "commonComponents/entity/discussion/DiscussionContainer/DiscussionContainer.api.types";
import { RecentItemsTooltipConfig } from "commonComponents/entity/recentItemsTooltip/RecentItemsTooltip";
import {
    RelatedInvoice,
    RelatedPurchaseOrder,
} from "commonComponents/entity/relatedItem/RelatedItem.types";
import { UpdateSignature } from "commonComponents/entity/signaturePad/SignaturePad/SignaturePad.api.types";
import { ILineItemRow } from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import { MarkupType } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { IChangeOrderLineItem } from "entity/changeOrder/ChangeOrderLineItemContainer/ChangeOrderLineItemContainer.types";
import {
    ChangeOrderStatuses,
    decidedStatuses,
    deleteStatuses,
    releaseableStatuses,
    ResetOptionTypes,
    undecidedStatuses,
    unreleaseableStatuses,
} from "entity/changeOrder/common/types";
import { TaxMethod } from "entity/tax/common/tax.types";
import { TaxRateBreakdownInfo } from "entity/tax/common/TaxRateBreakdown/TaxRateBreakdown.api.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";
import { VendorTypes } from "entity/vendor/common/vendor.types";

export type ChangeOrderFormActions =
    | undefined
    | "save"
    | "saveAndClose"
    | "saveAndRelease"
    | "delete"
    | "unrelease"
    | "resetToPending"
    | "approve"
    | "approveAndPay"
    | "decline"
    | "newInvoice"
    | "newBidPackage"
    | "newScheduleItem"
    | "sendToTheHomeDepotCart"
    | "checkedActions";

export enum ChangeOrderPresentingScreen {
    None = 0,
    FromRFI = 1,
    FromBidRequest = 2,
    FromSelection = 3,
    FromSelectionChoiceLineItem = 4,
    FromLeadProposalEstimateLineItem = 5,
    LeadOpportunity = 6,
    ScheduleItem = 7,
}

export interface IChangeOrderFormValues {
    changeOrderId: string;
    title: string;
    description: string;
    notes?: string;
    subsOnlyNotes?: string;
    ownerOnlyNotes?: string;
    deadLine?: moment.Moment;
    showLineItemsToOwner?: boolean;
    lineItems: LineItem[];
    subIDs: number[];
    customFields: ICustomFieldFormValues[];
    attachedFiles: AttachedFilesRequest;
    attachedFilesConfiguration: AttachedFiles;
    invoiceOnOwnerApproval?: boolean;
    amendments?: string;
    priceType: PriceTypes;
    lineItemContainerIsValid: boolean;

    // update values
    resetFiles?: ResetOptionTypes;
    notifyNewSubs?: boolean;
    notifySubs?: boolean;
    notifySubsResetText?: string;
    approvalComments?: string;
    approvalDate?: moment.Moment;
    updateSignature?: UpdateSignature;
    resetAmendedText?: ResetOptionTypes;
    approvalDisclaimer: boolean;

    changeOrderLineItems: ChangeOrderLineItem[];
    builderPrice?: number;
    ownerPrice?: number;
    amountInvoiced?: number;
    taxMethod: TaxMethod;
    taxGroupId?: number;
}

export class ChangeOrderEntity
    implements IBaseEntity, IHasDiscussionContainer, IHasLineItems, IHasCustomFieldContainer
{
    constructor(data: any) {
        this.id = data.id;
        this.jobId = data.jobId;
        this.builderId = data.builderId;
        this.jobInfo = data.jobInfo && new JobInfo(data.jobInfo);
        this.jobName = data.jobName;
        this.jobOpenedDate = data.jobOpenedDate && moment(data.jobOpenedDate);
        this.instanceKey = uuidv4();
        this.title = data.title.value;
        this.signaturePrefs = data.signaturePrefs
            ? data.signaturePrefs.value.map((item: any) => new BTSelectItem(item))
            : null;
        this.approvedBy = data.approvedBy;
        this.subsOnlyNotes = data.subsOnlyNotes && data.subsOnlyNotes.value;
        this.notes = data.notes && data.notes.value;
        this.ownerOnlyNotes = data.ownerOnlyNotes && data.ownerOnlyNotes.value;
        this.deadLine =
            data.deadLine && data.deadLine.value ? moment(data.deadLine.value) : undefined;
        this.createdBy = data.createdBy;
        this.createdById = data.createdById;
        this.createdByDate = data.createdByDate && moment(data.createdByDate.value);
        this.descHasHTML = data.descHasHTML;
        this.viewedByOwnerOn = data.viewedByOwnerOn && moment(data.viewedByOwnerOn);
        this.customChangeOrderId =
            data.changeOrderId && new CustomChangeOrderId(data.changeOrderId);
        this.description = data.description.value;
        this.descriptionPlainText = data.descriptionPlainText ?? undefined;
        this.amendments = data.amendments && data.amendments.value;
        this.approvalDetails = new ApprovalDetails(data.approvalDetails);
        this.approvalStatus = this.approvalDetails.status;
        this.ownerPrice = data.ownerPrice && data.ownerPrice.value;
        this.builderCost = data.builderCost && data.builderCost.value;
        this.useLineItems = data.useLineItems && data.useLineItems.value;
        this.priceType = this.useLineItems ? PriceTypes.LineItems : PriceTypes.FlatFee;
        this.lineItems = data.lineItems;
        this.hasLineItemsCanLinkToBid = data.hasLineItemsCanLinkToBid;
        this.hasLineItemsCanLinkToPo = data.hasLineItemsCanLinkToPo;
        this.subIDs = data.subIDs
            ? data.subIDs.value.map((item: any) => new BTSelectItem(item))
            : [];
        this.canAdd = data.canAdd;
        this.canEdit = data.canEdit;
        this.canDelete = data.canDelete;
        this.canAddBids = data.canAddBids;
        this.canEditBids = data.canEditBids;
        this.canViewBids = data.canViewBids;
        this.canAddPurchaseOrders = data.canAddPurchaseOrders;
        this.canEditPurchaseOrders = data.canEditPurchaseOrders;
        this.canViewPurchaseOrders = data.canViewPurchaseOrders;
        this.canAddScheduleItems = data.canAddScheduleItems;
        this.canViewWithoutPermission = data.canViewWithoutPermission;
        this.canEditWithoutPermission = data.canEditWithoutPermission;
        this.canViewPrice = data.canViewPrice;
        this.canSendToHomeDepotCart = data.canSendToHomeDepotCart ?? false;
        this.attachedFilesPreApproval = new PreApprovalAttachments(data);
        this.attachedFilesPostApproval =
            data.attachedFilesPostApproval && new PostApprovalAttachments(data);
        this.relatedRFIs = data.relatedRFIs && new RelatedRFIs(data.relatedRFIs);
        this.relatedBids = data.relatedBids
            ? data.relatedBids.map((item: any) => new RelatedBid(item))
            : null;
        this.relatedScheduleItems = data.relatedScheduleItems
            ? data.relatedScheduleItems.map((item: any) => new RelatedSchedule(item))
            : null;
        this.relatedPurchaseOrders = data.relatedPurchaseOrders
            ? data.relatedPurchaseOrders.map((item: any) => new RelatedPurchaseOrder(item))
            : [];
        this.relatedOwnerInvoices = data.relatedOwnerInvoices
            ? data.relatedOwnerInvoices.map((item: any) => new RelatedInvoice(item))
            : [];
        this.recentItemsTooltip =
            data.recentItemsTooltip === undefined
                ? undefined
                : new RecentItemsTooltipConfig(data.recentItemsTooltip);
        this.canMapToNewBidPackage = data.canMapToNewBidPackage;
        this.approvalStatusMessage = data.approvalStatusMessage || null;
        this.approvalDate = data.approvalDate && moment(data.approvalDate);
        this.showNoCustomFieldsMessage = data.showNoCustomFieldsMessage;
        this.customFieldOptions = data.customFieldOptions;
        this.customFields = data.customFields;
        this.customFieldAssociatedType = data.customFieldAssociatedType;
        this.customFieldsCanConfigure = data.customFieldsCanConfigure;
        this.costItemUpdateMessage = data.costItemUpdateMessage ?? null;
        this.discussions = new DiscussionsEntity(data.discussions);
        this.invoiceOnOwnerApproval =
            data.invoiceOnOwnerApproval && data.invoiceOnOwnerApproval.value;
        this.negativeAmountMessage = data.negativeAmountMessage;
        this.enableInvoiceOnOwnerApprovalCheckbox = data.enableInvoiceOnOwnerApprovalCheckbox;
        this.userCanInvoiceChangeOrder = data.userCanInvoiceChangeOrder;
        this.changeOrderIsInvoiceable = data.changeOrderIsInvoiceable;
        this.assignedToMessage = data.assignedToMessage && data.assignedToMessage.value;
        this.hasCostCodesFeature = data.hasCostCodesFeature;
        this.hasExistingInvoices = data.hasExistingInvoices;
        this.showApproveAndPayButton = data.showApproveAndPayButton;

        const userCanViewCOPrices = data.canViewWithoutPermission || data.canViewPrice;

        this.lineItemContainerData = {
            canAddOwnerPayments: data.canAddOwnerPayments,
            canEditCostCodes: data.lineItems ? data.lineItems.canEditCostCodes : false,
            canReorderLineItems: true,
            costCodeSelectorReadOnly: this.isDeclined(),
            displayTitle: true,
            entityId: data.id,
            entityPending: this.isPending(),
            hasEditPermission:
                userCanViewCOPrices && (this.canEdit || (this.isNew() && this.canAdd)),
            hasPercentInvoicePermission: data.userCanInvoiceChangeOrder,
            hasPriceViewingPermission: userCanViewCOPrices,
            inactiveCostCodeCutoffDate: data.inactiveCostCodeCutoffDate,
            isOwnerApproved: this.approvalStatus === ChangeOrderStatuses.APPROVED,
            jobId: data.jobId,
            linkedCostItemType: LinkedCostItemTypes.ChangeOrder,
            lineItemReadOnly: (!this.isNew() && !this.canEdit) || this.isOwnerDecided(),
            onlyLockMarkup: this.isOwnerDecided(),
            pageType: "change-order",
            pageTypeEnum: LineItemType.ChangeOrder,
            priceType: this.priceType,
            showCatalog: true,
            showMarkupTotal: true,
            showToOwner: data.showLineItemsToOwner
                ? data.showLineItemsToOwner.value
                : !!data.lineItems, // not present on the owner response.
            showVarianceCodes: false, // this may not be needed. I think the line item container defaults this false if this key isn't present.
            unitCostToolTipText:
                "The <b> Unit Cost</b> field on a Change Order represents the dollar amount of each item associated with the cost code.",
        };
        if (data.lineItems) {
            this.lineItemInitialValues = data.lineItems.value
                ? data.lineItems.value.map(
                      (x: ILineItemResponse) =>
                          new LineItem(
                              x,
                              this.lineItemContainerData.pageType,
                              this.lineItemContainerData.pageTypeEnum
                          )
                  )
                : [];
        }

        this.changeOrderLineItems = data.lineItems?.value.map(
            (x: ILineItemResponse) => new ChangeOrderLineItem(x) ?? []
        );

        // Tax Fields
        this.taxMethod = data.taxMethod && mapBTServiceDropdownToSelectItem(data.taxMethod);
        this.taxGroupId = data.taxGroupId;
        this.taxRateBreakdown =
            data.taxRateBreakdown && new TaxRateBreakdownInfo(data.taxRateBreakdown);
        this.totalWithTax = data.totalWithTax;

        // Owner only fields
        this.disclaimer = data.disclaimer ? data.disclaimer.value : null;
        this.expirationMessage = data.expirationMessage || null;
        this.canApprove = data.canApprove || null;
        this.requireSignatureForApproval = data.requireSignatureForApproval || null;
        this.approvalDisclaimer = false;
    }

    builderId: number;
    jobInfo: JobInfo;
    jobName: string;
    jobOpenedDate: moment.Moment;
    instanceKey: string;
    title: string;
    signaturePrefs?: BTSelectItem[];
    createdByDate?: moment.Moment;
    approvedBy?: string;
    subsOnlyNotes?: string;
    notes?: string;
    ownerOnlyNotes?: string;
    deadLine?: moment.Moment;
    createdBy?: string;
    createdById: number;
    descHasHTML?: boolean;
    viewedByOwnerOn?: moment.Moment;
    customChangeOrderId: CustomChangeOrderId;
    description: string;
    descriptionPlainText?: string;
    amendments?: string;
    approvalDetails: ApprovalDetails;
    ownerPrice?: number;
    builderCost?: number;
    useLineItems?: boolean;
    priceType: PriceTypes;
    lineItemInitialValues: LineItem[];
    lineItems: ILineItemData;
    lineItemContainerData: ILineItemContainerData;
    hasLineItemsCanLinkToBid?: boolean;
    hasLineItemsCanLinkToPo?: boolean;
    subIDs: BTSelectItem[];
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canAddBids?: boolean;
    canEditBids?: boolean;
    canViewBids?: boolean;
    canAddPurchaseOrders?: boolean;
    canEditPurchaseOrders?: boolean;
    canViewPurchaseOrders?: boolean;
    canAddScheduleItems?: boolean;
    canViewWithoutPermission: boolean;
    canEditWithoutPermission: boolean;
    canViewPrice: boolean;
    canSendToHomeDepotCart: boolean;
    attachedFiles: AttachedFiles;
    attachedFilesPreApproval: PreApprovalAttachments;
    attachedFilesPostApproval?: PostApprovalAttachments;
    relatedRFIs?: RelatedRFIs;
    relatedBids?: RelatedBid[];
    relatedScheduleItems?: RelatedSchedule[];
    relatedPurchaseOrders: RelatedPurchaseOrder[];
    relatedOwnerInvoices: RelatedInvoice[];
    recentItemsTooltip?: RecentItemsTooltipConfig;
    canMapToNewBidPackage: boolean;
    approvalStatusMessage?: string;
    approvalDate?: moment.Moment;
    id: number;
    jobId: number;
    approvalStatus: ChangeOrderStatuses;
    showNoCustomFieldsMessage: boolean;
    customFieldOptions: ICustomFieldOptionsList;
    customFields: ICustomFieldResponse[];
    customFieldAssociatedType: CustomFieldAssociatedType;
    customFieldsCanConfigure: boolean;
    costItemUpdateMessage: string | null;
    discussions: DiscussionsEntity;
    invoiceOnOwnerApproval?: boolean;
    negativeAmountMessage?: string;
    approvalDisclaimer: boolean;
    enableInvoiceOnOwnerApprovalCheckbox?: boolean;
    userCanInvoiceChangeOrder?: boolean;
    changeOrderIsInvoiceable?: boolean;
    assignedToMessage?: string;
    changeOrderLineItems: ChangeOrderLineItem[];
    hasCostCodesFeature: boolean;
    hasExistingInvoices: boolean;
    showApproveAndPayButton: boolean;

    // Tax Fields
    taxMethod: BTSelectItem[] | undefined;
    taxGroupId?: number;
    taxRateBreakdown?: TaxRateBreakdownInfo;
    totalWithTax?: number;

    // Owner only fields
    disclaimer?: string;
    expirationMessage?: string;
    canApprove?: boolean;
    requireSignatureForApproval?: boolean;

    isPending = () => {
        return this.hasStatus(undecidedStatuses);
    };

    hasUnreleaseableStatus = () => {
        return this.hasStatus(unreleaseableStatuses);
    };

    hasReleaseableStatus = () => {
        return this.hasStatus(releaseableStatuses);
    };

    hasResetToPendingStatus = () => {
        return this.hasStatus(decidedStatuses);
    };

    hasDeleteStatus = () => {
        return this.hasStatus(deleteStatuses);
    };

    hasStatus = (statuses: ChangeOrderStatuses[]) => {
        return statuses.some((s) => s === this.approvalStatus);
    };

    isDeclined = () => {
        return this.hasStatus([ChangeOrderStatuses.DECLINED, ChangeOrderStatuses.MANUAL_DECLINE]);
    };

    isApproved = () => {
        return this.hasStatus([ChangeOrderStatuses.APPROVED, ChangeOrderStatuses.MANUAL]);
    };

    /**
     * If a change order has a status of expired OR it has a status of pending
     * while being past the deadline date (if one exists), then it is expired.
     */
    isExpired = () => {
        if (this.approvalStatus === ChangeOrderStatuses.EXPIRED) {
            return true;
        }

        if (this.approvalStatus === ChangeOrderStatuses.PENDING && this.deadLine) {
            return this.deadLine.diff(moment(), "days") < 0;
        }

        return false;
    };

    isOwnerDecided = () => {
        return this.hasStatus([ChangeOrderStatuses.APPROVED, ChangeOrderStatuses.DECLINED]);
    };

    isNew = () => {
        return this.id === 0;
    };

    selectedSignaturePref = () => {
        return Number(this.signaturePrefs!.filter((x) => x.selected)[0].id) as SignaturePrefs;
    };

    // The user should only be able to save if they have edit permissions on COs.
    // However, even if a builder doesn't have Edit permission, they should be able to save
    // if they have Add permissions and are creating a new change order, (coId === 0)
    canSave = (): boolean => {
        return this.canEdit || this.canEditWithoutPermission || (this.canAdd && this.isNew());
    };
}
/**
 * Request object for the ResetStatus API call. Not actually for resetting the status. This can be used for any status, although we will only use this
 * for putting the status to unreleased, or to approved/declined for owner portal.
 */
export interface IChangeOrderResetStatusRequest {
    approvalStatus: ChangeOrderStatuses;
    approvalComments?: string;
    updateSignature?: UpdateSignature;
    updateCostItems?: boolean;
}

export interface IUpdateBidStatusFromChangeOrderRequest {
    changeOrderId: number;
    details?: string;
    notifyLoser: boolean;
    notifyWinner?: boolean;
}
const getOwnerPriceAndBuilderCost = (formData: IChangeOrderFormValues, useLineItems: boolean) => {
    let ownerPrice;
    let builderCost;
    let taxGroupId;
    let taxMethod;

    taxGroupId = formData.taxGroupId ?? null;
    taxMethod = formData.taxMethod;

    if (!useLineItems) {
        ownerPrice = formData.ownerPrice!;
        builderCost = formData.builderPrice!;
        if (formData.taxMethod === TaxMethod.None) {
            // if TaxMethod is None, we clear selected tax group
            taxGroupId = null;
        } else if ((taxGroupId ?? NoTaxId) === NoTaxId) {
            // if TaxMethod is not None, but no TaxGroup is selected, reset TaxMethod to None
            taxMethod = TaxMethod.None;
        }
    } else {
        // never send taxGroup in line item mode
        taxGroupId = null;
    }

    return { ownerPrice, builderCost, taxGroupId, taxMethod };
};

export class ChangeOrderSaveRequest {
    constructor(
        formData: IChangeOrderFormValues,
        approvalStatus: ChangeOrderStatuses,
        updateCostItems?: boolean
    ) {
        this.formData = formData;
        this.approvalStatus = approvalStatus;
        this.updateCostItems = updateCostItems;
        this.taxGroupId = formData.taxGroupId ?? null;

        this.useLineItems = this.formData.priceType === PriceTypes.LineItems;

        // Get ownerPrice, builderCost, taxGroupId, taxMethod for request
        Object.assign(this, getOwnerPriceAndBuilderCost(this.formData, this.useLineItems));
    }

    formData: IChangeOrderFormValues;
    approvalStatus: ChangeOrderStatuses;
    ownerPrice: number;
    builderCost: number;
    useLineItems: boolean;
    updateCostItems?: boolean;
    taxMethod: TaxMethod;
    taxGroupId: number | null;
}

export class ChangeOrderResetStatusResponse {
    constructor(data: any) {
        this.canPayOnline = data.canPayOnline;
        this.invoiceId = data.invoiceId;
        this.successMessage = data.successMessage;
    }

    canPayOnline: boolean;
    invoiceId?: number;
    successMessage?: string;
}

export class ChangeOrderVarianceImportResponse {
    constructor(data: any) {
        this.title = data.title;
        this.scopeOfWork = data.scopeOfWork;
    }

    title: string;
    scopeOfWork: string;
}

class CustomChangeOrderId {
    constructor(data: any) {
        this.value = data.value;
        this.title = data.title;
        this.prefix = data.prefix;
    }
    value: string;
    title: string;
    prefix: string;
}

class ApprovalDetails {
    constructor(data: any) {
        this.signature = data.signature;
        this.comments = data.comments && data.comments.value;
        this.status = data.status;
        this.statusText = data.statusText;
    }
    signature?: string;
    comments: string;
    status: number;
    statusText: string;
}

class RelatedBid {
    constructor(data: any) {
        this.bidId = data.bidId;
        this.bidPackageTitle = data.bidPackageTitle;
        this.subName = data.subName;
        this.isAccepted = data.isAccepted;
        this.isDeclined = data.isDeclined;
    }
    bidId: number;
    bidPackageTitle: string;
    subName: string;
    isAccepted: boolean;
    isDeclined: boolean;
}

class RelatedSchedule {
    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
    }
    id: number;
    title: string;
}

class PreApprovalAttachments implements IHasAttachedFiles {
    constructor(data: any) {
        this.attachedFiles = new AttachedFiles(data.attachedFilesPreApproval);
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.id = data.id;
    }
    documentInstanceType: DocumentInstanceType = DocumentInstanceType.ChangeOrder;
    name: string = "Pre-Approval";
    attachedFiles: AttachedFiles;
    id: number;
    jobId: number | undefined;
    builderId: number;
}

class PostApprovalAttachments implements IHasAttachedFiles {
    constructor(data: any) {
        this.attachedFiles = new AttachedFiles(data.attachedFilesPostApproval);
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.id = data.id;
    }
    documentInstanceType: DocumentInstanceType = DocumentInstanceType.ChangeOrder;
    name: string = "Post-Approval";
    attachedFiles: AttachedFiles;
    id: number;
    jobId: number | undefined;
    builderId: number;
}

export class ChangeOrderLineItem implements ILineItemRow, IChangeOrderLineItem {
    constructor(data: any) {
        this.id = data.lineItemId ?? data.id;
        this.builderCost = calculateBuilderCost(data.unitCost!, data.quantity);
        this.quantity = data.quantity;
        this.unitCost = data.unitCost;
        this.unit = data.unitType ?? data.unit;
        this.ownerPrice = data.ownerPrice;
        this.markupAmount = data.markupAmount ?? data.markupPrice;
        this.markupPerUnit = data.markupPerUnit;
        this.markupPercent = data.markupPercent ?? data.markupPercentage;
        this.margin = calculateMarginPercentageFromMarkup(
            data.markupPercent ?? data.markupPercentage ?? 0,
            data.markupAmount ?? data.markupPrice ?? 0,
            data.markupPerUnit ?? 0,
            data.unitCost ?? 0,
            data.quantity ?? 0
        );
        this.costCodeId = data.costCode ?? data.costCodeId;
        this.itemTitle = data.itemTitle ?? data.title;
        this.costItemId = data.costCodeItemId ?? data.costItemId;
        this.lineItemType = LineItemType.ChangeOrder;
        this.isExpanded = data.isExpanded ?? false;
        this.description = data.description;
        this.internalNotes = data.internalNotes;
        this.costTypes = data.costTypes ?? [];
        this.markedAs = data.markedAs ?? MarkedAs.None;
        this.isEditable = true;
        this._isInEdit = data._isInEdit;
        this.amountInvoiced = data.amountInvoiced ?? 0;
        this.autoFocusedField = data.autoFocusedField;
        let markupType = data.markupType ?? data.markupColumn;
        switch (markupType) {
            case MarkupColumnTypes.Percentage:
                this.markupType = MarkupType.percent;
                break;
            case MarkupColumnTypes.UnitPrice:
                this.markupType = MarkupType.perUnit;
                break;
            case MarkupColumnTypes.Price:
                this.markupType = MarkupType.flat;
                break;
            case MarkupColumnTypes.OwnerPrice:
                this.markupType = MarkupType.none;
                break;
            default:
                this.markupType = MarkupType.percent;
        }
        this.vendorProductId = data.vendorProductId ?? undefined;
        this.vendorType = data.vendorType ?? undefined;
        this.isDiscontinued = data.isDiscontinued ?? undefined;
        this.costCodeTitle = data.costCodeTitle;
        this.taxGroupId = data.taxGroupId;
        this.totalWithTax = data.totalWithTax;
    }

    id: number;
    unitCost: number;
    unit: string;
    quantity: number;
    builderCost: number;
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
    margin: number;
    description: string;
    internalNotes: string | null;
    costItemId: number | null;
    costTypes: CostTypes[] | null;
    markedAs: MarkedAs;
    parentId?: number | undefined;
    costCodeId: number | null;
    itemTitle: string | null;
    lineItemType: LineItemType;
    amountInvoiced: number | null;
    costCodeTitle: string | null;

    // IGridLineItemViewRow
    _isInEdit?: boolean;
    isSelected?: boolean;
    isExpanded?: boolean;
    isEditable: boolean;
    autoFocusedField?: string;

    // IVendorProductLineItem
    vendorProductId?: number;
    vendorType?: VendorTypes;
    isDiscontinued?: boolean;

    // ITaxLineItem
    taxGroupId?: number;
    totalWithTax?: number;
}
