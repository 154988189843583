import { Divider } from "antd";
import { message } from "antd";
import classNames from "classnames";
import update from "immutability-helper";
import { PureComponent, useCallback, useContext, useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import InfiniteScroll from "react-infinite-scroll-component";
import { RouteComponentProps } from "react-router";

import { BuilderInfoContext } from "helpers/globalContext/BuilderInfoContext";
import { useRequiredContext } from "helpers/globalContext/useRequiredContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { queryClient } from "helpers/queryClient";

import { BTLocalStorage } from "types/btStorage";

import { showAPIErrorMessage } from "utilities/apiHandler";
import { useActionBeingPerformed } from "utilities/form/form";
import { isInPortal } from "utilities/portal/portal";
import { routes } from "utilities/routes";

import { BTButton } from "commonComponents/btWrappers/BTButton/BTButton";
import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTIconSlidersHorizontal } from "commonComponents/btWrappers/BTIcon";
import { BTLinkRelative } from "commonComponents/btWrappers/BTLink/BTLink";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { NotificationCountQueryKey } from "commonComponents/utilities/MainNavigation/MainNavigation.api.handler";

import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { NotificationCard } from "entity/notification/NotificationCard/NotificationCard";
import { INotificationPanelHandler } from "entity/notification/NotificationPanel/NotificationPanel.api.handler";
import {
    InfiniteScrollResponse,
    NotificationItem,
    NotificationPanelFormActions,
} from "entity/notification/NotificationPanel/NotificationPanel.api.types";
import { NotificationPanelEmptyState } from "entity/notification/NotificationPanel/NotificationPanelEmptyState/NotificationPanelEmptyState";
import { SubSetupTabKeys } from "entity/sub/SubSetup/SubSetup.api.types";

import "./NotificationPanel.less";

export interface INotificationPanelProps extends RouteComponentProps {
    unreadCount?: number;
    notificationHandler: INotificationPanelHandler;
}

export interface INotificationListProps {
    list: NotificationItem[];
    mainNavRef: React.RefObject<HTMLDivElement>;
    openMenuId: number;
    onMenuOpen: (id: number) => void;
    onMarkRead: (notificationId: number, markRead: boolean) => Promise<void>;
}

class NotificationList extends PureComponent<INotificationListProps> {
    render() {
        const component = this.props.list.map((notification) => (
            <NotificationCard
                key={notification.id}
                entity={notification}
                notificationPanelRef={this.props.mainNavRef}
                openMenuId={this.props.openMenuId}
                onMenuOpen={this.props.onMenuOpen}
                onMarkRead={this.props.onMarkRead}
            />
        ));
        return component;
    }
}

export const NotificationPanel: React.FunctionComponent<INotificationPanelProps> = ({
    unreadCount,
    notificationHandler,
}) => {
    const [scrollData, setScrollData] = useState<InfiniteScrollResponse>(
        new InfiniteScrollResponse({ hasLoadedAll: false, infinitesScrollStatus: 0 })
    );
    const [cardItems, setCardItems] = useState<NotificationItem[]>([]);
    const [pagesLoaded, setPagesLoaded] = useState(0);
    const [openMenuId, setOpenMenuId] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [actionBeingPerformed, setActionBeingPerformed] =
        useActionBeingPerformed<NotificationPanelFormActions>();

    const builderInfo = useContext(BuilderInfoContext);
    const userInfo = useRequiredContext(UserInfoContext);

    const isMounted = useRef(false);
    const NotificationPanelRef = useRef(null);
    const unreadCountRef = useRef(unreadCount);

    const notificationsPerPage = 10;
    const previousId = cardItems?.slice(-1)[0]?.id ?? 0;

    const updateUnreadCount = useCallback((newCount: number) => {
        unreadCountRef.current = newCount;
        BTLocalStorage.set("bt-number-unreadNotificationCount", newCount);
        queryClient.setQueryData([NotificationCountQueryKey], newCount);
    }, []);

    const handleMarkRead = async (notificationId: number, markRead: boolean) => {
        const cardIndex = cardItems.findIndex((item) => item.id === notificationId);

        if (cardIndex === -1 || cardItems[cardIndex].isRead === markRead) {
            return;
        }

        try {
            await notificationHandler.updateStatus(
                notificationId,
                cardItems[cardIndex].messageData.builderId,
                markRead
            );
            const newCardItems = update(cardItems, {
                [cardIndex]: {
                    isRead: {
                        $set: markRead,
                    },
                },
            });

            let newCount = 0;
            if (typeof unreadCount !== "undefined") {
                newCount = markRead ? unreadCount - 1 : unreadCount + 1;
            }
            updateUnreadCount(newCount);
            if (isMounted.current) setCardItems(newCardItems);
        } catch (e: any) {
            void message.error("Error saving status update");
        }
    };

    const handleMarkAllRead = async () => {
        await setActionBeingPerformed({
            actionBeingPerformed: "markAllRead",
            callback: async () => {
                if (!userInfo || userInfo.globalUserId === null || !builderInfo) {
                    return;
                }

                try {
                    await notificationHandler.markAllRead(
                        userInfo.globalUserId!,
                        builderInfo.builderId
                    );
                    let newCardItems = cardItems.map((item: NotificationItem) => {
                        return { ...item, isRead: true };
                    });
                    if (isMounted.current) setCardItems(newCardItems);
                    updateUnreadCount(0);
                } catch (e: any) {
                    void message.error("Error saving status update");
                }
            },
        });
    };

    const loadList = useCallback(
        async (firstRow: number, lastRow: number, previousId: number) => {
            if (!isLoading) {
                try {
                    setIsLoading(true);
                    if (pagesLoaded === 0) {
                        // get updated notification count if loading first items for the first time
                        await queryClient.invalidateQueries([NotificationCountQueryKey]);
                    }
                    const entity = await notificationHandler.get(firstRow, lastRow, previousId);
                    if (isMounted.current) {
                        if (firstRow === 1) {
                            setCardItems(entity.notificationItems);
                        } else {
                            setCardItems((prevItems) => {
                                return [...prevItems, ...entity.notificationItems];
                            });
                        }
                        setScrollData(entity.infiniteScrollData);
                        setPagesLoaded(lastRow / notificationsPerPage);
                    }
                } catch (e) {
                    showAPIErrorMessage(e);
                } finally {
                    if (isMounted.current) setIsLoading(false);
                }
            }
        },
        [isLoading, notificationHandler, pagesLoaded]
    );

    const reloadList = useCallback(async () => {
        const firstRow = 1;
        const lastRow = notificationsPerPage * pagesLoaded;
        await loadList(firstRow, lastRow, 1);
    }, [pagesLoaded, loadList]);

    const loadNext = useCallback(async () => {
        const firstRow = notificationsPerPage * pagesLoaded + 1;
        const lastRow = notificationsPerPage * (pagesLoaded + 1);
        await loadList(firstRow, lastRow, previousId);
    }, [pagesLoaded, previousId, loadList]);

    useEffect(() => {
        isMounted.current = true;

        if (!pagesLoaded || pagesLoaded === 0) {
            void loadNext();
        }
        // notification count was updated while open
        else if (unreadCountRef.current !== unreadCount) {
            unreadCountRef.current = unreadCount;
            void reloadList();
        }

        return () => {
            isMounted.current = false;
        };
    }, [loadNext, pagesLoaded, unreadCount, reloadList]);

    return (
        <>
            <div className="NotificationPanel" id="NotificationPanel" ref={NotificationPanelRef}>
                <BTRow
                    data-testid="PanelTitle"
                    className="PanelHeader"
                    justify="center"
                    align="middle"
                >
                    <div className="flex-grow-1">Notifications</div>
                    <div className="margin-right-xs">
                        <BTButton<NotificationPanelFormActions>
                            actionBeingPerformed={actionBeingPerformed}
                            loadingAction="markAllRead"
                            data-testid="markAllRead"
                            type="tertiary"
                            onClick={handleMarkAllRead}
                            disabled={isLoading || unreadCount === 0}
                        >
                            Mark All Read
                        </BTButton>
                    </div>
                    <div>
                        <BTPopover
                            content="Preferences"
                            getPopupContainer={(trigger) => trigger.parentElement!}
                            placement="top"
                            breakWord={false}
                        >
                            <BTLinkRelative
                                to={`/NotificationPanel${getPreferencesLink(
                                    userInfo.globalUserId!
                                )}`}
                                className="NotificationLink"
                                isUnderline={false}
                            >
                                <BTButton
                                    data-testid="preferences"
                                    type="tertiary"
                                    icon={<BTIconSlidersHorizontal />}
                                />
                            </BTLinkRelative>
                        </BTPopover>
                    </div>
                </BTRow>
                {isLoading && (!cardItems || cardItems.length === 0) && (
                    <div>
                        <BTCol
                            xs={24}
                            data-testid="PanelLoadingState"
                            className="PanelLoadingState"
                        >
                            <BTLoading displayMode="absolute" />
                        </BTCol>
                    </div>
                )}
                <BTRow>
                    <BTCol xs={24}>
                        <Divider className="margin-vertical-zero" />
                    </BTCol>
                </BTRow>
                {(!cardItems || cardItems.length === 0) && !isLoading && (
                    <BTRow data-testid="PanelEmptyState" className="PanelEmptyState">
                        <BTCol xs={24}>
                            <NotificationPanelEmptyState
                                hasListData={false}
                                hasFilteredData={false}
                                isReadonly={true}
                                onCallToActionClick={() => {}}
                            />
                        </BTCol>
                    </BTRow>
                )}
                {cardItems && cardItems.length > 0 && (
                    <BTRow
                        id="NotificationPanelContent"
                        data-testid="NotificationPanelContent"
                        className="NotificationPanelContent"
                        onScroll={() => setOpenMenuId(0)}
                    >
                        <BTCol
                            xs={24}
                            className={classNames({
                                ForceOverflow: !scrollData.hasLoadedAll,
                            })}
                        >
                            <>
                                <InfiniteScroll
                                    hasMore={!scrollData.hasLoadedAll}
                                    next={loadNext}
                                    loader={<></>}
                                    dataLength={pagesLoaded}
                                    scrollThreshold={0.9}
                                    scrollableTarget="NotificationPanelContent"
                                    style={{ overflow: "unset" }}
                                >
                                    <NotificationList
                                        list={cardItems}
                                        mainNavRef={NotificationPanelRef}
                                        openMenuId={openMenuId}
                                        onMenuOpen={(id) => setOpenMenuId(id)}
                                        onMarkRead={handleMarkRead}
                                    />
                                </InfiniteScroll>
                            </>
                        </BTCol>
                    </BTRow>
                )}
            </div>
        </>
    );
};

export function getPreferencesLink(userId: number) {
    if (isInPortal({ builder: true })) {
        return routes.internalUsers.getInternalUserDetailsLink(
            userId,
            InternalUserTabKeys.Notifications
        );
    } else if (isInPortal({ subs: true })) {
        return routes.sub.getSetupLink(userId, SubSetupTabKeys.Notifications);
    } else if (isInPortal({ owner: true })) {
        return routes.owner.getSetupLink(userId);
    }
    return "";
}
