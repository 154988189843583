import { BTLocalStorage } from "types/btStorage";

let CurrentAppVersion: string | null = null;

export const checkForNewAppVersion = (version: string) => {
    version = removeColorSuffix(version);
    if (!CurrentAppVersion) {
        CurrentAppVersion = version;
    } else if (CurrentAppVersion !== version) {
        const currentTime = Date.now();
        CurrentAppVersion = version;
        BTLocalStorage.set("bt-string-newAppDetectedDate", currentTime);
    }
};

function removeColorSuffix(input: string): string {
    return input.replace(/-blue|-green/g, "");
}
