import { APIHandler } from "utilities/apiHandler";

import { AuthStoreForUsername } from "commonComponents/auth/AuthStoreDiscovery.types";

import { GenerateOAuthCodeResponse } from "entity/login/Login/Login.api.types";

export interface IAuthStoreDiscoveryHandler {
    getAuthStoreForUsername: (
        username: string,
        recaptchaToken: string,
        shareToken?: string
    ) => Promise<AuthStoreForUsername>;
    generateOauthCode(): Promise<GenerateOAuthCodeResponse>;
}

export class AuthStoreDiscoveryHandler implements IAuthStoreDiscoveryHandler {
    async getAuthStoreForUsername(
        username: string,
        recaptchaToken: string,
        shareToken?: string
    ): Promise<AuthStoreForUsername> {
        return APIHandler("/api/login/GetAuthStoreForUsername", {
            responseType: AuthStoreForUsername,
            method: "GET",
            data: {
                username,
                recaptchaToken,
                shareToken,
            },
        });
    }

    async generateOauthCode(): Promise<GenerateOAuthCodeResponse> {
        return await APIHandler("/api/Login/GenerateOauthCode", {
            method: "POST",
            responseType: GenerateOAuthCodeResponse,
        });
    }
}
