import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";

import { APIError, showAPIErrorMessage } from "utilities/apiHandler";
import { useActionBeingPerformed } from "utilities/form/form";

import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";

import { handleRedirectPage } from "entity/login/Login/Login.utils";
import { PasswordResetHandler } from "entity/passwordReset/PasswordReset/PasswordReset.api.handler";
import {
    IPasswordResetProps,
    IPasswordResetResponse,
    PasswordResetFormActions,
} from "entity/passwordReset/PasswordReset/PasswordReset.api.types";
import {
    IPasswordResetFormValues,
    PasswordResetPresentational,
} from "entity/passwordReset/PasswordReset/PasswordResetPresentational";
import { IUserActivationPageLoginRequest } from "entity/UserActivationPage/UserActivationPage.api.types";

const defaultPasswordResetHandler = new PasswordResetHandler();

const PasswordReset = ({
    handler = defaultPasswordResetHandler,
    jwt,
    globalUserId,
}: IPasswordResetProps & RouteComponentProps) => {
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [isTokenValid, setIsTokenValid] = useState<boolean>();
    const [loginData, setLoginData] = useState<IPasswordResetResponse>();
    const [actionBeingPerformed, setActionBeingPerformed] =
        useActionBeingPerformed<PasswordResetFormActions>();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const request = { id: globalUserId, jwt: jwt };
                await handler.validateJwt(request);
                setIsTokenValid(true);
            } catch (e) {
                showAPIErrorMessage(e);
                setIsTokenValid(false);
            }
        };
        void validateToken();
    }, [handler, globalUserId, jwt]);

    const handleSubmit = async (values: IPasswordResetFormValues) => {
        await setActionBeingPerformed({
            actionBeingPerformed: "submit",
            callback: async () => {
                try {
                    const request = {
                        pwd: values.password,
                        cpwd: values.confirmPassword,
                        jwt: jwt,
                        id: globalUserId,
                    };
                    const resp = await handler.updatePassword(request);
                    setLoginData(resp);
                    setPasswordUpdated(true);
                } catch (e) {
                    if (
                        e instanceof APIError &&
                        (e.response.message === "Invalid signature" ||
                            e.response.message === "Token has expired")
                    ) {
                        setIsTokenValid(false);
                    }
                    showAPIErrorMessage(e);
                }
            },
        });
    };

    const handleLogin = async (values: IPasswordResetFormValues) => {
        try {
            const loginRequest: IUserActivationPageLoginRequest = {
                username: loginData!.userName,
                password: values.password,
                signInDescription: "Password Reset",
            };

            const response = await handler.login(loginRequest);

            handleRedirectPage({
                loginResponse: response,
                isOAuthRequest: false,
                oauthRedirectUri: undefined,
                oauthState: undefined,
                loginPostRedirect: undefined,
                setLoginStateForMFA: redirectToLogin,
                setLoginStateForRequireEmail: redirectToLogin,
                resetLoadingSpinner: redirectToLogin,
            });
        } catch (e) {
            showAPIErrorMessage(e);
        }
    };

    const redirectToLogin = () => {
        window.location.assign("/");
    };

    if (isTokenValid === undefined) {
        return <BTLoading />;
    }

    return (
        <PasswordResetPresentational
            handleSubmit={handleSubmit}
            handleLogin={handleLogin}
            redirectToLogin={redirectToLogin}
            passwordUpdated={passwordUpdated}
            actionBeingPerformed={actionBeingPerformed}
            isTokenValid={isTokenValid}
        />
    );
};

export default PasswordReset;
