import {
    BuilderFlags,
    BuilderInfo,
    IntegrationUserProfile,
    UserInfo,
} from "helpers/AppProvider.types";

export class AnalyticsTraits {
    constructor(
        userInfo: UserInfo,
        integrationUserProfile: IntegrationUserProfile,
        builderInfo: BuilderInfo
    ) {
        this.userTraits = userInfo;
        this.integrationUserProfile = integrationUserProfile;
        this.builderTraits = new AnalyticsBuilderTraits(builderInfo);
    }

    userTraits: UserInfo;
    integrationUserProfile: IntegrationUserProfile;
    builderTraits: AnalyticsBuilderTraits;
}

export class AnalyticsBuilderTraits {
    constructor(data: BuilderInfo) {
        this.builderId = data.builderId;
        this.builderName = data.name;
        this.isDemoAccount = data.isDemoAccount;
        this.flags = data.flags;
    }

    builderId: number;
    builderName: string;
    isDemoAccount: boolean;
    flags: BuilderFlags;
}
