import { IGridSettingsFormValues } from "commonComponents/utilities/Grid/common/GridSettings/GridSettings.api.types";

import { FilterEntityType } from "entity/filters/Filter/Filter.api.types";
import { ISavedFilterFormValues } from "entity/filters/SavedFilter/SavedFilter.api.types";

export type SavedViewsAndFiltersCardActions =
    | undefined
    | "save"
    | "delete"
    | "apply"
    | "setDefault";

export type SavedViewsAndFiltersSettingsState =
    | "closed"
    | "open"
    | "addingNew"
    | "addingCurrent"
    | "copying"
    | "editing";

export const getFeatureLabel = new Map<number, string>([
    [FilterEntityType.None, "[Feature]"],
    // Types from list pages
    [FilterEntityType.LeadsListView, "Lead Opportunities"],
    [FilterEntityType.DailyLogView, "Daily Logs"],
    [FilterEntityType.ToDoSummaryView, "To-Do's"],
    [FilterEntityType.MessagesListView, "Messages"],
    [FilterEntityType.BidsBuilder, "Bids"],
    [FilterEntityType.MessagesCommentsView, "Comments"],
    [FilterEntityType.ScheduleBaselineView, "Baseline Schedule"],
    [FilterEntityType.CalendarView, "Calendar"],
    [FilterEntityType.ScheduleListView, "Schedule"],
    [FilterEntityType.CalendarGanttView, "Gantt"],
    [FilterEntityType.JobsList, "Jobs"],
    [FilterEntityType.ScheduleWorkdayExceptionView, "Workday Exceptions"],
    [FilterEntityType.NotificationHistory, "Notification History"],
    [FilterEntityType.ReceiptList, "Cost Inbox"],
    [FilterEntityType.EstimateWorksheet, "Estimate"],
    [FilterEntityType.TemplatesList, "Templates"],
    [FilterEntityType.OwnerPaymentsList, "Invoices"],
    [FilterEntityType.GroupedSurveyList, "Grouped Surveys"],
    [FilterEntityType.SelectionsListView, "Selections"],
    [FilterEntityType.IndividualSurveyList, "Individual Surveys"],
    [FilterEntityType.SurveyQuestionsList, "Survey Questions"],
    [FilterEntityType.WarrantySummaryView, "Warranties"],
    [FilterEntityType.CostCodeItems, "Cost Items"],
    [FilterEntityType.CostGroups, "Cost Groups"],
    [FilterEntityType.LeadsActivityView, "Lead Activities"],
    [FilterEntityType.LeadsActivityTemplate, "Lead Activity Templates"],
    [FilterEntityType.LeadProposals, "Lead Proposals"],
    [FilterEntityType.OnlinePaymentReport, "Online Payment Report"],
    [FilterEntityType.LeadProposalTemplate, "Lead Proposal Template"],
    [FilterEntityType.SelectionsAllowanceView, "Selection Allowances"],
    [FilterEntityType.PurchaseOrderList, "Purchase Orders"],
    [FilterEntityType.POPaymentsView, "Bills"],
    [FilterEntityType.ChangeOrdersListView, "Change Orders"],
    [FilterEntityType.UsersInternalView, "Internal Users"],
    [FilterEntityType.RFIsList, "RFIs"],
    [FilterEntityType.WarrantyServices, "Warranty Services"],

    // React pages with filters but no saved views
    [FilterEntityType.BudgetPOView, "Budget PO's"],
    [FilterEntityType.BudgetSummaryView, "Budget"],
    [FilterEntityType.BudgetCategory, "Budget Category"],
    [FilterEntityType.MessagesConversationsView, "Conversations"],
    [FilterEntityType.ChangeOrdersProfitSummaryView, "Change Orders Profit Summary"],
    [FilterEntityType.ActivityFeed, "Recent Activity"],
    [FilterEntityType.TimeClockReportView, "Time Clock"],
    [FilterEntityType.TimeClockMapView, "Shifts Map"],
    [FilterEntityType.LeadsDetailActivityView, "Lead Details Activites"],
    [FilterEntityType.SelectionCardView, "Selection Card View"],
]);

export interface ISavedViewsAndFiltersCardFormValues
    extends IGridSettingsFormValues,
        ISavedFilterFormValues {
    viewName: string;
    filterName: string;
    isDefault: boolean;
    isPrivate: boolean;
    isFilter: boolean;
    filterType: FilterEntityType;
    selectedFilter: number;
    value: any;
    columns: (string | number)[];
    savedView: number;
}
