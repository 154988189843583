import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import antd_en_US from "antd/lib/locale-provider/en_US";
import "array-flat-polyfill";
import * as elementClosest from "element-closest";
import Cookies from "js-cookie";
import { createContext, memo, ReactNode, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import "whatwg-fetch";

import {
    globalInfoQueryKey,
    IAppProviderHandler,
    useGetGlobalInfoQuery,
} from "helpers/AppProvider.api.handler";
import { AppProviderBTJScriptGlobalsHandler } from "helpers/AppProvider.btJScriptGlobals.api.handler";
import { btJScriptGlobalsDefault, GlobalInfo } from "helpers/AppProvider.types";
import { AuthLogHandler, IAuthLogHandler } from "helpers/AuthLog.api.handler";
import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";
import {
    BrandingInfoContext,
    defaultBrandingInfo,
} from "helpers/globalContext/BrandingInfoContext";
import { BuilderInfoProvider } from "helpers/globalContext/BuilderInfoContext";
import { EnvironmentInfoContext } from "helpers/globalContext/EnvironmentInfoContext";
import { FilterValueContextProvider } from "helpers/globalContext/FilterValueContext";
import { SupportContactInfoContext } from "helpers/globalContext/SupportContactInfoContext";
import { ThirdPartyInfoContext } from "helpers/globalContext/ThirdPartyInfoContext";
import { UserInfoContext } from "helpers/globalContext/UserInfoContext";
import { PushNotificationHandler } from "helpers/pushNotification.api.handler";
import { IPushNotificationHandler } from "helpers/pushNotification.types";
import { queryClient } from "helpers/queryClient";

import { useReadLocalStorage } from "types/btStorage";
import { BTLoginTypes } from "types/enum";

import {
    dispatchTracking,
    identify,
    ITrackingData,
    processTracking,
    resetAnonymousUserIdentity,
    shouldIdentifyUser,
    track,
    useTracking,
} from "utilities/analytics/analytics";
import { AnalyticsTraits } from "utilities/analytics/analytics.api.types";
import { getClosestModal } from "utilities/helpers";
import { ParagonProvider } from "utilities/thirdPartyIntegrations/paragon/ParagonProvider";
import { ThirdPartyIntegrations } from "utilities/thirdPartyIntegrations/ThirdPartyIntegrations";

import MapApiProvider from "commonComponents/entity/map/common/mapApiContext/MapApiProvider";
import { BTLoading } from "commonComponents/utilities/BTLoading/BTLoading";
import { FullscreenProvider } from "commonComponents/utilities/FullscreenContext/FullscreenProvider";
import { IntercomChat } from "commonComponents/utilities/IntercomChat/IntercomChat";
import PageTitle from "commonComponents/utilities/PageTitle/PageTitle";
import { StickyProvider } from "commonComponents/utilities/StickyContext";

import "../helpers/antdGlobalConfig.tsx";

// initialize element closest polyfill
elementClosest.default(window);

const customAntDatePickerLocale = {
    dateFormat: "ll",
    dateTimeFormat: "ll HH:mm",
    weekFormat: "wo [week of] YYYY",
    monthFormat: "MMM YYYY",
};

export interface IAppContextValue {
    /**
     * **Do not use this unless you have to.**
     * If you want to access context values, consume the sub-contexts instead.
     *
     * Right now this is only used for the gantt chart to get around issues with the added delay of
     * making the API call that fetches global info which populates the sub-contexts (even when, for
     * most cases, the call is instantaneous).
     */
    _globalInfo: GlobalInfo;
    reloadGlobalInfo: () => Promise<void>;
}

type AppContextValueWithOptionalGlobalInfo = Omit<IAppContextValue, "_globalInfo"> &
    Partial<Pick<IAppContextValue, "_globalInfo">>;

/**
 * Context to reload the global info/state of the app. Use this whenever switching accounts/builders
 * that should trigger a soft-reload for SPA enabled pages
 */
export const AppContext = createContext<IAppContextValue | undefined>(undefined);

const defaultAppProviderHandler = new AppProviderBTJScriptGlobalsHandler();

const isGlobalInfoDefined = (
    appContextValue: AppContextValueWithOptionalGlobalInfo
): appContextValue is IAppContextValue => {
    return appContextValue._globalInfo !== undefined;
};

export interface IAppProviderInternalProps {
    /**
     * Is this the root provider for the page. Used because we need to use this provider for some sub-modals.
     * This prop is needed because we use <Helmet><title> only on the root level (so confirms don't change the document title)
     * @default true
     */
    isRoot?: boolean;
    handler?: IAppProviderHandler;

    /**
     * When this provider is the base of a non-SPA list page, use this flag to track a page view event
     */
    sendPageView?: boolean;
    encryptedBuilderId?: string;
    deferIntercomLoad?: boolean;
}

const AppProviderInternal: React.FC<IAppProviderInternalProps> = ({
    handler = defaultAppProviderHandler,
    ...restProps
}) => {
    if (window.btJScriptGlobals === undefined) {
        window.btJScriptGlobals = btJScriptGlobalsDefault;
    }
    const { data, status, error } = useGetGlobalInfoQuery(
        handler,
        undefined,
        restProps.encryptedBuilderId
    );

    const contextValue: AppContextValueWithOptionalGlobalInfo = useMemo(() => {
        return {
            _globalInfo: data,
            reloadGlobalInfo: async () => {
                await queryClient.refetchQueries({
                    queryKey: [globalInfoQueryKey],
                    type: "active",
                });
            },
        };
    }, [data]);

    if (!isGlobalInfoDefined(contextValue)) {
        switch (status) {
            case "loading":
                return <BTLoading />;
            case "error":
                throw error;
            default:
                throw new Error("Global info returned undefined");
        }
    }

    // eslint-disable-next-line react/forbid-elements
    return <PassthroughAppProvider {...restProps} contextValue={contextValue} />;
};

interface IAppProviderPassthroughProps {
    isRoot?: boolean;
    sendPageView?: boolean;
    /**
     * **Do not use this unless you have to.**
     * If you want to overwrite context, overwrite the sub-contexts.
     */
    contextValue: IAppContextValue;
    pushNotificationHandler?: IPushNotificationHandler;
    deferIntercomLoad?: boolean;
}

const defaultPushNotificationHandler = new PushNotificationHandler();

/**
 * Do not use this component. This component only exists as a hack to get around the delay in
 * rendering that would normally occur with AppProvider as it loads the global info contexts.
 *
 * Currently, we need this for component tests so that the delay in rendering doesn't interfere with
 * the react-testing-libraries "instant"/"without-delay" queries. We also use this to get around some
 * weirdness and rendering issues with our Gantt chart library.
 */
export const PassthroughAppProvider: React.FC<IAppProviderPassthroughProps> = ({
    children,
    isRoot = true,
    contextValue,
    sendPageView,
    deferIntercomLoad,
    pushNotificationHandler = defaultPushNotificationHandler,
}) => {
    const { trackEvent } = useTracking();
    const builderInfo = contextValue._globalInfo.builderInfo;

    useEffect(() => {
        if (sendPageView) {
            const userInfo = contextValue._globalInfo.userInfo;
            const integrationUserProfile = contextValue._globalInfo.integrationUserProfile;

            // Reset external page users that were grouped under distinct_id: 0
            // Can be cleaned up after we stop getting new events with that id
            resetAnonymousUserIdentity();
            if (
                shouldIdentifyUser(userInfo?.globalUserId) &&
                builderInfo &&
                userInfo?.globalUserId &&
                integrationUserProfile
            ) {
                let traits: AnalyticsTraits = new AnalyticsTraits(
                    userInfo,
                    integrationUserProfile,
                    builderInfo
                );
                identify(userInfo.globalUserId, traits);
            }

            try {
                const payload: Partial<ITrackingData> = {
                    event: "PageView",
                    pageView: true,
                };
                trackEvent(payload);
            } catch {
                // Swallow error
            }
        }
    });

    // Register service worker if this is the root app provider. Eventually, post SPA, this should
    // probably be moved to some other component within App.tsx
    useEffect(() => {
        (async () => {
            if (!isRoot) {
                return;
            }

            try {
                const registration = await serviceWorkerRegistration.register({
                    firebase: contextValue._globalInfo.thirdPartyInfo.firebaseConfig,
                });

                if (
                    registration &&
                    contextValue._globalInfo.thirdPartyInfo.firebaseConfig &&
                    // AppProvider is used on external/login pages, but registering the browser
                    // for push notifications requires authentication (so we know what users are
                    // tied to a given firebase registration token for push delivery) which can
                    // cause auth errors and redirects.Therefore, require a user in session by
                    // checking if globalUserId is defined. Might need to adjust this in the
                    // future so that we register the events on login or something
                    contextValue._globalInfo.userInfo.globalUserId
                ) {
                    const pushNotificationInstance =
                        window.rootForegroundNotificationController.getInstance(
                            registration,
                            contextValue._globalInfo.thirdPartyInfo.firebaseConfig,
                            pushNotificationHandler
                        );
                    await pushNotificationInstance.enablePushNotificationsForCurrentUser(
                        contextValue._globalInfo.userInfo.globalUserId
                    );
                }
            } catch (e) {
                console.error("Failed to register service worker from within app provider", e);
            }
        })();
    }, [
        isRoot,
        pushNotificationHandler,
        contextValue._globalInfo.thirdPartyInfo.firebaseConfig,
        contextValue._globalInfo.userInfo.globalUserId,
    ]);

    const antLocale = {
        ...antd_en_US,
        DatePicker: {
            ...antd_en_US.DatePicker!,
            ...customAntDatePickerLocale,
        },
    };

    return (
        // eslint-disable-next-line react/forbid-elements
        <AppContext.Provider value={contextValue}>
            <ConfigProvider locale={antLocale} getPopupContainer={getClosestModal}>
                <GlobalInfoProvider
                    {...contextValue._globalInfo}
                    deferIntercomLoad={deferIntercomLoad}
                >
                    <ThirdPartyIntegrations appContext={contextValue} />
                    <MapApiProvider
                        enabled={contextValue._globalInfo.appDefaultInfo.enableGoogleMaps}
                        apiKey={contextValue._globalInfo.thirdPartyInfo.googleMapsApiKey}
                    >
                        <ParagonProvider>
                            <StickyProvider>
                                <TitleTracker builderId={builderInfo?.builderId.toString()}>
                                    {isRoot && (
                                        /* default the title to the current webforms document title, needed for the reverse proxy so we don't overwrite existing list page titles */
                                        <PageTitle
                                            title={document.title.replace("| Buildertrend", "")}
                                        />
                                    )}
                                    {children}
                                </TitleTracker>
                            </StickyProvider>
                        </ParagonProvider>
                    </MapApiProvider>
                </GlobalInfoProvider>
            </ConfigProvider>
        </AppContext.Provider>
    );
};

const GlobalInfoProviderInternal: React.FC<
    GlobalInfo & { deferIntercomLoad?: boolean; children: ReactNode }
> = ({
    brandingInfo,
    thirdPartyInfo,
    builderInfo,
    supportContactInfo,
    environmentInfo,
    appDefaultInfo,
    userInfo,
    deferIntercomLoad,
    children,
}) => {
    return (
        <FullscreenProvider>
            <BrandingInfoContext.Provider value={brandingInfo ?? defaultBrandingInfo}>
                <ThirdPartyInfoContext.Provider value={thirdPartyInfo}>
                    <EnvironmentInfoContext.Provider value={environmentInfo}>
                        <UserInfoContext.Provider value={userInfo}>
                            <BuilderInfoProvider value={builderInfo}>
                                <SupportContactInfoContext.Provider value={supportContactInfo}>
                                    <AppDefaultInfoContext.Provider value={appDefaultInfo}>
                                        <FilterValueContextProvider>
                                            <IntercomChat
                                                userInfo={userInfo}
                                                appDefaultInfo={appDefaultInfo}
                                                deferIntercomLoad={deferIntercomLoad}
                                            >
                                                <AuthLogger loginType={userInfo.loginType}>
                                                    {children}
                                                </AuthLogger>
                                            </IntercomChat>
                                        </FilterValueContextProvider>
                                    </AppDefaultInfoContext.Provider>
                                </SupportContactInfoContext.Provider>
                            </BuilderInfoProvider>
                        </UserInfoContext.Provider>
                    </EnvironmentInfoContext.Provider>
                </ThirdPartyInfoContext.Provider>
            </BrandingInfoContext.Provider>
        </FullscreenProvider>
    );
};

const GlobalInfoProvider = memo(GlobalInfoProviderInternal);

export const AppProvider: React.FC<IAppProviderInternalProps> = track(undefined, {
    dispatch: (data) => dispatchTracking(data),
    process: (ownTrackingData) => processTracking(ownTrackingData),
})((props) => {
    const showReactQueryDevTools = useReadLocalStorage("bt-boolean-debugReactQuery");
    return (
        <QueryClientProvider client={queryClient}>
            <AppProviderInternal {...props} />
            {showReactQueryDevTools && <ReactQueryDevtools />}
        </QueryClientProvider>
    );
});

const TitleTracker: React.FunctionComponent<{ builderId?: string | undefined }> = (props) => {
    const [page, setPage] = useState("");
    return (
        <>
            <Helmet
                onChangeClientState={(newState) => {
                    if (newState.title !== page) {
                        setPage(newState.title);
                    }
                }}
            />
            <TitleTrackerInner page={page} builderId={props.builderId}>
                {props.children}
            </TitleTrackerInner>
        </>
    );
};

const TitleTrackerInner: React.FunctionComponent<{
    page: string;
    builderId?: string | undefined;
}> = track((props) => ({
    page: props.page,
    builderId: props.builderId,
}))((props) => <>{props.children}</>);

export interface IAuthLoggerProps {
    loginType?: BTLoginTypes;
    handler?: IAuthLogHandler;
}

const AuthLogger: React.FC<IAuthLoggerProps> = ({
    handler = new AuthLogHandler(),
    ...restProps
}) => {
    const { loginType } = restProps;
    const hasUser =
        loginType === BTLoginTypes.BUILDER ||
        loginType === BTLoginTypes.SUBS ||
        loginType === BTLoginTypes.OWNER;

    useEffect(() => {
        async function callAuthLog() {
            try {
                await handler.authLog();
                const in8Hours = 1 / 3;
                // eslint-disable-next-line deprecate/member-expression
                Cookies.set("reauth-cookie-heartbeat", "true", { expires: in8Hours });
            } catch (e: any) {
                console.error(e);
            }
        }

        // eslint-disable-next-line deprecate/member-expression
        const reauthCookie = Cookies.get("reauth-cookie-heartbeat");
        // eslint-disable-next-line deprecate/member-expression
        const inProgressCookie = Cookies.get("reauth-cookie-heartbeat-progress");
        if (hasUser && !reauthCookie && !inProgressCookie) {
            const in1Minute = 1 / 1440;
            // eslint-disable-next-line deprecate/member-expression
            Cookies.set("reauth-cookie-heartbeat-progress", "true", { expires: in1Minute });
            void callAuthLog();
        }
    });
    return <>{restProps.children}</>;
};
