import { ModalFuncProps } from "antd";
import { FormikValues } from "formik/dist/types";

import { dispatchTracking } from "utilities/analytics/analytics";

import { btConfirm } from "commonComponents/btWrappers/BTConfirm/BTConfirm";
import { IModalConfiguration } from "commonComponents/btWrappers/BTModal/BTModal";
import { IDirtyTrackingProps } from "commonComponents/utilities/DirtyTracking/DirtyTracking.utilities";

interface IUnsavedChangesDialogProps
    extends Omit<
        ModalFuncProps,
        "title" | "content" | "okText" | "okType" | "onOk" | "cancelText" | "onCancel" | "closeable"
    > {
    onCloseWithSave?: () => void | Promise<void | Record<string, string>>;
    onCloseWithoutSave?: () => void;
    onDontClose?: () => void;
}

/**
 * Prompt the user to save the unsaved changes before exiting a details modal.
 */
export const showUnsavedChangesPrompt = function (
    dirtyKeys: string[],
    props: IUnsavedChangesDialogProps
) {
    const { onCloseWithSave, onCloseWithoutSave, onDontClose, ...rest } = props;
    btConfirm({
        ...rest,
        title: `Unsaved changes`,
        content: (
            <div>You have unsaved changes, do you want to save your changes before closing?</div>
        ),
        okText: "Save",
        okType: "primary",
        okButtonProps: { "data-testid": "dirtyTrackingSave" },
        onOk: async () => {
            await onCloseWithSave?.();
        },
        cancelText: "Don't save",
        onCancel: () => {
            onDontClose?.();
        },
        cancelButtonProps: {
            // Need to differentiate between the close and cancel buttons
            // Give onCancel time to fire before closing the parent modal
            onClickCapture: () => {
                dispatchTracking({
                    event: "ButtonClick",
                    uniqueId: "dirtyTrackingDontSaveData",
                    extraInfo: {
                        dirtyKeys,
                    },
                });
                // eslint-disable-next-line @buildertrend/enterprise-extras/no-unhandled-scheduling
                setTimeout(() => onCloseWithoutSave?.(), 200);
            },
            "data-testid": "dirtyTrackingDontSave",
        },
        closable: true,
    });
};

export const getUnsavedChangesModalConfig = function <Values extends FormikValues>(
    getDirtyTrackingProps: () => IDirtyTrackingProps<Values>,
    props: IUnsavedChangesDialogProps,
    parentRoute?: string,
    canSave: boolean = true,
    otherDirtyKeys: string[] = [],
    overrideIsDirty?: boolean
): IModalConfiguration {
    return {
        parentRoute: parentRoute ?? "",
        beforeClose: () => {
            if (!canSave) {
                props.onCloseWithoutSave?.();
            } else {
                const { dirtyKeys, isDirty } = getDirtyTrackingProps();
                if (isDirty || otherDirtyKeys.length > 0 || overrideIsDirty) {
                    showUnsavedChangesPrompt([...dirtyKeys, ...otherDirtyKeys], props);
                } else {
                    props.onCloseWithoutSave?.();
                }
            }
        },
    };
};
