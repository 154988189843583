import { ReactNode } from "react";
import { RouteComponentProps } from "react-router";

import { AppDefaultInfo, EnvironmentInfo } from "helpers/AppProvider.types";
import { BuilderInfo, UserInfo } from "helpers/AppProvider.types";

import { BTLoginTypes, MenuItemName, OpenActivityType } from "types/enum";

import { isDevEnvironment } from "utilities/environment/environment";
import { externalPages } from "utilities/externalPages";
import { getGlobalValue } from "utilities/globalValueUtils";
import { routes } from "utilities/routes";
import { routesWebforms } from "utilities/routesWebforms";
import { showChat } from "utilities/thirdPartyIntegrations/intercom/intercom";

import {
    BTIconAdditionalServices,
    BTIconArrowsClockwise,
    BTIconBidPackages,
    BTIconBillsPurchaseOrders,
    BTIconBudget,
    BTIconBulb,
    BTIconChangeOrders,
    BTIconClockCounterClockwise,
    BTIconComments,
    BTIconCompassOutlined,
    BTIconContacts,
    BTIconCopy,
    BTIconDailyLogs,
    BTIconDocuments,
    BTIconEstimate,
    BTIconGearSix,
    BTIconHelpCenter,
    BTIconInfo,
    BTIconIntegrations,
    BTIconInternalUsers,
    BTIconJobs,
    BTIconKeyLock,
    BTIconLeadProposals,
    BTIconLeads,
    BTIconLearningAcademy,
    BTIconListBullets,
    BTIconLiveChat,
    BTIconMapPin,
    BTIconMarketplace,
    BTIconMessages,
    BTIconOwnerInvoices,
    BTIconPhoneOutline,
    BTIconPhotos,
    BTIconPlusCircleTransparent,
    BTIconPriceTag,
    BTIconPurchaseOrders,
    BTIconRebates,
    BTIconReceipts,
    BTIconRFIs,
    BTIconSchedules,
    BTIconSelections,
    BTIconSettingsBlack,
    BTIconSignOut,
    BTIconSpecifications,
    BTIconSubs,
    BTIconSubscription,
    BTIconSurveys,
    BTIconTable,
    BTIconTermsAndConditions,
    BTIconTimeClock,
    BTIconTodos,
    BTIconVideos,
    BTIconWarranties,
} from "commonComponents/btWrappers/BTIcon";
import { BTUser } from "commonComponents/btWrappers/BTUser/BTUser";
import {
    MainNavigationResponse,
    NavHeaderResponse,
    NavMenuItemResponse,
} from "commonComponents/utilities/MainNavigation/MainNavigation.api.types";

import { InternalUserTabKeys } from "entity/internalUser/InternalUserDetails/InternalUser.api.types";
import { JobDetailTab } from "entity/job/Job.api.types";
import { JobListTabs } from "entity/job/JobList/JobList.api.types";
import { LeadTabTypes } from "entity/lead/Common/LeadTab/LeadTab.types";
import { PurchasingDashboardPages } from "entity/rebate/PurchasingDashboard/PurchasingDashboard.types";
import { ScheduleListContainerTabs } from "entity/schedule/ScheduleListContainer/ScheduleListContainer.api.types";
import { SelectionListContainerTabs } from "entity/selection/SelectionListContainer/SelectionListContainer.types";

export enum MenuOnClickTypes {
    Anchor = 1,
    /** Link relative to the current page */
    BTLinkRelative = 2,
    External = 3,
    NoLink = 4,
    /** Absolute link, if you want to add to the current route use BTLinkRelative */
    BTLink = 5,
}

export interface IMenuTabInfo {
    onClickType?: (builderInfo?: BuilderInfo, userInfo?: UserInfo) => MenuOnClickTypes;
    icon?: React.ReactNode;
    onClick?: (
        request: IMenuItemClickRequest,
        data: MainNavigationResponse,
        routeProps: RouteComponentProps
    ) => void;
    getLinkUrl?: (info: IMenuTabInfoLinkUrlInfo) => string;
    quickAddInfo?: IMenuTabInfoQuickAdd[];
    showInTemplateMode?: (builderInfo?: BuilderInfo | null | undefined) => boolean;
    getTitle?: (baseTitle: string, isInTemplateMode: boolean) => string;
    enableForSingleJobOnly?: boolean;
    showOnPortal?: BTLoginTypes[];

    /**
     * Use this property to include a tag after the menu item text
     * @see StatusTagDisplay for tag examples/types
     */
    tag?: React.ReactNode;
    getIsDisabled?: (userInfo?: UserInfo) => boolean | undefined;
}

export interface IMenuTabInfoLinkUrlInfo {
    loginType: BTLoginTypes;
    data: MainNavigationResponse;
    jobId: number;
    isTemplateMode: boolean;
    builderInfo?: BuilderInfo;
    userInfo?: UserInfo | null;
    appDefaultInfo?: AppDefaultInfo;
    environmentInfo?: EnvironmentInfo;
}

export interface IMenuTabInfoQuickAdd {
    entity: string;
    useLabel?: boolean;

    quickAddReactRoute: (request: IMenuItemClickRequest) => string;
    canQuickAdd?: (data: MainNavigationResponse) => boolean;
    onClick?: () => void;
}

export interface IMenuItemClickRequest {
    userId: number;
    builderId: number;
    jobId: number;
    fromReact: boolean;
    isTemplateMode: boolean;
    builderInfo?: BuilderInfo | null | undefined;
}

const refreshOnClose = () => {
    // eslint-disable-next-line deprecate/member-expression
    routesWebforms.refreshPage();
};

const getTemplateModeTitleDefault = (inputTitle: string, isTemplateMode: boolean) => {
    return isTemplateMode ? inputTitle.replace("Job", "Template") : inputTitle;
};

export const getLearningAcademyUrl = (appDefaultInfo: AppDefaultInfo | undefined) => {
    return appDefaultInfo?.gainsightSSO
        ? appDefaultInfo.buildertrendLearningAcademyURL
        : externalPages.LearningAcademy.getBLAUrl();
};

export const getProjectManagementUrl = (appDefaultInfo: AppDefaultInfo | undefined) => {
    return appDefaultInfo?.gainsightSSO
        ? appDefaultInfo.buildertrendLearningAcademyURL +
              appDefaultInfo.learningAcademyConfig.projectManagement
        : externalPages.LearningAcademy.getProjectManagementLink();
};

export const getTakeoffUrl = (appDefaultInfo: AppDefaultInfo | undefined) => {
    return appDefaultInfo?.gainsightSSO
        ? appDefaultInfo.buildertrendLearningAcademyURL +
              appDefaultInfo.learningAcademyConfig.takeOff
        : externalPages.LearningAcademy.getTakeOffLink();
};

export const getCostCodesBestPracticeUrl = (appDefaultInfo: AppDefaultInfo | undefined) => {
    return appDefaultInfo?.gainsightSSO
        ? `${appDefaultInfo.buildertrendLearningAcademyURL}${appDefaultInfo.learningAcademyConfig.costCodes}`
        : externalPages.LearningAcademy.getCostCodesBestPracticesLink();
};

class SalesMenu {
    leadOpportunities: IMenuTabInfo = {
        icon: <BTIconLeads size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.lead.getLeadListLink(LeadTabTypes.ListView));
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Lead Opportunity",
                quickAddReactRoute: () => getQuickAddReactRoute(routes.lead.getDetailsLink(0)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    leadActivities: IMenuTabInfo = {
        icon: <BTIconPhoneOutline size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.lead.getLeadListLink(LeadTabTypes.ActivityView));
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Lead Activity",
                quickAddReactRoute: () =>
                    getQuickAddReactRoute(
                        routes.leadActivity.getDetailsLink(0, 0, OpenActivityType.Edit, undefined)
                    ),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    leadProposals: IMenuTabInfo = {
        icon: <BTIconLeadProposals size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.lead.getLeadListLink(LeadTabTypes.Proposals));
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Lead Proposal",
                quickAddReactRoute: () =>
                    getQuickAddReactRoute(`${routes.lead.getDetailsLink(-1)}/LeadProposal/${0}`),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    activityCalendar: IMenuTabInfo = {
        icon: <BTIconSchedules size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.lead.getLeadListLink(LeadTabTypes.ActivityCalendar));
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Lead Activity",
                quickAddReactRoute: () =>
                    getQuickAddReactRoute(
                        routes.leadActivity.getDetailsLink(0, 0, OpenActivityType.Edit, undefined)
                    ),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    leadMap: IMenuTabInfo = {
        icon: <BTIconMapPin size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.lead.getLeadListLink(LeadTabTypes.Map));
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getTitle: getTemplateModeTitleDefault,
    };
}

class JobMenu {
    summary: IMenuTabInfo = {
        icon: <BTIconJobs size="large" />,
        onClickType: (builderInfo: BuilderInfo | undefined, userInfo: UserInfo | undefined) => {
            if (
                userInfo?.loginType === BTLoginTypes.OWNER ||
                userInfo?.loginType === BTLoginTypes.CONTACT ||
                userInfo?.loginType === BTLoginTypes.BUILDER
            ) {
                return isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor;
            }
            return MenuOnClickTypes.Anchor;
        },
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            if (info.loginType === BTLoginTypes.OWNER || info.loginType === BTLoginTypes.CONTACT) {
                return getSPACheckedRoute(routes.ownerSummary.getLink());
            } else if (info.loginType === BTLoginTypes.BUILDER) {
                return getSPACheckedRoute(routes.landingPage.getLink());
            }

            return routesWebforms.Summary.GetSubSummaryUrl();
        },
        getTitle: getTemplateModeTitleDefault,
    };
    jobInfo: IMenuTabInfo = {
        icon: <BTIconInfo size="large" />,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            "/QuickAction" +
            (info.isTemplateMode
                ? routes.template.getTemplateDetailsLink(info.jobId)
                : routes.job.getDetailsLink(info.jobId)),
        showInTemplateMode: () => true,
        enableForSingleJobOnly: true,
        getTitle: getTemplateModeTitleDefault,
    };
    subJobInfo: IMenuTabInfo = {
        icon: <BTIconInfo size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.job.getListLink()),
        showOnPortal: [BTLoginTypes.SUBS],
        getTitle: getTemplateModeTitleDefault,
    };
    jobPriceSummary: IMenuTabInfo = {
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        icon: <BTIconPriceTag size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            routes.job.getJobPriceSummaryLink(info.jobId),
        enableForSingleJobOnly: true,
        getTitle: getTemplateModeTitleDefault,
    };
    jobsList: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconListBullets />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            if (info.isTemplateMode) {
                return getSPACheckedRoute(routes.template.getListLink());
            } else {
                return getSPACheckedRoute(routes.job.getListLink());
            }
        },

        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    jobsMap: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconMapPin size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.job.getListLink(JobListTabs.Map));
        },
        getTitle: getTemplateModeTitleDefault,
    };
    newJobFromScratch: IMenuTabInfo = {
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        icon: <BTIconPlusCircleTransparent size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            "/QuickAction" +
            (info.isTemplateMode
                ? routes.template.getTemplateDetailsLink(0)
                : routes.job.getDetailsLink(
                      0, // jobId
                      JobDetailTab.Job, // defaultTab
                      false, // accessedFromContact
                      false, // accessedFromLead
                      null, // ownerInvoiceId
                      true // openCondensed
                  )),
        getTitle: getTemplateModeTitleDefault,
    };
    newJobFromTemplate: IMenuTabInfo = {
        showInTemplateMode: () => true,
        icon: <BTIconCopy size="large" />,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            "/QuickAction" +
            (info.isTemplateMode
                ? routes.template.getTemplateFromTemplateLink()
                : routes.job.getJobFromTemplateLink(0, 0, "0")),
        getTitle: getTemplateModeTitleDefault,
    };
}

const isSinglePageApp = window.location.pathname.toLowerCase().startsWith("/app");

/**
 * Determine if we get the route basename (appBase) for free with usage of BTLink in the SPA page
 * If not we need to add it manually.
 * @param route
 * @returns
 */
function getSPACheckedRoute(route: string) {
    return isSinglePageApp ? route : `${routes.appBase}${route}`;
}

export function getQuickAddReactRoute(route: string) {
    return "/QuickAction" + route;
}

class ProjectManagementMenu {
    schedule: IMenuTabInfo = {
        icon: <BTIconSchedules size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(
                routes.schedule.getListLink(ScheduleListContainerTabs.Default)
            );
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Schedule Item",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.schedule.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    dailyLogs: IMenuTabInfo = {
        icon: <BTIconDailyLogs size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.dailyLog.getListLink()),
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Daily Log",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.dailyLog.getDetailsLink(0, request.jobId)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    todos: IMenuTabInfo = {
        icon: <BTIconTodos size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.toDo.getListLink()),
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "To-Do",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(
                        routes.toDo.getDetailsLink(0, request.jobId, false, false)
                    ),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    changeOrders: IMenuTabInfo = {
        icon: <BTIconChangeOrders size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.changeOrder.getListLink()),
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Change Order",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(
                        routes.changeOrder.getChangeOrderDetailsLink(0, request.jobId)
                    ),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    selections: IMenuTabInfo = {
        icon: <BTIconSelections size="large" />,
        onClickType: (_: BuilderInfo | undefined, userInfo?: UserInfo | undefined) => {
            if (userInfo?.loginType === BTLoginTypes.BUILDER) {
                return isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor;
            }
            return MenuOnClickTypes.Anchor;
        },
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            if (info.userInfo?.loginType === BTLoginTypes.BUILDER) {
                return getSPACheckedRoute(
                    routes.selection.getListLink(SelectionListContainerTabs.Default)
                );
            }
            const { loginType, data } = info;

            return routesWebforms.Selection.GetListPageUrl(
                loginType,
                data.defaultViews.ownerSelectionDefaultView
            );
        },
        quickAddInfo: [
            {
                entity: "Selection",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.selection.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    warranty: IMenuTabInfo = {
        icon: <BTIconWarranties size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.warranty.getListLink()),
        quickAddInfo: [
            {
                entity: "Warranty Claim",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.warranty.getDetailsLink(0, request.jobId)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    timeClock: IMenuTabInfo = {
        icon: <BTIconTimeClock size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.timeClock.getListLink()),
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Shift",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.timeClock.getShiftDetailsLink(0, request.jobId)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    specifications: IMenuTabInfo = {
        icon: <BTIconSpecifications size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.specification.getListLink()),
        quickAddInfo: [
            {
                entity: "Specification",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(
                        routes.specification.getSpecificationDetails(0, request.jobId)
                    ),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    clientUpdates: IMenuTabInfo = {
        icon: <BTIconDailyLogs size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.clientUpdates.getListLink()),
        quickAddInfo: [],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
}

class FilesMenu {
    documents: IMenuTabInfo = {
        icon: <BTIconDocuments size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.document.getListLink()),
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    photos: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconPhotos size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.photo.getListLink()),
        showInTemplateMode: () => true,
    };
    videos: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconVideos size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.video.getListLink()),
        showInTemplateMode: () => true,
    };
}

class MessagingMenu {
    comments: IMenuTabInfo = {
        icon: <BTIconComments size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.comment.getListLink()),
    };
    messages: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconMessages size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.messages.getListLink()),
        quickAddInfo: [
            {
                entity: "Message",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.messages.getComposeLink(0, request.jobId)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    rfis: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconRFIs size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.rfi.getListLink()),
        quickAddInfo: [
            {
                entity: "RFI",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.rfi.getDetailsLink(0, request.jobId)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    notificationHistory: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconClockCounterClockwise size="large" />,
        getLinkUrl: () => getSPACheckedRoute(routes.notifications.getLegacyListLink()),
        getTitle: getTemplateModeTitleDefault,
    };
    surveys: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconSurveys size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            return getSPACheckedRoute(routes.survey.getListLink(info.loginType));
        },
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
}

class FinancialMenu {
    bids: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconBidPackages size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            if (info.loginType === BTLoginTypes.BUILDER) {
                return getSPACheckedRoute(routes.bidPackage.getListLink());
            } else if (info.loginType === BTLoginTypes.SUBS) {
                return getSPACheckedRoute(routes.bid.getListLink());
            }
            throw new Error("Invalid login type");
        },
        quickAddInfo: [
            {
                entity: "Bid Package",
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.bidPackage.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    estimates: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconEstimate size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            if (info.loginType === BTLoginTypes.OWNER || info.loginType === BTLoginTypes.CONTACT) {
                return getSPACheckedRoute(
                    routes.estimate.getOwnerProposalMainNavLink(info.builderInfo)
                );
            }

            return getSPACheckedRoute(routes.estimate.getListLink());
        },
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    purchaseOrders: IMenuTabInfo = {
        icon: <BTIconPurchaseOrders size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.purchaseOrder.getListLink());
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Purchase Order",
                useLabel: true,
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.purchaseOrder.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    bills: IMenuTabInfo = {
        icon: <BTIconBillsPurchaseOrders size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.bill.getListLink());
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        quickAddInfo: [
            {
                entity: "Bill",
                useLabel: true,
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.bill.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    budget: IMenuTabInfo = {
        icon: <BTIconBudget size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            routesWebforms.Budget.GetListUrl(info.loginType),
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    jobCostingBudget: IMenuTabInfo = {
        icon: <BTIconBudget size="large" />,
        getLinkUrl: () => `${routes.appBase}${routes.jobCostingBudget.getLink()}`,
        showInTemplateMode: () => true,
    };
    rebates: IMenuTabInfo = {
        icon: <BTIconRebates size="large" />,
        showOnPortal: [BTLoginTypes.BUILDER],
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            return getSPACheckedRoute(
                info.builderInfo?.flags.purchasingOneVisionIteration1
                    ? routes.purchasing.getDashboardLink(PurchasingDashboardPages.Summary)
                    : routes.rebates.getListLink()
            );
        },
        getTitle: getTemplateModeTitleDefault,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        showInTemplateMode: () => false,
    };
    receipts: IMenuTabInfo = {
        icon: <BTIconReceipts size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.receipts.getListLink());
        },
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        showInTemplateMode: () => false,
        showOnPortal: [BTLoginTypes.BUILDER],
        quickAddInfo: [
            {
                entity: "Receipt",
                useLabel: true,
                quickAddReactRoute: () => getQuickAddReactRoute(routes.receipts.getUploadLink()),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    ownerInvoices: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconOwnerInvoices size="large" />,
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.ownerInvoice.getListLink());
        },
        quickAddInfo: [
            {
                entity: "Invoice",
                useLabel: true,
                quickAddReactRoute: (request) =>
                    getQuickAddReactRoute(routes.ownerInvoice.getDetailsLink(0, request.jobId)),
            },
        ],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    onlinePaymentSettings: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconSettingsBlack size="large" />,
        showInTemplateMode: () => true,
        getLinkUrl: () => getSPACheckedRoute(routes.onlinePayments.paymentOverview),
        getTitle: getTemplateModeTitleDefault,
    };
    onlinePaymentReport: IMenuTabInfo = {
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        icon: <BTIconTable size="large" />,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            getSPACheckedRoute(routes.onlinePayments.getPaymentReportLink(info.loginType)),
        getTitle: getTemplateModeTitleDefault,
    };
}

class ReportsMenu {
    reports: IMenuTabInfo = {
        showOnPortal: [BTLoginTypes.BUILDER],
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.NoLink,
        // eslint-disable-next-line no-restricted-syntax
        onClick: () => ((window as any).location = routesWebforms.Report.GetListUrl()),
        getTitle: getTemplateModeTitleDefault,
    };
}

class UsersMenu {
    internalUsers: IMenuTabInfo = {
        icon: <BTIconInternalUsers size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => {
            return `${routes.appBase}${routes.internalUsers.getListLink()}`;
        },
        quickAddInfo: [
            {
                entity: "Internal User",
                quickAddReactRoute: () =>
                    getQuickAddReactRoute(routes.internalUsers.getInternalUserDetailsLink(0)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    subs: IMenuTabInfo = {
        icon: <BTIconSubs size="large" />,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => getSPACheckedRoute(routes.sub.getListLink()),
        quickAddInfo: [
            {
                entity: "Sub/Vendor",
                quickAddReactRoute: () => getQuickAddReactRoute(routes.sub.getDetailsLink(0)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
    contacts: IMenuTabInfo = {
        icon: <BTIconContacts size="large" />,
        getLinkUrl: () => {
            return `${routes.appBase}${routes.contact.getListLink()}`;
        },
        quickAddInfo: [
            {
                entity: "Client Contact",
                quickAddReactRoute: () =>
                    getQuickAddReactRoute(routes.contact.getDetailsLink(0, false)),
            },
        ],
        getTitle: getTemplateModeTitleDefault,
    };
}

class NotificationsMenu {
    notifications: IMenuTabInfo = {
        showOnPortal: [
            BTLoginTypes.BUILDER,
            BTLoginTypes.SUBS,
            BTLoginTypes.OWNER,
            BTLoginTypes.CONTACT,
        ],
        getTitle: getTemplateModeTitleDefault,
    };
}

class HelpMenu {
    trainingPlan: IMenuTabInfo = {
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        showOnPortal: [BTLoginTypes.BUILDER],
        icon: <BTIconCompassOutlined size="large" />,
        showInTemplateMode: () => true,
        getLinkUrl: () => routes.trainingPlan.getListLink(),
        getTitle: getTemplateModeTitleDefault,
    };
    contactUs: IMenuTabInfo = {
        icon: <BTIconPhoneOutline size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: () => routes.contact.getContactUsLink(),
        getTitle: getTemplateModeTitleDefault,
    };
    supportChat: IMenuTabInfo = {
        icon: <BTIconLiveChat size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.Anchor,
        onClick: () => showChat(),
        getTitle: getTemplateModeTitleDefault,
    };
    buildertrendLearningAcademy: IMenuTabInfo = {
        icon: <BTIconLearningAcademy size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.External,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => getLearningAcademyUrl(info.appDefaultInfo),
        getTitle: getTemplateModeTitleDefault,
    };
    helpCenter: IMenuTabInfo = {
        icon: <BTIconHelpCenter size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.External,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) =>
            routesWebforms.Help.GetHelpCenterLink(info.loginType),
        getTitle: getTemplateModeTitleDefault,
    };
    giveFeedback: IMenuTabInfo = {
        icon: <BTIconBulb size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.External,
        getLinkUrl: () => externalPages.LaunchNotes,
        getTitle: getTemplateModeTitleDefault,
    };
    emailSupport: IMenuTabInfo = {
        icon: <BTIconMessages size="large" />,
        getLinkUrl: () => "mailto:support@buildertrend.com",
        showOnPortal: [BTLoginTypes.SUBS],
        getTitle: getTemplateModeTitleDefault,
    };
    termsAndConditions: IMenuTabInfo = {
        icon: <BTIconTermsAndConditions size="large" />,
        getLinkUrl: () => externalPages.BuildertrendTermsAndConditions,
        onClickType: () => MenuOnClickTypes.External,
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    privacyPolicy: IMenuTabInfo = {
        icon: <BTIconKeyLock size="large" />,
        getLinkUrl: () => externalPages.BuildertrendPrivacyPolicy,
        onClickType: () => MenuOnClickTypes.External,
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    softwareAttributions: IMenuTabInfo = {
        icon: <BTIconInfo size="large" />,
        getLinkUrl: () =>
            isDevEnvironment()
                ? externalPages.BuildertrendSoftwareAttributionDEV
                : externalPages.BuildertrendSoftwareAttribution,
        onClickType: () => MenuOnClickTypes.External,
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
}

class SetupMenu {
    userPreferences: IMenuTabInfo = {
        icon: (
            <BTUser
                size="small"
                displayName={getGlobalValue("userDisplayName")}
                globalUserId={getGlobalValue("globalUserId")}
                contactCard="none"
            />
        ),
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            return (
                "/QuickSettings" +
                routes.internalUsers.getInternalUserDetailsLink(
                    info.userInfo!.globalUserId!,
                    InternalUserTabKeys.Overview,
                    true
                )
            );
        },
        getTitle: getTemplateModeTitleDefault,
    };
    settings: IMenuTabInfo = {
        icon: <BTIconGearSix size={24} />,
        showInTemplateMode: () => true,
        onClickType: () => (isSinglePageApp ? MenuOnClickTypes.BTLink : MenuOnClickTypes.Anchor),
        getLinkUrl: () => {
            return getSPACheckedRoute(routes.settings.getSettingsLink());
        },
        getTitle: getTemplateModeTitleDefault,
    };
    subSettings: IMenuTabInfo = {
        icon: <BTIconSettingsBlack size="large" />,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            return routes.sub.getSetupLink(info.userInfo!.globalUserId!);
        },
        showOnPortal: [BTLoginTypes.SUBS],
        getTitle: getTemplateModeTitleDefault,
    };
    ownerSettings: IMenuTabInfo = {
        icon: <BTIconSettingsBlack size="large" />,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: (info: IMenuTabInfoLinkUrlInfo) => {
            return routes.owner.getSetupLink(info.jobId);
        },
        showOnPortal: [BTLoginTypes.OWNER, BTLoginTypes.CONTACT],
        getTitle: getTemplateModeTitleDefault,
    };
    manageSubscription: IMenuTabInfo = {
        icon: <BTIconSubscription size="large" />,
        onClickType: () => MenuOnClickTypes.BTLinkRelative,
        getLinkUrl: () => {
            return routes.subscriptionManager.getSettingsLink(false, false);
        },
        showOnPortal: [BTLoginTypes.BUILDER],
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };
    syncSchedule: IMenuTabInfo = {
        icon: <BTIconArrowsClockwise size="large" />,
        showInTemplateMode: () => true,
        onClickType: () => MenuOnClickTypes.NoLink,
        onClick: () => {
            // eslint-disable-next-line no-restricted-syntax
            (window as any).ReactDialogHandler.Settings.OpenQuickBooksSyncSchedule(refreshOnClose);
        },
        getTitle: getTemplateModeTitleDefault,
    };

    buildertrendMarketplace: IMenuTabInfo = {
        onClickType: () => MenuOnClickTypes.External,
        icon: <BTIconMarketplace size="large" />,
        getLinkUrl: () => {
            return routes.marketplace.getMarketplaceLink();
        },
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };

    integrations: IMenuTabInfo = {
        onClickType: () => MenuOnClickTypes.External,
        icon: <BTIconIntegrations size="large" />,
        getLinkUrl: () => {
            return routes.marketplace.getMarketplaceLink();
        },
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };

    additionalServices: IMenuTabInfo = {
        onClickType: () => MenuOnClickTypes.External,
        icon: <BTIconAdditionalServices size="large" />,
        getLinkUrl: () => {
            return routes.additionalServices.getAdditionalServicesLink();
        },
        showInTemplateMode: () => true,
        getTitle: getTemplateModeTitleDefault,
    };

    logout: IMenuTabInfo = {
        getIsDisabled: (userInfo?: UserInfo) => {
            return userInfo?.isBuilderImpersonatingOwner;
        },
        icon: <BTIconSignOut size="large" />,
        showInTemplateMode: () => true,
        getLinkUrl: () => routesWebforms.Login.GetLogoutUrl(),
        getTitle: getTemplateModeTitleDefault,
    };
}

export class MenuInfo {
    sales: SalesMenu = new SalesMenu();
    job: JobMenu = new JobMenu();
    projectManagement: ProjectManagementMenu = new ProjectManagementMenu();
    files: FilesMenu = new FilesMenu();
    messaging: MessagingMenu = new MessagingMenu();
    financial: FinancialMenu = new FinancialMenu();
    reports: ReportsMenu = new ReportsMenu();
    users: UsersMenu = new UsersMenu();
    help: HelpMenu = new HelpMenu();
    setup: SetupMenu = new SetupMenu();
    notifications: NotificationsMenu = new NotificationsMenu();
}

export class NavHeaderTabs {
    sales: NavHeaderResponse & {
        leadOpportunities?: NavMenuItemResponse;
        leadActivities?: NavMenuItemResponse;
        leadMap?: NavMenuItemResponse;
        leadProposals?: NavMenuItemResponse;
        activityCalendar?: NavMenuItemResponse;
    };

    job: NavHeaderResponse & {
        summary?: NavMenuItemResponse;
        jobInfo?: NavMenuItemResponse;
        jobPriceSummary?: NavMenuItemResponse;
        jobsList?: NavMenuItemResponse;
        jobsMap?: NavMenuItemResponse;
        newJobFromScratch?: NavMenuItemResponse;
        newJobFromTemplate?: NavMenuItemResponse;
    };

    projectManagement: NavHeaderResponse & {
        schedule?: NavMenuItemResponse;
        dailyLogs?: NavMenuItemResponse;
        todos?: NavMenuItemResponse;
        changeOrders?: NavMenuItemResponse;
        selections?: NavMenuItemResponse;
        options?: NavMenuItemResponse;
        warranty?: NavMenuItemResponse;
        timeClock?: NavMenuItemResponse;
    };

    files: NavHeaderResponse & {
        documents?: NavMenuItemResponse;
        photos?: NavMenuItemResponse;
        videos?: NavMenuItemResponse;
    };

    messaging: NavHeaderResponse & {
        comments?: NavMenuItemResponse;
        messages?: NavMenuItemResponse;
        rfis?: NavMenuItemResponse;
        notificationHistory?: NavMenuItemResponse;
        surveys?: NavMenuItemResponse;
    };

    financial: NavHeaderResponse & {
        bids?: NavMenuItemResponse;
        estimates?: NavMenuItemResponse;
        bills?: NavMenuItemResponse;
        purchaseOrders?: NavMenuItemResponse;
        budget?: NavMenuItemResponse;
        jobCostingBudget?: NavMenuItemResponse;
        receipts?: NavMenuItemResponse;
        ownerInvoices?: NavMenuItemResponse;
        onlinePaymentReport?: NavMenuItemResponse;
    };

    reports: NavHeaderResponse & {
        reports?: NavMenuItemResponse;
    };

    users: NavHeaderResponse & {
        internalUsers?: NavMenuItemResponse;
        subs?: NavMenuItemResponse;
        contacts?: NavMenuItemResponse;
    };

    notifications: NavHeaderResponse & {
        notifications?: NavMenuItemResponse;
    };

    help: NavHeaderResponse & {
        contactUs?: NavMenuItemResponse;
        liveChat?: NavMenuItemResponse;
        helpCenter?: NavMenuItemResponse;
        giveFeedback?: NavMenuItemResponse;
    };

    setup: NavHeaderResponse & {
        userPreferences?: NavMenuItemResponse;
        settings?: NavMenuItemResponse;
        integrations?: NavMenuItemResponse;
        onlinePaymentSettings?: NavMenuItemResponse;
        additionalServices?: NavMenuItemResponse;
        logout?: NavMenuItemResponse;
    };
}

export const FlatMenus: (keyof MenuInfo)[] = ["reports", "notifications"];

export const CustomMenus: (keyof MenuInfo)[] = ["notifications"];

export interface IBTSubMenuItemProps {
    itemKey: keyof MenuInfo;

    /** menu icon to display */
    icon?: ReactNode;

    /**
     * should only the icon be shown
     * @default false
     */
    iconOnly?: boolean;

    className?: string;
    countForBadge?: number;

    menuInfo: MenuInfo;

    selectedMenuItem: MenuItemName;
    jobId: number;
    isTemplateMode: boolean;
    loginType: BTLoginTypes;

    data: MainNavigationResponse;

    routeInfo: IMenuItemClickRequest;

    mainNavResponse: MainNavigationResponse;

    builderInfo?: BuilderInfo;

    onContentClick: (menuTabInfo: IMenuTabInfo, navMenuItem: NavMenuItemResponse) => void;

    onCustomMenuClick?: () => void;

    children?: React.ReactNode;

    intercomTarget?: string;
    userInfo: UserInfo;
    appDefaultInfo?: AppDefaultInfo;
    envInfo?: EnvironmentInfo;
    onItemClick?: (key: string, isExternalLink?: boolean) => void;
    destroyPopupOnHide?: boolean;
}

export const menuItemClass = "MainNavMenuItem";
export const quickAddClass = "MainNavMenuItemQuickAdd MenuLink";
