import { FormikErrors } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";

import { AppDefaultInfoContext } from "helpers/globalContext/AppDefaultInfoContext";

import { BTLocalStorage } from "types/btStorage";
import { ActivationErrorTypes, BTLoginTypes } from "types/enum";

import { useActionBeingPerformed } from "utilities/form/form";
import { routes } from "utilities/routes";

import { AuthStoreDiscoveryHandler } from "commonComponents/auth/AuthStoreDiscovery.api.handler";
import {
    AuthStoreDiscoveryActionType,
    AuthStoreTypes,
    IAuthStoreDiscoveryFormValues,
    IOAuthStateParamState,
} from "commonComponents/auth/AuthStoreDiscovery.types";
import { redirectToAuth0User } from "commonComponents/auth/AuthStoreDiscovery.utils";
import { UserActivationSkeleton } from "commonComponents/entity/userActivation/UserActivationSkeleton/UserActivationSkeleton";
import { withErrorBoundary } from "commonComponents/helpers/ErrorBoundary/ErrorBoundary";

import {
    emailsStatusGetV2,
    EmailsStatusGetV2Params,
    usersExternalActivationLoginTypeByShareTokenGetV2,
} from "handlers";

import { CreateAccountPresentational } from "entity/LinkAccountActivation/CreateAccount/CreateAccountPresentational";
import { InvalidInvitePage } from "entity/LinkAccountActivation/InvalidInvite/InvalidInvitePage";
import {
    defaultLinkAccountHandler,
    ILinkAccountHandler,
} from "entity/LinkAccountActivation/LinkAccountBuildertrend/LinkAccount.api.handler";
import { LinkAccountEntity } from "entity/LinkAccountActivation/LinkAccountBuildertrend/LinkAccount.api.types";

// todo put any url props your component uses in this interface
interface IUrlProps {}

export interface ICreateAccountProps extends RouteComponentProps<IUrlProps> {
    shareToken: string;
    username: string | undefined;
    handler?: ILinkAccountHandler;
}

const discoveryHandler = new AuthStoreDiscoveryHandler();

export interface IErrorInfo {
    activationErrorType: ActivationErrorTypes;
    logoSrc: string;
    sessionLoginType: BTLoginTypes;
    builderName: string;
}

export async function loadLoginType(
    shareToken: string | undefined,
    setLoginType: (response: BTLoginTypes | undefined) => void
) {
    const resp = await usersExternalActivationLoginTypeByShareTokenGetV2(shareToken ?? "");
    setLoginType(resp.data.loginType);
}

export const handleActivationError = (
    errorInfoRef: React.MutableRefObject<IErrorInfo>,
    errorType: ActivationErrorTypes | undefined = ActivationErrorTypes.None,
    logoSrc: string | undefined = "",
    sessionLoginType: BTLoginTypes | undefined = BTLoginTypes.ALL,
    builderName: string | undefined = ""
) => {
    if (errorType !== ActivationErrorTypes.None) {
        errorInfoRef.current.activationErrorType = errorType;
        errorInfoRef.current.logoSrc = logoSrc;
        errorInfoRef.current.sessionLoginType = sessionLoginType;
        errorInfoRef.current.builderName = builderName;
        throw new Error(`Activation error: ${errorType}`);
    }
};

export const renderInvalidInvitePage = (
    errorInfoRef: React.MutableRefObject<IErrorInfo>,
    loginType: BTLoginTypes | undefined,
    shareToken: string | undefined
) => (
    <InvalidInvitePage
        loginType={loginType}
        errorType={errorInfoRef.current.activationErrorType}
        logoSrc={errorInfoRef.current.logoSrc}
        sessionLoginType={errorInfoRef.current.sessionLoginType}
        builderName={errorInfoRef.current.builderName}
        shareToken={shareToken}
    />
);

function CreateAccountInternal({
    shareToken,
    username,
    history,
    handler = defaultLinkAccountHandler,
}: ICreateAccountProps) {
    const [data, setData] = useState<LinkAccountEntity | undefined>(undefined);
    const [actionBeingPerformed, setActionBeingPerformed] =
        useActionBeingPerformed<AuthStoreDiscoveryActionType>();
    const [error, setError] = useState<unknown>(null);

    const [loginType, setLoginType] = useState<BTLoginTypes | undefined>(BTLoginTypes.BUILDER);
    const errorInfoRef = useRef<IErrorInfo>({
        activationErrorType: ActivationErrorTypes.None,
        logoSrc: "",
        sessionLoginType: BTLoginTypes.BUILDER,
        builderName: "",
    });

    const appDefaultInfo = useContext(AppDefaultInfoContext);

    useEffect(() => {
        void loadLoginType(shareToken, setLoginType);

        const loadData = async () => {
            try {
                const apiResponse = await handler.get(shareToken);
                handleActivationError(
                    errorInfoRef,
                    apiResponse.errorType,
                    apiResponse.logoSrc,
                    apiResponse.sessionLoginType,
                    apiResponse.builderName
                );
                setData(apiResponse);
            } catch (e) {
                setError(e);
            }
        };
        void loadData();
    }, [handler, shareToken]);

    useEffect(() => {
        if (data?.isActivated) {
            history.push(routes.user.getLinkInvalidInvite(shareToken, data.loginType));
        }

        if (data?.hasActiveSession) {
            const username = data?.username ?? "";
            if (data?.hasAlreadyLinked) {
                // redirect to the user signed in and already linked page
                history.push(
                    routes.user.getSignedInLinkedActivation(shareToken, username, data.loginType)
                );
            } else {
                // redirect to the user signed in already page
                history.push(
                    routes.user.getLinkSignedInAccountActivation(
                        shareToken,
                        encodeURIComponent(username),
                        data.loginType
                    )
                );
            }
        }
    }, [data, history, shareToken]);

    const routeToAuthStore = async (
        values: IAuthStoreDiscoveryFormValues,
        setErrors: (errors: FormikErrors<IAuthStoreDiscoveryFormValues>) => void
    ) => {
        try {
            const resp = await discoveryHandler?.getAuthStoreForUsername(
                values.username,
                values.recaptchaToken!,
                shareToken
            );

            if (resp?.userExists && resp?.authStore === AuthStoreTypes.Auth0) {
                // Link Account | Auth0
                const stateIdentifier = appDefaultInfo?.oAuthStateParamChanges
                    ? crypto.randomUUID().toUpperCase()
                    : shareToken;
                const oAuthState: IOAuthStateParamState = {
                    stateIdentifier: stateIdentifier,
                    shareToken: shareToken,
                };

                BTLocalStorage.set("bt-object-oAuthStateParam", oAuthState);
                redirectToAuth0User(values.username);
            } else if (resp?.userExists) {
                // Link Account | BT
                history.push(
                    routes.user.getLinkAccountActivation(
                        shareToken,
                        encodeURIComponent(values.username)
                    )
                );
            } else if (!resp?.emailValid) {
                // At this point we know we are creating a new account.
                // All new accounts must have a valid email
                setErrors({ username: "Email must be a valid email" });
            } else if (loginType === BTLoginTypes.BUILDER) {
                // Create Account | Auth0
                window.location.assign(
                    `/api/Users/Activation?username=${encodeURIComponent(
                        values.username
                    )}&shareToken=${shareToken}`
                );
            } else {
                // Create Account | BT
                history.push(
                    routes.user.getNewUserActivation(
                        shareToken,
                        encodeURIComponent(values.username)
                    )
                );
            }
        } catch (e) {
            setError(e);
        }
    };

    const handleSubmit = async (
        values: IAuthStoreDiscoveryFormValues,
        setErrors: (errors: FormikErrors<IAuthStoreDiscoveryFormValues>) => void
    ) => {
        await setActionBeingPerformed({
            actionBeingPerformed: "submit",
            callback: async () => {
                try {
                    if (appDefaultInfo?.removeCreateLinkDecision) {
                        await routeToAuthStore(values, setErrors);
                    } else {
                        if (loginType === BTLoginTypes.BUILDER) {
                            const params: EmailsStatusGetV2Params = {
                                EmailAddress: encodeURIComponent(values.username),
                                RecaptchaToken: values.recaptchaToken!,
                                ShareToken: shareToken,
                            };
                            const resp = await emailsStatusGetV2(params);
                            if (resp.data.available) {
                                window.location.assign(
                                    `/api/Users/Activation?username=${encodeURIComponent(
                                        values.username
                                    )}&shareToken=${shareToken}`
                                );
                            } else {
                                setErrors({ username: "Did you mean to sign in?" });
                            }
                        } else {
                            const resp = await discoveryHandler?.getAuthStoreForUsername(
                                values.username,
                                values.recaptchaToken!,
                                shareToken
                            );
                            // check if the username already exists
                            if (resp?.userExists) {
                                setErrors({ username: "Did you mean to sign in?" });
                            } else {
                                // if username does not exist, we can proceed to create the account
                                history.push(
                                    routes.user.getNewUserActivation(
                                        shareToken,
                                        encodeURIComponent(values.username)
                                    )
                                );
                            }
                        }
                    }
                } catch (e) {
                    setError(e);
                }
            },
        });
    };

    if (errorInfoRef.current.activationErrorType !== ActivationErrorTypes.None) {
        return renderInvalidInvitePage(errorInfoRef, loginType, shareToken);
    }

    if (error) {
        return <InvalidInvitePage loginType={loginType} />;
    }

    if (!data) {
        return <UserActivationSkeleton />;
    }

    return (
        <CreateAccountPresentational
            actionBeingPerformed={actionBeingPerformed}
            onSubmit={handleSubmit}
            username={username ?? data.suggestedUsername}
            logoSrc={data.logoSrc}
            loginType={data.loginType}
            userDisplayName={data.name}
            builderName={data.builderName}
            shareToken={shareToken}
            requireEmailAsUsername={!(appDefaultInfo?.removeCreateLinkDecision ?? false)}
        />
    );
}

export const CreateAccount = withErrorBoundary(CreateAccountInternal)(
    "Could not load Create Account Page"
);

export default CreateAccount;
