import { useContext } from "react";

import { BTAdminPermissions } from "helpers/globalContext/btAdmin/BTAdminContext.types";
import { BTAdminUserContext } from "helpers/globalContext/btAdmin/BTAdminUserInfoProvider";
import { useBTAdminPermission } from "helpers/globalContext/btAdmin/useBTAdminPermission";

export function ShowSupportToolsAndBillingDiv() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasRiskInsuranceAgentPerm = useBTAdminPermission(BTAdminPermissions.RiskInsuranceAgent);
    const hasRiskInsuranceManagementPerm = useBTAdminPermission(
        BTAdminPermissions.RiskInsuranceManagement
    );
    return !(!hasSuperUserPerm && (hasRiskInsuranceAgentPerm || hasRiskInsuranceManagementPerm));
}

export function ShowBuilders() {
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasBDRPerm = useBTAdminPermission(BTAdminPermissions.BDR);
    const hasBDSPerm = useBTAdminPermission(BTAdminPermissions.BDS);
    const hasRSMPerm = useBTAdminPermission(BTAdminPermissions.RSM);
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    return hasSupportPerm || hasBDRPerm || hasBDSPerm || hasRSMPerm || hasSuperUserPerm;
}

export function ShowBTAdminUsers() {
    const hasBTAdminUserManagementPerm = useBTAdminPermission(
        BTAdminPermissions.BTAdminUserManagment
    );
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const btadminContext = useContext(BTAdminUserContext);
    let flag = false;

    if (hasSuperUserPerm && btadminContext?.rep.repId !== 45) {
        flag = true;
    }

    if (hasBTAdminUserManagementPerm || hasSuperUserPerm) {
        flag = true;
    }

    return flag;
}

export function ShowRebateHawk() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    return useBTAdminPermission(BTAdminPermissions.RebateReceiptReview) || hasSuperUserPerm;
}

export function ShowStuckPaymentUtility() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasDeveloperPerm = useBTAdminPermission(BTAdminPermissions.Developer);
    const hasPaymentOpsPerm = useBTAdminPermission(BTAdminPermissions.PaymentOperationsSuperUser);
    return hasSuperUserPerm || hasSupportPerm || hasDeveloperPerm || hasPaymentOpsPerm;
}

export function ShowBuilderSetup() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasDeveloperPerm = useBTAdminPermission(BTAdminPermissions.Developer);
    return hasSuperUserPerm || hasSupportPerm || hasDeveloperPerm;
}

export function ShowPaymentsPrescreening() {
    const hasPaymentOpsPerm = useBTAdminPermission(BTAdminPermissions.PaymentOperationsSuperUser);
    return hasPaymentOpsPerm;
}

export function ShowRiskInsurance() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasRiskInsuranceAgentPerm = useBTAdminPermission(BTAdminPermissions.RiskInsuranceAgent);
    const hasRiskInsuranceManagementPerm = useBTAdminPermission(
        BTAdminPermissions.RiskInsuranceManagement
    );
    const btadminContext = useContext(BTAdminUserContext);

    return (
        (hasSuperUserPerm && btadminContext?.rep.repId !== 45) ||
        hasRiskInsuranceAgentPerm ||
        hasRiskInsuranceManagementPerm
    );
}

export function ShowOnlinePaymentReport() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasBillingPerm = useBTAdminPermission(BTAdminPermissions.Billing);
    const hasLendingPerm = useBTAdminPermission(BTAdminPermissions.LendingDashboard);

    return hasSuperUserPerm || hasSupportPerm || hasBillingPerm || hasLendingPerm;
}

export function ShowSubscriptionItemSetup() {
    return useBTAdminPermission(BTAdminPermissions.BillingUtilities);
}

export function ShowSubscriptionSignUpCodes() {
    return useBTAdminPermission(BTAdminPermissions.SubscriptionSignUpCodes);
}

export function ShowSubscriptionPricingGuardrails() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasPricingLimits = useBTAdminPermission(BTAdminPermissions.PricingLimits);
    return hasSuperUserPerm || hasPricingLimits;
}

export function ShowDevUtilitiesDiv() {
    return (
        ShowLogs() ||
        ShowHangfire() ||
        ShowAccountingWebhookTesting() ||
        ShowFeatureFlagsAndIntegration() ||
        ShowApplicationAndSearchAndSpam() ||
        ShowUsernameLookupTool()
    );
}

export function ShowLogs() {
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);

    return hasSupportPerm || hasSuperUserPerm;
}

export function ShowHangfire() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasHangfirePerm = useBTAdminPermission(BTAdminPermissions.Hangfire);

    return hasSuperUserPerm || hasHangfirePerm;
}

export function ShowAccountingWebhookTesting() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasDeveloperPerm = useBTAdminPermission(BTAdminPermissions.Developer);

    return hasSuperUserPerm || hasDeveloperPerm;
}

// always on
export function ShowFeatureFlagsAndIntegration() {
    return true;
}

export function ShowApplicationAndSearchAndSpam() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const btadminContext = useContext(BTAdminUserContext);
    return hasSuperUserPerm && btadminContext?.rep.repId !== 45;
}

export function ShowUsernameLookupTool() {
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    return hasSuperUserPerm || hasSupportPerm;
}

export function HasAdminMain() {
    const hasTradeShowPerm = useBTAdminPermission(BTAdminPermissions.TradeShow);
    const hasSalesPerm = useBTAdminPermission(BTAdminPermissions.Sales);
    const hasBillingPerm = useBTAdminPermission(BTAdminPermissions.Billing);
    const hasSupportPerm = useBTAdminPermission(BTAdminPermissions.Support);
    const hasBDRPerm = useBTAdminPermission(BTAdminPermissions.BDR);
    const hasSupportManagerPerm = useBTAdminPermission(BTAdminPermissions.SupportManager);
    const hasDeveloperPerm = useBTAdminPermission(BTAdminPermissions.Developer);
    const hasSuperUserPerm = useBTAdminPermission(BTAdminPermissions.SuperUser);
    const hasSalesPermMarketing = useBTAdminPermission(BTAdminPermissions.SalesMarketing);
    const hasHRMarketingPerm = useBTAdminPermission(BTAdminPermissions.BTAdminUserManagment);
    const hasRSMPerm = useBTAdminPermission(BTAdminPermissions.RSM);
    const hasRiskInsuranceAgentPerm = useBTAdminPermission(BTAdminPermissions.RiskInsuranceAgent);
    const hasRiskInsuranceManagementPerm = useBTAdminPermission(
        BTAdminPermissions.RiskInsuranceManagement
    );
    const hasFeatureFlagDashboardPerm = useBTAdminPermission(
        BTAdminPermissions.FeatureFlagDashboard
    );
    const hasBillingUtilitiesPerm = useBTAdminPermission(BTAdminPermissions.BillingUtilities);
    const hassubscriptionSignUpCodesPerm = useBTAdminPermission(
        BTAdminPermissions.SubscriptionSignUpCodes
    );
    const hasHangfirePerm = useBTAdminPermission(BTAdminPermissions.Hangfire);
    const hasSpecialBuilderAccessPerm = useBTAdminPermission(
        BTAdminPermissions.SpecialBuilderAccess
    );
    const hasInfrastructureBuilderAccessPerm = useBTAdminPermission(
        BTAdminPermissions.InfrastructureBuilderAccess
    );
    const hasPaymentOpsPerm = useBTAdminPermission(BTAdminPermissions.PaymentOperationsSuperUser);
    const hasRebateReceiptReviewPerm = useBTAdminPermission(BTAdminPermissions.RebateReceiptReview);
    const hasLendingPerm = useBTAdminPermission(BTAdminPermissions.LendingDashboard);

    return (
        hasTradeShowPerm ||
        hasSalesPerm ||
        hasBillingPerm ||
        hasSupportPerm ||
        hasBDRPerm ||
        hasSupportManagerPerm ||
        hasDeveloperPerm ||
        hasSuperUserPerm ||
        hasSalesPermMarketing ||
        hasHRMarketingPerm ||
        hasRSMPerm ||
        hasRiskInsuranceAgentPerm ||
        hasRiskInsuranceManagementPerm ||
        hasFeatureFlagDashboardPerm ||
        hasBillingUtilitiesPerm ||
        hassubscriptionSignUpCodesPerm ||
        hasHangfirePerm ||
        hasSpecialBuilderAccessPerm ||
        hasInfrastructureBuilderAccessPerm ||
        hasPaymentOpsPerm ||
        hasRebateReceiptReviewPerm ||
        hasLendingPerm
    );
}
