import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { CSSProperties, PureComponent } from "react";

import { BTSelectItem } from "types/apiResponse/apiResponse";
import { DateRangeFilterOptionItem } from "types/enum";

import { KeyOfOrString } from "utilities/type/PropsOfType";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTDatePicker } from "commonComponents/btWrappers/BTDatePicker/BTDatePicker";
import { BTMonthPicker } from "commonComponents/btWrappers/BTMonthPicker/BTMonthPicker";
import { BTPopover } from "commonComponents/btWrappers/BTPopover/BTPopover";
import { BTRangePicker } from "commonComponents/btWrappers/BTRangePicker/BTRangePicker";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTSelect } from "commonComponents/btWrappers/BTSelect/BTSelect";
import { BTYearPicker } from "commonComponents/btWrappers/BTYearPicker/BTYearPicker";

export interface IDateRangeFormValues {
    dateRange: RangeValue<Moment>;
    selectedItem: DateRangeFilterOptionItem;
}

export interface IDateRangeEntity {
    dateRange: RangeValue<Moment>;
    options: BTSelectItem[];
}

interface IDateRangeProps<FormValues> {
    id: KeyOfOrString<FormValues>;

    title: string;

    entity: IDateRangeEntity;

    value: IDateRangeFormValues;

    /** pass formik setFieldTouched */
    onBlur: (field: string, touched: boolean) => void;

    /** pass formik setFieldValue */
    onChange: (field: string, value: IDateRangeFormValues) => void;

    onDateRangeChange: (
        field: string,
        value: any,
        selectedItem?: BTSelectItem<undefined> | undefined
    ) => void;

    isWeekDateDisabled: (currentDate: Moment | null) => boolean;
    isSemiMonthDateDisabled: (currentDate: Moment | null) => boolean;
    isDisabled?: boolean;
    disabledPopoverContent?: string | JSX.Element;
    className?: string;
    dropdownStyle?: CSSProperties;
}

export class DateRange<FormValues> extends PureComponent<IDateRangeProps<FormValues>> {
    private handleRangeChange = (
        field: KeyOfOrString<FormValues>,
        newRangeValue: RangeValue<Moment>
    ) => {
        const newFormValue: IDateRangeFormValues = {
            ...this.props.value,
            dateRange: newRangeValue,
        };
        this.props.onChange(field as string, newFormValue);
    };

    private handleDateChange = (
        field: KeyOfOrString<FormValues> | string,
        newDateValue: Moment | undefined
    ) => {
        const newFormValue: IDateRangeFormValues = {
            ...this.props.value,
            dateRange: newDateValue ? [newDateValue, null] : null,
        };
        this.props.onChange(field as string, newFormValue);
    };

    render() {
        const {
            value,
            entity,
            id,
            onBlur,
            title,
            isDisabled,
            disabledPopoverContent,
            className,
            dropdownStyle,
        } = this.props;
        const selectedDate = value.dateRange ? value.dateRange[0] : null;

        const isCustom = value.selectedItem === DateRangeFilterOptionItem.CustomDates;
        const isWeekly =
            value.selectedItem === DateRangeFilterOptionItem.Weekly ||
            value.selectedItem === DateRangeFilterOptionItem.BiWeekly;
        const isMonthly = value.selectedItem === DateRangeFilterOptionItem.Monthly;
        const isYearly = value.selectedItem === DateRangeFilterOptionItem.Yearly;
        const isSemiMonthly = value.selectedItem === DateRangeFilterOptionItem.SemiMonthly;
        const is90DayRange =
            value.selectedItem === DateRangeFilterOptionItem.LessThan90Days ||
            value.selectedItem === DateRangeFilterOptionItem.GreaterThan90Days;
        const isExpanded =
            isCustom || isWeekly || isSemiMonthly || isMonthly || is90DayRange || isYearly;

        return (
            <div className={"FilterDateRange " + className}>
                {title !== "" && (
                    <BTRow>
                        <BTCol span={24}>
                            <span>{title}</span>
                        </BTCol>
                    </BTRow>
                )}
                <BTRow gutter={10}>
                    <BTCol span={isExpanded ? undefined : 24}>
                        <BTPopover
                            content={disabledPopoverContent}
                            disabled={!isDisabled || !disabledPopoverContent}
                        >
                            <div>
                                <BTSelect
                                    style={dropdownStyle}
                                    id={id}
                                    data-testid={id.toString() + "Select"}
                                    onBlur={onBlur}
                                    className="FilterSelectDropdown"
                                    onChange={this.props.onDateRangeChange}
                                    treeData={entity.options}
                                    value={value.selectedItem}
                                    disabled={isDisabled}
                                />
                            </div>
                        </BTPopover>
                    </BTCol>
                    {(isCustom || is90DayRange) && (
                        <BTCol>
                            <BTRangePicker
                                id={id}
                                className="RangePicker"
                                data-testid={id.toString() + "Range"}
                                onChange={this.handleRangeChange}
                                value={value.dateRange}
                                disabled={isDisabled}
                            />
                        </BTCol>
                    )}
                    {isWeekly && (
                        <BTCol>
                            <BTDatePicker
                                id={id}
                                data-testid={id.toString() + "DatePicker"}
                                className="DatePicker"
                                onChange={this.handleDateChange}
                                value={selectedDate}
                                disabledDate={this.props.isWeekDateDisabled}
                                disabled={isDisabled}
                            />
                        </BTCol>
                    )}
                    {isSemiMonthly && (
                        <BTCol>
                            <BTDatePicker
                                id={id}
                                data-testid={id.toString() + "SemiMonthPicker"}
                                className="DatePicker"
                                onChange={this.handleDateChange}
                                value={selectedDate}
                                disabledDate={this.props.isSemiMonthDateDisabled}
                                disabled={isDisabled}
                            />
                        </BTCol>
                    )}
                    {isMonthly && (
                        <BTCol>
                            <BTMonthPicker
                                id={id}
                                data-testid={id.toString() + "MonthPicker"}
                                className="DatePicker"
                                onChange={this.handleDateChange}
                                value={selectedDate}
                                disabled={isDisabled}
                            />
                        </BTCol>
                    )}
                    {isYearly && (
                        <BTCol>
                            <BTYearPicker
                                id={id as string}
                                data-testid={id.toString() + "YearPicker"}
                                className="DatePicker"
                                onChange={this.handleDateChange}
                                value={selectedDate}
                                disabled={isDisabled}
                            />
                        </BTCol>
                    )}
                </BTRow>
            </div>
        );
    }
}
