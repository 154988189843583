/**
 * Generated by orval - Do not edit manually.
 * Buildertrend API (all versions)
 * A view showing all available versions of the API. Some routes may be deprecated. Use the latest version available whenever possible.
 * OpenAPI spec version: all
 * If there are merge conflicts to this file,
 * please rebuild the Clients.Api project to resolve.
 */
import { faker } from "@faker-js/faker";
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { http, HttpResponse } from "msw";

import { apixHandler } from "../utilities/api/apix-handler";
import type { ErrorType } from "../utilities/api/apix-handler";
import type { NotificationsGetV2Params } from "./models";
import { EntityActionType } from "./models";
import type { HasNotificationResponse, NotificationSummaryResponse } from "./models";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const notificationsGetV2 = (
    params?: NotificationsGetV2Params,
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<NotificationSummaryResponse>(
        { url: `/apix/v2/Notifications`, method: "GET", params, signal },
        options
    );
};

export const getNotificationsGetV2QueryKey = (params?: NotificationsGetV2Params) => {
    return [`/apix/v2/Notifications`, ...(params ? [params] : [])] as const;
};

export const getNotificationsGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof notificationsGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: NotificationsGetV2Params,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getNotificationsGetV2QueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsGetV2>>> = ({ signal }) =>
        notificationsGetV2(params, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof notificationsGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type NotificationsGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof notificationsGetV2>>
>;
export type NotificationsGetV2QueryError = ErrorType<unknown>;

export function useNotificationsGetV2<
    TData = Awaited<ReturnType<typeof notificationsGetV2>>,
    TError = ErrorType<unknown>
>(
    params?: NotificationsGetV2Params,
    options?: {
        query?: UseQueryOptions<Awaited<ReturnType<typeof notificationsGetV2>>, TError, TData>;
        request?: SecondParameter<typeof apixHandler>;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getNotificationsGetV2QueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const notificationsHasNotificationsGetV2 = (
    options?: SecondParameter<typeof apixHandler>,
    signal?: AbortSignal
) => {
    return apixHandler<HasNotificationResponse>(
        { url: `/apix/v2/Notifications/has-notifications`, method: "GET", signal },
        options
    );
};

export const getNotificationsHasNotificationsGetV2QueryKey = () => {
    return [`/apix/v2/Notifications/has-notifications`] as const;
};

export const getNotificationsHasNotificationsGetV2QueryOptions = <
    TData = Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    >;
    request?: SecondParameter<typeof apixHandler>;
}) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getNotificationsHasNotificationsGetV2QueryKey();

    const queryFn: QueryFunction<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>
    > = ({ signal }) => notificationsHasNotificationsGetV2(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type NotificationsHasNotificationsGetV2QueryResult = NonNullable<
    Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>
>;
export type NotificationsHasNotificationsGetV2QueryError = ErrorType<unknown>;

export function useNotificationsHasNotificationsGetV2<
    TData = Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<typeof notificationsHasNotificationsGetV2>>,
        TError,
        TData
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
    const queryOptions = getNotificationsHasNotificationsGetV2QueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

    query.queryKey = queryOptions.queryKey;

    return query;
}

/**
 * **Authorization**: `session-based` | `user-bearer`


**Authentication**: `Session` | `LegacyBearer` | `auth0`


**Policy**: `InternalUser`


**Stability Level**: `stable`
 */
export const notificationsViewedNotificationsPostV2 = (
    options?: SecondParameter<typeof apixHandler>
) => {
    return apixHandler<void>(
        { url: `/apix/v2/Notifications/viewed-notifications`, method: "POST" },
        options
    );
};

export const getNotificationsViewedNotificationsPostV2MutationOptions = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationOptions<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        void
    > = () => {
        return notificationsViewedNotificationsPostV2(requestOptions);
    };

    return { mutationFn, ...mutationOptions };
};

export type NotificationsViewedNotificationsPostV2MutationResult = NonNullable<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>
>;

export type NotificationsViewedNotificationsPostV2MutationError = ErrorType<unknown>;

export const useNotificationsViewedNotificationsPostV2 = <
    TError = ErrorType<unknown>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
        TError,
        void,
        TContext
    >;
    request?: SecondParameter<typeof apixHandler>;
}): UseMutationResult<
    Awaited<ReturnType<typeof notificationsViewedNotificationsPostV2>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = getNotificationsViewedNotificationsPostV2MutationOptions(options);

    return useMutation(mutationOptions);
};

export const getNotificationsGetV2ResponseMock = (
    overrideResponse: Partial<NotificationSummaryResponse> = {}
): NotificationSummaryResponse => ({
    hasMoreItems: faker.datatype.boolean(),
    notificationSummaryItems: Array.from(
        { length: faker.number.int({ min: 1, max: 10 }) },
        (_, i) => i + 1
    ).map(() => ({
        actionType: faker.helpers.arrayElement(Object.values(EntityActionType)),
        canUnfollow: faker.datatype.boolean(),
        dateSent: (() =>
            /* @__PURE__ */ new Date(`${faker.date.past().toISOString().split(".")[0]}Z`))(),
        id: faker.number.int({ min: undefined, max: undefined }),
        isRead: faker.datatype.boolean(),
        messageData: {
            body: faker.helpers.arrayElement([
                faker.helpers.arrayElement([faker.word.sample(), null]),
                undefined,
            ]),
            builderId: faker.number.int({ min: undefined, max: undefined }),
            entityId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            entityType: faker.number.int({ min: undefined, max: undefined }),
            extraData: faker.helpers.arrayElement([
                {
                    [faker.string.alphanumeric(5)]: {},
                },
                undefined,
            ]),
            jobId: faker.helpers.arrayElement([
                faker.helpers.arrayElement([
                    faker.number.int({ min: undefined, max: undefined }),
                    null,
                ]),
                undefined,
            ]),
            recipientLoginType: faker.number.int({ min: undefined, max: undefined }),
            title: faker.word.sample(),
            userId: faker.number.int({ min: undefined, max: undefined }),
        },
        notificationType: faker.number.int({ min: undefined, max: undefined }),
    })),
    ...overrideResponse,
});

export const getNotificationsHasNotificationsGetV2ResponseMock = (
    overrideResponse: Partial<HasNotificationResponse> = {}
): HasNotificationResponse => ({
    hasNotifications: faker.helpers.arrayElement([faker.datatype.boolean(), undefined]),
    ...overrideResponse,
});

export const getNotificationsGetV2MockHandler = (
    overrideResponse?:
        | NotificationSummaryResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<NotificationSummaryResponse> | NotificationSummaryResponse)
) => {
    return http.get("*/v2/Notifications", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getNotificationsGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getNotificationsHasNotificationsGetV2MockHandler = (
    overrideResponse?:
        | HasNotificationResponse
        | ((
              info: Parameters<Parameters<typeof http.get>[1]>[0]
          ) => Promise<HasNotificationResponse> | HasNotificationResponse)
) => {
    return http.get("*/v2/Notifications/has-notifications", async (info) => {
        return new HttpResponse(
            JSON.stringify(
                overrideResponse !== undefined
                    ? typeof overrideResponse === "function"
                        ? await overrideResponse(info)
                        : overrideResponse
                    : getNotificationsHasNotificationsGetV2ResponseMock()
            ),
            { status: 200, headers: { "Content-Type": "application/json" } }
        );
    });
};

export const getNotificationsViewedNotificationsPostV2MockHandler = (
    overrideResponse?:
        | void
        | ((info: Parameters<Parameters<typeof http.post>[1]>[0]) => Promise<void> | void)
) => {
    return http.post("*/v2/Notifications/viewed-notifications", async (info) => {
        if (typeof overrideResponse === "function") {
            await overrideResponse(info);
        }
        return new HttpResponse(null, { status: 200 });
    });
};
export const getNotificationsMock = () => [
    getNotificationsGetV2MockHandler(),
    getNotificationsHasNotificationsGetV2MockHandler(),
    getNotificationsViewedNotificationsPostV2MockHandler(),
];
