import { ColProps, Input } from "antd";
import { Gutter } from "antd/lib/grid/row";
import { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

import { ILabelSchema } from "commonComponents/btWrappers/BTForm/BTForm";
import { default as EmailJson } from "commonComponents/entity/email/InputEmail/InputEmail.json";

type EmailAPIJson = typeof EmailJson;

type OptionalEmailKeys = "friendlyName" | "fullEmail";
export type MinimalEmail = Omit<EmailAPIJson, OptionalEmailKeys> &
    Partial<Pick<EmailAPIJson, OptionalEmailKeys>>;

/**
 * Standard Email model based off of the backend BTEmailAddress
 */
export class Email {
    /**
     * @param data an object with an Email similar shape, a string to use as the address, or nothing for an blank Email
     * @param key a unique identifier for the Email, use for static key instead of autogenerated one
     */
    constructor(data?: string | MinimalEmail, key?: string) {
        this.key = key ?? uuidv4();

        if (typeof data === "string") {
            this.emailAddress = data;
            this.friendlyName = "";
            this.fullEmail = null;
        } else {
            this.emailAddress = data?.emailAddress ?? "";
            this.friendlyName = data?.friendlyName ?? "";
            this.fullEmail = data?.fullEmail ?? null;
        }
    }

    key: string;
    emailAddress: string;
    friendlyName: string;
    fullEmail: string | null;
}

export type InputEmailFormValue = Email;

export interface IInputEmailProps {
    id: string;
    "data-testid": string;

    isReadOnly?: boolean;
    canDelete?: boolean;

    emailInputLabel?: string | JSX.Element | ((schema: ILabelSchema) => ReactNode) | null;
    labelInputLabel?: string | JSX.Element | ((schema: ILabelSchema) => ReactNode) | null;
    gutterWidth?: Gutter;

    /**
     * A description of how you'd like the BTCols to be laid out within the component
     *
     * "auto" (default) - divides space between the cols evenly
     *
     * string | number - defines the value of span for the corresponding col
     *
     * ColProps - defines a set of props to pass to the corresponding col
     */
    columnLayout?: "auto" | [string | number | ColProps, string | number | ColProps];
    /**
     * Position of the delete button, either inside or outside the second col
     *
     * "inside" (default) - insert the delete button within the second col, taking up some of the
     * second field's space
     *
     * "outside" - insert the delete button as a third fixed col
     */
    deletePosition?: "inside" | "outside";

    value: InputEmailFormValue;
    onChange: (id: string, value: InputEmailFormValue | string) => void;
    onBlur: (id: string, touched?: boolean) => void;
    /**
     * Callback for when the delete button is pressed
     *
     * Default behavior is to call onChange with a blank form value
     */
    onDelete?: (id: string) => void;

    children?: never;

    emailFieldRef?: React.RefObject<Input>;
}
