import { Skeleton } from "antd";

import { BTCol } from "commonComponents/btWrappers/BTCol/BTCol";
import { BTRow } from "commonComponents/btWrappers/BTRow/BTRow";
import { BTLogo } from "commonComponents/utilities/BTLogo/BTLogo";

import { UserActivationPageValuePropositionsSkeleton } from "entity/UserActivationPage/ValuePropositions/ValuePropositions";

import "./UserActivationSkeleton.less";

interface IUserActivationSkeletonProps {}

export const UserActivationSkeleton: React.FC<IUserActivationSkeletonProps> = () => {
    return (
        <BTRow className="UserActivationSkeleton" responsiveMode="viewport">
            <BTCol md={24} lg={8}>
                <BTRow align="middle" justify="center" style={{ height: "100%" }}>
                    <BTCol>
                        <BTRow className="flex-column" gutter={[16, 16]}>
                            <BTCol>
                                <Skeleton.Image style={{ width: "300px", height: "100px" }} />
                            </BTCol>
                            <BTCol style={{ width: "100%" }}>
                                <Skeleton active />
                            </BTCol>
                            <BTCol style={{ width: "100%" }}>
                                <Skeleton active />
                            </BTCol>
                            <BTCol style={{ width: "100%" }}>
                                <Skeleton active />
                            </BTCol>
                            <BTCol style={{ width: "100%" }}>
                                <BTLogo
                                    className="margin-bottom-md"
                                    layout="horizontal"
                                    color="dark-blue-blue"
                                    size="sm"
                                />
                            </BTCol>
                        </BTRow>
                    </BTCol>
                </BTRow>
            </BTCol>
            <BTCol md={24} lg={16}>
                <UserActivationPageValuePropositionsSkeleton />
            </BTCol>
        </BTRow>
    );
};
