import { SortOrder } from "antd/lib/table/interface";
import { isEqual } from "lodash-es";
import moment from "moment";

import { BTSelectItem, IBaseEntity } from "types/apiResponse/apiResponse";
import { CustomFieldDataType } from "types/enum";

import { isEqualIgnoreOrder } from "utilities/array/array";

import {
    ICustomFieldOptionsList,
    ICustomFieldResponse,
} from "commonComponents/entity/customField/CustomFieldContainer/CustomFieldContainer.types";
import { IEmptyStateHandler } from "commonComponents/entity/emptyState/common/EmptyState.api.handler";
import { IFileIconLinkResponse } from "commonComponents/utilities/Grid/common/columnImplementations/columnFileIconLink";
import { GridColumnType } from "commonComponents/utilities/Grid/GridContainer.types";

import { IFilterHandler } from "entity/filters/Filter/Filter.api.handler";
import {
    FilterEntity,
    FilterEntityType,
    IFilterFormValues,
    mapFilterEntityToFormValues,
    SystemFilterType,
} from "entity/filters/Filter/Filter.api.types";
import { applySerializeBehavior } from "entity/filters/filters.utils";
import { SavedFilterItem } from "entity/filters/SavedFilter/SavedFilter.api.types";

export enum ListEntityType {
    None = 0,
    Leads = 1,
    Warranty = 2,
    NotificationHistory = 3,
    ChangeOrders = 4,
    SubsList = 5,
    UsersList = 6,
    Payments = 7,
    JobsitesList = 8,
    TemplatesList = 9,
    PurchaseOrders = 10,
    Selections = 11,
    LeadActivities = 12,
    DailyLogs = 13,
    Todos = 14,
    Calendar = 15,
    Tasks = 16,
    BidPackages = 17,
    Bids = 18,
    SubsBids = 19,
    SubChangeOrders = 20,
    SubPurchaseOrders = 21,
    Photos = 22,
    SubSelections = 23,
    SubToDos = 24,
    SubWarranty = 25,
    SubDailyLogs = 26,
    SubCalendar = 27,
    // ArchiveEmailNotifications = 28,
    TimeClock = 29,
    SurveyQuestions = 30,
    SurveyDefinitions = 31,
    SurveyQuestionAnswers = 32,
    Surveys = 33,
    CustomerInvoices = 34,
    GroupedSurveyFormats = 35,
    SurveyReportAnswers = 36,
    TimeClockMap = 37,
    Comments = 38,
    CreditMemos = 39,
    InvoicePayments = 40,
    WorkdayExceptions = 41,
    OwnerSurvey = 42,
    LeadsMap = 44,
    SubcontractorAssignments = 45,
    AdminAudit = 46,
    AdminPartnerReport = 47,
    // AdminPaymentConsultantReport = 48,
    MerchantPayments = 49,
    Contacts = 50,
    Videos = 51,
    AdminAccountManagement = 52,
    FavoritedSelectionChoices = 53,
    ContactOpportunities = 54,
    ContactJobs = 55,
    AdminChurnReport = 57,
    LeadActivityTemplate = 58,
    ContactSearch = 59,
    BudgetCostCodes = 60,
    BudgetCategories = 61,
    AdminPayments = 62,
    ErrorDetails = 63,
    Phases = 64,
    ErrorReports = 65,
    MediaList = 66,
    LeadProposals = 67,
    // VendorSelectionList = 68
    LeadProposalTemplates = 69,
    OwnerDailyLogs = 70,
    RFIList = 71,
    Onboarding = 74,
    BillPayments = 75,
    BTAdminUsers = 76,
    UnprocessedEmails = 77,
    RiskInsurance = 78,
    Settlements = 79,
    // AttachedPhotosSelections = 80,
    // AttachedPhotosChangeOrders = 81,
    // AttachedPhotosWarrantyServiceAppointments = 82,
    // AttachedPhotosScheduleItems = 83,
    // AttachedPhotosCostGroups = 84,
    // AttachedPhotosEstimates = 85,
    // AttachedPhotosBids = 86,
    // AttachedPhotosBidPackages = 87,
    // AttachedPhotosBillsAndPurchaseOrders = 88,
    // AttachedPhotosLienWaivers = 89,
    // AttachedPhotosOwnerPayments = 90,
    // AttachedPhotosMessages = 91,
    // <Description("ScheduledProcessesRan")> ScheduledProcessesRan = 92
    Categories = 93,
    OnlinePaymentReport = 94,
    // ChurnReport = 95,
    BlacklistEmails = 96,
    // BuilderStatusReport = 98,
    // AttachedPhotosToDoChecklist = 99,
    // AdminPromoterSurveyBlitz = 100,
    BulkExport = 101,
    Estimates = 102,
    InternalUserAssignments = 103,
    SubAssignmentsTransfer = 104,
    ScheduledProcesses = 106,
    CostItems = 107,
    CostGroups = 108,
    CostItemsFromCostGroupEditor = 109,
    // VendorProducts = 110,
    Suspensions = 111,
    SubSelectionsPending = 112,
    SubSelectionsRequested = 113,
    SubSelectionsCompleted = 114,
    WebServicesQueue = 115,
    Messages = 116,
    Baseline = 117,
    Receipts = 119,
    JobsMap = 120,
    Allowances = 121,
    WarrantyServices = 122,
    ScheduleList = 123,
    SelectionHierarchy = 124,
    ScheduleGantt = 125,
    ActivityFeed = 126,
    Partners = 127,
    FeatureFlag = 128,
    MicrodepositVerification = 129,
    // MerchantAccountStatus = 130,
    TaxRates = 131,
    TaxGroups = 132,
    SubscriptionSignUpCodes = 133,
    Bills = 134,
    Rebates = 135,
    Specification = 136,
    Products = 137,
    OnlinePaymentAccountStatus = 138,
    Deposits = 139,
    Settings = 140,
    Budget = 141,
    JobCostingBudget = 142,
    LeadActivitiesCalendar = 143,
    ClientUpdates = 144,
    GroupedOnlinePayments = 145,
}

export class ISortableColumn {
    id: string;
    isSortable: boolean;
    name: string;
}

// the server response type
export class ServerGridColumn implements ISortableColumn {
    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.jsonKey = data.jsonKey;
        this.order = data.order;
        this.enabled = data.enabled;
        this.align = data.align;
        this.isRemovableFromGrid = data.isRemovableFromGrid;
        this.isResizeable = data.isResizeable;
        this.isSortable = data.isSortable;
        this.isSearchable = data.isSearchable;
        this.columnType = data.columnType;
        this.isFixedWidth = data.isFixedWidth;
        this.isFrozen = data.isFrozen;
        this.isHidden = data.isHidden;
        this.isAlwaysVisible = data.isAlwaysVisible;
        this.printHeaderDisplayAs = data.printHeaderDisplayAs;
        this.isCustomField = data.isCustomField;
        this.isCustomFieldRelated = data.isCustomFieldRelated;
        this.isCustomFieldTransform = data.isCustomFieldTransform;
        this.title = data.name;
        this.isExpandable = data.isExpandable;
        this.toolTipHeader = data.toolTipHeader;
        this.width = data.width;
        this.defaultWidth = data.defaultWidth;

        if (data.customFieldMetadata) {
            this.customFieldMetadata = new CustomFieldMetadata(data.customFieldMetadata);
        }
    }

    // TODO: See if we can change this to a number type
    id: string;
    name: string;
    jsonKey: string; // this is a dataKey that links to the data portion of the response
    title: string;
    toolTipHeader: string;

    width: number;
    defaultWidth: number;
    order: number;
    enabled: boolean;
    align: "left" | "right" | "center";

    isRemovableFromGrid: boolean;
    isResizeable: boolean;
    isSortable: boolean;
    isSearchable: boolean;
    isExpandable?: boolean;

    // New Keys
    columnType: GridColumnType;

    isFixedWidth: boolean;
    isFrozen: boolean; // only if we want to persist these to the DB, need to see if new line items has this feature
    isHidden: boolean; // might be the same as "enabled", need to look into
    isAlwaysVisible: boolean;

    printHeaderDisplayAs: string;

    isCustomField: boolean;
    isCustomFieldRelated: boolean;
    isCustomFieldTransform: boolean;
    customFieldMetadata: CustomFieldMetadata;
}

export class CustomFieldMetadata {
    constructor(data: any) {
        if (data.options) {
            this.options = data.options.map((o: any) => new BTSelectItem(o));
        } else {
            this.options = [];
        }
        if (data.documents) {
            this.documents = data.documents;
        } else {
            this.documents = {};
        }
    }
    options: BTSelectItem[];
    documents: { [key: number]: IFileIconLinkResponse };

    public static fromCustomFieldsOptionsList = (
        customFields: ICustomFieldResponse[],
        customFieldOptions: ICustomFieldOptionsList
    ) => {
        let customFieldMetadataMap: { [customFieldId: number]: CustomFieldMetadata } = {};
        customFields.forEach((field) => {
            let selector = field.optionsKey ? field.optionsKey : field.id;
            if (customFieldOptions[selector]) {
                customFieldMetadataMap[field.id] = new CustomFieldMetadata({
                    options: customFieldOptions[selector],
                });
            } else {
                let documents = {
                    [selector]: {
                        fileName:
                            field.typeId === CustomFieldDataType.File && field.selectedFile
                                ? field.selectedFile.title
                                : "",
                    },
                };
                customFieldMetadataMap[field.id] = new CustomFieldMetadata({
                    documents: documents,
                });
            }
        });
        return customFieldMetadataMap;
    };
}

export interface ICustomGridColumn<TGridDataRow = {}> extends Omit<ServerGridColumn, "name"> {
    name: string | ((column: ServerGridColumn) => JSX.Element);

    /** lets our grid know which column this will trigger on, the service will pass down this type from the service */
    id: string;

    className?: string;

    /** Runs whenever a cell is clicked or focused on */
    onCellFocus?: (record: TGridDataRow) => void;

    onSort?: () => void;

    /** Overwrites how we render the data in the grid */
    render: (record: TGridDataRow, index: number, column: ServerGridColumn) => JSX.Element;
}

export class CustomField {
    constructor(data: any) {
        this.label = data.label;
        if (data.type === CustomFieldDataType.Date && data.value) {
            this.value = moment(data.value);
        } else {
            this.value = data.value;
        }
        this.customFieldId = data.customFieldId;
        this.type = data.type;
    }
    label: string;
    value: any;
    customFieldId: number;
    type: CustomFieldDataType;

    public static fromCustomFieldResponse = (resp: ICustomFieldResponse) => {
        return new CustomField({
            label: resp.title,
            value: CustomField.getCustomFieldValue(resp),
            customFieldId: resp.id,
            type: resp.typeId,
        });
    };
    private static getCustomFieldValue = (field: ICustomFieldResponse) => {
        switch (field.typeId) {
            case CustomFieldDataType.Currency: {
                return {
                    value: field.value ? field.value : 0,
                    scale: 2,
                };
            }
            case CustomFieldDataType.WholeNumber: {
                return {
                    value: field.value ? field.value : 0,
                    scale: 0,
                };
            }
            case CustomFieldDataType.DropDownList:
            case CustomFieldDataType.DropDownCheckList:
            case CustomFieldDataType.ListOfUsers:
            case CustomFieldDataType.ListOfSubs:
            case CustomFieldDataType.MultiSelectSubs:
            case CustomFieldDataType.MultiSelectUsers: {
                if (field.value instanceof Array) {
                    return field.value;
                } else {
                    return [field.value];
                }
            }
            case CustomFieldDataType.File: {
                return field.id;
            }
            case CustomFieldDataType.None:
            case CustomFieldDataType.Text:
            case CustomFieldDataType.MultiLineText:
            case CustomFieldDataType.Date:
            case CustomFieldDataType.Link:
            default:
                return field.value;
        }
    };
}

export interface IUserPermissions {
    canAdd: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    canView: boolean;
}

export interface IListRowData extends IBaseEntity {
    [key: string]: any; // todo - see if we can use type unknown here, but right now DailyLogEntry is not assignable to this type
}

export interface IListEntity<TRowData, TFooterRow = {}> {
    serverColumnsList: ServerGridColumn[] | null;
    data: TRowData[];
    footerData: TFooterRow | null;
    pagingData: PagingData;

    totalPages: number;
    records: number;
    pageSize: number;
    page: number;
    infiniteScrollStatus: number;
    isLoaded: boolean;
    canAdd: boolean;
    listMetadata: ListMetadata;
}

export class ListEntity<TFooterRow = {}> implements IListEntity<IListRowData, TFooterRow> {
    constructor(data: any) {
        this.serverColumnsList =
            data.columnsList && data.columnsList.map((c: any) => new ServerGridColumn(c));
        this.data = data.data;
        this.footerData = data.footerData;
        this.totalPages = data.totalPages;
        this.records = data.records;
        this.pageSize = data.pageSize;
        this.page = data.page;
        this.infiniteScrollStatus = data.infiniteScrollStatus;
        this.isLoaded = data.isLoaded;
        this.canAdd = data.canAdd;
        this.canUpdate = data.canUpdate;
        this.canDelete = data.canDelete;
        this.pagingData = getPagingData(data.page, data.pageSize, data.records);
        this.listMetadata = new ListMetadata(data.listMetaData);
    }

    serverColumnsList: ServerGridColumn[] | null;
    data: IListRowData[];
    footerData: TFooterRow | null;
    pagingData: PagingData;

    totalPages: number;
    records: number;
    pageSize: number;
    page: number;
    infiniteScrollStatus: number;
    isLoaded: boolean;
    canAdd: boolean;
    canUpdate: boolean;
    canDelete: boolean;
    listMetadata: ListMetadata;
}

export interface IHasEmptyStateBannerProps {
    /**
     * Empty state event handler
     */
    emptyStateHandler?: IEmptyStateHandler;
}

export interface IHasEmptyStateBannerState {
    isEmptyStateBannerDismissed: boolean;
}

export interface IListContainerProps {
    entityType: ListEntityType;
    checkedActions?: (selectedItems: IBaseEntity[]) => JSX.Element;
    customColumns?: any;
    filterHandler?: IFilterHandler;
    jobPickerReady?: boolean;
    systemFilter?: SystemFilterType;
    jobIds?: number[];
}

export interface IListContainerState<T extends ListEntity> {
    filterEntity?: FilterEntity;
    previousFilters?: IFilterFormValues;
    listEntity?: T;
}

export interface IListFooterWithCustomFields {
    customFields: CustomField[] | null;
}

export function getFilterEntityType(type: ListEntityType): FilterEntityType {
    switch (type) {
        case ListEntityType.None:
            return FilterEntityType.None;
        case ListEntityType.Leads:
        case ListEntityType.LeadsMap:
            return FilterEntityType.LeadsListView;
        case ListEntityType.DailyLogs:
        case ListEntityType.SubDailyLogs:
        case ListEntityType.OwnerDailyLogs:
            return FilterEntityType.DailyLogView;
        case ListEntityType.Todos:
            return FilterEntityType.ToDoSummaryView;
        case ListEntityType.Messages:
            return FilterEntityType.MessagesListView;
        case ListEntityType.BidPackages:
        case ListEntityType.SubsBids:
            return FilterEntityType.BidsBuilder;
        case ListEntityType.Comments:
            return FilterEntityType.MessagesCommentsView;
        case ListEntityType.Baseline:
            return FilterEntityType.ScheduleBaselineView;
        case ListEntityType.Calendar:
            return FilterEntityType.CalendarView;
        case ListEntityType.ScheduleList:
            return FilterEntityType.ScheduleListView;
        case ListEntityType.ScheduleGantt:
            return FilterEntityType.CalendarGanttView;
        case ListEntityType.JobsitesList:
        case ListEntityType.JobsMap:
            return FilterEntityType.JobsList;
        case ListEntityType.WorkdayExceptions:
            return FilterEntityType.ScheduleWorkdayExceptionView;
        case ListEntityType.NotificationHistory:
            return FilterEntityType.NotificationHistory;
        case ListEntityType.Receipts:
            return FilterEntityType.ReceiptList;
        case ListEntityType.Estimates:
            return FilterEntityType.EstimateWorksheet;
        case ListEntityType.TemplatesList:
            return FilterEntityType.TemplatesList;
        case ListEntityType.CustomerInvoices:
            return FilterEntityType.OwnerPaymentsList;
        case ListEntityType.GroupedSurveyFormats:
            return FilterEntityType.GroupedSurveyList;
        case ListEntityType.Selections:
            return FilterEntityType.SelectionsListView;
        case ListEntityType.Surveys:
            return FilterEntityType.IndividualSurveyList;
        case ListEntityType.SurveyQuestions:
            return FilterEntityType.SurveyQuestionsList;
        case ListEntityType.Warranty:
            return FilterEntityType.WarrantySummaryView;
        case ListEntityType.CostItems:
            return FilterEntityType.CostCodeItems;
        case ListEntityType.CostGroups:
            return FilterEntityType.CostGroups;
        case ListEntityType.LeadActivities:
            return FilterEntityType.LeadsActivityView;
        case ListEntityType.LeadActivitiesCalendar:
            return FilterEntityType.LeadsActivityCalendarView;
        case ListEntityType.LeadActivityTemplate:
            return FilterEntityType.LeadsActivityTemplate;
        case ListEntityType.LeadProposals:
            return FilterEntityType.LeadProposals;
        case ListEntityType.OnlinePaymentReport:
            return FilterEntityType.OnlinePaymentReport;
        case ListEntityType.LeadProposalTemplates:
            return FilterEntityType.LeadProposalTemplate;
        case ListEntityType.Allowances:
            return FilterEntityType.SelectionsAllowanceView;
        case ListEntityType.Payments:
            return FilterEntityType.POPaymentsView;
        case ListEntityType.SelectionHierarchy:
            return FilterEntityType.SelectionConditionView;
        case ListEntityType.JobCostingBudget:
            return FilterEntityType.JobCostingBudget;
        case ListEntityType.BudgetCostCodes:
        case ListEntityType.BudgetCategories:
            return FilterEntityType.BudgetSummaryView;
        case ListEntityType.TimeClock:
            return FilterEntityType.TimeClockReportView;
        case ListEntityType.TimeClockMap:
            return FilterEntityType.TimeClockMapView;
        case ListEntityType.PurchaseOrders:
            return FilterEntityType.PurchaseOrderList;
        case ListEntityType.ChangeOrders:
        case ListEntityType.SubChangeOrders:
            return FilterEntityType.ChangeOrdersListView;
        case ListEntityType.UsersList:
            return FilterEntityType.UsersInternalView;
        case ListEntityType.RFIList:
            return FilterEntityType.RFIsList;
        case ListEntityType.ActivityFeed:
            return FilterEntityType.ActivityFeed;
        case ListEntityType.WarrantyServices:
            return FilterEntityType.WarrantyServices;
        case ListEntityType.MerchantPayments:
            return FilterEntityType.MerchantPayments;
        case ListEntityType.Contacts:
            return FilterEntityType.UsersContactsView;
        case ListEntityType.SubsList:
            return FilterEntityType.UsersSubsView;
        case ListEntityType.InvoicePayments:
            return FilterEntityType.InvoicePayments;
        case ListEntityType.CreditMemos:
            return FilterEntityType.CreditMemos;
        case ListEntityType.Deposits:
            return FilterEntityType.Deposits;
        case ListEntityType.TaxRates:
            return FilterEntityType.TaxRates;
        case ListEntityType.TaxGroups:
            return FilterEntityType.TaxGroups;
        case ListEntityType.SubscriptionSignUpCodes:
            return FilterEntityType.SubscriptionSignUpCodes;
        case ListEntityType.Specification:
            return FilterEntityType.Specification;
        case ListEntityType.Settlements:
            return FilterEntityType.Settlements;
        case ListEntityType.BillPayments:
            return FilterEntityType.OutgoingPayments;
        case ListEntityType.OnlinePaymentAccountStatus:
            return FilterEntityType.OnlinePaymentAccountStatus;
        case ListEntityType.GroupedOnlinePayments:
            return FilterEntityType.GroupedOnlinePayments;
        // TODO: Add filter entity type mapping
        default:
            throw new Error("Unsupported FilterType");
    }
}

export const shouldReloadFilters = (currentFilter: FilterEntity, prevFilter?: FilterEntity) => {
    if (prevFilter === undefined) {
        return true;
    }
    return !isEqualIgnoreOrder(currentFilter.builderIds, prevFilter.builderIds);
};

export async function getInitialFilterValues(props: IListContainerProps, isBTAdmin?: boolean) {
    const { filterHandler, entityType, jobIds } = props;

    const nonSpecialJobIDs = jobIds?.filter((j) => j > 0);
    const jobID = nonSpecialJobIDs?.length === 1 ? nonSpecialJobIDs[0] : null;
    const filterEntity = await filterHandler!.get(
        getFilterEntityType(entityType),
        isBTAdmin,
        jobID
    );

    if (props.systemFilter && filterEntity.systemFilters[props.systemFilter] !== undefined) {
        filterEntity.selectedSystemFilter = new SavedFilterItem({
            id: props.systemFilter,
            value: filterEntity.systemFilters[props.systemFilter],
        });
    }
    const filterValues = applySerializeBehavior(
        filterEntity,
        mapFilterEntityToFormValues(filterEntity)
    );
    return { filterEntity, filterValues };
}

export const getListUpdateDataForFullReload = async (
    props: IListContainerProps,
    prevFilterEntity?: FilterEntity
): Promise<IFullReloadListUpdateData> => {
    const { filterEntity, filterValues } = await getInitialFilterValues(props);
    const filters = !isEqual(filterEntity, prevFilterEntity) ? filterValues : undefined;
    return {
        updatedFilterValues: filters,
        filterEntity,
    };
};

export const getDefaultPagingData = (
    pagingData: PagingData | undefined,
    resetPaging: boolean = false
) => {
    if (resetPaging && pagingData) {
        return getPagingData(1, pagingData.pageSize, pagingData.pageSize);
    } else if (pagingData) {
        return pagingData;
    } else {
        return getPagingData(1);
    }
};

export const getPagingData = (currentPage: number, pageSize?: number, totalRecords?: number) => {
    const pagingData = new PagingData();
    if (pageSize) {
        pagingData.pageSize = pageSize;
    }
    if (totalRecords !== undefined) {
        pagingData.total = totalRecords;
    }
    pagingData.currentPage = currentPage;
    pagingData.firstRow = (pagingData.currentPage - 1) * pagingData.pageSize + 1;
    pagingData.lastRow = pagingData.firstRow + pagingData.pageSize - 1;
    return pagingData;
};

export const mapPagingDataToRequest = (
    pagingData: PagingData,
    resetScroll: boolean
): IPagingDataRequest => ({
    pageNumber: pagingData.currentPage.toString(),
    pageSize: pagingData.pageSize,
    resetScroll: resetScroll,
    firstRow: pagingData.firstRow,
    lastRow: pagingData.lastRow,
    totalRowsAllPages: pagingData.total,
});

export class PagingData {
    constructor(pageSize?: number) {
        const defaultPageSize = pageSize ? pageSize : 50;
        // default values that will be replaced after the first API call
        this.pageSize = defaultPageSize;
        this.currentPage = 1;
        this.lastRow = defaultPageSize;
        this.firstRow = 1;
        this.total = defaultPageSize;
    }

    currentPage: number;
    pageSize: number;
    firstRow: number;
    lastRow: number;
    total: number;
}

export interface IPagingDataRequest {
    pageNumber: string;
    pageSize: number;
    resetScroll: boolean;
    firstRow: number;
    lastRow: number;
    totalRowsAllPages: number;
}

export class GridRequest {
    constructor(entity: EmptyStateEntity) {
        this.emptyStateEntity = entity;
    }

    // This should default to true and be overriden to false as needed by the implementing entity
    hideMultiJobsColumns: boolean = true;
    headerGroupIds: string;
    selectedColumns: string[];
    searchColumns: string[];
    sortColumn: string;
    sortDirection: "asc" | "desc";
    hasFooter: boolean;
    emptyStateEntity: EmptyStateEntity;
    savedViewId?: number;
    shouldUseDefaultSort: boolean;
    selectedSystemFilter?: SystemFilterType;
    groupColumn?: string;
}

export interface IGridRequestData {
    gridRequest?: GridRequest;
    selectedJobIds?: number[];
    pagingData?: IPagingDataRequest;
    filters?: string;
    selectedSystemFilter?: SystemFilterType;
    gridType?: ListEntityType;
    includeEntitiesNotAssociatedWithJob?: boolean;
}

export interface IGridRequestDataExternal {
    gridRequest?: GridRequest;
    pagingData?: IPagingDataRequest;
    filters?: string;
    gridType?: ListEntityType;
}

export interface IJobParamGridRequestData {
    gridRequest?: GridRequest;
    jobIds?: number[];
    pagingData?: IPagingDataRequest;
    filters?: string;
    selectedSystemFilter?: SystemFilterType;
    gridType?: ListEntityType;
}

export interface IJobParamAndNonJobGridRequestData extends IJobParamGridRequestData {
    includeEntitiesNotAssociatedWithJob: boolean;
}

export interface IExcelExportData {
    gridRequest?: GridRequest;
    filters?: string;
    jobIds?: number[];
}

export class ExcelExportRequest<Data extends IExcelExportData> implements RequestInit {
    constructor(data: Data) {
        this.method = "POST";
        this.data = data;
        this.responseType = "blob";
    }

    data: Data;
    method: "POST";
    responseType: "blob";
}

export interface IIncludeAllJobs {
    includeEntitiesNotAssociatedWithJob?: boolean;
}

export type JobParamAndNonJobExcelExportData = IExcelExportData & IIncludeAllJobs;

export class JobParamAndNonJobExcelExportRequest extends ExcelExportRequest<JobParamAndNonJobExcelExportData> {}

export class ListMetadata {
    constructor(data: any) {
        if (data) {
            this.hasData = data.hasData;
            this.emptyStatesMetadata = data.emptyStatesMetadata // If emptyState type is "None" set the default as the server will not return it.
                ? new EmptyStateMetadata(data.emptyStatesMetadata)
                : {
                      hasFilteredData: false,
                      isNewToEntity: false,
                      isReadOnly: true,
                      isMissingRequiredParentEntities: false,
                  };
        }
    }
    hasData: boolean;
    emptyStatesMetadata: EmptyStateMetadata;
}

export class EmptyStateMetadata {
    constructor(data: any) {
        this.hasFilteredData = data.hasFilteredData;
        this.isNewToEntity = data.isNewToEntity;
        this.isReadOnly = data.isReadOnly;
        this.isMissingRequiredParentEntities = data.isMissingRequiredParentEntities ?? false;
        this.helpLink = data.helpLink;
    }
    hasFilteredData: boolean;
    isNewToEntity: boolean;
    isReadOnly: boolean;
    isMissingRequiredParentEntities: boolean;
    helpLink?: string;
}

export interface IListData {
    updatedFilterValues?: IFilterFormValues;
    updatedPagingData?: PagingData;
    updatedSortData?: GridSort;
}

export interface IFullReloadListUpdateData extends IListData {
    filterEntity?: FilterEntity;
}

export class GridSort {
    constructor(sortColumn: string, sortDirection: SortDirection) {
        this.sortColumn = sortColumn;
        this.sortDirection = sortDirection;
    }

    sortColumn: string;
    sortDirection: SortDirection;
}

export function getBTTableSortKeys(
    sortData: GridSort,
    key: string,
    onSortUpdated: (sortData: GridSort) => void
) {
    const sortName = sortData.sortDirection === "asc" ? "ascend" : "descend";
    const sortOrder: boolean | SortOrder | undefined =
        sortData.sortColumn === key ? sortName : undefined;
    return {
        sortOrder: sortOrder,
        sorter: true,
        onHeaderCell: () => ({
            onClick: () => {
                const direction =
                    sortData.sortColumn === key && sortData.sortDirection === "desc"
                        ? "asc"
                        : "desc";
                const gridSort = new GridSort(key, direction);
                onSortUpdated(gridSort);
            },
        }),
    };
}

export type SortDirection = "asc" | "desc" | undefined;

export enum EmptyStateEntity {
    None = 0,
    Summary = 1,
    Jobs = 2,
    JobTemplates = 3,
    ChangeOrders = 4,
    Documents = 5,
    GlobalDocuments = 6,
    Messages = 7,
    Subs = 8,
    Admin = 9,
    Warranty = 10,
    Selections = 11,
    PurchaseOrderPayments = 12,
    PurchaseOrders = 13,
    Leads = 14,
    Budget = 15,
    Bidding = 16,
    ToDos = 17,
    DailyLogs = 18,
    TimeClock = 19,
    Surveys = 20,
    JobEstimates = 21,
    OwnerInvoices = 22,
    Contacts = 24,
    Videos = 25,
    GlobalVideos = 26,
    RFIs = 27,
    CreditMemos = 28,
    InternalUsers = 29,
    Schedule = 30,
    Photos = 31,
    Allowances = 32,
    LeadProposals = 33,
    LeadProposalTemplates = 34,
    OnlinePaymentReport = 35,
    Comments = 36,
    NotificationHistory = 37,
    Reports = 38,
    LeadActivities = 39,
    LeadProposalsForLeads = 40,
    Baseline = 41,
    Receipts = 42,
    CostItems = 43,
    CostGroups = 44,
    LeadActivityTemplates = 45,
    WorkdayExceptions = 46,
    HomeDepotUnconnected = 47,
    WarrantyService = 48,
    Partners = 49,
    InvoicePayments = 50,
    TaxRates = 51,
    TaxGroups = 52,
    SubscriptionSignUpCodes = 53,
    Specifications = 55,
    Settlements = 56,
    OutgoingPayments = 57,
    Bills = 58,
    Deposits = 59,
    Rebates = 60,
}
