import { AttachedFiles, BTFileSystem } from "legacyComponents/FileUploadContainer.types";
import moment, { Moment } from "moment";

import { CostTypes, MarkedAs, ProposalGroupTypes } from "types/enum";

import { AddressEntity } from "commonComponents/entity/address/Address/Address.api.types";
import { MarkupType } from "commonComponents/utilities/LineItemContainer/types/LineItem.types";

import { OptionMode } from "entity/estimate/common/estimate.common.types";
import {
    IProposalSignatureObject,
    ProposalBodyLayouts,
    ProposalColumnTypes,
    ProposalHeaderLayouts,
    ProposalPrintoutType,
    ProposalSignatureObject,
    ProposalStatusObject,
} from "entity/estimate/Proposal/Proposal.types";
import { default as ProposalJustLineItemsAPIResponse } from "entity/estimate/Proposal/ProposalJustLineItems.api.json";
import { default as ProposalWithGroupsAPIResponse } from "entity/estimate/Proposal/ProposalWithGroups.api.json";

type ProposalApiResponse =
    | typeof ProposalWithGroupsAPIResponse
    | typeof ProposalJustLineItemsAPIResponse;
type GroupApiResponse =
    | (typeof ProposalWithGroupsAPIResponse)["proposalData"]["formatItems"][number]
    | (typeof ProposalWithGroupsAPIResponse)["proposalData"]["formatItems"][number]["items"][number];
type LineItemApiResponse =
    (typeof ProposalJustLineItemsAPIResponse)["proposalData"]["formatItems"][number];

export class ProposalForDisplay {
    constructor(data: ProposalApiResponse["proposalData"]) {
        this.builderId = data.builderId;
        this.jobId = data.jobId;
        this.worksheetId = data.worksheetId;

        this.title = data.title;
        this.approvalDeadline = data.approvalDeadline ? moment(data.approvalDeadline) : undefined;
        this.status = new ProposalStatusObject(data.status);
        this.groupingType = data.groupingType;

        this.builderInfo = new BuilderInfoForDisplay(data.builderInfo);
        this.builderAddress = data.builderAddress
            ? new AddressEntity(data.builderAddress)
            : undefined;
        this.customerInfo = data.customerInfo
            ? new CustomerInfoForDisplay(data.customerInfo)
            : undefined;
        this.customerAddress = data.customerAddress
            ? new AddressEntity(data.customerAddress)
            : undefined;

        this.displayOptions = new ProposalFormatOptionsForDisplay(data.displayOptions);
        this.whatToDisplay = data.whatToDisplay;

        this.introductionText = data.introductionText;
        this.closingText = data.closingText;
        this.disclaimerText = data.disclaimerText;
        this.files = data.files.map((file) => new BTFileSystem(file));
        this.attachedFilesBase = new AttachedFiles({ ...data.attachedFilesBase, files: [] });
        this.signatures = data.signatures.map(
            (signature: IProposalSignatureObject) => new ProposalSignatureObject(signature)
        );

        this.formatItems =
            data.formatItems.length && "items" in data.formatItems[0]
                ? (data.formatItems as GroupApiResponse[]).map(
                      (item) => new ProposalGroupForDisplay(item)
                  )
                : (data.formatItems as LineItemApiResponse[]).map(
                      (item) => new ProposalLineItemForDisplay(item)
                  );

        this.baseBuilderCost = data.baseBuilderCost;
        this.baseMarkupAmount = data.baseMarkupAmount;
        this.baseOwnerPrice = data.baseOwnerPrice;
    }

    builderId: number;
    jobId: number;
    worksheetId: number;
    proposalId?: number;

    title: string;
    approvalDeadline?: Moment;
    status: ProposalStatusObject;
    groupingType: ProposalGroupTypes;

    builderInfo: BuilderInfoForDisplay;
    builderAddress?: AddressEntity;
    customerInfo?: CustomerInfoForDisplay;
    customerAddress?: AddressEntity;

    displayOptions: ProposalFormatOptionsForDisplay;
    whatToDisplay: ProposalColumnTypes[];

    introductionText: string;
    closingText: string;
    disclaimerText: string;
    files: BTFileSystem[];
    /** If included, will render the interactive media viewer on the proposal */
    attachedFilesBase?: AttachedFiles;
    signatures: IProposalSignatureObject[];

    formatItems: ProposalGroupForDisplay[] | ProposalLineItemForDisplay[];

    // totals for required scope only
    baseBuilderCost: number;
    baseMarkupAmount: number;
    baseOwnerPrice: number;
}

export class BuilderInfoForDisplay {
    constructor(data: ProposalApiResponse["proposalData"]["builderInfo"]) {
        this.logo = data.logo;
        this.companyName = data.companyName;
        this.phone = data.phone;
        this.fax = data.fax;
        this.additionalFields = data.additionalFields;
    }

    logo: string;
    companyName: string;
    phone: string;
    fax: string;
    additionalFields: string[];
}

export class CustomerInfoForDisplay {
    constructor(data: ProposalApiResponse["proposalData"]["customerInfo"]) {
        this.ownerName = data.ownerName;
        this.ownerPhone = data.ownerPhone;
        this.ownerCell = data.ownerCell;
    }

    ownerName: string | null;
    ownerPhone: string | null;
    ownerCell: string | null;
}

export class ProposalFormatOptionsForDisplay {
    constructor(data: ProposalApiResponse["proposalData"]["displayOptions"]) {
        this.header = data.header;
        this.body = data.body;
        this.printoutType = data.printoutType;
    }

    header: ProposalHeaderLayouts;
    body: ProposalBodyLayouts;
    printoutType: ProposalPrintoutType;
}

export class ProposalGroupForDisplay {
    constructor(data: GroupApiResponse) {
        this.proposalFormatItemId = data.proposalFormatItemId;
        this.parentId = data.parentId ?? undefined;
        this.optionMode = data.optionMode;
        this.isExpanded = data.isExpanded;
        this.displayOrder = data.displayOrder;

        this.title = data.title;
        this.description = data.description;
        this.files = data.files.map((file) => new BTFileSystem(file));

        this.groupBuilderCostSubtotal = data.groupBuilderCostSubtotal;
        this.groupMarkupAmountSubtotal = data.groupMarkupAmountSubtotal;
        this.groupOwnerPriceSubtotal = data.groupOwnerPriceSubtotal;

        this.items = data.items.map((item) => {
            if ("items" in item) {
                return new ProposalGroupForDisplay(item);
            } else {
                return new ProposalLineItemForDisplay(item);
            }
        });
    }

    proposalFormatItemId: number;
    parentId?: number;
    optionMode: OptionMode;
    isExpanded: boolean;
    displayOrder: number;

    title: string;
    description: string;
    files: BTFileSystem[];

    groupBuilderCostSubtotal: number;
    groupMarkupAmountSubtotal: number;
    groupOwnerPriceSubtotal: number;

    items: (ProposalGroupForDisplay | ProposalLineItemForDisplay)[];
}

export class ProposalLineItemForDisplay {
    constructor(data: LineItemApiResponse) {
        this.proposalFormatItemId = data.proposalFormatItemId;
        this.parentId = data.parentId ?? undefined;
        this.displayOrder = data.displayOrder;

        this.title = data.title;
        this.costCodeTitle = data.costCodeTitle;
        this.description = data.description;
        this.costTypes = data.costTypes;
        this.markedAs = data.markedAs;
        this.unitCost = data.unitCost;
        this.unitPrice = data.unitPrice;
        this.unit = data.unit;
        this.quantity = data.quantity;
        this.builderCost = data.builderCost;
        this.markupType = data.markupType;
        this.markupPercent = data.markupPercent;
        this.markupPerUnit = data.markupPerUnit;
        this.markupAmount = data.markupAmount;
        this.ownerPrice = data.ownerPrice;
    }

    proposalFormatItemId: number;
    parentId?: number;
    displayOrder: number;

    title: string;
    costCodeTitle: string;
    description: string;
    costTypes: CostTypes[];
    markedAs: MarkedAs;
    unitCost: number;
    unitPrice: number;
    unit: string;
    quantity: number;
    builderCost: number;
    markupType: MarkupType;
    markupPercent: number;
    markupPerUnit: number;
    markupAmount: number;
    ownerPrice: number;
}
