import { useContext } from "react";

import { BTAdminPermissions } from "helpers/globalContext/btAdmin/BTAdminContext.types";
import { BTAdminUserContext } from "helpers/globalContext/btAdmin/BTAdminUserInfoProvider";

export function useBTAdminPermission(permission: BTAdminPermissions | BTAdminPermissions[]) {
    const btAdminContext = useContext(BTAdminUserContext);

    if (!btAdminContext) {
        return false;
    }

    const repPerms = btAdminContext.rep.btAdminPerms;

    let areAllPermissionsTrue: boolean;
    if (Array.isArray(permission)) {
        areAllPermissionsTrue = permission.every((permission) =>
            HasBTadminPermission(repPerms, permission)
        );
    } else {
        areAllPermissionsTrue = HasBTadminPermission(repPerms, permission);
    }

    return areAllPermissionsTrue;
}

export function HasBTadminPermission(permissions: number, permission: BTAdminPermissions): boolean {
    if (permission === BTAdminPermissions.None) {
        return true;
    }

    const bitAndValueFromPermissionEnum = 1 << (permission - 1);

    return !!(permissions & bitAndValueFromPermissionEnum);
}

export function GetPermissionValue(permission: BTAdminPermissions): number {
    if (permission === BTAdminPermissions.None) {
        return 0;
    }

    return 1 << (permission - 1);
}
