import { Skeleton } from "antd";

import "./NotificationCardSkeleton.less";

function NotificationCardSkeleton({ quantity }: { quantity: number }) {
    return (
        <div className="NotificationCardSkeleton">
            {Array.from({ length: quantity }).map((_, index) => (
                <div className="NotificationCardSkeleton__Item" key={index}>
                    <Skeleton.Avatar active size="small" shape="circle" />
                    <div className="NotificationCardSkeleton__Content">
                        <Skeleton
                            active
                            title={false}
                            paragraph={{
                                rows: 2,
                                width: ["50%", "90%"],
                            }}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NotificationCardSkeleton;
