import { Badge } from "antd";
import classNames from "classnames";

import "./BTBadge.less";

interface IBTBadgeProps {
    statusType?: StatusTypes;
    overflowCount?: number;
    title?: string;
    /** Number to show in badge */
    count?: React.ReactNode;
    /** Whether to show red dot without number */
    dot?: boolean;
    className?: string;
    offset?: [number | string, number | string];
    children?: React.ReactNode;
}

export type StatusTypes = "info" | "alert";

export function BTBadge({
    children,
    className,
    count,
    offset,
    statusType = "alert",
    overflowCount,
    title,
    dot,
}: IBTBadgeProps) {
    return (
        // eslint-disable-next-line react/forbid-elements
        <Badge
            offset={offset}
            count={count}
            className={classNames("BTBadge", className, statusType)}
            overflowCount={overflowCount}
            title={title}
            dot={dot}
        >
            {children}
        </Badge>
    );
}
