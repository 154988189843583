import classNames from "classnames";
import update from "immutability-helper";
import { LineItemType } from "legacyComponents/LineItemContainer.types";

import { BTLocalStorage } from "types/btStorage";

import { isNullOrUndefined } from "utilities/object/object";

import { RelatedItemType } from "commonComponents/entity/relatedItem/RelatedItem.types";
import { GridLineItemColumnType } from "commonComponents/utilities/LineItemContainer/types/LineItem.enums";
import {
    IGridLineItemActionRow,
    ILineItemColumn,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.interfaces";
import {
    GroupingType,
    isLineItemRow,
} from "commonComponents/utilities/LineItemContainer/types/LineItem.types";
import { WorksheetLineItemContainerRow } from "commonComponents/utilities/ProposalBaseLineItemContainer/ProposalBaseLineItemContainer.types";

import {
    FormatItem,
    FormatItemList,
    IFormatItemPathIndices,
    InScopeOptionModes,
    isFormatItemListGrouped,
    isProposalCategory,
    LineItemsToGet,
    OptionMode,
    ProposalCategory,
    ProposalSubCategory,
    UnassignedCostGroupId,
    WorksheetLineItem,
} from "entity/estimate/common/estimate.common.types";
import { ProposalGroupForDisplay } from "entity/estimate/common/FormattedProposal/FormattedProposal.types";
import { unassignedGroupId } from "entity/estimate/common/LineItemQuickFind/LineItemQuickFind.types";
import { IProposalFormatData } from "entity/estimate/common/ProposalFormat/ProposalFormat.types";
import { NoTaxId } from "entity/tax/TaxRate/TaxRate.api.types";

export function getFormatLineItemsFromFormatItemList(
    formatItemList: readonly WorksheetLineItemContainerRow[]
): WorksheetLineItem[] {
    let resultList: WorksheetLineItem[] = [];
    for (const formatItem of formatItemList) {
        if (isProposalLineItemGroupRow(formatItem)) {
            // handle parent group
            for (const subFormatItem of formatItem.items) {
                if (isProposalLineItemGroupRow(subFormatItem)) {
                    // handle subgroup
                    resultList.push(...subFormatItem.items);
                } else {
                    resultList.push(subFormatItem);
                }
            }
        } else if (isProposalLineItemRow(formatItem)) {
            resultList.push(formatItem);
        }
    }
    return resultList;
}

export function getFilteredFormatData(
    formatItemList: FormatItemList,
    lineItemsToGet: LineItemsToGet,
    favoritedIds?: number[]
): FormatItemList {
    if (lineItemsToGet === LineItemsToGet.All) {
        return formatItemList;
    }

    switch (lineItemsToGet) {
        case LineItemsToGet.InScope:
            return formatItemList.filter(
                (item) =>
                    !isProposalLineItemGroupRow(item) ||
                    InScopeOptionModes.includes(item.optionMode)
            );
        case LineItemsToGet.OutOfScope:
            return formatItemList.filter(
                (item) =>
                    isProposalLineItemGroupRow(item) &&
                    !InScopeOptionModes.includes(item.optionMode)
            );
        case LineItemsToGet.OnlyRequired:
            return formatItemList.filter(
                (item) =>
                    !isProposalLineItemGroupRow(item) || item.optionMode === OptionMode.Required
            );
        case LineItemsToGet.OnlyPending:
            return formatItemList.filter(
                (item) => isProposalLineItemGroupRow(item) && item.optionMode === OptionMode.Pending
            );
        case LineItemsToGet.OnlyApproved:
            return formatItemList.filter(
                (item) =>
                    isProposalLineItemGroupRow(item) && item.optionMode === OptionMode.Approved
            );
        case LineItemsToGet.OnlyDeclined:
            return formatItemList.filter(
                (item) =>
                    isProposalLineItemGroupRow(item) && item.optionMode === OptionMode.Declined
            );
        case LineItemsToGet.OnlyFavorited:
            if (!favoritedIds || favoritedIds.length === 0) {
                return [];
            }
            return formatItemList.filter(
                (item) =>
                    isProposalLineItemGroupRow(item) &&
                    item.optionMode === OptionMode.Pending &&
                    favoritedIds.includes(item.id)
            );
        case LineItemsToGet.OnlyDecided:
            return formatItemList.filter(
                (item) =>
                    isProposalLineItemGroupRow(item) &&
                    (item.optionMode === OptionMode.Approved ||
                        item.optionMode === OptionMode.Declined)
            );
    }
}

export function getFormatGroupsFromFormatItems(
    formatItemList: WorksheetLineItemContainerRow[],
    includeSubGroups: boolean
): ProposalCategory[] {
    const formatGroups: ProposalCategory[] = [];

    for (const item of formatItemList) {
        if (isProposalLineItemGroupRow(item)) {
            if (includeSubGroups) {
                formatGroups.push(item, ...getFormatGroupsFromFormatItems(item.items, true));
            } else {
                formatGroups.push(item);
            }
        }
    }

    return formatGroups;
}

export function flattenNestedFormatData<T extends WorksheetLineItemContainerRow>(
    formatItemList: T[]
): (T | ProposalSubCategory | WorksheetLineItem)[] {
    return formatItemList.flatMap<T | ProposalSubCategory | WorksheetLineItem>((item) => {
        return isProposalLineItemGroupRow(item)
            ? [
                  item,
                  ...item.items.flatMap((subItem) =>
                      isProposalLineItemGroupRow(subItem) ? [subItem, ...subItem.items] : [subItem]
                  ),
              ]
            : [item];
    });
}

export function findItemInEdit(
    data: WorksheetLineItemContainerRow[]
): WorksheetLineItemContainerRow | undefined {
    return flattenNestedFormatData(data).find(
        (item) => isNotProposalLineItemActionRow(item) && item._isInEdit
    );
}

export const childStylePlaceholderProperty: string = "childStylePlaceholder";

/**
 * @param row group row
 * @param collapsedIds list of group ids (stored in local storage) that are collapsed
 * @returns whether the row is in the list of collapsed groups
 */
export const isExpanded = (row: ProposalCategory | WorksheetLineItem, collapsedIds: number[]) => {
    if (isProposalLineItemGroupRow(row) && row.jobId) {
        if (flattenNestedFormatData([row]).some((item) => item.id !== row.id && item._isInEdit)) {
            return true;
        }
        return !collapsedIds.includes(
            row.id === unassignedGroupId ? getUniqueGroupIdForUnassigned(row.jobId) : row.id
        );
    } else {
        return row.isExpanded;
    }
};

export const isFavorited = (
    group: ProposalGroupForDisplay,
    jobId: number,
    favoritedIds?: number[]
) => {
    return !!favoritedIds?.includes(
        group.proposalFormatItemId === unassignedGroupId
            ? getUniqueGroupIdForUnassigned(jobId)
            : group.proposalFormatItemId
    );
};

/**
 * @returns the unique id that identifies that row across leads/jobs
 *
 * ⚠ Warning, we already use negative ids for newly created groups and line items and shouldn't be
 * reusing them here. Realistically this should never collide or cause a significant problem even
 * if it does, so we will leave it as is for now.
 */
export const getUniqueGroupIdForUnassigned = (jobId?: number, leadId?: number) => {
    // If the row is the unassigned row (id === -1),
    // then use the negative lead/job id so that the id is unique across leads/jobs.
    if (leadId) {
        return -leadId;
    } else if (jobId) {
        return -jobId;
    } else {
        // Default back to the -1 id if somehow neither of those fields are populated.
        return unassignedGroupId;
    }
};

export const getEstimateRowKey = (row: WorksheetLineItemContainerRow) => {
    if (isProposalLineItemActionRow(row)) {
        return `actionrow-${row.id}`;
    } else if (isProposalLineItemGroupRow(row)) {
        if (row.id === unassignedGroupId) {
            return `unassigned-${getUniqueGroupIdForUnassigned(row.jobId, row.leadId)}}`;
        }
        return `group-${row.id}`;
    } else {
        return `lineitem-${row.id}`;
    }
};

export function transformNestedFormatDataForDisplay<T extends WorksheetLineItemContainerRow>(
    formatItemList: T[]
): (T | ProposalSubCategory | WorksheetLineItem)[] {
    /** This method adds the childStylePlaceholder property so that antd will style these rows properly.
     * Without these array placeholder properties, antd thinks these elements don't have children
     * and modifies the first few columns on the table.
     */
    const collapsedIds = BTLocalStorage.get("bt-numberArray-proposalCollapsedGroups");
    return formatItemList.flatMap<T | ProposalSubCategory | WorksheetLineItem>((item) => {
        if (isProposalLineItemGroupRow(item) && isExpanded(item, collapsedIds)) {
            return [
                { ...item, [childStylePlaceholderProperty]: [] },
                ...item.items.flatMap((subItem) =>
                    isProposalLineItemGroupRow(subItem) && isExpanded(subItem, collapsedIds)
                        ? [{ ...subItem, [childStylePlaceholderProperty]: [] }, ...subItem.items]
                        : [{ ...subItem, [childStylePlaceholderProperty]: [] }]
                ),
            ];
        } else if (isProposalLineItemGroupRow(item)) {
            return [{ ...item, [childStylePlaceholderProperty]: [] }];
        } else {
            return [item];
        }
    });
}

export function getParentFormatItemOfFormatItem(
    row: WorksheetLineItemContainerRow,
    data: WorksheetLineItemContainerRow[]
): ProposalCategory | null {
    for (let dataItem of data) {
        if (isProposalLineItemGroupRow(dataItem)) {
            for (let subItem of dataItem.items) {
                if (subItem.id === row.id) {
                    return dataItem;
                }
                if (isProposalLineItemGroupRow(subItem)) {
                    for (let subGroupLineItem of subItem.items) {
                        if (subGroupLineItem.id === row.id) {
                            return subItem;
                        }
                    }
                }
            }
        }
    }
    return null;
}

/**
 * @param id id of the format item
 * @param categories proposal categories passed in
 * @returns matching item if found, otherwise null
 */
export function getFormatItemInCategoriesById(
    id: string | number,
    categories: WorksheetLineItemContainerRow[]
): FormatItem | null {
    for (let category of categories) {
        if (!isProposalLineItemGroupRow(category)) {
            continue;
        }

        if (category.id === id) {
            return category;
        }

        let item = getFormatItemInCategoryById(id, category);
        if (item) {
            return item;
        }
    }
    return null;
}

/**
 * @param id id of the format item
 * @param category proposal category
 * @returns matching item if found, otherwise null
 */
function getFormatItemInCategoryById(
    id: string | number,
    category: WorksheetLineItemContainerRow
): FormatItem | null {
    if (!isProposalLineItemGroupRow(category)) {
        return null;
    }

    for (let item of category.items) {
        if (item.id === id) {
            return item;
        }

        let subItem = getFormatItemInSubCategoryById(id, item);
        if (subItem) {
            return subItem;
        }
    }
    return null;
}

/**
 * @param id id of the format item
 * @param subCategory sub proposal category
 * @returns matching item if found, otherwise null
 */
function getFormatItemInSubCategoryById(
    id: string | number,
    subCategory: WorksheetLineItemContainerRow
): FormatItem | null {
    if (!isProposalLineItemGroupRow(subCategory)) {
        return null;
    }

    return getFormatItemFromListById(id, subCategory.items) ?? null;
}

/**
 * @param id id of the format item
 * @param items format items
 * @returns matching item if found, otherwise null
 */
function getFormatItemFromListById(id: string | number, items: FormatItem[]): FormatItem | null {
    for (let item of items) {
        if (item.id === id) {
            return item;
        }
    }
    return null;
}

export function selectEverythingUnderParentCategory(
    row: WorksheetLineItemContainerRow,
    data: WorksheetLineItemContainerRow[],
    selected: boolean,
    decidedOptionItems: Record<number, OptionMode>,
    getDataItemFieldPath: (
        dataItem: WorksheetLineItemContainerRow,
        data: WorksheetLineItemContainerRow[]
    ) => string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
) {
    if (decidedOptionItems[Number(row.id)] === OptionMode.Declined) {
        return;
    }

    if (!isProposalLineItemGroupRow(row)) {
        const path = getDataItemFieldPath(row, data);
        const updatedRow = { ...row, isSelected: selected };
        setFieldValue(path, updatedRow, false);
        return;
    }

    if (!isProposalCategory(row)) {
        return;
    }

    const updatedCategory = {
        ...row,
        isSelected: selected,
        items: row.items.map((rowItem) => {
            if (isProposalSubCategory(rowItem)) {
                return {
                    ...rowItem,
                    isSelected: selected,
                    items: rowItem.items.map((x) => {
                        return { ...x, isSelected: selected };
                    }),
                };
            }
            return { ...rowItem, isSelected: selected };
        }),
    };

    const categoryPath = getDataItemFieldPath(row, data);
    setFieldValue(categoryPath, updatedCategory, false);
}

export function selectEverythingInFormatItemList(
    data: WorksheetLineItemContainerRow[],
    selected: boolean
) {
    const newSelectedList = [];

    for (let dataItem of data) {
        if (isProposalLineItemGroupRow(dataItem)) {
            const newDataItem = {
                ...dataItem,
                isSelected: selected,
                items: dataItem.items.map((rowItem) => {
                    if (isProposalSubCategory(rowItem)) {
                        return {
                            ...rowItem,
                            isSelected: selected,
                            items: rowItem.items.map((x) => {
                                return { ...x, isSelected: selected };
                            }),
                        };
                    }
                    return { ...rowItem, isSelected: selected };
                }),
            };
            newSelectedList.push(newDataItem);
        } else {
            const updatedRow = { ...dataItem, isSelected: selected };
            newSelectedList.push(updatedRow);
        }
    }

    return newSelectedList;
}

export function isProposalLineItemRow(
    item: WorksheetLineItemContainerRow
): item is WorksheetLineItem {
    return (item as WorksheetLineItem).costCodeId !== undefined;
}

export function isProposalLineItemGroupRow(
    item: WorksheetLineItemContainerRow
): item is ProposalCategory {
    return (item as ProposalCategory).items !== undefined;
}

export function isProposalLineItemActionRow(
    item: WorksheetLineItemContainerRow
): item is IGridLineItemActionRow {
    return (item as IGridLineItemActionRow).isActionRow;
}

export function isNotProposalLineItemActionRow(
    item: WorksheetLineItemContainerRow
): item is Exclude<WorksheetLineItemContainerRow, IGridLineItemActionRow> {
    return !isProposalLineItemActionRow(item);
}

export const isProposalSubCategory = (
    formatItem: WorksheetLineItemContainerRow
): formatItem is ProposalSubCategory => {
    return (
        isNotProposalLineItemActionRow(formatItem) &&
        isProposalCategory(formatItem) &&
        !isNullOrUndefined(formatItem.parentId) &&
        formatItem.parentId !== UnassignedCostGroupId
    );
};

export function toRootCategory(subCategory: ProposalSubCategory): ProposalCategory {
    return {
        ...subCategory,
        name: "Category",
        parentId: undefined,
    };
}

/**
 * Throws away child groups. We currently only support one level of group nesting.
 */
export function toSubCategory(category: ProposalCategory, parentId: number): ProposalSubCategory {
    return {
        ...category,
        name: "Sub Category",
        parentId,
        items: category.items.filter(isLineItemRow),
    };
}

function findIndexOfMinBy<T>(list: T[], transform: (item: T) => number) {
    return list.reduce(
        (indexOfMinBy, currentItem, currentIndex) =>
            transform(currentItem) < transform(list[indexOfMinBy]) ? currentIndex : indexOfMinBy,
        0
    );
}

export function findMostRecentRowIndex(data: WorksheetLineItemContainerRow[]) {
    const newestAddedInEditIndex = data.findIndex(
        (row) => isNotProposalLineItemActionRow(row) && row._isInEdit
    );

    if (newestAddedInEditIndex >= 0) {
        return newestAddedInEditIndex;
    } else {
        return findIndexOfMinBy(data, (item) =>
            isNotProposalLineItemActionRow(item) ? item.id : Number.POSITIVE_INFINITY
        );
    }
}

export function filterOutLineItemsFromFormatItemList(
    formatData: FormatItemList,
    lineItemIds: number[],
    leaveEmptyCategories: boolean
): [ProposalCategory[], number[]] {
    const updatedFormatData: ProposalCategory[] = [];
    const filteredOutFormatItemGroupIds: number[] = [];

    for (const parentFormatItem of formatData) {
        if (!isProposalCategory(parentFormatItem)) {
            continue;
        }

        filterOutLineItems(
            parentFormatItem,
            new Set(lineItemIds),
            filteredOutFormatItemGroupIds,
            updatedFormatData,
            leaveEmptyCategories
        );
    }
    return [updatedFormatData, filteredOutFormatItemGroupIds];
}

/**
 * Figures out which line items rows and groups should be filtered out of PFIs as deleted after a set of line items are deleted.
 * @param formatItem - The format item to filter items from
 * @param setOfLineItemIdsToFilterOut - The IDs of recently deleted line items
 * @param filteredOutFormatItemGroupIds - OUTPUT - This list contains IDs of all format item group that should be removed
 * @param updatedProposalCategories - OUTPUT - This is an updated state value for proposal categories.
 * @param leaveEmptyCategories - If true, any empty categories will be left in the proposal format, which means filteredOutFormatItemGroupIds will be empty.
 * @returns Remaining sub items (line items or subgroups). This is primarily for recursive use of this function.
 */
function filterOutLineItems(
    formatItem: ProposalCategory,
    setOfLineItemIdsToFilterOut: Set<number>,
    filteredOutFormatItemGroupIds: number[],
    updatedProposalCategories: ProposalCategory[],
    leaveEmptyCategories: boolean = false
) {
    const subItems: (WorksheetLineItem | ProposalSubCategory)[] = [];
    for (let subItem of formatItem.items) {
        if (isProposalSubCategory(subItem)) {
            // recursively filter deleted line items on subgroup
            const internalSubItems = filterOutLineItems(
                subItem,
                setOfLineItemIdsToFilterOut,
                filteredOutFormatItemGroupIds,
                updatedProposalCategories,
                leaveEmptyCategories
            );
            // if subgroup still has line items, include it in parent
            if (internalSubItems.length > 0 || leaveEmptyCategories) {
                subItems.push(
                    update(subItem, {
                        items: {
                            $set: getFormatLineItemsFromFormatItemList(internalSubItems),
                        },
                    })
                );
            }
        } else if (!setOfLineItemIdsToFilterOut.has(subItem.id)) {
            subItems.push(subItem);
        }
    }
    // Check that we actually deleted items
    const hasDeletedItems = formatItem.items.length !== subItems.length;
    if (subItems.length === 0 && hasDeletedItems && !leaveEmptyCategories) {
        // If no items remain in the parent format item, keep track of it so we can
        // let the server know it's deleted
        // The only exception is if the id of the group to delete is
        // <= UnassignedCostGroupId, as that means the group being deleted hasn't
        // even been saved yet.
        if (formatItem.id > UnassignedCostGroupId) {
            filteredOutFormatItemGroupIds.push(formatItem.id);
        }
        // Now don't bother adding the deleted format item to the updated array
    } else if (!isProposalSubCategory(formatItem)) {
        updatedProposalCategories.push(
            update(formatItem, {
                items: {
                    $set: subItems,
                },
            })
        );
    }
    return subItems;
}

export function isProposalLineItemRowInSubCategory(
    row: WorksheetLineItemContainerRow,
    data: WorksheetLineItemContainerRow[]
): boolean {
    if (!isProposalLineItemRow(row)) {
        return false;
    }

    const rowParentCategory = getParentFormatItemOfFormatItem(row, data);
    if (!rowParentCategory) {
        return false;
    }
    return isProposalSubCategory(rowParentCategory);
}

/**
 * Returns the path indices for the format item.
 */
export function getFormatItemPathIndices(
    itemId: number | string,
    rootCategories: ProposalCategory[]
): IFormatItemPathIndices | undefined {
    if (rootCategories.length === 0) {
        return;
    }

    for (const [rootCategoryIndex, rootCategory] of rootCategories.entries()) {
        // Check to see if the item is the root category
        if (itemId === rootCategories[rootCategoryIndex].id) {
            return { id: itemId, rootCategoryIndex };
        }

        if (rootCategory.items.length === 0) {
            continue;
        }

        // Check to see if the item is in this root category
        for (const [rootItemIndex, rootItem] of rootCategory.items.entries()) {
            if (rootItem.id === itemId) {
                return {
                    id: itemId,
                    rootCategoryIndex: rootCategoryIndex,
                    subCategoryIndex: isProposalSubCategory(rootItem) ? rootItemIndex : undefined,
                    lineItemIndex: isProposalLineItemRow(rootItem) ? rootItemIndex : undefined,
                };
            }
            // Subcategory check
            if (isProposalSubCategory(rootItem) && rootItem.items.length !== 0) {
                // Check to see if the line item is in any of the subcategories
                for (const [subItemIndex, subItem] of rootItem.items.entries()) {
                    // Line Item Check
                    if (subItem.id === itemId && isProposalLineItemRow(subItem)) {
                        return {
                            id: itemId,
                            rootCategoryIndex: rootCategoryIndex,
                            subCategoryIndex: rootItemIndex,
                            lineItemIndex: subItemIndex,
                        };
                    }
                }
            }
        }
    }
    // The Unassigned Category will have to be created
    if (itemId === UnassignedCostGroupId) {
        return { id: UnassignedCostGroupId, rootCategoryIndex: -1 };
    }

    return undefined;
}

export const canClickIntoWorksheetCell = (
    record: WorksheetLineItemContainerRow,
    colType: GridLineItemColumnType,
    groupingType?: GroupingType,
    isEditable: boolean = true
) => {
    if (colType === GridLineItemColumnType.action) {
        return false;
    }

    if (isProposalLineItemGroupRow(record)) {
        return groupingType !== GroupingType.None && isEditable;
    }

    const isNotActionOrTitleAndCostCode =
        colType !== GridLineItemColumnType.worksheetTitleAndCostCode &&
        colType !== GridLineItemColumnType.titleAndCostCode;

    return (
        isEditable &&
        isNotActionOrTitleAndCostCode &&
        isProposalLineItemRow(record) &&
        record.isEditable
    );
};

export const getClassNamesForProposalTableCell = (
    colIndex: number,
    record: WorksheetLineItemContainerRow,
    groupingType?: GroupingType
) => {
    if (isProposalLineItemActionRow(record)) {
        return "text-left";
    } else if (isProposalCategory(record)) {
        return classNames({
            "text-left": colIndex === 0,
            proposalFormatCategoryTitleCell: groupingType !== GroupingType.None && colIndex === 0,
            editing: record._isInEdit,
        });
    } else {
        return classNames({
            proposalFormatLineItemTitleCell: colIndex === 0,
            editing: record._isInEdit,
        });
    }
};

const getColSpanForProposalActionRowCell = (
    colIndex: number,
    columnsList: ILineItemColumn<unknown, unknown>[],
    isExpandable: boolean,
    hasSelectableRows: boolean
) => {
    if (colIndex !== 0) {
        return 0;
    }

    let colSpanLength = columnsList.length;

    // need to account for the expand and select columns ant adds
    if (isExpandable) {
        colSpanLength++;
    }
    if (hasSelectableRows) {
        colSpanLength++;
    }

    return colSpanLength;
};

const getColSpanForProposalGroupRowCell = (
    colIndex: number,
    columnsList: ILineItemColumn<unknown, unknown>[],
    groupingType: GroupingType
) => {
    // this shouldn't happen
    if (groupingType === GroupingType.None) {
        return 0;
    }

    const isTitleColumn = colIndex === 0;
    const isNextAfterTitleColumn = colIndex === 1;

    if (!isTitleColumn && !isNextAfterTitleColumn) {
        return 1;
    }

    // we will allow the title cell to span 2 columns if there is no content in the adjacent cell
    const nextColAfterTitle = columnsList[1];
    const colSpanForTitle =
        !nextColAfterTitle ||
        nextColAfterTitle.columnType === GridLineItemColumnType.action ||
        nextColAfterTitle.getGroupedValue
            ? 1
            : 2;

    if (isTitleColumn) {
        return colSpanForTitle;
    } else {
        // the next column needs to adjust for the title column to keep column count consistent
        return 2 - colSpanForTitle;
    }
};

interface IColSpanForTableCellParams {
    colIndex: number;
    columnsList: ILineItemColumn<unknown, unknown>[];
    record: WorksheetLineItemContainerRow;
    groupingType?: GroupingType;
    hasSelectableRows: boolean;
    isExpandable: boolean;
}

export const getColSpanForProposalTableCell = (params: IColSpanForTableCellParams) => {
    const {
        colIndex,
        columnsList,
        record,
        groupingType = GroupingType.GroupedLineItems,
        hasSelectableRows,
        isExpandable,
    } = params;

    if (isProposalLineItemActionRow(record)) {
        return getColSpanForProposalActionRowCell(
            colIndex,
            columnsList,
            isExpandable,
            hasSelectableRows
        );
    } else if (isProposalCategory(record)) {
        return getColSpanForProposalGroupRowCell(colIndex, columnsList, groupingType);
    } else {
        return 1;
    }
};

export const isRowTiedToBidPackage = (lineItem: WorksheetLineItem) => {
    return lineItem.relatedItems?.find((x) => x.type === RelatedItemType.BidPackage) !== undefined;
};

export const hasEditPerms = (
    proposalData: IProposalFormatData,
    row: WorksheetLineItem,
    canEditGeneralItems: boolean,
    canAddGeneralItems: boolean
) => {
    return (
        ((canEditGeneralItems && row.id > 0) || (canAddGeneralItems && row.id === 0)) &&
        (proposalData.isRevisedCosts || !proposalData.worksheetLocked)
    );
};

export const isRowEditable = (
    proposalData: IProposalFormatData,
    row: WorksheetLineItem,
    canEditGeneralItems: boolean,
    canAddGeneralItems: boolean
) => {
    const hasEditPerm = hasEditPerms(proposalData, row, canEditGeneralItems, canAddGeneralItems);
    return (
        row.lineItemType === LineItemType.EstimateLineItem &&
        !isRowTiedToBidPackage(row) &&
        isNullOrUndefined(row.takeoffLineItemId) &&
        hasEditPerm
    );
};

// Same as isRowEditable minus the bid package check
export const isUnitCostEditable = (
    proposalData: IProposalFormatData,
    row: WorksheetLineItem,
    canEditGeneralItems: boolean,
    canAddGeneralItems: boolean
) => {
    return (
        row.lineItemType === LineItemType.EstimateLineItem &&
        row.takeoffLineItemId == null &&
        hasEditPerms(proposalData, row, canEditGeneralItems, canAddGeneralItems)
    );
};

// Same as isRowEditable minus the takeoff check
export const isTaxEditable = (
    proposalData: IProposalFormatData,
    row: WorksheetLineItem,
    canEditGeneralItems: boolean,
    canAddGeneralItems: boolean,
    taxGroupId: number
) => {
    return (
        row.lineItemType === LineItemType.EstimateLineItem &&
        !isRowTiedToBidPackage(row) &&
        hasEditPerms(proposalData, row, canEditGeneralItems, canAddGeneralItems) &&
        taxGroupId !== NoTaxId
    );
};

export const isMarkupOrMarginEditable = (
    lineItem: WorksheetLineItem,
    decidedOptionItems: Record<number, OptionMode>
) => {
    return !(
        typeof lineItem.takeoffLineItemId === "number" ||
        [LineItemType.Allowance, LineItemType.SelectionChoice].includes(lineItem.lineItemType) ||
        decidedOptionItems[lineItem.id] === OptionMode.Declined ||
        decidedOptionItems[lineItem.id] === OptionMode.Approved
    );
};

export const isLineItemEditable = (
    lineItem: WorksheetLineItem,
    canEditGeneralItems: boolean,
    canAddGeneralItems: boolean,
    isWorksheetLocked: boolean
) => {
    return (
        ((canEditGeneralItems && lineItem.id > 0) || (canAddGeneralItems && lineItem.id === 0)) &&
        !isWorksheetLocked
    );
};

export const getSelectedItemsFromFormatItems = (formatItems: FormatItemList) => {
    return isFormatItemListGrouped(formatItems)
        ? getFormatLineItemsFromFormatItemList(formatItems).filter((li) => li.isSelected)
        : formatItems.filter((li) => li.isSelected);
};

export const getUpdatedRowData = (
    data: WorksheetLineItemContainerRow[],
    rows: WorksheetLineItemContainerRow[]
): WorksheetLineItemContainerRow[] => {
    if (rows.length === 0) {
        return data;
    }
    const rowsMap: Map<string | number, WorksheetLineItemContainerRow> = new Map(
        rows.map((row) => [row.id, row])
    );
    return data.map((item) => {
        if (rowsMap.has(item.id)) {
            return rowsMap.get(item.id) as WorksheetLineItemContainerRow;
        }
        if (isProposalLineItemGroupRow(item)) {
            return getUpdatedCategory(item, rowsMap) as WorksheetLineItemContainerRow;
        }
        return item;
    });
};

const getUpdatedCategory = (
    category: ProposalCategory,
    rowsMap: Map<string | number, WorksheetLineItemContainerRow>
) => {
    return {
        ...category,
        items: category.items.map((item) => {
            if (rowsMap.has(item.id)) {
                return rowsMap.get(item.id) as WorksheetLineItem | ProposalSubCategory;
            }
            if (isProposalSubCategory(item)) {
                return getUpdatedSubCategory(item, rowsMap);
            }
            return item;
        }),
    };
};

const getUpdatedSubCategory = (
    subCategory: ProposalSubCategory,
    rowsMap: Map<string | number, WorksheetLineItemContainerRow>
) => {
    return {
        ...subCategory,
        items: subCategory.items.map((subItem) => {
            if (rowsMap.has(subItem.id)) {
                return rowsMap.get(subItem.id) as WorksheetLineItem;
            }
            return subItem;
        }),
    };
};
