import mixpanelPlugin from "@analytics/mixpanel";
// eslint-disable-next-line no-restricted-imports
import Analytics from "analytics";
import { isEqual } from "lodash-es";
// eslint-disable-next-line no-restricted-imports
import {
    useTracking as _useTracking,
    Options,
    Track,
    TrackingHook,
    TrackingProp,
} from "react-tracking";

import { BdsAnalyticsEvent, BdsTrack, IBdsTrackingData } from "@buildertrend/components";

import { UserInfo } from "helpers/AppProvider.types";

import { BTLocalStorage, BTSessionStorage } from "types/btStorage";
import { ImpersonationTypes } from "types/enum";

import { AnalyticsBuilderTraits, AnalyticsTraits } from "utilities/analytics/analytics.api.types";
import { translateRouteParams } from "utilities/analytics/analyticsUtils";
import { qualtricsPlugin } from "utilities/analytics/customPlugins/qualtricsPlugin";
import { sawmillPlugin } from "utilities/analytics/customPlugins/sawmillPlugin";
import { getMixpanelOverrides } from "utilities/analytics/mixpanelOverrides";
import { getAppDefaultFlagValue } from "utilities/appDefaultFlags";
import { isNullOrUndefined } from "utilities/object/object";
import { getCurrentPortalType, PortalType } from "utilities/portal/portal";

export const allowedAnalyticsEvents = Object.keys(BdsAnalyticsEvent);
export type AnalyticsEvent = keyof typeof BdsAnalyticsEvent;

export interface ITrackingData extends Omit<Partial<IBdsTrackingData>, "event"> {
    jobId: number | undefined;
    builderId: number | undefined;
    page: string | undefined;
    modal: string | undefined;
    panel: string | undefined;
    tab: string | undefined;
    component: string | undefined;
    element: string | undefined;
    uniqueId: string | undefined;
    event: AnalyticsEvent | undefined;
    value: string | number | undefined;
    extraInfo: any | undefined;
    // On SPA, this can be captured by just the page view events, but until then, we'll manually
    // track the route that the page is on to account for the use of memory routers.
    route: string | undefined;
    routeParams: any | undefined;
    multitab: boolean | undefined;
    userType: number | undefined;
    impersonation: number | undefined;
    /**
     * DO NOT SET THIS VALUE. THIS SHOULD ONLY BE USED BY AppProvider.
     */
    pageView: boolean | undefined;
}

export interface ITrackingProp {
    tracking?: TrackingProp<ITrackingData>;
}

function getAnalytics() {
    const plugins = getPlugins();

    return Analytics({
        app: "Web App",
        debug: import.meta.env.MODE === "development",
        plugins,
    });
}

function getPlugins() {
    const plugins: Record<string, unknown>[] | undefined = [];
    const disableAnalytics = BTLocalStorage.get("bt-boolean-disableAllAnalytics");

    if (disableAnalytics) {
        return plugins;
    }

    // Mixpanel
    const mixpanelAnalyticsToken = getAppDefaultFlagValue("mixpanelAnalyticsProjectId", null);
    if (mixpanelAnalyticsToken && getAppDefaultFlagValue("enableMixpanel", false)) {
        let mixpanelUrl = "";
        try {
            mixpanelUrl = getAppDefaultFlagValue("mixpanelUrl", "");
        } catch (e) {}
        let options = null;
        if (mixpanelUrl) {
            options = {
                api_host: mixpanelUrl,
            };
        }
        const plugin = mixpanelPlugin({
            token: mixpanelAnalyticsToken,
            pageEvent: "PageView",
            options: options,
        });
        plugins.push({
            ...plugin,
            ...getMixpanelOverrides(plugin),
        });
    }

    // Qualtrics
    // eslint-disable-next-line no-restricted-syntax
    if ((window as any).QSI) {
        plugins.push({
            ...qualtricsPlugin({}),
        });
    }

    // Sawmill
    const sawmillTenantId = getAppDefaultFlagValue("sawmillFrontendTenantId", null);
    const sawmillIngestionEndpoint = getAppDefaultFlagValue("sawmillIngestionEndpoint", null);
    const sawmillBatchThreshold = getAppDefaultFlagValue("sawmillFrontendBatchThreshold", 1);
    const sawmillFrontendMaxCachedEvents = getAppDefaultFlagValue(
        "sawmillFrontendMaxCachedEvents",
        999
    );
    const sawmillFrontendMaxPayloadSizeBytes = getAppDefaultFlagValue(
        "sawmillFrontendMaxPayloadSizeBytes",
        500_000
    );
    if (
        !isNullOrUndefined(sawmillTenantId) &&
        !isNullOrUndefined(sawmillIngestionEndpoint) &&
        getAppDefaultFlagValue("enableSawmillFrontend", false)
    ) {
        plugins.push(
            sawmillPlugin({
                tenantId: sawmillTenantId,
                ingestionEndpoint: sawmillIngestionEndpoint,
                batchThreshold: sawmillBatchThreshold,
                maxCachedEvents: sawmillFrontendMaxCachedEvents,
                maxPayloadSizeBytes: sawmillFrontendMaxPayloadSizeBytes,
            })
        );
    }

    return plugins;
}

const analytics = getAnalytics();

export function identify(globalUserId: number, traits: AnalyticsTraits) {
    void analytics.identify(globalUserId.toString(), traits);
}

export function resetAnonymousUserIdentity() {
    const userId = analytics.user("userId");
    if (userId !== null && userId === 0) {
        void analytics.reset();
    }
}

export function shouldIdentifyUser(globalUserId: number | null): boolean {
    const userId = analytics.user("userId");
    if (userId == null) {
        return true;
    }
    return globalUserId !== null && globalUserId !== userId;
}

export const track: Track<ITrackingData> = BdsTrack as Track<ITrackingData>;
export const useTracking = (
    trackingData?: Partial<ITrackingData>,
    options?: Partial<Options<ITrackingData>>
): TrackingHook<ITrackingData> => _useTracking(trackingData, options);

export function dispatchTracking(data: Partial<ITrackingData>) {
    const { event, routeParams, ...trackingData } = data;

    const traits = analytics?.getState?.().user?.traits;
    const builderTraits: AnalyticsBuilderTraits = traits?.builderTraits;
    const userTraits: UserInfo = traits?.userTraits;

    const sessionStorageJobPickerState = BTSessionStorage.get("bt-object-dangerousJobPickerState");
    const localStorageJobPickerState = BTLocalStorage.get("bt-object-dangerousJobPickerState");

    const ssSelectedJobIds = sessionStorageJobPickerState?.selectedJobIds;
    const lsSelectedJobIds = localStorageJobPickerState?.selectedJobIds;

    let multitab = false;
    if (ssSelectedJobIds && lsSelectedJobIds && !isEqual(ssSelectedJobIds, lsSelectedJobIds)) {
        multitab = true;
    }

    let impersonationType = ImpersonationTypes.None;
    if (userTraits?.isImpersonatingBuilder) {
        impersonationType = ImpersonationTypes.BTAdmin;
    } else if (userTraits?.isBuilderImpersonatingOwner) {
        impersonationType = ImpersonationTypes.BuilderImpersonatingOwner;
    }

    const payload = {
        // Flatten any route params, typically includes id and jobId
        ...translateRouteParams(routeParams),
        // Populate builderId in case it wasn't provided in the supplied event data.
        builderId: builderTraits?.builderId,
        // And spread the original tracking data. This may override properties from routeParams
        // and the builderId, but we'll assume the trackingData is the most correct if defined
        multitab: multitab,
        portalType: PortalType[getCurrentPortalType()],
        impersonationType: ImpersonationTypes[impersonationType],
        ...trackingData,
    };

    if (event === "PageView") {
        void analytics.page(payload as Partial<ITrackingData>);
    } else {
        void analytics.track((event ?? "Undefined") as string, payload);
    }
}

export function processTracking(data: Partial<ITrackingData>): Partial<ITrackingData> | null {
    return data.pageView ? { event: "PageView" } : null;
}
